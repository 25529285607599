export const emtrConstant = {
  rulesConstant: {
    rulesHeader: 'Rules',
    addNewRule: 'Add New Rule',
    editRuleName: 'Edit Rule',
    viewRuleName: 'View Rule',
    ruleName: 'Rule Name',
    characterLimit: 'Char. Limit:',
    ruleType: 'Rule Type',
    individualSecurity: 'Individual Security',
    underlyingOptions: 'Underlying Options',
    securityType: 'Security Type',
    advancedSearchTxt: 'Advanced Search',
    appliesTo: 'Applies To',
    selectDates: 'Select Date(s)',
    descriptionNotesTitle: 'Description and Notes',
    ruleAssign: 'Rule Assigned to',
    fromToDateTimeCheck: (30 * 60 * 1000),
    pleaseCorrectFollowingError: 'Please correct following error.',
    securityModalMaxLimitError: 'The maximum number of results has been Reached:',
    ruleHistory: 'Rule History',
    maxSecurityCount: 2000,
    ruleNameLbl: 'Rule Name',
    subTypeLbl: 'Sub Type',
    startDate: 'Start Date',
    endDate: 'End Date',
    expiredCapsTxt: 'EXPIRED',
    editCapsTxt: 'EDIT',
    ruleActiveStatus: 'ACTIVE',
    ruleDraftStatus: 'DRAFT',
    globalRuleLbl: 'Global Rule',
    changeRequestId: 'Change Request ID',
    globalObj: { bu: 'RBS', orgIds: [], type: 'GLOBAL'},
    semiGlobalObj: { bu: 'RBS', orgIds: [], type: 'SEMI_GLOBAL'},
    autoComplete: {
      placeholder: 'Search Securities',
      fundFamilySearchPlaceHolder: 'Search Fund Families',
      noRecords: 'No Records Found',
      FNDISUTxt: 'FNDFMLY',
      RESTRICT_LST_ENGTxt: 'RESTRICT_LST_ENG',
      duplicateRecord: 'Security is already added into the list',
      searchAndSelectSecurityTo: 'Search and Select Securities to',
      searchAndSelectSecurity: 'Search and Select Securities',
      selectFundFamiliesToRestrict: 'Select Fund Families to',
      fundFamilesRestrictTxt: 'Select Fund Families to Restrict',
      fundFamilesOverrideTxt: 'Select Fund Families to Override',
      securityInstrumentGrpRestrictText: 'Select Securities/Instrument Groups to Restrict',
      selectedSecOrInstGrpRestrictText: 'Selected Securities/Instrument Groups to Restrict',
      selectFundFamiliesToRestrictTxt: 'Selected Fund Families to Restrict',
      searchFundFamiliesToRestrict: 'Search and Select Fund Families to',
      noSecuritiesSelectedTo: 'No Securities Selected. Please Search and Select Securities to',
      noSecuritiesSelected: 'No Securities Selected. Please Search and Select Securities',
      noFundFamiliesSelected: 'No Fund Families Selected. Please Search and Select Fund Families to',
      securitySelected: 'Security selected:',
      fundFamilySelected: 'Fund Family Selected',
      instrumentGroupPlaceholder: 'Search Instrument Groups',
      instrumentGroupToRestrict: 'Search and Select Instrument Groups to Restrict',
      instrumentGroupToOverride: 'Search and Select Instrument Groups to Override',
      noInstrumentGroupsSelected: 'No Instrument Groups Selected. Please Search and Select Instrument Groups',
      instrumentGroupDuplicateRecord: 'Instrument Group is already added into the list',
      noSecuritySelected: 'No Securities Selected.',
      noInstrumentGroupSelected: 'No Instrument Groups Selected.',
      downloadTempValue: 'SEC',
      securityTempFileName: 'EMTR_Security_Bulk_Upload_Template',
      invalidFileFormatError: 'File Type must be .CSV! Please select the correct format and try again.',
      uploadFailedError: 'File failed to upload. Please check the Specifications tab of the template, make necessary changes, and upload again.',
      uploadSuccessMsg: 'File uploaded successfully. Available securities are shown in list below.',
      uploadMaxCountError: 'Only 300 securities can be uploaded at a time.',
      cusipText: 'cusip',
      tickerText: 'ticker',
      tickerSymbolText: 'ticker symbol',
      searchSecurityMustBeSelected: 'Security Type must be selected',
      exactTicker: 'Exact Ticker',
      exactMatchSymbol: 'SYMBOL_EXACTMATCH',
      exactMatchCusip: 'CUSIP_EXACTMATCH'

    },
    ruleNameMsg: {
      the: 'The ',
      ruleNameExistWarningMsg: ' Rule Name already exist. Please enter a unique name or click the Continue button to proceed with a duplicate Rule Name.',
      invalidCharacter: 'Invalid Character Entered - Please Use Valid Characters Only A-Z, a-z, 0-9, +,-,_,(,),[,], . (period) and , (comma).',
    },
    ruleGlobalConst: {
      recurrence: 'Recurrence',
      override: 'Override',
      restriction: 'Restriction',
      watchList: 'Watchlist',
      fundFamily: 'Fund Family',
      delete: 'Delete',
      restore: 'Restore',
      cancel: 'Cancel',
      close: 'Close',
      hideAcceptButton: 'hideAcceptButton',
      deleteSuccessMsg: 'rule has been deleted',
      restoreSuccessMsg: 'rule has been restored.<br><p></p><strong>Note:</strong> Review the rule to ensure necessary assignments have been made.',
      bulkUploadTitle: 'Bulk Upload',
      bulkUploadRules: 'Bulk Upload - Rules',
      noCientSelected: 'Please Select a Client to Continue.',
      justification: 'Justification for Override  <span class="sdps-text-market-red">*</span>',
      copySuffixTxt: ' - Copy',
      viewSymboleInstGrpText: 'View Symbols'
    },
    rulesLanding: {
      copy: 'Copy',
      history: 'History',
      delete: 'Delete',
      cancel: 'Cancel',
      deleteMsgConfirmation: 'Are you sure you want to delete?',
      deleteNotAssignedMsgConfirmation: 'is not currently assigned to any Restrictions.',
      instrumentGrpDeleteMsg: 'must be removed from the following assignment(s) before it can be deleted',
    },
    ruleMessages: {
      ruleWaSuccessfullySavedAsDraft: ' rule was successfully saved as draft. ',
      continueByAssigningRule: 'Continue by assigning rule to All Employees, Employee Groups, Employee ID or Employee Account #',
      continueByAssigningRuleToMasterAcc: 'Continue by assigning rule to Master Groups and/or Master Accounts.',
      ruleWasSuccessfullySavedAndActivated: ' rule was successfully saved and activated',
      ruleChangesHaveBeenSavedAndUpdated: 'Rule Changes have been saved and updated',
      ruleWasSuccessfullySavedAndHasBeenScheduledToStart: ' rule was successfully saved and has been scheduled to start ',
      ruleWasExpired: 'rule has Expired',
      ruleWasSuccessfullySaved: ' rule was successfully saved.',
    },
    ruleTypeLabels: {
      ruleTypeTitle: 'Buys/Sells/Short Sells <span class="sdps-text-market-red">*</span>',
      ruleTypeFundFamilyTitle: 'Buys/Sells <span class="sdps-text-market-red">*</span>',
      WATCH_LST_ENG: 'Watch',
      sells: 'Sells',
      shortSells: 'Short Sells',
      buys: 'Buys',
      RESTRICT_LST_ENG: 'Restrict',
      Override: 'Allow',
      Override_Fund: 'Override',
      security_WATCH_LST_ENG: 'Select Securities to Watchlist',
      security_RESTRICT_LST_ENG: 'Select Securities to Restrict',
      security_Override: 'Select Securities to Override',
      securityInstrumentGrpOverrideText: 'Select Securities/Instrument Groups to Override',
      selectedSecOrInstGrpOverrideText: 'Selected Securities/Instrument Groups to Override',
      search_Securities: 'Search and',
      selectSecurity: 'Select Securities to ',
      selectedSecurity: 'Selected Securities to ',
      ruleAttributes: 'Rule Attributes',
      restrictBuysSells: 'Restrict Buys/Sells',
      selectSecuritiesWarning: 'No Security Types selected yet',
      goToTop: 'Go to top',
      shortSellInfo: 'Short Sell Securities Types ONLY apply to: ALL Level 1 Equity Security (EQ), Level 2 Exchange Trade Fund and Product (ETF and ETFP), and Level 2 Options (OPT).',
      securityInfoText: 'The maximum number of results has been reached ',
      instrumentGroups_WATCH_LST_ENG: 'Select Instrument Groups to Watchlist',
      instrumentGroups_RESTRICT_LST_ENG: 'Select Instrument Groups to Restrict',
      instrumentGroups_Override: 'Select Instrument Groups to Override',
      WATCH_LST_ENGText : 'WATCH_LST_ENG',
    },
    assetType: {
      bond: 'BOND',
      other: 'OTH'
    },
    timePicker: ['1:00', '1:30', '2:00', '2:30', '3:00', '3:30', '4:00', '4:30', '5:00', '5:30', '6:00', '6:30', '7:00', '7:30', '8:00', '8:30', '9:00', '9:30', '10:00', '10:30', '11:00', '11:30', '12:00', '12:30'],
    dateTimePicker: {
      endDateError: 'To Date should be greater than From Date',
      endtimeError: 'End Time should be 30min ahead of From Time',
      validTimeError: 'Enter a valid time',
      dateRangeError: 'Date range should be between one year.'
    },
    editRule: {
      copyRulename: ' - Copy',
      overrideJustification: {
        justificaton10b5: '10b5-1',
        legalClearance: 'Legal Clearance',
        taxCOver: 'Tax Cover',
        marginSellout: 'Margin Sellout',
        other: 'Other'
      },
      editStep1ReviewRule: {
        securities: 'Securities',
        securitiesRestricted: ' Restricted',
        securitiesAllowed: ' Allowed',
        securitiesWatched: ' Watched'
      },
    },
    confirmationMessage: {
      cancelBtnClickMsg: 'Are you sure you want to Cancel? This will cancel your session and unsaved changes will be lost.',
      menuClickMsg: 'You are about to leave this page and unsaved changes will not be saved. Are you sure you want to leave and Cancel?',
      previousBtnClickMsg: 'Do you want to save your current changes?'
    },
    reviewRuleMsg: {
      activateWarmingMsg: 'Must Activate the rule in order to take the new rule into effect',
      ruleIsCurrently: ' rule is currently ',
      anyUpdatesMustBeSaved: '. Any updates must be saved.',
      expiredRuleWarningMsg: '. No changes are allowed on expired rules.',
      missingFieldWarningMsg: 'The rule is missing required fields. Return to previous steps and fill out the required fields to proceed.'
    }
  },
  ruleSteps: {
    step2: {
      step2Restriction: 'Restriction Rule',
      step2Override: 'Override Rule',
      step2Recurrence: 'Set Recurrence',
      mutualFunds: 'Mutual Funds',
      mutualFundsSscLevel: {name: 'Open-End Mutual Fund', code: 'OEMF', sscLevel: 'SC2'},
      operatingNetExpenseRatio: 'Operating / Gross Expense Ratio',
      optionsAG: 'Options',
      changeRequestIdInvalidMessage: 'Please Use Valid Characters Only A-Z, a-z, 0-9, - and space.'
    },
    step3: {
      clientsTab: 'Master Accounts',
      masterGrpTab: 'Master Groups',
      k4Text: 'K4',
      allClientInfo: {
        clientName: 'Business Unit',
        k4ClientName: 'Business Unit',
        orgId: 1,
        clientMasterAccounts: []
      }
    },
    step4: {
      reviewRule: 'Review Rule',
      ruleTypeTooltip: 'Rule Type and Sub Type fields are non-editable.',
      overrideJustification: {
        justificaton10b5: '10b5-1',
        legalClearance: 'Legal Clearance',
        taxCOver: 'Tax Cover',
        marginSellout: 'Margin Sellout',
        other: 'Other'
      },
      descTooltip: 'Please make the changes in text box itself.',
      overrideTooltip: 'Please make the changes in Step 4 itself.'
    }
  },
  multiselectText: 'Select Security Type',
  inValidDateMessage: 'Please enter a valid date MM/DD/YYYY',
  pastFromDateMessage: 'From Date should be Current or Future Date',
  pastToDateMessage: 'To Date should be Current or Future Date',
  invalidDate: 'Invalid Date',
  ruleFilter: {
    subType: [{value: 'All', label: 'All'}, {value: 'INDSEC', label: 'Individual Security'}, {value: 'SECTYP', label: 'Security Type'}, {value: 'FNDFMLY', label: 'Fund Family'}],
    ruleType: [{value: 'Restriction', label: 'Restriction'}, {value: 'Override', label: 'Override'}, {value: 'Watchlist', label: 'Watchlist'}],
    status: [{value: 'Active', label: 'Active'}, {value: 'Draft', label: 'Draft'}, {value: 'Expired', label: 'Expired'}, {value: 'Scheduled', label: 'Scheduled'}],
    clearButton: 'Clear',
    applyButton: 'Apply',
    view: [{value: 'Client', label: 'Client'}, {value: 'Global', label: 'Global'}, {value: 'Semi-Global', label: 'Semi-Global'}],
    statusDefaultValue: ['Active', 'Draft', 'Scheduled']
  },
  rule10b5Msg: {
    planIdTxt: 'Plan ID',
    label10b5Txt: '10b5-1',
    restrictionCategoryTxt: 'Restriction Category',
    invalidCharacterEnteredNumericValue: 'Invalid Character Entered - Please enter numeric values 0-9',
    duplicateRuleName: 'Please enter a unique name or click the Continue button to proceed with a duplicate Rule Name',
    duplicateRuleNameAndPlanId: 'Please enter a unique plan id and click the Continue button to proceed with a duplicate Rule Name',
    duplicatePlanId: 'Rule must be created with a unique plan id',
    ruleNameNotExists: 'Rule name doesn\'t exist'
  },
  bulkUpload: {
    uploadTxt: 'Upload',
    downloadText: 'Download Rules Bulk Upload Template (Optional)',
    empDownloadTemplateTxt: 'Download Manage Employee Template',
    empGrpDownloadTemplateTxt: 'Download Manage Employee Groups Template',
    empBulkUploadTitleTxt: 'Bulk Upload - Manage Employees',
    bulkUploadManageEmployeeGroupTitle: 'Bulk Upload - Manage Employee Groups'
  },
  mangeEmployees: {
    editEmployeeTitle: 'Edit Employee',
    editEmployeeBlankMsg: {
      employeeIdMsg: 'Employee Id is a required field!',
      employeeFirstNameMsg: 'Employee first name is a required field!',
      employeeLastNameMsg: 'Employee last name is a required field!',
      employeeLastNameInvalidLengthMsg: 'Last name length is greater than 30!',
      employeeFirstNameInvalidLengthMsg: 'First name length is greater than 30!',
      employeeId: 'Employee ID',
      employeeFirstName: 'Employee First Name',
      employeeLastName: 'Employee Last Name',
      accountNumber: 'Account Number',
    },
  },
  manageEmployeeGroups: {
    manageEmployeeGroupsTitle: 'Manage Employee Groups',
    addNewEmpGrp: 'Add New Employee Group',
    empGrpSavedMsg: ' Employee Group was successfully saved.',
    deleteEmpGroupMessage: ' is assigned to following Rules and will no longer be applicable to the Employee Accounts that are part of this Employee Group.  Please review the Rule assignment to assure the rule is still applicable.',
    deleteEmpGroupSuccessMsg: 'Employee Group has been deleted.',
    employeeGroupAlreadyExist: ' Employee Group Name already exist. Please enter a unique Employee Group Name.',
    empGroupStep1: {
      enterGroupDetails: 'Enter Group Details',
      groupName: 'Group Name',
      groupDescription: 'Group Description'
    },
    empGroupStep2: {
      addEmployees: 'Add Employees',
      uploadEmployeeList: 'Upload Employee List',
    },
    empGroupStep3: {
      groupNameDescription: 'Group Name and Description',
      employees: 'Employees',
      reviewAndSave: 'Review and Save',
      addRemoveEmployees: 'Added / Removed Employees'
    },
    empGroup10b5Msg: {
      planIdTxt: 'Plan ID',
      empGrpExistTxt: 'employee group name already exists - please enter a unique employee group name',
      planIdExitsTxt: 'Employee group must be created with a unique plan id',
      bothNameAndPlanId: 'Employee group name already exists - please enter a unique employee group name with unique plan id'
    }
  },
  submitButton: 'Submit',
  manageMasterGroups: {
    masterGroupText: 'Manage Master Groups',
    masterGroupList: {
      addNewMasterGroup: 'Add New Master Group'
    }
  },
  instrumentGroups: {
    instrumentGroupsText: 'Instrument Groups',
    instrumentGroupList: {
      addNewInstrumentGroup: 'Add New Instrument Group',
      toStep1: '/ems/emtr/instrument-groups/create-instrument-group/step1',
      toStep2: '../create-instrument-group/step2',
      toStep3: '../create-instrument-group/step3',
      toLandingPage: '/ems/emtr/instrument-groups/instrument-group-list',
      mainRoute: 'instrument-groups',
      instrumentGrpSaveMsg: 'Instrument Group was successfully saved.',
      instrumentGroupHistory: 'Instrument Group History'
    },
    instrumentGrpStep1: {
      addNewInstrumentGroupHeading: 'Add New Instrument Group',
      editInstrumentGroupHeading: 'Edit Instrument Group',
      viewInstrumentGroupHeading: 'View Instrument Group',
      businessUnit: 'Business Unit',
      groupNameExists: ' instrument group name already exists - please enter a unique instrument group name'
    },
    instrumentGrpStep2: {
      selectSecurities: 'Select Securities',
      selectInstruments: 'Select Securities',
      addedOrRemovedInstruments: 'Added / Removed Securities'
    },
    instrumentGrpDelete: {
      deleteInstrumentGroupMessage: ' is assigned to following Rule(s) and will no longer be applicable to the plans that are subject the Rule(s). Please review the Rule assignment to ensure the rule is still applicable.',
      instrumentGroupDeleteMsg: 'Instrument Group has been deleted.',
      instrumentGroupRestoreMsg: 'Instrument Group has been restored.<br><p></p><strong>Note:</strong> This does not automatically restore the Group to any rules that previously used it. Re-assignments will need to be made manually.'
    }
  },

  presetRules: {
    presetRulesText: 'Rules',
    assetGroupText: 'Asset Group',
    assetGroupsAndAttributesText: 'Asset Groups & Attributes',
    miscellaneousAssetGroupText: 'Miscellaneous Asset Group',
    reviewSecTypeDetailsText: 'Review Security Type Details',
    customAssetGroupText: 'CUSTOM ASSET GROUP',
    assetGrpWarningMsg: 'Please note that by selecting a category in the Asset Group section, this is no longer a Preset rule.',
  },
  showRecords: [{value: true, label: 'Active'}, {value: false, label: 'Deleted'}]
};

export const DefaultValues = {
  defaultTableRecords: 15
};
