import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-workbench-loader',
  templateUrl: './workbench-loader.component.html',
  styleUrls: ['./workbench-loader.component.scss']
})
export class WorkbenchLoaderComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
