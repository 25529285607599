<div class="sdps-p-horizontal_medium">
  <sdps-notification type="information" class="sdps-m-top_none" *ngIf="showClientMsg">
    <p class="sdps-notification__text" [innerHTML]="getShellConstant.noCientSelected"></p>
  </sdps-notification>
</div>

<div class="sdps-p-horizontal_medium" *ngIf="!showClientMsg">
  <form [formGroup]="addlinkReportForm">
  <div class="sdps-grid-container sdps-wrap report-dropdown">

  <div class="sdps-row sdps-p-top_x-large">
      <div class="display-flex">
        <app-report-from-to-datepicker (calendarValidation)="calendarValidation($event)" [dateRange]="null" [initialDateRange]="7" (getEmittedVal)="setDate($event)"
                                       [calendarForm]="addlinkReportForm" (throwDateError)="throwDateError($event)" (throwDateRangeError)="throwDateRangeError($event)"></app-report-from-to-datepicker>
      </div>
    </div>
  <div class="sdps-row sdps-p-top_medium select-report-type">
  <div class="col-3 sdps-p-left_none sdps-p-right_small sdps-dropdown--lightGrey emtr-dropdown employee-dropdown timePickerForm">
    <p [innerHTML]="getShellConstant.reports.ADDLinkUsage.submitterType">
    </p>
    <p-dropdown optionLabel="label" placeholder=" " [options]="getShellConstant.manageUserPage.manageUserFilter.userType" formControlName="submitterType"></p-dropdown>
  </div>

    <div
      class="col-3 emtr-dropdown sdps-p-left_none sdps-p-right_small request-list-filter addlink-report-multi-dropdown">
      <p [innerHTML]="getShellConstant.reports.ADDLinkUsage.source"></p>
      <p-multiSelect appendTo="body" [options]="sourceDropdownData" [defaultLabel]="' ' " formControlName="source"
                     [displaySelectedLabel]= "true" [maxSelectedLabels]= "1" [filterPlaceHolder]="'All'"
                     [filter]="true" [showHeader]="true">
      </p-multiSelect>
    </div>

    <div
         class="col-3 emtr-dropdown sdps-p-left_none sdps-p-right_small request-list-filter addlink-report-multi-dropdown">
      <p [innerHTML]="getShellConstant.reports.ADDLinkUsage.status"></p>
      <p-multiSelect appendTo="body" [options]="statusDropdownData" [defaultLabel]="' ' " formControlName="status"
                     [displaySelectedLabel]= "true" [maxSelectedLabels]= "1" [filterPlaceHolder]="'All'"
                     [filter]="true" [showHeader]="true">
      </p-multiSelect>
    </div>
  </div>

  </div>
  </form>

  <ems-step-wizard-buttons [showCancelBtn]="true" (closeBtn)="cancelReport()" [showRunReportBtn]="true" (runReport)="runReports()"
                           [disableBtn]="addlinkReportForm.invalid || endDateLessThanStartDate || dateRangeConstarint || dateError"></ems-step-wizard-buttons>
</div>

