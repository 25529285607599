<div [formGroup]="dateForm" *ngIf="dateForm">
  <div class="emtr-datepicker sdps-grid-container">
    <div class="emtr-calender request-list-filter-calender rule-type-textbox"
         [ngClass]="{'date-errorHighlight':showError}">
      <span class="p-float-label timeTextbox sdps-p-vertical_none" [ngClass]="{disabled: disabled}">
        <p-calendar appendTo="body" #dateEntered (onClose)="onCloseMethod($event, dateEntered)" formControlName="date" [showIcon]="true"
                    name="addlink-date" [minDate]="minDate" [maxDate]="maxDateValue" [readonlyInput]="readonlyInput"
                    [monthNavigator]="true" [yearNavigator]="true" yearRange="{{commonConstants.calendarYear}}"
                    keepInvalid="true"></p-calendar>
      </span>
    </div>
  </div>
</div>
