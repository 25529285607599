export interface AuthAgentTableHeader {
    field?: string;
    header?: string;
}
export const authAgentTableHeader: AuthAgentTableHeader[] = [
    { field: 'authAgentFirstNm', header: 'First Name' },
    { field: 'authAgentLastNm', header: 'Last Name' },
    { field: 'action', header: '' }
];

export const authAgentTableHeaderSCT: AuthAgentTableHeader[] = [
  { field: 'authAgentFirstName', header: 'First Name' },
  { field: 'authAgentLastName', header: 'Last Name' }
];
