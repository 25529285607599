<footer>
  <div class="sdps-grid-container sdps-wrap">
    <div class="col-8">
      &copy; {{year | date : 'yyyy'}} Charles Schwab &amp; Co., Inc. All rights reserved. Member <a href="https://www.sipc.org/" target="_blank">SIPC</a>. Unauthorized access is prohibited. Usage will be monitored. {{releaseVersion}}
    </div>

    <div class="col-4" *ngIf="sharedService.showTC"> {{sharedService.showTC}}
      <a class="sdps-pull-right cursor" (click)="downloadAgreement()">Agreements and Disclosures</a>
    </div>
  </div>
</footer>
