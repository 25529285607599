<sdps-notification type="error" class="sdps-m-top_none" *ngIf="showSchError || showRequestError">
  <div class="sdps-notification__text">
    <p>Please correct following error.</p>
    <ul class="sdps-navigation--horizontal__list error-list">
      <li *ngFor='let msg of schErrorMsg' [innerHTML]="msg"></li>
      <li *ngIf="showRequestError" [innerHTML]="getConstant.ruleSteps.step2.changeRequestIdInvalidMessage"></li>
    </ul>
  </div>
</sdps-notification>

<ems-toast-message *ngIf="toastMsg" #toastMessage></ems-toast-message>

<div class="add-new-rule-group">
  <h2 *ngIf="this.editRuleData.mode!== 'EDIT'" class="page-title sdps-m-bottom_large" [innerHTML]="getConstant.rulesConstant.addNewRule"></h2>
  <h2 *ngIf="this.editRuleData.mode === 'EDIT' && (editRuleData.status==='EXPIRED' || ruleService.deletedRuleInfo || !sharedService.checkEntitlements(entitlementConst.navUUID.emtrAccess.manageRule.uuid, '', '') || (editRuleData.global && !sharedService.checkEntitlements(entitlementConst.navUUID.emtrAccess.manageGlobalRule.uuid, '', '')))" class="page-title sdps-m-bottom_large" [innerHTML]="getConstant.rulesConstant.viewRuleName"></h2>
  <h2 *ngIf="this.editRuleData.mode === 'EDIT' && editRuleData.status!=='EXPIRED' && !ruleService.deletedRuleInfo && ((!editRuleData.global && sharedService.checkEntitlements(entitlementConst.navUUID.emtrAccess.manageRule.uuid, '', '')) || (editRuleData.global && sharedService.checkEntitlements(entitlementConst.navUUID.emtrAccess.manageGlobalRule.uuid, '', '') && sharedService.checkEntitlements(entitlementConst.navUUID.emtrAccess.manageRule.uuid, '', '')))" class="page-title sdps-m-bottom_large" [innerHTML]="getConstant.rulesConstant.editRuleName"></h2>
  <ems-step-wizard [stepObj]="items" *ngIf="items"></ems-step-wizard>
</div>
