import {SharedConstant} from '../constants/sharedConstants';

export interface ManageEmployeeGroupActionMenu {
  label?: string;
  icon?: string;
  routerLink?: any;
  disabled?: boolean;
}

export const ManageEmployeeGroupsListActionMenuItems: ManageEmployeeGroupActionMenu[] = [
  {label: SharedConstant.manageEmployeeActionItems.historyText, icon: 'pi pi-clock'},
  {label: SharedConstant.deleteModal.modalTitle, icon: 'pi pi-trash'},
  {label: SharedConstant.manageEmployeeActionItems.restore, icon: 'pi pi-undo'}
];

export const ManageEmployeeGroupsListHistoryActionMenu: ManageEmployeeGroupActionMenu[] = [
  {label: SharedConstant.manageEmployeeActionItems.historyText, icon: 'pi pi-clock'}
];
