import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { RequestListService } from '../../../services/request-list.service';
import { AddlinkService } from '../../../../addlink.service';
import { Table } from 'primeng/table';
import { CommonService } from '../../../../shared/services/common.service';
import { AddlinkConstant } from '../../../../constants/addlinkConstants';
import { SharedService } from '@ems/shared';
import { Subscription } from 'rxjs';

@Component({
  selector: 'addlink-resubmit-requests-modal',
  templateUrl: './resubmit-requests-modal.component.html',
  encapsulation: ViewEncapsulation.None
})
export class ResubmitRequestsModalComponent implements OnInit {

  public title: string;
  addLinkConstant = AddlinkConstant;
  public requestIds: any[];
  public validationMismatch = 0;
  public dbsActionNeeded = 0;
  public missingIndexKey = 0;
  public requestListTable: Table;
  intervalJobs: any = {};
  wholeRecordArr = [];
  orgId: any;

  constructor(public sharedService: SharedService, private commonService: CommonService, private requestListService: RequestListService, private addlinkService: AddlinkService) {
  }

  /* Initialize component variables */
  ngOnInit() {
    this.title = 'Resubmit Request(s)';
  }

  /* Opens The Modal */
  public openModal(e: Event, requestIds: any, validationMismatch: number, dbsActionNeeded: number, missingIndexKey: number, requestListTable: Table, orgId: any): void {
    e.stopPropagation();
    this.requestIds = requestIds;
    this.validationMismatch = validationMismatch;
    this.dbsActionNeeded = dbsActionNeeded;
    this.missingIndexKey = missingIndexKey;
    this.requestListTable = requestListTable;
    this.orgId = orgId;
    this.sharedService.openModal('beta-resubmit-request-modal');
  }

  /* Closes The Modal */
  public closeModal(): void {
    this.sharedService.openModal('beta-resubmit-request-modal');
  }

  public resubmitRequests(): void {
    this.requestListTable.selection = [];
    const count = 0;
    this.requestListService.addlinkRequestPutEndPoint(this.requestIds, '', 'RESUBMIT', this.orgId).subscribe((res) => {
      this.requestIds.forEach(requestId => {
        const localObj = {reqId: requestId, count};
        this.wholeRecordArr.push(localObj);
        this.intervalJobs[requestId] = setInterval(() => {
          this.requestListService.getRequestStatus(requestId, this.intervalJobs);
          this.wholeRecordArr.forEach((data) => {
            if (data.reqId === requestId) {
              data.count++;
              if (data.count > 4) {
                window.clearInterval(this.intervalJobs[data.reqId]);
              }
            }
          });
        }, this.addLinkConstant.reqDetailPollingInterval);
      });
    });
    this.sharedService.closeModal('beta-resubmit-request-modal');
  }
}
