import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { emtrConstant } from '../../constants/emtrConstants';
import { EmtrService } from '../../emtr.service';
import { ManageEmpGrpService, SharedService, SharedConstant, SharedMasterGroupsComponent } from '@ems/shared';
import { EmpGroupFormDataService } from '../../manage-employee-groups/create-employee-group/emp-group-form-data/emp-group-form-data.service';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { EmployeeService } from '../../services/employee-service/employee.service';
import { Observable, Observer } from 'rxjs';
@Component({
  selector: 'tr-master-groups-list',
  templateUrl: './master-groups-list.component.html'
})

export class MasterGroupsListComponent implements OnInit, OnDestroy {

  currentOrgSubscription: Subscription;
  private messageTxt: string;
  getEmtrConstants = emtrConstant;
  sharedConstant = SharedConstant;
  businessUnit: any;
  masterGroups: any;
  getConstant = emtrConstant;
  ruleNames = '';
  tableCountData: any = {
    grpCount: 0,
    subAccount: 0,
    grpSelected: 0,
    subAccSelected: 0,
    totalRecords: 0,
    paginationCount: 0
  };
  resetTable: any;
  schError = {
    minCharacter: false,
    invalidCharacter: false
  };
  showActionItems = true;
  @ViewChild('masGrpDeleteConfirm') masGrpDeleteConfirm: any;
  @ViewChild(SharedMasterGroupsComponent) sharedEmpGrp: SharedMasterGroupsComponent;
  @ViewChild('toastMessage') msgToast: any;
  masterGroupSaveMsg: any;
  removeSuccessMsg = false;
  /* eslint-disable-next-line max-len */
  constructor(public emtrService: EmtrService, public sharedService: SharedService, public manageEmpGrpService: ManageEmpGrpService, private employeeService: EmployeeService, public formDataService: EmpGroupFormDataService, @Inject('entitlementConst') public entitlementConst, private router: Router, private r: ActivatedRoute) {
    this.getSaveMasterGrpMessage();
    this.sharedService.groupType = this.sharedConstant.manageMasterGroups.groupTypeMGP;
  }

  ngOnInit() {
    this.sharedService.emitClientDropdownDisableValue.next(true);
    this.currentOrgSubscription = this.emtrService.clientInformation.subscribe(clientInfo => {
    this.showActionItems = this.sharedService.checkEntitlements(this.entitlementConst.navUUID.emtrAccess.manageMasterAccountGroup.uuid, '', '');
    });
    if (this.masterGroupSaveMsg) {
      setTimeout(() => {
        this.msgToast.pushMessage('success', this.masterGroupSaveMsg);
      }, 1);
      this.removeSuccessMsg = true;
    }
  }

  paginateMasterGrpList($event) {
    this.sharedService.clearErrorMessage();
    $event.filterObj.orgId = 0;
    this.getMasterGrpRecords($event);
  }

  getMasterGrpRecords(param) {
    const localFormData = this.formDataService.getFormData();
    if (this.sharedService.checkEntitlements(this.entitlementConst.navUUID.emtrAccess.masterAccountGroupSummary.uuid, '', '')) {
      this.manageEmpGrpService.getEmployeeGrpOrPlanList(param.paramObj, param.filterObj).subscribe((response) => {
        this.masterGroups = response.data.content;
        if (this.masterGroups.length > 0) {
          if (this.tableCountData.empCount === 0) {
            this.tableCountData.empCount = this.masterGroups[0].noOfEmployees;
            this.tableCountData.subAccCount = this.masterGroups[0].noOfSubAccounts;
            this.manageEmpGrpService.empCount = this.masterGroups[0].noOfEmployees;
            this.manageEmpGrpService.empAccCount = this.masterGroups[0].noOfSubAccounts;
          }
          this.tableCountData.totalRecords = this.sharedService.totalElementsWithCommas(response.data.totalElements);
          this.tableCountData.paginationCount = response.data.totalElements;
          this.resetTable = false;
          return this.masterGroups;
        } else {
          this.masterGroups = [];
          this.tableCountData = {};
          return this.masterGroups;
        }
      }, error => {
        this.masterGroups = [];
        this.tableCountData = {};
        return this.masterGroups;
      });
    }
  }

  ngOnDestroy(): void {
    if (this.currentOrgSubscription) {
      this.currentOrgSubscription.unsubscribe();
    }
    if (this.router.routerState.snapshot.url.indexOf(this.sharedConstant[this.sharedConstant.manageMasterGroups.groupTypeMGP].mainRoute) === -1) {
      this.formDataService.resetForm();
    }
    this.manageEmpGrpService.clearMessage();
    this.sharedService.updatedDate.next('');
    this.sharedService.disableClientSelector.next(this.sharedService.ClientCount === this.sharedConstant.clientCount);
  }

  setErrorValue(event) {
    this.schError = event;
  }

  navigateToCreateMstrGroup() {
    /* Set isDTBtnClicked value to true, generate the data track value with 'linkid-lc' . */
    this.sharedService.isDTBtnClicked = true;
    this.sharedService.deletedMastGrp = false;
    this.sharedService.loadDataTrackValue('newmastergroup');
    this.formDataService.resetForm();
    this.router.navigate([this.sharedConstant[this.sharedConstant.manageMasterGroups.groupTypeMGP].toStep1], { relativeTo: this.r });
  }

  getSaveMasterGrpMessage() {
    this.manageEmpGrpService.getMessage().subscribe((data) => {
      this.masterGroupSaveMsg = data.text ? data.text : '';
    });
  }

  fetchRuleNamesLinkedToMasGroup(grpData) {
    const confirmObj = {
      header: `${this.getConstant.rulesConstant.ruleGlobalConst.delete} - ${grpData.groupData.name}`,
      message: `${this.getConstant.rulesConstant.rulesLanding.deleteNotAssignedMsgConfirmation} ${this.getConstant.rulesConstant.rulesLanding.deleteMsgConfirmation}`,
      acceptBtn: this.getConstant.rulesConstant.ruleGlobalConst.delete,
      rejectBtn: this.getConstant.rulesConstant.ruleGlobalConst.cancel
    };
    const confirmWithCloseObj = {
      header: `${this.getConstant.rulesConstant.ruleGlobalConst.delete} - ${grpData.groupData.name}`,
      message: `${this.sharedConstant[this.sharedConstant.manageMasterGroups.groupTypeMGP].masterGrpDeleteMsg}`,
      acceptBtn: this.getConstant.rulesConstant.ruleGlobalConst.hideAcceptButton,
      rejectBtn: this.getConstant.rulesConstant.ruleGlobalConst.close
    };
    if (grpData.groupData.noOfRules) {
      this.employeeService.deleteEmployeeGrp(grpData.groupData.id, false).subscribe((response: any) => {
        const names = response.data;
        if (names) {
          if (names.rulesInActiveStatus.length > 0 || names.rulesInDraftStatus.length > 0 || names.rulesInScheduledStatus.length > 0) {
            Object.keys(names).forEach((status) => {
              const statusValue = names[status];
              if (status !== 'rulesInExpiredStatus' && statusValue.length > 0) {
                statusValue.forEach((key) => {
                  const rulesLinked = key.name;
                  this.ruleNames = this.ruleNames.concat('<li>' + rulesLinked + '</li>');
                });
                confirmWithCloseObj.message = `<strong>${grpData.groupData.name}</strong> ${this.sharedConstant[this.sharedConstant.manageMasterGroups.groupTypeMGP].masterGrpDeleteMsg} <ul class='sdps-list--ordered sdps-m-vertical_medium show-scrollable-list'>
                ${this.ruleNames}</ul>`;
              }
            });
          } else {
            confirmObj.message = `<strong>${grpData.groupData.name}</strong> ${this.getConstant.rulesConstant.rulesLanding.deleteNotAssignedMsgConfirmation} ${this.getConstant.rulesConstant.rulesLanding.deleteMsgConfirmation}`;
          }
          const obj = this.ruleNames.length > 0 ? confirmWithCloseObj : confirmObj;
          this.deleteMasterGroup(obj, grpData);
        }
      });
    } else {
      confirmObj.message = `<strong>${grpData.groupData.name}</strong> ${this.getConstant.rulesConstant.rulesLanding.deleteNotAssignedMsgConfirmation} ${this.getConstant.rulesConstant.rulesLanding.deleteMsgConfirmation}`;
      this.deleteMasterGroup(confirmObj, grpData);
    }
  }

  deleteMasterGroup(confirmObj, grpData) {
    this.removeSuccessMsg = true;
    Observable.create((observer: Observer<boolean>) => {
      this.ruleNames = '';
      this.masGrpDeleteConfirm.showConfirmMsg(confirmObj, observer);
    }).subscribe(accept => {
      if (accept) {
        this.employeeService.deleteEmployeeGrp(grpData.groupData.id, true).subscribe(data => {
          this.messageTxt = '<strong>' + grpData.groupData.name + '</strong>&nbsp;' + this.sharedConstant[this.sharedConstant.manageMasterGroups.groupTypeMGP].deleteEmpGroupOrPlanSuccessMsg;
          this.msgToast.pushMessage('success', this.messageTxt);
          const param = {
            paramObj: grpData.paramObj,
            filterObj: grpData.filterObj
          };
          this.getMasterGrpRecords(param);
        });
      }
    });
  }

  restoreMasterGroup(grpData) {
    grpData.active = true;
    this.removeSuccessMsg = true;
    this.manageEmpGrpService.saveEmpGroup(grpData).subscribe(res => {
      this.messageTxt = '<strong>' + grpData.groupData.name + '</strong>&nbsp;' + this.sharedConstant[this.sharedConstant.manageMasterGroups.groupTypeMGP].restoreEmpGroupOrPlanSuccessMsg;
      this.msgToast.pushMessage('success', this.messageTxt);
      const param = {
        paramObj: grpData.paramObj,
        filterObj: grpData.filterObj
      };
      this.getMasterGrpRecords(param);
    });
  }

  getMasterGroupData(rowData) {
    this.employeeService.getEmpGroupData(rowData.id).subscribe((empGrp: any) => {
      this.formDataService.setMode('EDIT');
      const res = empGrp.data;
      res.selectedEmpCount = {noOfEmployees: res.noOfEmployees, noOfSubAccounts: res.noOfSubAccounts};
      this.router.navigate([this.sharedConstant[this.sharedConstant.manageMasterGroups.groupTypeMGP].toStep3], {relativeTo: this.r});
      this.formDataService.validateAllSteps();
      this.formDataService.setFormData(res);
    });
  }
}
