<div class="sdps-text-l-body security-container">
    <div class="security-main sdps-m-left_xx-large">
        <div class="security-title">
          <span [innerHTML]="emtrConstant.rulesConstant.appliesTo"></span>
          <span class="sdps-text-market-red" [innerHTML]="commonConstants.asterix"></span>
        </div>
        <div class="security-type">
            <p-checkbox class="emtr-checkbox sdps-p-right_x-large" [value]="" label="Individual Securities" [(ngModel)]="appliesType['individualSecurities']" binary="true" (ngModelChange)="updateAppliesTo()"></p-checkbox>
            <p-checkbox class="emtr-checkbox" label="Underlying Options" [(ngModel)]="appliesType['underlyingOptions']" binary="true" (ngModelChange)="updateAppliesTo()"></p-checkbox>
        </div>
    </div>
</div>
