import {Component, ViewChild} from '@angular/core';
import {Toast} from 'primeng/toast';
import {MessageService} from 'primeng/api';
import {SharedService} from '../../shared.service';

@Component({
  selector: 'ems-toast-message',
  templateUrl: './toast-message.component.html'
})
export class ToastMessageComponent {

  constructor(public messageService: MessageService, public sharedService: SharedService) {
  }

  @ViewChild(Toast, { static: true }) toast: Toast;

  pushMessage(type, message) {
    let messageExists = false;
    if (this.toast.messages) {
      for (const toastMessage of this.toast.messages) {
        if (toastMessage.detail === message) {
          messageExists = true;
          break;
        }
      }
    }
    if (!messageExists) {
      this.messageService.clear();
      this.messageService.add({severity: type, detail: message, sticky: true});
    }
  }

  onClose() {
    this.sharedService.closeToast = true;
  }

}
