<ems-confirm-message #confirmationMessage></ems-confirm-message>

<div class="heading">
  <span class="select-attributes">Assign Rule</span>
  <tr-rule-name-type *ngIf="assignFormData" [ruleName]="assignFormData.name"
                     [ruleType]="assignFormData.rulesCategory"></tr-rule-name-type>
</div>

<div class="step-border-box sdps-p-around_large">
  <p-tabView *ngIf="!(assignFormData.globalRule && sharedService.checkEntitlements(entitlementConst.navUUID.emtrAccess.manageGlobalRule.uuid,'',''))" class="emtr-tab"
             [ngClass]="{'sdps-hide': !sharedService.checkEntitlements(entitlementConst.navUUID.emtrAccess.manageRule.uuid, '', '')}" [activeIndex]="activeIndex" (onChange)="onChangeTab($event)">
    <p-tabPanel [header]=sharedConstant[businessUnit].employeeGrpOrPlan [disabled]="shouldDisabledGroupsOrPlans">
      <ems-shared-manage-employee-group [showSelection]="true" [empGrpformData]="assignFormData.employeeGroups"
                                        (initialCount)='getInitialCount($event)'
                                        [moduleType]="selectedModuleType"
                                        (rowSelected)="employeeRowSelected(true)"
                                        (employeeGroups)="getDataEmpGrp($event)"
                                        [tableCountData]="tableCountData" [employees]="employees"
                                        (paginateEmployeeList)="paginateEmployeeList($event)"></ems-shared-manage-employee-group>
    </p-tabPanel>
    <p-tabPanel [header]=sharedConstant[businessUnit].employeeOrParticipant [disabled]="shouldDisabledEmployeesOrParticipants">
      <ems-shared-manage-employee-accounts [assignFormData]='assignFormData' [getOrgId]='assignFormData.orgId'
                                           [moduleType]='loadedModuleType'
                                           [empAccformData]="assignFormData.employeeAccounts"
                                           [editGroupData]='assignFormData'
                                           (rowSelected)="employeeRowSelected(true)"
                                           (selectedEmployeeAccounts)="updateSelectedArray($event)"></ems-shared-manage-employee-accounts>
    </p-tabPanel>
  </p-tabView>
  <p-tabView *ngIf="assignFormData.globalRule && sharedService.checkEntitlements(entitlementConst.navUUID.emtrAccess.manageGlobalRule.uuid,'','')" class="emtr-tab"
              [ngClass]="{'sdps-hide': !sharedService.checkEntitlements(entitlementConst.navUUID.emtrAccess.manageRule.uuid, '', '')}" [activeIndex]="activeIndex" (onChange)="onChangeTab($event)">
    <!-- Master Group Section -->
    <p-tabPanel
      [header]=emtrConst.ruleSteps.step3.masterGrpTab [disabled]="shouldDisabledMasterGroups">
      <ems-shared-master-groups [showMGPSelection]="true" [mstrGrpformData]="employeeGroups"
                                [showActionBtn] = "showActionBtn"
                                [masterAcctList]="employees"
                                (paginateMasterGrpList)="paginateEmployeeList($event)"
                                (employeeGroups)="updatedMasterGrpData($event)"
                                (rowSelected)="employeeRowSelected(true)"
                                (selectedMasterGrp)="masterGrpData($event)"
                                [tableCountData]="tableCountData">
      </ems-shared-master-groups>
    </p-tabPanel>

    <!-- Master Accounts Section -->
    <p-tabPanel
      [header]=emtrConst.ruleSteps.step3.clientsTab [disabled]="shouldDisabledMasterAccounts">
      <tr-client-selection-table (rowSelected)="employeeRowSelected(true)"
                                 (selectedMasterAccounts)="updatedMasterAcc($event)"
                                 (unAssignSelectedMstrAccts)="unAssignMstrAccts = $event"
                                 (selectedClients)="getSelectedClientData($event)"></tr-client-selection-table>
    </p-tabPanel>
  </p-tabView>
</div>

<ems-step-wizard-buttons [disableBtn]="((employeeGroups && employeeGroups.length > 0 || employeeAccounts && employeeAccounts.length > 0 || (assignOrgIds && assignOrgIds.length > 0)) && !isRuleExpired)?'':true"
                         [disablePrevBtn]="isRuleExpired" (goToPrevious)="goToPreviousStep();"
                         [showCancelBtn]="true" [showSaveContinueBtn]="true" [showPreviousBtn]="true"
                         (closeBtn)="cancelCreateRule()" (goToNext)="confirmationPopup()"></ems-step-wizard-buttons>
