<sdps-notification type="information" class="sdps-m-top_none" *ngIf="!orgId">
  <p class="sdps-notification__text" [innerHTML]="emtrConstant.rulesConstant.ruleGlobalConst.noCientSelected"></p>
</sdps-notification>
<ems-confirm-message #confirmationMessage></ems-confirm-message>
<ems-bulk-upload [lib]="library" [downloadTemplate]="fileTemplate" [downloadTemplateLabel]="downloadTemplateLabel" [uploadFileApi]="uploadFilePoint"
                 [parentPage]="navigateTo" [createdName]="nameEntered" *ngIf="orgId" (FileUploadEmit)="getEmittedFileVal($event)"
                 [bulkUploadTitles]="emtrConstant.rulesConstant.ruleGlobalConst.bulkUploadRules" [uploadBtnGutTack] = 'uploadBtnGutTack'
                 [downloadTemplateGutTack]="downloadTempGutTrackValue" [chooseFileGutTack]="chooseFileGutTrackValue"></ems-bulk-upload>
<div *ngIf="orgId" class="sdps-text-right sdps-m-top_x-large sdps-m-bottom_x-large">
  <button type="reset" class="buttons" (click)="cancelFileUpload()"
          [innerHTML]="emtrConstant.rulesConstant.ruleGlobalConst.cancel"></button>
  <button type="button" class="buttons button-save"
          [disabled]="isFileUploaded ? isFileUploaded && showError : !isFileUploaded"
          (click)="uploadFile();"
          [innerHTML]="emtrConstant.bulkUpload.uploadTxt"></button>
</div>
