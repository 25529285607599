import { Component, OnInit } from '@angular/core';
import {emtrConstant} from '../constants/emtrConstants';
import {EmtrService} from '../emtr.service';

@Component({
  selector: 'tr-rule-groups',
  templateUrl: './rule-groups.component.html'
})
export class RuleGroupsComponent implements OnInit {
  public orgId;
  emtrConstant = emtrConstant;

  constructor(private e: EmtrService) { }

  ngOnInit() {
    this.e.currentOrg.subscribe(clientInfo => {
      this.orgId = clientInfo;
    });
  }

}
