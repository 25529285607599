import { Component, Input, ViewChild, Output, EventEmitter } from '@angular/core';
// import { SchPanelComponent } from '@schwab/sch-panel';

@Component({
  selector: 'ems-accordion',
  templateUrl: './accordion.component.html'
})
export class AccordionComponent {

  @Input() label: any;
  @Input() editable: boolean;
  @Input() compact = false;
  @Input() disabledButton = false;
  @Input() toolTip = false;
  @Input() message = '';
  @Output() editPanel = new EventEmitter<boolean>();
  // @ViewChild(SchPanelComponent, { static: true }) panel: SchPanelComponent;
  @Input() showEditIcon: any;
  isCollapsed = false;

  constructor() { }

  goToEditPage() {
    this.editPanel.emit();
  }

  toggle(event?: KeyboardEvent) {
    if (event) {
      event.preventDefault();
    }
    // this.panel.toggle();
  }

  skipToggle(event) {
    event.preventDefault();
    event.stopPropagation();
  }

}
