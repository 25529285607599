import { Component, OnInit, OnDestroy, Injector } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import { ShellConstant } from '@shell_components/constants/shellConstants';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { commonConstants } from '@shell_components/constants/commonConstants';
import { ReportsService } from '@core_services/report/reports.service';
import { AppService } from '@core_services/app.service';
import { PresetReportsComponent } from '@shell_components/reports/preset-reports/preset-reports.component';
import {SharedConstant, SharedService} from '@ems/shared';
import { AddlinkService } from '@ems/addlink';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-employee-account-details',
  templateUrl: './employee-account-details.component.html'
})
export class EmployeeAccountDetailsComponent implements OnInit, OnDestroy {
  showEmpIdCharLimit: boolean;
  showTextboxCharLimit: boolean;
  employeeIDDropDownData: any;
  getShellConstant = ShellConstant;
  reportDetails: UntypedFormGroup;
  getCommConst = commonConstants;
  reportGroupName: any;
  reportRuleName: any;
  fromDate: any;
  toDate: any;
  parent: PresetReportsComponent;
  finalAppendedUrl: string;
  runReportFor: string;
  passReportName: string;
  dateError: any;
  endDateLessThanStartDate = '';
  getOrgId: any;
  showClientMsg: any = true;
  reportName: any;
  sharedConstant = SharedConstant;
  businessUnit: any;
  dateRangeConstarint = '';
  private currentOrgSubscription: Subscription;

  constructor(public addlinkService: AddlinkService, private appService: AppService, private fb: UntypedFormBuilder, public reportService: ReportsService, private injector: Injector, private activatedRoute: ActivatedRoute, public sharedService: SharedService) {
    this.parent = this.injector.get(PresetReportsComponent);
    this.reportName = this.reportService.getReportName();
  }

  ngOnInit() {
    this.reportDetails = this.fb.group({
      reportName: [{value: '', disabled: true}, Validators.required],
      dateTo: this.fb.group({
        date: ['', Validators.required],
      }),
      dateFrom: this.fb.group({
        date: ['']
      }),
      employeeID: '',
      employeeAccount: '',
      employeeLastName: '',
      empStatusActive: '',
      empStatusInactive: '',
      employeeIdAssociation: '',
    });

    /*Freeze the global client selector when the report is selected*/
    this.reportService.freezeGlobalClientSelector(this.reportDetails);

    this.employeeIDDropDownData = this.getShellConstant.reports.presetReports.employeeIDAssociationDropDown;
    this.reportService.makeFormDirty(this.reportDetails, this.parent);
    this.currentOrgSubscription = this.addlinkService.clientInformation.subscribe(clientInfo => {
      if (clientInfo !== null) {
        this.getOrgId = clientInfo && clientInfo.orgId;
        this.businessUnit = clientInfo && clientInfo.businessUnit;
        if (this.getOrgId > 0) {
          if ((this.businessUnit !== this.getShellConstant.rbsBUText && this.reportService.selectedReport === this.getShellConstant.reports.presetReports.reportNames.participantAssociationTxt) ||
            (this.businessUnit === this.getShellConstant.rbsBUText && this.reportService.selectedReport !== this.getShellConstant.reports.presetReports.reportNames.participantAssociationTxt)) {
            this.cancelReport();
          }
        }
        this.onChanges();
      }
    });
    this.reportService.reportsArray.subscribe((data: any) => {
      if (data !== null) {
        data.map((res) => {
          if (res.name === this.reportName) {
            res.accessType === 'CLIENT' ? this.getOrgId > 0 ? this.showClientMsg = false : this.showClientMsg = true : this.showClientMsg = false;
          }
        });
      }
    });
  }

  onChanges() {
    this.reportService.formErrorMessage(this.reportDetails, this.parent, '', 'employeeID', this.getShellConstant.reports.presetReports.employeeIdErrorMsg);
    this.reportService.formCharLimitErrorMessage(this.reportDetails, this.parent, 'employeeAccount', 8, this.getShellConstant.manageClients.clientDetailsPage.errorMsg.empAcctNumShouldContain8Digits );
    this.reportService.formErrorMessage(this.reportDetails, this.parent, '', 'employeeLastName', this.getShellConstant.manageClients.clientDetailsPage.errorMsg.invalidCharacterEnteredAlphabeticValue);
  }

  setDate(event) {
    this.fromDate = event.fromDate;
    this.toDate = event.toDate;
    this.dateError = event.dateError;
  }

  throwDateRangeError(event) {
    this.dateRangeConstarint = event;
    this.parent.throwDateRangeError(event);
  }

  calendarValidation(event) {
    this.parent.calendarValidation(event);
  }

  throwDateError(event) {
    this.endDateLessThanStartDate = event;
    this.parent.throwDateError(event);
  }

  runReports() {
    const localInput = {
      employeeId: this.reportDetails.get('employeeID').value ? this.reportDetails.get('employeeID').value.trim() : '',
      accountNumber: this.reportDetails.get('employeeAccount').value ? this.reportDetails.get('employeeAccount').value.trim() : '',
      employeeLastName: this.reportDetails.get('employeeLastName').value ? this.reportDetails.get('employeeLastName').value.trim().toUpperCase() : '',
      employeeGrpName: this.reportGroupName ? this.reportGroupName.trim() : '',
      endDate: encodeURIComponent(this.reportService.formatDate(this.toDate)),
      startDate: encodeURIComponent(this.reportService.formatDate(this.fromDate)),
      employeeStatus: this.fetchEmpStatus(),
      employeeIdAssociation: this.reportDetails.get('employeeIdAssociation').value ? this.reportDetails.get('employeeIdAssociation').value: 'ALL',
      orgId: this.reportService.orgId,
      clientName: this.reportService.clientName,
      clientId: this.reportService.clientId
    };

    this.reportService.executeReports(this.parent, localInput, this.reportService.selectedReport, this.getShellConstant.reports.presetReports.reportNames.employeeAssociationTxt, this.getShellConstant.reports.sendReportAPI.empAccount,
      this.getShellConstant.reports.presetReports.reportNames.employeeAssociationReportName, this.appService, '');
  }

  fetchEmpStatus() {
    const getActive = this.reportDetails.get('empStatusActive').value;
    const getInactive = this.reportDetails.get('empStatusInactive').value;

    if (getActive || getInactive) {
      let status = '';
      status = (getActive && !getInactive) ? 'ACTIVE' : (!getActive && getInactive) ? 'INACTIVE' : 'BOTH';
      return status;
    }
  }

  cancelReport() {
    this.reportService.cancelReport(this.reportDetails, this.parent);
  }

  ngOnDestroy(): void {
    if (this.currentOrgSubscription) {
      this.currentOrgSubscription.unsubscribe();
    }
    this.parent.clearErrorMsg();
    this.reportService.errorInEmpLastName = false;
    this.reportService.errorInAccntHolderLastName = false;
    this.parent.reportFormIsDirty = false;
  }
}
