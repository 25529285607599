<sdps-modal id='beta-edit-request-modal' #focusElement [sdpsTitle]="title" [size]="'flex--medium'"
           (close)="resetDisableIcon()" (addlinkAfterIf)="focusModal()">

  <div slot="modal-body">

    <p class="adl-modal__heading">
      <span><strong>Request ID: {{ requestId }}</strong></span>
      <span class="sdps-pull-right"><strong>Account No: {{ accountNumber }}</strong></span>
    </p>

    <sdps-notification type="error" class="sdps-m-top_none" *ngIf="(editRequestsForm.get('employeeFirstName').value && editRequestsForm.get('employeeFirstName').invalid) ||
        (editRequestsForm.get('employeeLastName').value && editRequestsForm.get('employeeLastName').invalid) ||
         (editRequestsForm.get('accountHolderFirstName').value && editRequestsForm.get('accountHolderFirstName').invalid) ||
          (editRequestsForm.get('accountHolderLastName').value && editRequestsForm.get('accountHolderLastName').invalid)">
      <div class="sdps-notification__text">
        <p [innerHTML]="addLinkConstant.pleaseCorrectFollowingError"></p>
        <ul class="sdps-navigation--horizontal__list error-list">
          <li *ngIf="(editRequestsForm.get('employeeFirstName').value && editRequestsForm.get('employeeFirstName').invalid) ||
        (editRequestsForm.get('employeeLastName').value && editRequestsForm.get('employeeLastName').invalid) ||
         (editRequestsForm.get('accountHolderFirstName').value && editRequestsForm.get('accountHolderFirstName').invalid) ||
          (editRequestsForm.get('accountHolderLastName').value && editRequestsForm.get('accountHolderLastName').invalid)"
              [innerHTML]="addLinkConstant.textFieldErrorMsg.invalidCharacterForName"></li>
        </ul>
      </div>
    </sdps-notification>

    <form class="container" [formGroup]="editRequestsForm">

      <div class="sdps-grid-fluid sdps-wrap">

        <div class="sdps-row sdps-m-vertical_small sdps-wrap">

          <div class="col-4">
            <div class="ems-input edit-modal-input-textbox" [ngClass]="{'invalidField': (editRequestsForm.get('employeeFirstName').value && editRequestsForm.get('employeeFirstName').invalid)}">
              <span class="p-float-label">
                <input type="text" formControlName="employeeFirstName" maxlength="50" emsNamePatternCheck emsCharacterLimit
                       (emsCharacterLimitCheck)="showTextboxCharLimit = $event"
                       [charMaxLimit]="commonConstants.charLimitValues.fiftyMaxLimit" pInputText />
                <label>Employee First Name<em>*</em></label>
              </span>
              <p class="col-6 sdps-text-right sdps-p-around_none sdps-m-around_none sdps-text-small" *ngIf="editRequestsForm.get('employeeFirstName').value && editRequestsForm.get('employeeFirstName').value.length > showTextboxCharLimit">{{addLinkConstant.characterLimit}}
                <span class="character-limit">{{editRequestsForm.get('employeeFirstName').value.length}}/
                  {{commonConstants.charLimitValues.fiftyMaxLimit}}</span>
              </p>
            </div>
          </div>

          <div class="col-4">
            <div class="ems-input edit-modal-input-textbox" [ngClass]="{'invalidField': (editRequestsForm.get('employeeLastName').value && editRequestsForm.get('employeeLastName').invalid)}">
              <span class="p-float-label">
                <input type="text" formControlName="employeeLastName" maxlength="50"
                       emsNamePatternCheck emsCharacterLimit
                       (emsCharacterLimitCheck)="showTextboxCharLimit = $event"
                       [charMaxLimit]="commonConstants.charLimitValues.fiftyMaxLimit" pInputText/>
                <label>Employee Last Name<em>*</em></label>
              </span>
              <p class="col-6 sdps-text-right sdps-p-around_none sdps-m-around_none sdps-text-small" *ngIf="editRequestsForm.get('employeeLastName').value && editRequestsForm.get('employeeLastName').value.length > showTextboxCharLimit">{{addLinkConstant.characterLimit}}
                <span class="character-limit">{{editRequestsForm.get('employeeLastName').value.length}}/{{commonConstants.charLimitValues.fiftyMaxLimit}}</span>
              </p>
            </div>
          </div>
        </div>
        <div class="sdps-row sdps-wrap">

          <div class=" col-4">

            <div class="ems-input edit-modal-input-textbox" [ngClass]="{'invalidField': (editRequestsForm.get('accountHolderFirstName').value && editRequestsForm.get('accountHolderFirstName').invalid) || (editRequestsForm.get('employeeFirstName').value && editRequestsForm.get('employeeFirstName').invalid && editRequestsForm.get('accountHolderSameAsEmployee').value === true)}">
              <span class="p-float-label">
                <input type="text" formControlName="accountHolderFirstName" maxlength="50"
                       emsNamePatternCheck emsCharacterLimit
                       (emsCharacterLimitCheck)="showTextboxCharLimit = $event"
                       [charMaxLimit]="commonConstants.charLimitValues.fiftyMaxLimit" pInputText />
                <label>Account Holder First Name<em>*</em></label>
              </span>
              <p class="col-6 sdps-text-right sdps-p-around_none sdps-m-around_none sdps-text-small" *ngIf="editRequestsForm.get('accountHolderFirstName').value && editRequestsForm.get('accountHolderFirstName').value.length > showTextboxCharLimit">{{addLinkConstant.characterLimit}}
                <span class="character-limit">{{editRequestsForm.get('accountHolderFirstName').value.length}}/{{commonConstants.charLimitValues.fiftyMaxLimit}}</span>
              </p>
            </div>

            <div class="ems-checkbox">
              <p-checkbox formControlName="accountHolderSameAsEmployee" label="Acct. Holder Same as Emp." binary="true">
              </p-checkbox>
            </div>

          </div>

          <div class="col-4">
            <div class="ems-input edit-modal-input-textbox" [ngClass]="{'invalidField': (editRequestsForm.get('accountHolderLastName').value && editRequestsForm.get('accountHolderLastName').invalid) || (editRequestsForm.get('employeeLastName').value && editRequestsForm.get('employeeLastName').invalid && editRequestsForm.get('accountHolderSameAsEmployee').value === true)}">
              <span class="p-float-label">
                <input type="text" formControlName="accountHolderLastName" maxlength="50" emsNamePatternCheck emsCharacterLimit
                       (emsCharacterLimitCheck)="showTextboxCharLimit = $event"
                       [charMaxLimit]="commonConstants.charLimitValues.fiftyMaxLimit" pInputText />
                <label>Account Holder Last Name<em>*</em></label>
              </span>
              <p class="col-6 sdps-text-right sdps-p-around_none sdps-m-around_none sdps-text-small" *ngIf="editRequestsForm.get('accountHolderLastName').value && editRequestsForm.get('accountHolderLastName').value.length > showTextboxCharLimit">{{addLinkConstant.characterLimit}}
                <span class="character-limit">{{editRequestsForm.get('accountHolderLastName').value.length}}/{{commonConstants.charLimitValues.fiftyMaxLimit}}</span>
              </p>
            </div>
          </div>

          <div class="col-4">
            <ems-multiselect #affiliationsSelector [singleSelect]="true" [label]="'Emp to Acct Holder Affiliation'" [searchable]="false"
              [options]="affiliations" formControlName="employeeToAccountHolderAffiliation">
            </ems-multiselect>
          </div>

        </div>

      </div>

    </form>

  </div>

  <div slot="modal-footer">
    <button class="buttons" type="reset" (click)="closeModal();resetDisableIcon()">Cancel</button>
    <button type="button" class="buttons button-save" [disabled]="!editRequestsForm.valid || (!formValueChange && !editRequestsForm.get('accountHolderFirstName').dirty && !editRequestsForm.get('accountHolderLastName').dirty && !isAffiliationValChange)" (click)="submit()">Resubmit</button>
  </div>

</sdps-modal>
