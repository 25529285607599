import {Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {emtrConstant} from '../../../constants/emtrConstants';
import {SharedConstant, SharedService} from '@ems/shared';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {RuleService} from '../../../services/rule-service/rule.service';
import {SecurityTypeComponent} from '../../../rules/security-type/security-type.component';
import {InstrumentGroupFormDataService} from '../instrument-group-form-data/instrument-group-form-data.service';

@Component({
  selector: 'tr-step2-add-instruments',
  templateUrl: './step2-add-instruments.component.html',
})

export class Step2AddInstrumentsComponent implements OnInit, OnDestroy {

  addInstrumentsForm: UntypedFormGroup;
  getConstant = emtrConstant;
  getSharedConstant = SharedConstant;
  multiselectSelectedData: any;
  indSecsDuplicateErr: any = '';
  errorMsg = [];
  indSecsDuplicateErrMsg = '';
  addInstrumentFormData: any;
  confirmMessage: any;
  setPreviousStep = false;
  cancelButton = true;
  localObserver: any;

  @ViewChild('confirmationMessage', { static: true }) confirmationMessage: any;
  @ViewChild(SecurityTypeComponent) childSecurityType: SecurityTypeComponent;

  constructor(private fb: UntypedFormBuilder, private router: Router, private r: ActivatedRoute, private ruleService: RuleService, @Inject('commonConstants') public commonConstants, public sharedService: SharedService,
              @Inject('entitlementConst') public entitlementConst, public formDataService: InstrumentGroupFormDataService) {
    this.addInstrumentFormData = {...this.formDataService.getFormData()};
  }

  ngOnInit() {
    this.addInstrumentsForm = this.fb.group({
      indSecs: [[], Validators.required]
    });

    this.addInstrumentsForm.patchValue({
      indSecs: this.addInstrumentFormData.securityInfoList && this.addInstrumentFormData.securityInfoList.length > 0 ? this.addInstrumentFormData.securityInfoList : []
    });
    this.confirmMessage = this.confirmationMessage.confirmMessage;
    this.confirmMessage.message = this.getConstant.rulesConstant.confirmationMessage.menuClickMsg;
  }

  updateIndividualSecurities(securities) {
    if (this.addInstrumentFormData) {
      this.addInstrumentsForm.patchValue({indSecs: securities});
      /* Setting the Form to Dirty if Autocomplete Record is selected */
      this.addInstrumentsForm.markAsDirty();
    }
  }

  updateMultiselectValues(selecteddata) {
    this.multiselectSelectedData = selecteddata;
  }

  displayErrorAutocomplete(errorMsg) {
    if (errorMsg.msg === this.getConstant.rulesConstant.autoComplete.duplicateRecord) {
      /*To show the duplicate record information msg*/
      this.ruleService.toastType.emit('info');
      this.ruleService.toastMsg.emit(errorMsg.msg);
    } else {
      this.indSecsDuplicateErr = errorMsg.msg;
      this.ruleService.toastType.emit('');
      this.ruleService.toastMsg.emit(errorMsg.msg);
    }

    /*To show 'No Records Found' error msg*/
    if (this.indSecsDuplicateErr) {
      this.indSecsDuplicateErrMsg = this.indSecsDuplicateErr;
      if (this.errorMsg.indexOf(this.indSecsDuplicateErr) === -1) {
        this.errorMsg.push(this.indSecsDuplicateErr);
      }
      this.ruleService.showSchError.emit(true);
    } else {
      if (this.indSecsDuplicateErrMsg) {
        if (this.errorMsg.indexOf(this.indSecsDuplicateErrMsg) !== -1) {
          this.errorMsg.splice(this.errorMsg.indexOf(this.indSecsDuplicateErrMsg), 1);
        }
      }
    }

    /*Hide the error block when errorMsg is empty*/
    if (this.errorMsg.length === 0) {
      this.ruleService.showSchError.emit(false);
    }

    this.ruleService.schErrorMsg.emit(this.errorMsg);
  }

  cancelCreateInstGroup() {
    this.confirmMessage.message = this.getConstant.rulesConstant.confirmationMessage.cancelBtnClickMsg;
    this.router.navigate([this.getConstant.instrumentGroups.instrumentGroupList.toLandingPage]);
  }

  goToNextStep() {
    this.storePageInfo('');
    this.router.navigate(['../step3'], {relativeTo: this.r});
  }

  goToPreviousStep() {
    this.setPreviousStep = true;
    this.storePageInfo('falseStep2');
    this.navigateTo('../step1');
  }

  navigateTo(path) {
    this.router.navigate([path], {relativeTo: this.r});
  }

  storePageInfo(type) {
    this.cancelButton = false;
    const formObj = {
      securityInfoList: this.addInstrumentsForm.get('indSecs').value
    };
    if (type === 'falseStep2') {
      this.formDataService.setStep2(formObj, false);
    } else {
      this.formDataService.setStep2(formObj, true);
    }
  }

  canDeactivate(event, observer) {
    this.confirmMessage.message = (this.confirmMessage.message !== undefined && this.confirmMessage.message) ? this.confirmMessage.message : this.getConstant.rulesConstant.confirmationMessage.menuClickMsg;
    if (this.cancelButton) {
      this.localObserver = observer;
      return this.confirmationMessage.showConfirmMsg(this.confirmMessage, observer);
    }
    return true;
  }

  ngOnDestroy() {
    this.ruleService.showSchError.emit(false);
    this.ruleService.toastMsg.emit('');
    this.ruleService.toastType.emit('');
  }

}
