import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {emtrConstant} from '../../../constants/emtrConstants';
import {InstrumentGroupFormDataService} from '../instrument-group-form-data/instrument-group-form-data.service';
import {ManageEmpGrpService, SharedConstant, SharedService} from '@ems/shared';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {EmtrPagination, EmtrPaginationValues} from '../../../models/pagination-model';
import {ActivatedRoute, Router} from '@angular/router';
import {Paginator} from 'primeng/paginator';
import {DefaultSecurityGridHeader} from '../../../models/search-security-modal.model';
import {InstrumentGroupService} from '../../../services/instrument-group-service/instrument-group.service';
import {RuleService} from '../../../services/rule-service/rule.service';
import {Observable, Observer} from 'rxjs';
import {EmtrService} from '../../../emtr.service';

@Component({
  selector: 'tr-step3-review-and-save',
  templateUrl: './step3-review-and-save-instrument-group.component.html'
})
export class Step3ReviewAndSaveInstrumentGroupComponent implements OnInit {

  @ViewChild('confirmationMessage', { static: true }) confirmationMessage: any;
  @ViewChild('p') paginator: Paginator;

  pageObject: EmtrPagination = EmtrPaginationValues;
  securityTableHeader = DefaultSecurityGridHeader;
  getConstant = emtrConstant;
  getSharedConstant = SharedConstant;
  noOfRecords: any = 15;
  reviewInstGroupData: any;
  reviewInstrumentGrpForm: UntypedFormGroup;
  showGroupNameCharLimit: boolean;
  showGroupDescCharLimit: boolean;
  businessUnitData = [];
  showActionItems = true;
  revertDisable = true;
  selectedSecurities: any;
  addOrRemovedSecurities: any;
  message: string;
  cancelButton = true;
  errorMsg = [];
  confirmMessage: any;
  localObserver: any;
  unAssignedSsId = [];
  assignedSsId = [];
  toolTipMessage: any;
  groupNameExists = false;
  instGroupNameExistsErrMsg = '';
  
  constructor(@Inject('commonConstants') public commonConstants, public fb: UntypedFormBuilder, private router: Router, public emtrService: EmtrService,
              private r: ActivatedRoute, public formDataService: InstrumentGroupFormDataService, public instrumentGrpService: InstrumentGroupService,
              public sharedService: SharedService, @Inject('entitlementConst') public entitlementConst, private manageEmpGrpService: ManageEmpGrpService, public ruleService: RuleService) {
    this.reviewInstGroupData = {...this.formDataService.getFormData()};
  }

  ngOnInit() {
    this.showActionItems = this.sharedService.checkEntitlements(this.entitlementConst.navUUID.emtrAccess.manageInstruments.uuid, '', '') && !this.instrumentGrpService.deletedIG;
    this.pageObject.pageNo = 0;
    this.pageObject.pageSize = 15;
    this.reviewInstrumentGrpForm = this.fb.group({
      groupName: '',
      description: '',
      businessUnit: ''
    });
    this.onChanges();
    this.populateData();
    if (!this.showActionItems) {
    this.reviewInstrumentGrpForm.get('groupName').disable();
    this.reviewInstrumentGrpForm.get('description').disable();
    }
    this.reviewInstrumentGrpForm.get('businessUnit').disable();
    this.confirmMessage = this.confirmationMessage.confirmMessage;
    this.confirmMessage.message = this.getConstant.rulesConstant.confirmationMessage.menuClickMsg;
    this.toolTipMessage = this.getConstant.ruleSteps.step4.descTooltip;
  }

  populateData() {
    this.selectedSecurities = this.reviewInstGroupData.securityInfoList;
    /* To form addOrRemovedSecurities list to display removed and newly selected securities on UI with green and red highlight */
    this.setAssignedAndUnassignedSsid();
    this.businessUnitData.push(this.reviewInstGroupData.businessUnit);
    this.reviewInstrumentGrpForm.patchValue({
      groupName: this.reviewInstGroupData.name,
      description: this.reviewInstGroupData.description,
      businessUnit: this.reviewInstGroupData.businessUnit
    });
  }

  cancelInstGroup() {
    this.navigateTo(this.getConstant.instrumentGroups.instrumentGroupList.toLandingPage);
    this.confirmMessage.message = this.getConstant.rulesConstant.confirmationMessage.cancelBtnClickMsg;
  }

  goToPreviousStep(path) {
    if (this.reviewInstrumentGrpForm.dirty && !this.revertDisable) {
      this.confirmMessage.message = this.getConstant.rulesConstant.confirmationMessage.previousBtnClickMsg;
      Observable.create((observer: Observer<boolean>) => {
        this.confirmationMessage.showConfirmMsg(this.confirmMessage, observer);
      }).subscribe((accept) => {
        if (accept) {
          this.errorMsg = [];
          this.emtrService.schErrorMsg.emit(this.errorMsg);
          this.emtrService.showSchError.emit(false);
          const formObj = {
            groupName: this.reviewInstrumentGrpForm.get('groupName').value.trim(),
            description: this.reviewInstrumentGrpForm.get('description').value,
            businessUnit: this.reviewInstrumentGrpForm.get('businessUnit').value
          };
          this.cancelButton = false;
          this.formDataService.setStep1(formObj, true);
          this.navigateTo(path);
        }
      });
    } else {
      this.formDataService.setStep3('', false);
      this.navigateTo(path);
    }
  }


  revertChanges() {
    this.reviewInstrumentGrpForm.get('groupName').setValue(this.reviewInstGroupData.name);
    this.reviewInstrumentGrpForm.get('description').setValue(this.reviewInstGroupData.description);
    this.revertDisable = true;
  }
  editGroupNameAndDescription() {
    this.revertDisable = false;
  }
  receiveDataFromChild(e) {
    this.noOfRecords = parseInt(e, 10);
    this.selectedSecurities = [...this.selectedSecurities];
    this.addOrRemovedSecurities = [...this.addOrRemovedSecurities];
  }

  saveGroup() {
    this.cancelButton = false;
    this.saveInstGroup('save');
  }

  saveInstGroup(inputData) {
    const reviewDataCopy: any = {...this.reviewInstGroupData};
    reviewDataCopy.assignSsId = this.assignedSsId;
    reviewDataCopy.unAssignSsId = this.unAssignedSsId;
    delete reviewDataCopy.instrumentGrpAssignInfo;
    delete reviewDataCopy.employeeGroupAssignInfo;
    delete reviewDataCopy.securityInfoList;
    delete reviewDataCopy.previousSecurityInfoList;
    this.getFormValues();
    if (inputData) {
      reviewDataCopy.name = this.reviewInstrumentGrpForm.get('groupName').value.trim();
      reviewDataCopy.description = this.reviewInstrumentGrpForm.get('description').value;
      reviewDataCopy.businessUnit = this.reviewInstrumentGrpForm.get('businessUnit').value.name;
    }
    this.checkWhetherInstGroupNameExists(reviewDataCopy);
  }

  /* To set value to assigned, unassigned and addOrRemovedSecurities */
  setAssignedAndUnassignedSsid() {
    this.addOrRemovedSecurities = [];
    this.assignedSsId = [];
    this.unAssignedSsId = [];
    const securitiesList: any = [...this.reviewInstGroupData.securityInfoList];
    const localFormDataObj: any = {...this.reviewInstGroupData};
    if (securitiesList && securitiesList.length > 0) {
      securitiesList.forEach(security => {
        /* Loop through selected security array and compare with the security that was already assigned to IG to create assignedSsId */
        if (localFormDataObj.previousSecurityInfoList.map(y => y.ssid).indexOf(security.ssid) === -1) {
          security.selectedSec = true;
          this.addOrRemovedSecurities.push(security);
          this.assignedSsId.push(security.ssid);
        }
      });
    }
    localFormDataObj.previousSecurityInfoList.forEach(securities => {
      /* Loop security that was already assigned to IG and compare with securities selected to filter the removed securities */
      const unAssignedVal = securitiesList.map(y => y.ssid).indexOf(securities.ssid) === -1;
      if (unAssignedVal) {
        this.addOrRemovedSecurities.push(securities);
        this.unAssignedSsId.push(securities.ssid);
      }
    });
  }

  getFormValues() {
    this.reviewInstGroupData.name = this.reviewInstrumentGrpForm.get('groupName').value.trim();
    this.reviewInstGroupData.description = this.reviewInstrumentGrpForm.get('description').value;
  }

  navigateTo(path) {
    this.router.navigate([path], {relativeTo: this.r});
  }

  saveInstGroupData(reviewDataCopy) {
    this.instrumentGrpService.saveInstrumentGroup(reviewDataCopy).subscribe(res => {
      this.message = '<strong>' + this.reviewInstrumentGrpForm.get('groupName').value.trim() + ' - ' + '</strong>' + this.getConstant.instrumentGroups.instrumentGroupList.instrumentGrpSaveMsg;
      this.manageEmpGrpService.sendMessage(this.message, '');
      this.navigateTo(this.getConstant.instrumentGroups.instrumentGroupList.toLandingPage);
    });
  }

  canDeactivate(event, observer) {
    this.showActionItems = this.sharedService.checkEntitlements(this.entitlementConst.navUUID.emtrAccess.manageInstruments.uuid, '', '') && !this.instrumentGrpService.deletedIG;
    this.confirmMessage.message = (this.confirmMessage.message !== undefined && this.confirmMessage.message) ? this.confirmMessage.message : this.getConstant.rulesConstant.confirmationMessage.menuClickMsg;
    if (this.cancelButton && this.showActionItems && !this.revertDisable) {
      this.localObserver = observer;
      return this.confirmationMessage.showConfirmMsg(this.confirmMessage, observer);
    }
    return true;
  }

  onChanges(): void {
    this.reviewInstrumentGrpForm.controls.groupName.valueChanges.subscribe(groupNameValue => {
      this.groupNameExists = false;
      const regExp = new RegExp('^[a-zA-Z0-9+_()\[\\],.\\- ]*$');
      if (groupNameValue && !regExp.test(groupNameValue)) {
        if (this.errorMsg.indexOf(this.getSharedConstant.textFieldErrorMsg.invalidCharacterForGrpOrRuleName) === -1) {
          this.errorMsg.push(this.getSharedConstant.textFieldErrorMsg.invalidCharacterForGrpOrRuleName);
        }
        this.ruleService.showSchError.emit(true);
      } else {
        this.errorMsg.splice(this.errorMsg.indexOf(this.getSharedConstant.textFieldErrorMsg.invalidCharacterForGrpOrRuleName), 1);
      }

      /*To remove group name already exist error msg from the errorMsg array*/
      if (this.errorMsg.indexOf(this.instGroupNameExistsErrMsg) !== -1) {
        this.errorMsg.splice(this.errorMsg.indexOf(this.instGroupNameExistsErrMsg), 1);
      }

      /*Hide the error block when errorMsg is empty*/
      if (this.errorMsg.length === 0) {
        this.ruleService.showSchError.emit(false);
      }

      this.ruleService.schErrorMsg.emit(this.errorMsg);
    });
  }

  checkWhetherInstGroupNameExists(ruleObj) {
    const formData = this.formDataService.getFormData();
    if (this.reviewInstGroupData.mode === 'EDIT' && this.reviewInstrumentGrpForm.get('groupName').value.trim() === formData.currentEditedName.trim()) {
      this.saveInstGroupData(ruleObj);
    } else {
      const type = this.sharedService.groupType === this.getSharedConstant.manageMasterGroups.groupTypeMGP ? this.sharedService.groupType : '';
      const planIdValue = '';
      this.instrumentGrpService.checkWhetherInstGrpExists(ruleObj.name, ruleObj.businessUnit).subscribe(res => {
        this.sharedService.decodeUrl = true;
        if (res && res.data !== null) {
          /* eslint-disable-next-line max-len */
          this.instGroupNameExistsErrMsg = this.getConstant.rulesConstant.ruleNameMsg.the + '<strong>' + ' " ' + this.reviewInstrumentGrpForm.get('groupName').value.trim() + '"' + '</strong>' + this.getConstant.instrumentGroups.instrumentGrpStep1.groupNameExists;
          this.groupNameExists = true;
          this.cancelButton = false;

          /*To show group name already exist error msg*/
          if (this.errorMsg.indexOf(this.instGroupNameExistsErrMsg) === -1) {
            this.errorMsg.push(this.instGroupNameExistsErrMsg);
          }
          this.ruleService.schErrorMsg.emit(this.errorMsg);
          this.ruleService.showSchError.emit(true);

        } else {
          this.saveInstGroupData(ruleObj);
        }
      });
    }
  }
}
