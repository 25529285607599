export interface EmpGrpAccTableHead {
  field?: string;
  header?: string;
}

export interface EmpGrpAccOrPartipantAccTableHead {
  DBS?: EmpGrpAccTableHead[];
  RBS?: EmpGrpAccTableHead[];
  SPS?: EmpGrpAccTableHead[];
}

export const empAccTableHeader: EmpGrpAccTableHead[] = [
  {field: 'empId', header: 'Employee ID'},
  {field: 'subAcctNum', header: 'Account Number'},
  {field: 'masterSubTypes', header: 'Master Account (SL)'},
  {field: 'firstName', header: 'First Name'},
  {field: 'lastName', header: 'Last Name'},
  {field: 'acctHolderFn', header: 'Account Holder <br> First Name'},
  {field: 'acctHolderLn', header: 'Account Holder <br> Last Name'},
  {field: 'ruleIds', header: 'No. of Rules'},
  {field: 'groupIds', header: 'No. of Employee Groups'}
];

export const empOrAccTableParticipantHeader: EmpGrpAccOrPartipantAccTableHead = {
  DBS: [
    {field: 'empId', header: 'Employee ID'},
    {field: 'subAcctNum', header: 'Account Number'},
    {field: 'masterSubTypes', header: 'Master Account (SL)'},
    {field: 'firstName', header: 'First Name'},
    {field: 'lastName', header: 'Last Name'},
    {field: 'acctHolderFN', header: 'Account Holder <br> First Name'},
    {field: 'acctHolderLN', header: 'Account Holder <br> Last Name'},
    {field: 'ruleIds', header: 'No. of Rules'},
    {field: 'groupIds', header: 'No. of Employee Groups'}
  ],

  SPS: [
    {field: 'empId', header: 'Employee ID'},
    {field: 'subAcctNum', header: 'Account Number'},
    {field: 'masterSubTypes', header: 'Master Account (SL)'},
    {field: 'firstName', header: 'First Name'},
    {field: 'lastName', header: 'Last Name'},
    {field: 'acctHolderFN', header: 'Account Holder <br> First Name'},
    {field: 'acctHolderLN', header: 'Account Holder <br> Last Name'},
    {field: 'ruleIds', header: 'No. of Rules'},
    {field: 'groupIds', header: 'No. of Employee Groups'}
  ],

  RBS: [
    {field: 'subAcctNum', header: 'Account Number'},
    {field: 'masterSubTypes', header: 'Master Account'},
    {field: 'firstName', header: 'First Name'},
    {field: 'lastName', header: 'Last Name'},
    {field: 'acctHolderFN', header: 'Account Holder <br> First Name'},
    {field: 'acctHolderLN', header: 'Account Holder <br> Last Name'},
    {field: 'ruleIds', header: 'No. of Rules'},
    {field: 'groupIds', header: 'No. of Plans'}
  ]
};
