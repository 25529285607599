import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  SimpleChanges,
  HostListener
} from '@angular/core';
import { SharedService } from '../../shared.service';
import {
  EmpTableHeader,
  empGrpOrPlanTableHeader,
  empGroupTableHeader,
  empGrpOrPlanTableSubHeader, EmpTableHead
} from '../../models/manage-employee-group-table-header';
import { DatePipe } from '@angular/common';
import { SharedConstant } from '../../constants/sharedConstants';
import { FormDataService } from '../../services/form-data/form-data.service';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import * as temp from 'moment';
const moment = temp['default'];
import { Paginator } from 'primeng/paginator';
import { CustomSortService } from '../../services/custom-sort/custom-sort.service';
import { Table } from 'primeng/table';
import {ManageEmployeeGroupsListActionMenuItems, ManageEmployeeGroupsListHistoryActionMenu} from '../../models/manage-employee-group-action-menu';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {DateRangeFilterComponent} from '../date-range-filter/date-range-filter.component';
import {Subscription} from 'rxjs';

@Component({
  selector: 'ems-shared-manage-employee-group',
  templateUrl: './shared-manage-employee-group.component.html',
  styleUrls: ['./shared-manage-employee-group.component.scss']
})
export class SharedManageEmployeeGroupComponent implements OnInit, OnDestroy, OnChanges {

  sharedConst = SharedConstant;
  @Output() employeeGroups: any = new EventEmitter();
  @Output() rowSelected: any = new EventEmitter();
  @Output() deleteEmployeeGroup: any = new EventEmitter();
  @Output() restoreEmployeeGroup: any = new EventEmitter();
  @Output() paginateEmployeeList: any = new EventEmitter();
  @Output() sendFilterObject: any = new EventEmitter();
  @Input() employees: any[];
  @Input() showSelection: any;
  @Input() empGrpformData: any;
  @Input() getOrgId: any;
  @Input() moduleType: any;
  moment = moment;
  showSelectedcount: any = '';
  public header: EmpTableHeader = empGrpOrPlanTableHeader;
  assignFormData: any;
  param = {
    pageNo: 1,
    pageSize: 15,
    sortField: ''
  };
  @Input() tableCountData: any = {
    totalRecords: 0,
    paginationCount: 0
  };
  selectedArr: any = [];
  unAssignedGrps: any[] = [];
  unAssignedGrpsArray: any[] = [];
  selection = false;
  empAcc: any;
  showActionMenu: boolean;
  actionMenu: any[];
  empId: any;
  parent: number;
  child: number;
  noOfRecords: any = 15;
  @ViewChild('empGrpDeleteConfirm') empGrpDeleteConfirm: any;
  @ViewChild('toastMessage') msgToast: any;
  @ViewChild('p', { static: true }) paginator: Paginator;
  @ViewChild(Table, { static: true }) tableComponent: Table;
  @ViewChild('viewHistory', { static: true }) viewHistoryData: any;
  @ViewChild('range') rangeCalendar: DateRangeFilterComponent;
  @Output() initialCount: any = new EventEmitter();
  @Output() empGrpEdit: any = new EventEmitter();
  @Output() tableRef: any = new EventEmitter();
  @Output() sendError: any = new EventEmitter();
  @Input() clientInfo: any;
  sortField: any;
  pageNo: any = 1;
  multiSortMeta: any = [];
  deFaultMultiSortMeta: any = [{field: 'updTs', order: -1}];
  empGrpHistoryRecord = {
    historyId: '',
    empGrpSubType: '',
    empGrpName: '',
    empGrpHistory: '',
    historyRecords: '',
    historyMessage: ''
  };
  processID: any = [];
  intervalJobs: any = {};
  showUpdatedCount = {
    data: {
      noOfEmployees: null,
      noOfSubAccounts: null
    }
  };
  showSpinnerFlag: any = {};
  showErrorFlag: any = {};
  wholeRecordArr: any = [];
  businessUnit: any;
  toggle = false;
  filterSelectedDate: any;
  filterClicked = false;
  empGroupOrPlanForm: UntypedFormGroup;
  filterBtnDisableForName = false;
  formFilterObj: any;
  defaultObj: any;
  orgId: any;
  schError = {
    minCharacter: false,
    invalidCharacter: false
  };
  showActionItems = false;
  clientsInfo: any = [];
  batchCitiOrg: any;
  errMsg = [];
  filterGutTrack = '';
  stateVal: any;
  initialLoad = false;
  initialOrgId: number;
  isSortingChanged = false;
  defaultSortDataField = {field: 'updTs', order: -1};
  public groupSubHeader: EmpTableHead[] = empGrpOrPlanTableSubHeader;
  currentOrgSubscription: Subscription;

  constructor(public fb: UntypedFormBuilder, public sharedService: SharedService, public datePipe: DatePipe, public formDataService: FormDataService, private router: Router,
              public customSortService: CustomSortService, @Inject('entitlementConst') public entitlementConst, public messageService: MessageService) {
    this.assignFormData = this.formDataService.getFormData();
    this.sharedService.setHistoryResponse.subscribe(value => {
      this.empGrpHistoryRecord.empGrpHistory = value.data.content;
      this.empGrpHistoryRecord.historyRecords = value.data.totalElements;
      this.empGrpHistoryRecord.historyMessage = value.message;
    });
    this.currentOrgSubscription = this.sharedService.clientInformation.subscribe(clientInfo => {
      if (clientInfo) {
        this.clientsInfo = clientInfo;
        this.orgId = clientInfo.orgId;
        this.businessUnit = clientInfo.businessUnit;
        this.multiSortMeta = [this.defaultSortDataField];
        this.resetFilterValues(this.orgId);
        this.showActionItems = this.sharedService.checkEntitlements(this.entitlementConst.navUUID.addLinkAppAccess.manageEmpGroup.uuid, '', '');
        this.filterGutTrack = this.businessUnit ? this.sharedConst[this.businessUnit].grpPlanFilterGutTrackValue : '';
        /* Setting the isClientChanged to false to avoid the condition pass writtin in manage employees/participants when navigating using No. of Accounts link. isClientChanged is causing trouble only during page reload on employee groups/plans. */
        this.sharedService.isClientChanged = false;
      }
    });
    this.batchCitiOrg = this.sharedService.applicationInfo.data.batchCitiOrg;
    this.stateVal = this.sharedService.getStateVal();
  }

  paramObj(pageNo, records, sortCol) {
    return this.param = {
      pageNo,
      pageSize: records,
      sortField: sortCol
    };
  }

  ngOnDestroy(): void {
    if (this.currentOrgSubscription) {
      this.currentOrgSubscription.unsubscribe();
    }
    if (this.moduleType === 'EmployeeGroup' && this.wholeRecordArr.length > 0) {
      this.wholeRecordArr.forEach((data) => {
        window.clearInterval(this.intervalJobs[data.id]);
      });
    }
    this.sharedService.updatedDate.next('');
  }

  ngOnInit() {
    this.empGroupOrPlanForm = this.fb.group({
      name: [''],
      description: [''],
      updTs: [''],
      showRecords: this.sharedConst.showRecords[0]
    });
    /* Set 'Active' as default value for Show Records dropdown */
    const showRecorValue = this.sharedConst.showRecords[0];
    this.empGroupOrPlanForm.get('showRecords').setValue(showRecorValue);

    this.header = this.businessUnit ? this.sharedService.removeActionsForRules(this.moduleType, [...empGrpOrPlanTableHeader[this.businessUnit]]) : this.sharedService.removeActionsForRules(this.moduleType, [...empGroupTableHeader]);
    this.customSortService.sortArr = [];
    this.stateVal && this.stateVal.stateManagementFor === 'employeeGroup' && this.stateVal.multiSortMeta ? this.multiSortMeta = this.stateVal.multiSortMeta : this.multiSortMeta = [this.defaultSortDataField];
    this.tableRef.emit(this.tableComponent);
    if (this.empGrpformData) {
      this.empGrpformData.filter(data => this.selectedArr.push(data));
      this.unAssignedGrps = this.selectedArr;
    }
    this.onChanges();
    this.initialLoad = true;
    this.initialOrgId = this.orgId;
    /* Assigning filter icon value from sessionStorage for State management*/
    if (this.stateVal && this.stateVal.filterObj && this.stateVal.stateManagementFor === 'employeeGroup') {
      this.toggle = this.stateVal.filterClicked;
      this.filterClicked = this.stateVal.filterClicked;
      if (this.toggle) {
        this.empGroupOrPlanForm.markAsDirty();
        this.updateformValues(this.stateVal.filterObj);
        this.formFilterObj = this.stateVal.filterObj;
      }
    }
    this.resetFilterForm();
  }

  resetFilterForm() {
    this.sharedService.resetFilterForm.subscribe(val => {
      /* if user changes the tab then it will reset the filter form for previous active tab but won't make any API call for that */
      if (val.tabChange && this.empGroupOrPlanForm.dirty) {
        this.empGroupOrPlanForm.reset();
        this.schError.invalidCharacter = false;
        this.schError.minCharacter = false;
        this.empGroupOrPlanForm.markAsPristine();
        this.sharedService.clearValidationFields();
        this.filterBtnDisableForName = false;
      }
      /* if user clicks to previous active tab then only it will make API call. API call will happen when toggle has been clicked. */
      if (val.tabName === this.sharedService.getSharedConstant[this.businessUnit].employeeGrpOrPlan && (this.toggle || this.filterClicked)) {
        this.toggle = false;
        this.filterClicked = false;
        const noOfRecords = this.param.pageSize;
        this.paginateEmployeeList.emit({
          paramObj: this.paramObj(this.pageNo, noOfRecords, this.sortField),
          filterObj: this.filterClicked ? this.formFilterObj : this.defaultObj
        });
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.employees.length > 0 && this.moduleType === 'EmployeeGroup') {
      this.wholeRecordArr.forEach((data) => {
        window.clearInterval(this.intervalJobs[data.id]);
      });
      this.showSpinner();
    }
  }

  onChanges() {
    this.empGroupOrPlanForm.get('name').valueChanges.subscribe(value => {
      if (value) {
        if (value.length < 2) {
          this.filterBtnDisableForName = true;
          this.schError.minCharacter = true;
        } else {
          this.filterBtnDisableForName = false;
          this.schError.minCharacter = false;
        }
        if (this.empGroupOrPlanForm.get('name').invalid) {
          this.schError.invalidCharacter = true;
        } else {
          this.schError.invalidCharacter = false;
        }
      } else {
        this.schError.invalidCharacter = false;
        this.schError.minCharacter = false;
        this.filterBtnDisableForName = false;
      }
      this.sharedService.validationFields.filterBtnDisableForGrpName = this.filterBtnDisableForName;
      this.sharedService.validationFields.invalidGrpName = this.schError.invalidCharacter;
      this.sharedService.setErrorMessage(this.sharedService.isAccountNumInvalid, this.errMsg);
      this.sendError.emit(this.schError);
    });
  }

  showSpinner() {
    this.employees.forEach((process) => {
      let count = 0;
      if (process.assignStatus && process.assignStatus.toLowerCase() === 'inprogress') {
        this.processID.push(process.id);
        const localObj = {id: process.id, count};
        this.showSpinnerFlag[process.id] = true;
        this.showErrorFlag[process.id] = false;
        this.wholeRecordArr.push(localObj);
        this.intervalJobs[process.id] = setInterval(() => {
          this.loadRecords(process.id, this.wholeRecordArr);
          count++;
          this.wholeRecordArr.forEach((data) => {
            if (data.id === process.id) {
              data.count = count;
              if (data.count === 5) {
                window.clearInterval(this.intervalJobs[process.id]);
              }
            }
          });
        }, 5000);
      }
    });
  }

  loadRecords(id, arr) {
    this.sharedService.getEmpGroupData(id).subscribe((empGrp: any) => {
      const localCount = this.wholeRecordArr.filter((val) => val.id === id);
      if (empGrp.data.assignStatus && empGrp.data.assignStatus.toLowerCase() === 'completed') {
        window.clearInterval(this.intervalJobs[id]);
        this.showUpdatedCount.data = empGrp.data;
        this.updateCount(id, 'Completed');
      } else if (empGrp.data.assignStatus && empGrp.data.assignStatus.toLowerCase() === 'failed') {
        window.clearInterval(this.intervalJobs[id]);
        this.updateCount(id, 'Failed');
      } else if (empGrp.data.assignStatus && empGrp.data.assignStatus.toLowerCase() === 'inprogress' && localCount[0].count === 5) {
        window.clearInterval(this.intervalJobs[id]);
        this.updateCount(id, 'Inprogress');
      }

    }, error => {
      this.updateCount(id, 'Error');
    });
  }

  updateCount(id, param) {
    this.wholeRecordArr.forEach((record) => {
      if (record.id === id && param === 'Completed') {
        this.showSpinnerFlag[id] = false;
        this.showErrorFlag[id] = false;
      } else {
        this.showErrorFlag[id] = true;
        this.showSpinnerFlag[id] = false;
      }
    });
  }

  receiveDataFromChild(e) {
    this.pageNo = 1;
    this.sortField = this.param.sortField;
    const noOfRecords = Number(e);
    this.sharedService.orgIdValue = this.orgId;
    this.sharedService.clearSuccessMsgs(this.messageService, this.showSelection);
    this.paginateEmployeeList.emit({
      paramObj: this.paramObj(this.pageNo, noOfRecords, this.sortField),
      filterObj: this.filterClicked ? this.formFilterObj : this.defaultObj
    });
  }

  customSort(event) {
    this.defaultObj = {
      orgId: this.orgId ? this.orgId : '',
      groupName: '',
      groupDescription: '',
      lastUpdatedFromDate: '',
      lastUpdatedToDate: '',
      active: true
    };
    /*When previous BU and current BU is not same, then set orgId = 0 which will avoid duplicate call. Again when the call happens, the BU will be updated so the orgid will be set*/
    if (this.sharedService.businessUnitVal !== this.businessUnit) {
      /*To make a call only when switching between DBS to SPS or SPS to DBS*/
      if (this.businessUnit !== 'RBS' && ((this.sharedService.businessUnitVal === 'DBS' && this.businessUnit !== 'DBS') || (this.sharedService.businessUnitVal === 'SPS' && this.businessUnit !== 'SPS'))) {
        this.sharedService.orgIdValue = this.orgId;
      } else {
        this.sharedService.orgIdValue = 0;
      }
    } else {
      this.sharedService.orgIdValue = this.orgId;
    }
    /*this line can be enabled in order to empty slected list while sorting*/
    if (event.multiSortMeta) {
      if (this.sortField !== this.customSortService.customSort(event)) {
        this.sortField = this.customSortService.customSort(event);
        this.stateVal = this.sharedService.getStateVal();
        let noOfRecords = this.param.pageSize;
        this.isSortingChanged = (JSON.stringify(event.multiSortMeta) === JSON.stringify([this.defaultSortDataField])) ? false : true;
        if (this.initialLoad) {
          /* Assigning updated page number from sessionStorage for State management*/
          if (this.stateVal && this.stateVal.stateManagementFor === 'employeeGroup' && this.stateVal.pageObj.pageNo) {
            noOfRecords = this.stateVal ? this.stateVal.pageObj.pageSize : this.param.pageSize;
            this.pageNo = this.stateVal.pageObj.pageNo;
            this.paginateEmployeeList.emit({
              paramObj: this.paramObj(this.pageNo, noOfRecords, this.sortField),
              filterObj: this.filterClicked ? this.formFilterObj : this.defaultObj
            });
            this.sharedService.stateValues.filterClicked = this.filterClicked;
            this.sharedService.setStateVal(JSON.stringify(this.sharedService.stateValues));
            setTimeout(() => {
              this.initialLoad = false;
              this.paginator.totalRecords = this.stateVal ? this.stateVal.totalElem : 0;
              this.paginator.changePage(this.stateVal.pageObj.pageNo - 1);
            }, 0);
          } else {
            this.pageNo = this.param.pageNo;
            this.paginateEmployeeList.emit({
              paramObj: this.paramObj(this.pageNo, noOfRecords, this.sortField),
              filterObj: this.filterClicked ? this.formFilterObj : this.defaultObj
            });
            if (this.stateVal && this.stateVal.stateManagementFor === 'employeeGroup') {
              this.sharedService.stateValues.filterClicked = this.filterClicked;
              this.sharedService.setStateVal(JSON.stringify(this.sharedService.stateValues));
            }
            this.initialLoad = false;
            this.paginator.changePage(0);
          }
        } else {
          this.sharedService.clearSuccessMsgs(this.messageService, this.showSelection);
          this.pageNo = 1;
          this.sharedService.stateValues.multiSortMeta = event.multiSortMeta;
          this.param.sortField =  event.multiSortMeta;
          this.paginateEmployeeList.emit({
            paramObj: this.paramObj(this.pageNo, noOfRecords, this.sortField),
            filterObj: this.filterClicked ? this.formFilterObj : this.defaultObj
          });
          this.paginator.changePage(0);
        }
      }
    }
  }

  paginate(e) {
    if (this.pageNo !== e.page + 1) {
      this.pageNo = e.page + 1;
      this.sortField = this.param.sortField;
      this.stateVal = this.sharedService.getStateVal();
      const noOfRecords = (this.stateVal && !this.showSelection) ? this.stateVal.pageObj.pageSize : this.param.pageSize;
      this.sharedService.orgIdValue = this.orgId;
      this.sharedService.clearSuccessMsgs(this.messageService, this.showSelection);
      this.paginateEmployeeList.emit({
        paramObj: this.paramObj(this.pageNo, noOfRecords, this.sortField),
        filterObj: this.filterClicked ? this.formFilterObj : this.defaultObj
      });
    }
  }

  formatDate(date) {
    const formattedDate = date && date.split('T');
    const splitedData = formattedDate[0];
    const newDate = this.datePipe.transform(splitedData, 'MM/dd/yy');
    return newDate;
  }

  navigateToRulesPage(data) {
    this.sharedService.stateValues.multiSortMeta = this.deFaultMultiSortMeta;
    this.sharedService.stateValues.pageObj.pageNo = 1;
    this.sharedService.loadDataTrackValue('client_redirect');
    this.sharedService.selectedGroupOrPlanName = data.name;
    this.router.navigate(['/ems/emtr/rules/rules-list']);
  }

  grpSelected(data, event) {
    if (data) {
      if (data.type === 'ORG') {
        this.selectedArr = [];
        this.assignFormData.employeeAccounts = [];
        /* eslint-disable-next-line max-len */
        event.target.matches('.p-highlight') ? this.selectedArr.push(data) : this.selectedArr.splice(this.selectedArr.indexOf(data), 1);
      } else {
        const index = this.selectedArr.map(x => x.type).indexOf('ORG');
        if (index > -1) {
          const localArr = this.selectedArr;
          localArr.splice(index, 1);
          this.selectedArr = [...localArr];
        }
      }
    } else {
      this.selection = !this.selection;
      /* eslint-disable-next-line max-len */
      if (!this.selection) {
        this.selectedArr = [];
      }
      const index = this.selectedArr.map(x => x.type).indexOf('ORG');
      if (index > -1) {
        const localArr = this.selectedArr;
        localArr.splice(index, 1);
        this.selectedArr = [...localArr];
      }
    }
    const unAssignedGrpArray = this.unAssignedGrps.filter(x => this.selectedArr.findIndex(y => x.id === y.id) === -1);
    this.unAssignedGrpsArray = [];
    unAssignedGrpArray.forEach(grp => {
      this.unAssignedGrpsArray.push(grp.id);
    });
    const emitData = {
      selectedArr: this.selectedArr,
      unassignedEmpGroup: this.unAssignedGrpsArray
    };
    this.employeeGroups.emit(emitData);
    this.rowSelected.emit();
  }

  getDatafromParent(e) {
    this.empAcc = e;
    const index = this.selectedArr.map(x => x.type).indexOf('ORG');
    this.parent = 0;
    this.child = 0;
    if (e && e.length >= 1 && index > -1) {
      this.selectedArr = [];
      this.getSelectedCount();
    } else {
      this.getSelectedCount();
    }
  }

  getSelectedCount() {
    let partialSelected;
    const localEmpId = [];
    if (this.empAcc && this.empAcc.length > 0) {
      // console.log('empAcc val in getSelectedCount of employee group : ', this.empAcc)
     /* this.empAcc.forEach(data => {
        if (data.parent) {
          /!* Edit Scenario partialSelected will be undefined so setting it to true *!/
          partialSelected = data.parent.partialSelected ? data.parent.partialSelected : false;
          if (partialSelected && data.parent.data.empId !== null && localEmpId.indexOf(data.parent.data.empId) === -1) {
            this.parent++;
            localEmpId.push(data.parent.data.empId);
          } else if (data.parent.data.empId.indexOf('null') !== 1) {
            this.parent++;
          }
          this.child++;
        } else {
          if (data.data.empId.indexOf('null') !== 1 && partialSelected) {
            this.parent++;
          }
        }
      });*/
      this.empAcc.forEach(data => {
        if (data) {
          /* Edit Scenario partialSelected will be undefined so setting it to true */
          // partialSelected = data.partialSelected ? data.partialSelected : false;
          if (data.empId !== null && localEmpId.indexOf(data.empId) === -1) {
            this.parent++;
            localEmpId.push(data.empId);
          } else if (data.empId && data.empId.indexOf('null') !== 1) {
            this.parent++;
          }
          this.child++;
        } /*else {
          if (data.data.empId.indexOf('null') !== 1 && partialSelected) {
            this.parent++;
          }
        }*/
      });
    }
  }

  deleteEmpGroup(grpData) {
    this.deleteEmployeeGroup.emit({
      groupData: grpData,
      filterObj: this.filterClicked ? this.formFilterObj : this.defaultObj,
      paramObj: this.param
    });
  }

  restoreEmpGroup(grpData) {
    this.restoreEmployeeGroup.emit({
      groupData: grpData,
      filterObj: this.filterClicked ? this.formFilterObj : this.defaultObj,
      paramObj: this.param
    });
  }

  editEmpGrp(rowData) {
    this.sharedService.isLoadPageOnClientChange = false;
    this.empGrpEdit.emit(rowData);
  }

  loadEmpGrpHistory(empData) {
    this.empGrpHistoryRecord.historyId = empData.id;
    this.empGrpHistoryRecord.empGrpSubType = empData.type;
    this.empGrpHistoryRecord.empGrpName = this.sharedConst[this.businessUnit].employeeGroupOrPlanHistory + ' - ' + empData.name;
    this.sharedService.loadViewHistory(this.getOrgId, empData, 'empgroup', this.viewHistoryData);
  }

  getHistoryPagination(id, data) {
    this.sharedService.getHistoryPaginatedValues(id, 'empgroup', data);
  }

  formData() {
    const actionItem = this.showActionItems ? ManageEmployeeGroupsListActionMenuItems : ManageEmployeeGroupsListHistoryActionMenu;
    actionItem.forEach(data => {
      if (this.batchCitiOrg === this.clientsInfo.orgId && data.label === 'History') {
        data.disabled = true;
      } else {
        data.disabled = false;
      }
    });
    if (this.sharedService.deletedGrpOrPlan) {
      this.actionMenu = actionItem.filter(item => item.label !== 'Delete');
    } else {
      this.actionMenu = actionItem.filter(item => item.label !== 'Restore');
    }
    return this.actionMenu;
  }

  @HostListener('document:click', [])
  onClick() { /* Capture the Click Event outside the function */
    if (!this.showActionMenu) {
      this.empId = '';
    }
    this.showActionMenu = false;
  }

  onActionMenuClick(event: any, ruleData: any) {
    const actionItem = event.target.parentElement.innerText;
    this.clearSuccessAndErrorMessages();
    if (actionItem === 'Delete') {
      this.deleteEmpGroup(ruleData);
    } else if (actionItem === 'History' && this.batchCitiOrg !== this.clientsInfo.orgId) {
      this.loadEmpGrpHistory(ruleData);
    } else if (actionItem === 'Restore') {
      this.restoreEmpGroup(ruleData);
    }
  }

  toggleFunction() {
    if (!this.showSelection) {
      this.sharedService.gutTracking(this.filterGutTrack);
    }
    this.toggle = !this.toggle;
    this.stateVal = this.sharedService.getStateVal();
    if (this.stateVal && this.stateVal.stateManagementFor === 'employeeGroup') {
      /* Assigning filter value to sessionStorage for State management*/
      this.sharedService.stateValues.toggle = this.toggle;
      this.sharedService.setStateVal(JSON.stringify(this.sharedService.stateValues));
    }
  }

  getSelectedDate(date) {
    this.filterSelectedDate = date;
    this.empGroupOrPlanForm.get('updTs').setValue(this.filterSelectedDate);
    if (this.filterSelectedDate !== '' && this.filterSelectedDate !== null && this.filterSelectedDate !== undefined) {
      this.empGroupOrPlanForm.markAsDirty();
    }
  }

  resetForm(resetSortFromUI?) {
    if (resetSortFromUI) {
      this.sortField = this.customSortService.customSort({multiSortMeta: this.multiSortMeta});
      this.param.sortField = this.sortField;
      this.multiSortMeta = [this.defaultSortDataField];
    }
    if (this.empGroupOrPlanForm) {
      this.empGroupOrPlanForm.reset();

      /* Set 'Active' as default value for Show Records dropdown */
      const showRecorValue = this.sharedConst.showRecords[0];
      this.empGroupOrPlanForm.get('showRecords').setValue(showRecorValue);
      this.sharedService.deletedGrpOrPlan = false;

      this.paginator.changePage(0);
      if (this.toggle) {
        this.rangeCalendar.clearSelection();
      }
    }
    this.filterClicked = false;
    this.filterBtnDisableForName = false;
    this.sharedService.stateValues.filterClicked = false;
    this.empGroupOrPlanForm.markAsPristine();
  }

  clickedClearButton(resetSortFromUI?) {
    this.resetForm(resetSortFromUI);
    this.pageNo = 1;
    // this.sortField = this.param.sortField;
    if (!resetSortFromUI) {
      this.defaultFiltering();
    }
  }

  defaultFiltering() {
    this.defaultObj = {
      orgId: this.orgId ? this.orgId : '',
      groupName: '',
      groupDescription: '',
      lastUpdatedFromDate: '',
      lastUpdatedToDate: '',
      active: true
    };
    this.pageNo = 1;
    this.sortField = this.param.sortField;
    this.sharedService.orgIdValue = this.orgId;
    const noOfRecords = this.stateVal ? this.stateVal.pageObj.pageSize : this.param.pageSize;
    this.paginateEmployeeList.emit({
      paramObj : this.paramObj(this.pageNo, this.param.pageSize, this.sortField),
      filterObj: this.defaultObj
    });
  }

  applyFilter() {
    /* Set the selected date value to the empGroupOrPlanForm before hitting the Filter API */
    if (this.rangeCalendar) {
      this.rangeCalendar.applyToDate();
      this.getSelectedDate(this.rangeCalendar.dateRangeForm.get('dateSelected').value);
    }
    this.filterClicked = true;
    this.sharedService.stateValues.filterClicked = this.filterClicked;
    const localUpdatedFormValues = this.empGroupOrPlanForm.getRawValue();
    const fromDate = [];
    if (this.filterSelectedDate !== undefined && this.filterSelectedDate !== '' && this.filterSelectedDate !== null) {
      this.filterSelectedDate.forEach(date => {
        fromDate.push(date);
      });
    }
    this.formFilterObj = {
      orgId: this.getOrgId ? this.getOrgId : this.orgId,
      groupName: localUpdatedFormValues.name ? localUpdatedFormValues.name : '',
      groupDescription: localUpdatedFormValues.description ? localUpdatedFormValues.description : '',
      lastUpdatedFromDate: fromDate.length > 0 ? moment(fromDate[0]).format('MM/DD/YYYY') : '',
      lastUpdatedToDate: fromDate.length > 0 ? moment(fromDate[1]).format('MM/DD/YYYY') : '',
      active: localUpdatedFormValues.showRecords ? localUpdatedFormValues.showRecords.value : '',
    };
    this.updateformValues(this.formFilterObj);
    this.paginator.changePage(0);
    this.pageNo = 1;
    this.sortField = this.param.sortField;
    this.sharedService.orgIdValue = this.orgId;
    this.stateVal = this.sharedService.getStateVal();
    const noOfRecords = this.stateVal ? this.stateVal.pageObj.pageSize : this.param.pageSize;
    this.clearSuccessAndErrorMessages();
    this.paginateEmployeeList.emit({
      paramObj: this.paramObj(this.pageNo, noOfRecords, this.sortField),
      filterObj: this.formFilterObj
    });
  }

  updateformValues(formVals) {
    this.sharedService.deletedGrpOrPlan = !formVals.active;
    this.sharedService.updatedDate.next(formVals.lastUpdatedFromDate ? {name: 'Require Indexing', fromDate: formVals.lastUpdatedFromDate, toDate: formVals.lastUpdatedToDate } : '');
    const showRecordLabel = formVals.active ? 'Active' : 'Deleted';
    this.empGroupOrPlanForm.patchValue({
      name: formVals.groupName ? formVals.groupName : '',
      description: formVals.groupDescription ? formVals.groupDescription : '',
      showRecords: {value: formVals.active, label: showRecordLabel}
    });
  }

  navigateToManageEmployee(data) {
    this.sharedService.loadDataTrackValue('client_redirect');
    this.sharedService.selectedGroupAndPlanNameInEmpGroup = data.name;
    this.sharedService.selectedGroupAndPlanIdInEmpGroup = data.id;
    /* Set navFromGrpOrPlan to true to call Apply filter in manage employees/participants page when navigating from Manage Employee Groups/Plans page using no. of account link */
    this.sharedService.navFromGrpOrPlan = true;
    this.router.navigate([this.sharedConst[this.businessUnit].toEmployeeOrParticipantLandingPage]);
  }

  resetFilterValues(orgId: any) {
    if (this.initialOrgId && orgId !== this.initialOrgId) {
      this.defaultFiltering();
      this.resetForm();
      this.toggle = false;
      this.filterClicked = false;
      this.sharedService.stateValues.filterClicked = false;
      this.initialOrgId = orgId;
      this.sharedService.stateValues.pageObj.pageSize = 15;
      this.sharedService.tableDropdownVal.next(15);
      this.sharedService.setStateVal(JSON.stringify(this.sharedService.stateValues));
    }
  }

  // Clear success & Error msg only. leave the warning msgs
  clearSuccessAndErrorMessages() {
    this.sharedService.clearSuccessMsgs(this.messageService, this.showSelection);
    this.sharedService.clearErrorMessage();
  }
}
