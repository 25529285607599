export interface ClientSelection {
  field?: string;
  header?: string;
  enum?: string;
}

export const ClientSelectionTableHeader: ClientSelection[] = [
  {field: 'clientName', header: 'Client / Registration Name', enum: ' '},
  {field: 'masterAccount', header: 'Master Account', enum: 'textbox'}
];

export const ClientSelectionTableHeaderForMasterGrp: ClientSelection[] = [
  {field: 'firstName', header: 'Client / Registration Name', enum: ' '},
  {field: 'subAcctNum', header: 'Master Account', enum: 'textbox'}
];
