import {Component, Inject, Input, OnChanges, Output, EventEmitter} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {AddlinkConstant} from '../../constants/addlinkConstants';

@Component({
  selector: 'addlink-addlink-datepicker',
  templateUrl: './addlink-datepicker.component.html',
  styleUrls: ['./addlink-datepicker.component.scss']
})

export class AddlinkDatepickerComponent implements OnChanges {

  @Input() dateForm: UntypedFormGroup;
  @Input() dateLabel: any;
  @Input() minDate: Date;
  @Input() maxDateValue: Date;
  @Input() emptyDateField: any;
  @Input() readonlyInput: any;
  @Input() dateRangeError: any;
  @Input() endDateLessThanFromDate: any;
  @Input() disabled: any;
  @Output() calendarValidationEvent = new EventEmitter<any>();

  showError: any;
  getConstant = AddlinkConstant;
  errorFlag = {
    isInValidDate: false,
    isPastDate: false,
    isFutureDate: false,
    isDateEmpty: false,
    isDateInRange: false,
    isToDateLessThanFromDate: false,
    dateLabel: ''
  };

  constructor(@Inject('commonConstants') public commonConstants) {
  }

  ngOnChanges() {
    this.setInputErroeFlagValue();
    this.onError();
  }

  onCloseMethod(event, dateEntered) {
    const dateSelected = dateEntered.el.nativeElement.querySelector('input').value;
    if (dateSelected) {
      const selectedDate = new Date(dateSelected);
      this.validateDate(selectedDate);
    } else {
      this.clearErrorFlag();
      this.onError();
    }
  }

  validateDate(selectedDate) {
    this.setInputErroeFlagValue();
    let minDate = this.minDate;
    if (minDate) {
      minDate = new Date(minDate.toDateString());
    }
    const maxDateValue = new Date(this.maxDateValue);
    if (selectedDate.toString() === this.getConstant.dateError.invalidDate) {
      this.errorFlag.isInValidDate = true;
      this.errorFlag.isPastDate = false;
      this.errorFlag.isFutureDate = false;
      this.onError();
      this.calendarValidationEvent.emit(this.errorFlag);
      return;
    } else if (selectedDate < minDate) {
      this.errorFlag.isPastDate = true;
      this.errorFlag.isInValidDate = false;
      this.errorFlag.isFutureDate = false;
    } else if (selectedDate > maxDateValue) {
      this.errorFlag.isPastDate = false;
      this.errorFlag.isInValidDate = false;
      this.errorFlag.isFutureDate = true;
    } else {
      this.errorFlag.isPastDate = false;
      this.errorFlag.isInValidDate = false;
      this.errorFlag.isFutureDate = false;
    }
    this.onError();
    this.calendarValidationEvent.emit(this.errorFlag);
  }

  onError() {
    this.setInputErroeFlagValue();
    if (this.errorFlag.isInValidDate || this.errorFlag.isPastDate || this.errorFlag.isFutureDate || this.errorFlag.isToDateLessThanFromDate || this.errorFlag.isDateEmpty || this.errorFlag.isDateInRange) {
      this.showError = true;
    } else {
      this.showError = false;
    }
  }

  clearErrorFlag() {
    this.errorFlag = {
      isInValidDate: false,
      isPastDate: false,
      isFutureDate: false,
      isDateEmpty: false,
      isDateInRange: false,
      isToDateLessThanFromDate: false,
      dateLabel: this.dateLabel
    };
    this.calendarValidationEvent.emit(this.errorFlag);
    this.showError = false;
  }

  setInputErroeFlagValue() {
    this.errorFlag.dateLabel = this.dateLabel;
    this.errorFlag.isToDateLessThanFromDate = this.endDateLessThanFromDate;
    this.errorFlag.isDateEmpty = this.emptyDateField;
    this.errorFlag.isDateInRange = this.dateRangeError;
  }
}
