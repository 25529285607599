<ems-confirm-message #confirmationMessage></ems-confirm-message>

<div class="heading-font sdps-p-bottom_small"
     [innerHTML]="getSharedConstant[businessUnit].step1IndicatorName"></div>

<div class="step-border-box sdps-p-around_large select-rule-type">
  <form [formGroup]="createEmpGrpRuleForm">
    <div class="sdps-grid-container sdps-wrap">
      <div class="sdps-row">
        <div class="rule-type-textbox sdps-p-around_medium col-3"
             [ngClass]="{'invalidField': ((createEmpGrpRuleForm.get('groupName').value && (createEmpGrpRuleForm.get('groupName').invalid) || groupNameExists))}">
          <span class="p-float-label">
            <input *ngIf="this.businessUnit==='DBS'|| this.businessUnit==='SPS'" type="text" name="groupName" [maxlength]="100" formControlName="groupName"
                   #focusName emsAlphaNumericSpecialCharacterValidation emsCharacterLimit
                   [charMaxLimit]="commonConstants.charLimitValues.hundredMaxLimit"
                   (emsCharacterLimitCheck)="showGroupNameCharLimit = $event" pInputText/>
          <input *ngIf="this.businessUnit==='RBS' || this.businessUnit===this.getSharedConstant.manageMasterGroups.groupTypeMGP" type="text" name="groupName" [maxlength]="100" formControlName="groupName"
                   #focusName emsCharacterLimit
                   [charMaxLimit]="commonConstants.charLimitValues.hundredMaxLimit"
                   (emsCharacterLimitCheck)="showGroupNameCharLimit = $event" pInputText/>
            <label>{{getSharedConstant[businessUnit].name}}
              <span class="sdps-text-market-red" [innerHTML]="commonConstants.asterix"></span>
            </label>
          </span>
        </div>

        <div class="col-6 sdps-p-left_xx-large sdps-flex" *ngIf="is10b5Client">
          <div class="sdps-p-top_medium">
            <p-checkbox class="emtr-checkbox" label="10b5-1" formControlName="enable10b5Checkbox"
                        (onChange)="selected10b5()" [disabled]="isEditMode" binary="true"></p-checkbox>
          </div>
          <div class="sdps-display-inline-block sdps-m-left_xx-large rule-type-textbox sdps-p-around_medium"
               [ngClass]="{'invalidField': (createEmpGrpRuleForm.get('planId').value && (createEmpGrpRuleForm.get('planId').invalid)), 'input-disabled': isEditMode }"
               *ngIf="createEmpGrpRuleForm.get('enable10b5Checkbox').value">
          <span class="p-float-label">
            <input type="text" [attr.disabled]="isEditMode ? true : null" name="planId" formControlName="planId" (blur)="removeLeadingZeroes()"
                   emsNumberPatterncheck pInputText/>
            <label>{{getConstant.manageEmployeeGroups.empGroup10b5Msg.planIdTxt}}
              <span class="sdps-text-market-red" [innerHTML]="commonConstants.asterix"></span>
            </label>
          </span>
          </div>
        </div>
      </div>
      <p class="col-3 sdps-text-right sdps-p-around_none sdps-m-around_none sdps-text-small"
         *ngIf="createEmpGrpRuleForm.get('groupName').value && createEmpGrpRuleForm.get('groupName').value.trim().length > showGroupNameCharLimit">{{getConstant.rulesConstant.characterLimit}}
        <span class="character-limit">{{createEmpGrpRuleForm.get('groupName').value.trim().length}}
          /{{commonConstants.charLimitValues.hundredMaxLimit}}</span>
      </p>

      <div class="sdps-row sdps-p-top_large">
        <div class="rule-type-textbox sdps-p-around_medium col-6">
          <span class="p-float-label">
            <textarea class="sdps-p-vertical_x-small group-desc" rows="5" maxlength="300" formControlName="description"
                      name="description" emsCharacterLimit (emsCharacterLimitCheck)="showGroupDescCharLimit = $event"
                      [charMaxLimit]="commonConstants.charLimitValues.threeHundredMaxLimit" pInputTextarea></textarea>
            <label>{{getSharedConstant[businessUnit].description}}</label>
          </span>
        </div>
      </div>
      <p class="col-6 sdps-text-right sdps-p-around_none sdps-m-around_none sdps-text-small"
         *ngIf="createEmpGrpRuleForm.get('description').value && createEmpGrpRuleForm.get('description').value.length > showGroupDescCharLimit">{{getConstant.rulesConstant.characterLimit}}
        <span class="character-limit">{{createEmpGrpRuleForm.get('description').value.length}}
          /{{commonConstants.charLimitValues.threeHundredMaxLimit}}</span>
      </p>
    </div>
  </form>
</div>

<ems-step-wizard-buttons [disableBtn]="createEmpGrpRuleForm?(createEmpGrpRuleForm.invalid || groupNameExists || (createEmpGrpRuleForm.get('groupName').value && createEmpGrpRuleForm.get('groupName').value.trim() ==='')):''"
                         [showCancelBtn]=true (closeBtn)="cancelCreateEmpGroup()" [showContinueBtn]="true"
                         (goToNext)="goToNextStep()"></ems-step-wizard-buttons>
