import {ShellConstant} from '../constants/shellConstants';

export interface ReportListActionMenu {
  label?: string;
  icon?: string;
  routerLink?: any;
  disabled?: boolean;
}

export const ReportListActionMenuItems: ReportListActionMenu[] = [
  {label: ShellConstant.reports.reportActionMenu.reportDetails, icon: 'sch-chat'},
  {label: ShellConstant.reports.reportActionMenu.downloadReport, icon: 'sch-file-excel'},
];

export const UploadListActionMenuItems: ReportListActionMenu[] = [
  {label: ShellConstant.uploadListPage.uploadActionMenu.downloadFile, icon: 'sch-download'},
  {label: ShellConstant.uploadListPage.uploadActionMenu.detailsReport, icon: 'sch-chat'},
  {label: ShellConstant.uploadListPage.uploadActionMenu.exportExcel, icon: 'sch-file-excel'}
];
