import { Inject, Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ConfigUrlService, SharedService } from '@ems/shared';

@Injectable({
    providedIn: 'root'
})
export class ChartsService {
    orgId: any;
    configURL = this.configService.setApplicationUrl();

    constructor(private httpService: HttpClient, @Inject('env') public env, private configService: ConfigUrlService, public sharedService: SharedService) {
    }

    getRestrictionData(): Observable<any> {
      const rstrctApi  = this.configURL.workbenchEndPoint + '/activeAndScheduled/restrictions';
      if (this.orgId === 0 || !this.orgId) {
        return this.httpService.get(rstrctApi );
      } else {
        let schwabClientHeader = [];
        schwabClientHeader = this.sharedService.setSchwabClientHeaders(this.sharedService.applicationInfo.data.queryParamMap.orgId, this.orgId, schwabClientHeader);
        return this.httpService.get(rstrctApi  + '/orgId/' + this.orgId + ' ?Schwab-Client-Ids= ' + schwabClientHeader);
      }
    }

    getOverrideData(): Observable<any> {
      const overrideApi  = this.configURL.workbenchEndPoint + '/activeAndScheduled/overrides';
      if (this.orgId === 0 || !this.orgId) {
        return this.httpService.get(overrideApi );
      } else {
        let schwabClientHeader = [];
        schwabClientHeader = this.sharedService.setSchwabClientHeaders(this.sharedService.applicationInfo.data.queryParamMap.orgId, this.orgId, schwabClientHeader);
        return this.httpService.get(overrideApi + '/orgId/' + this.orgId + '?Schwab-Client-Ids= ' + schwabClientHeader);
      }
    }

    getAuthorizedAgent(orgId): Observable<any> {
      let schwabClientHeader = [];
      schwabClientHeader = this.sharedService.setSchwabClientHeaders(this.sharedService.applicationInfo.data.queryParamMap.orgId, orgId, schwabClientHeader);
      const url = this.configURL.bffAddlinkAuthAgentEndPointV1 + '&workbench=true&Schwab-Client-Ids=' + schwabClientHeader;
      return this.httpService.get(url);
    }

    getAddlinkDataAll(): Observable<any> {
      return this.httpService.get(this.configURL.bffAddlinkRequestStatusCountEndPointV1);
    }

  getAddlinkClientData(orgId): Observable<any> {
    let schwabClientHeader = [];
    schwabClientHeader = this.sharedService.setSchwabClientHeaders(this.sharedService.applicationInfo.data.queryParamMap.orgId, orgId, schwabClientHeader);
    if (orgId && (orgId !== null && orgId !== 0)) {
      return this.httpService.get(this.configURL.bffAddlinkRequestStatusCountEndPointV1 + '&Schwab-Client-Ids= ' + schwabClientHeader);
    } else {
      return this.httpService.get(this.configURL.bffAddlinkRequestStatusCountEndPointV1);
    }
  }

    getMasterAccount(orgId): Observable<any> {
      let schwabClientHeader = [];
      schwabClientHeader = this.sharedService.setSchwabClientHeaders(this.sharedService.applicationInfo.data.queryParamMap.orgId, orgId, schwabClientHeader);
      return this.httpService.get(this.configURL.bffMasterAccountEndpointV1 + '&appSource=EMTR' + '&Schwab-Client-Ids= ' + schwabClientHeader);
    }

    getRestrictOrOverrideData(): Observable<any> {
      const overrideApi = this.configURL.bffRulesCountEndPointV1;
      if (this.orgId === 0 || !this.orgId) {
        return this.httpService.get(overrideApi);
      } else {
        let schwabClientHeader = [];
        schwabClientHeader = this.sharedService.setSchwabClientHeaders(this.sharedService.applicationInfo.data.queryParamMap.orgId, this.orgId, schwabClientHeader);
        return this.httpService.get(overrideApi + '&Schwab-Client-Ids= ' + schwabClientHeader);
      }
    }
}
