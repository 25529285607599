import { Component, OnInit } from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {SharedConstant, SharedService} from '@ems/shared';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  agreementsInfo = SharedConstant.agreements;
  constructor(public sharedService: SharedService) { }
  year: number = Date.now();
  releaseVersion: any;
  ngOnInit() {
    this.releaseVersion = (this.sharedService.applicationInfo && this.sharedService.applicationInfo.data) ? this.sharedService.applicationInfo.data.releaseNumber : '';
  }

  downloadAgreement() {
    let filename = '';
    this.sharedService.downloadFileSystem(this.agreementsInfo.sposName, 'spos')
      .subscribe((response: HttpResponse<Blob>) => {
        filename = this.agreementsInfo.fileName;
        this.sharedService.saveFile(response.body, filename);
      });
  }

}
