import {Component, Inject, Input, OnInit} from '@angular/core';
import {searchSecuritiesTableHeads} from '../../models/search-securities-table-header.model';
import {InstrumentGroupService} from '../../services/instrument-group-service/instrument-group.service';
import {DefaultValues, emtrConstant} from '../../constants/emtrConstants';
import {SharedService} from '@ems/shared';

@Component({
  selector: 'tr-view-symbols-modal',
  templateUrl: './view-symbols-modal.component.html',
  styleUrls: ['./view-symbols-modal.component.scss']
})
export class ViewSymbolsModalComponent implements OnInit {

  @Input() loadModal = false;

  modalTitle = '';
  modalId: any;
  colHeader: any[];
  tickerSymbolData: any[];
  getEmtrConstants = emtrConstant;
  totalRecords = 0;
  noOfRecords: any = DefaultValues.defaultTableRecords;
  businessUnit: any;
  orgId: any;

  constructor(@Inject('commonConstants') public commonConstants, public instrumentGroupService: InstrumentGroupService, public sharedService: SharedService) {
    this.sharedService.clientInformation.subscribe(clientInfo => {
      this.businessUnit = clientInfo && clientInfo.businessUnit;
      this.orgId = clientInfo && clientInfo.orgId;
    });
  }

  ngOnInit() {
    this.colHeader = searchSecuritiesTableHeads;
  }

  /*Open View Symbols Modal*/
  openModal(groupData) {
    console.log('loadModal : ', this.loadModal);
    this.modalTitle = groupData.name + ' Instrument Group';
    this.modalId = groupData.id;
    this.getTickerSymbolsData(groupData);
    this.sharedService.openModal('view-symbol-modal');
  }

  /*Close View Symbols Modal*/
  closeViewSymbolModal() {
    this.noOfRecords =  DefaultValues.defaultTableRecords;
    this.tickerSymbolData = [];
  }

  getTickerSymbolsData(groupData) {
    this.instrumentGroupService.getSecurityGroupbyID(groupData.id, this.businessUnit, this.orgId).subscribe((instGrpData: any) => {
      this.tickerSymbolData = instGrpData.data.securityInfoList;
      this.totalRecords = this.tickerSymbolData.length;
    });
  }

  receiveDataFromChild(e) {
    this.noOfRecords = parseInt(e, 10);
    this.tickerSymbolData = [...this.tickerSymbolData];
  }
}
