import {
  Component,
  OnInit,
  ViewChild,
  ViewEncapsulation,
  ViewChildren,
  QueryList,
  OnDestroy,
  Inject, ElementRef, AfterViewChecked, HostListener
} from '@angular/core';
import {AddlinkService} from '../../addlink.service';
import {RemarksModalComponent} from '../../shared/components/modals/remarks-modal/remarks-modal.component';
import {EditIndexKeyModalComponent} from '../../shared/components/modals/edit-index-key-modal/edit-index-key-modal.component';
import {RequestListService} from '../../shared/services/request-list.service';
import {Router, ActivatedRoute} from '@angular/router';
import {ResubmitRequestsModalComponent} from '../../shared/components/modals/resubmit-requests-modal/resubmit-requests-modal.component';
import {EditRequestsModalComponent} from '../../shared/components/modals/edit-requests-modal/edit-requests-modal.component';
import {Paginator} from 'primeng/paginator';
import {ExpandedRowComponent} from '../../shared/components/expanded-row/expanded-row.component';
import {Table} from 'primeng/table';
import {AddlinkConstant} from '../../constants/addlinkConstants';
import {CommonService} from '../../shared/services/common.service';
import {
  requestListDataRowColumns,
  requestListHeaderColumns,
  requestListSubHeaderDateColumns
} from '../../constants/requestColumns';
import {IRequest} from '../../constants/request.interface';
import {AccountNumberValidationService, LinkDelinkService, SharedConstant, SharedService} from '@ems/shared';
import {RequestListSubHeaderComponent} from '../../shared/components/request-list-sub-header/request-list-sub-header.component';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import * as temp from 'moment';
const moment = temp['default'];
import {DenyRequestsModalComponent} from '../../shared/components/modals/deny-requests-modal/deny-requests-modal.component';
import {ClientCentralNotesComponent} from '../../shared/components/modals/client-central-notes/client-central-notes.component';
import { RequestListActionMenuItems} from '../../models/request-list-action-menu';

export interface IPaginator {
  totalPages: number;
  totalElements: number;
  size: number; // page size #of req per page
  currentPageNumber: number;
  numberOfElements: number;
}

@Component({
  selector: 'addlink-request-list',
  templateUrl: './request-list.component.html',
  styleUrls: ['./request-list.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class RequestListComponent implements OnInit, OnDestroy, AfterViewChecked {

  public requests: IRequest[];
  sortColumn = 'requestId';
  sortOrder = 'desc';
  pageSize = 15;

  public pageObject: IPaginator = {
    size: this.pageSize,
    totalPages: 0,
    totalElements: 0,
    currentPageNumber: 0,
    numberOfElements: 0
  };

  public requestColumns: any[] = requestListHeaderColumns;
  public requestSubHeaderColumns: any[] = requestListSubHeaderDateColumns;
  public requestDataRowColumns: any[] = requestListDataRowColumns;

  public expandedRowFilter: string[];

  public filteredRequestColumns: any[];
  public filteredExpandedRowColumns: any[] = [];
  public dataRowListColumns: any[] = [];

  public selectedClientId: string;

  public selectedRequests: any[];

  public getAdlConst = AddlinkConstant;
  public clientInfoSubscribe: any;
  public clientInfo: any;
  clientName: string;
  businessUnitClass: string;
  requestListHeaderForm: UntypedFormGroup;
  getSharedConstant = SharedConstant;
  paginationCount: any = 0;

  @ViewChild('remarks', { static: true }) remarksViewChild: RemarksModalComponent;
  @ViewChild('editIndexKeyModal', { static: true }) editIndexKeyChild: EditIndexKeyModalComponent;
  @ViewChild('resubmitRequestsModal', { static: true }) resubitRequestsChild: ResubmitRequestsModalComponent;
  @ViewChild('denyRequestsModal', { static: true }) denyRequestsChild: DenyRequestsModalComponent;
  @ViewChild('editRequestsModal', { static: true }) editRequestsChild: EditRequestsModalComponent;
  @ViewChild('paginator', { static: true }) paginator: Paginator;
  @ViewChildren(ExpandedRowComponent) expandedrow: QueryList<ExpandedRowComponent>;
  @ViewChild('requestListTable', { static: true }) requestListTable: Table;
  @ViewChild('toastMessage', { static: true }) msgToast: any;
  @ViewChildren(RequestListSubHeaderComponent) subHeader: QueryList<RequestListSubHeaderComponent>;
  @ViewChild('clientCentralNotes', { static: true }) clientCentralNotesChild: ClientCentralNotesComponent;
  entitlements: any;
  isHidden: boolean;
  showMsg: any;
  showServiceError: any;
  toggle = false;
  showHeader = false;
  noRecordFiltermessage: any;
  accordionHeader = [];
  isExpanded = false;
  expandedRows = {};
  itemDataLength: number;
  subHeaderSchErrorMsg = [];
  isDisabled = false;
  filterState: any;
  selectedOptions = [];
  filterBtnDisable = false;
  filterBtnDisableForEmpName = false;
  filterBtnDisableForAcctNum = false;
  filterBtnDisableForMastAcctNum = false;
  statusDropdownData = [];
  filterClicked = false;
  subHeaderHasValue = false;
  storeFilterObj = {};
  takeActionMenuList = this.getAdlConst.takeActionMenuMsg.takeActionMenuTxt;
  selectedAction: any;
  externalAccess = false;
  usertype: any;
  hasADDLinkAccess = true;
  showActionItems = false;
  autoIndexEnabled: boolean;
  isDataTrackCalled = false;
  requestId: any;
  public getOrgId: any;
  businessUnit: any;
  bulkUploadBtnHide = false;

  @ViewChild('filterBtn') filterBtn: ElementRef;
  actionMenu: any[];
  showActionMenu: boolean;
  isSortingChanged = false;
  defaultSortDataField = { sortColumn: 'requestId', sortOrder: 'desc'};
  @HostListener('document:click', [])
  onClick() { /* Capture the Click Event outside the function */
    if (!this.showActionMenu) {
      this.requestId = '';
    }
    this.showActionMenu = false;
  }

  constructor(public fb: UntypedFormBuilder, public requestListService: RequestListService, public addlinkService: AddlinkService, private commonService: CommonService, public el: ElementRef,
              private router: Router, private activatedRouter: ActivatedRoute, @Inject('commonConstants') public commonConstants, public sharedService: SharedService,
              @Inject('entitlementConst') public entitlementConst, @Inject('env') public environment, public linkDelinkService: LinkDelinkService,
              private acctNumValidationService: AccountNumberValidationService) {
    this.requestListService.showRequestListError.subscribe((data) => {
      this.showServiceError = data;
    });

    /*Update the Resubmited or Denided request response in the request-list table*/
    this.requestListService.updateRequest.subscribe((data) => {
      if (data) {
        this.updateResubmitRequests(data);
      }
    });
    this.autoIndexEnabled =  this.sharedService.applicationInfo.data.autoIndexEnabled;
  }

ngOnDestroy(): void {
    if (this.clientInfoSubscribe && this.clientInfoSubscribe._subscriptions && this.clientInfoSubscribe._subscriptions.length > 0) {
      this.clientInfoSubscribe.unsubscribe();
    }
    if (typeof this.requestListService.sourceAndStatusMultiselectValues.subscribe === 'function') {
      this.requestListService.sourceAndStatusMultiselectValues.next(null);
    }
    this.requestListService.clearMessage();
    this.sharedService.updatedDate.next('');
    this.requestListService.showRequestListError.next(false);
    this.sharedService.requestStatus = '';
    this.sharedService.requestStatusFilterObj.liable407 = '';
  }

  /* Initialize component variables */
  ngOnInit() {
    this.requestListHeaderForm = this.fb.group({
      requestId: [''],
      action: [''],
      status: [this.selectedOptions],
      internalStatus: [this.selectedOptions],
      employeeId: [''],
      employeeName: [''],
      acctNumber: [''],
      client: [''],
      masterAccountNbr: [''],
      dateTo: this.fb.group({
        date: ['']
      }),
      dateFrom: this.fb.group({
        date: ['']
      })
    });

    this.requestListService.clearMessage();
    this.showMsg = null;
    this.requestListService.getMessage().subscribe((data) => {
      this.showMsg = data.text ? data.text : null;
      if (this.showMsg && this.showMsg !== null) {
        this.requestListService.showRequestListError.next(false);
        setTimeout(() => {
          /*this.msgToast.pushMessage('success', this.showMsg);*/
        }, 1);
      }
    });
    this.expandedRowFilter = [
      'acctHolderName',
      'accountHolderAffiliation',
      'authAgentName',
      'commissionCode',
      'source',
      'createdBy',
      'createdDate',
      'completedBy',
      'completedDate',
      'liable407',
      'indexKey'
    ];
    this.addlinkService.userType.subscribe(result => {
      this.usertype = result;
    });
    this.addlinkService.userPolicy.subscribe(result => {
      const userPolicy = result.getValue();
      this.entitlements = userPolicy.entitlements;
      if (this.usertype === 'External') {
        this.isHidden = true;
      } else {
        this.isHidden = false;
      }

      this.filterRequestColumns();
    });

    this.clientInfoSubscribe = this.addlinkService.clientInformation.subscribe(clientInfo => {
      if (clientInfo !== null) {
        this.bulkUploadBtnHide = clientInfo.charitable ? true : false;
        this.isExpanded = false;
        this.showActionItems = this.sharedService.checkEntitlements(this.entitlementConst.navUUID.accountLinking.uuid, '', '');
        this.hasADDLinkAccess = this.sharedService.checkEntitlements(this.entitlementConst.navUUID.accountSummary.uuid, '', '');
        this.selectedClientId = clientInfo.clientId;
        this.requestListService.getOrgId = clientInfo.orgId;
        this.clientName = clientInfo.k4ClientName;
        this.businessUnitClass = clientInfo.businessUnit;
        this.clientInfo = clientInfo;
        this.sortColumn = 'requestId';
        this.sortOrder = 'desc';
        this.clientName !== 'All' ? (clientInfo.externalAccess && clientInfo.externalAccess === 'Y' ? this.externalAccess = true : this.externalAccess = false) : this.externalAccess = false;
        this.clickedClearBtn();
        this.showHeader = false;
        this.expandedRows = {};
        this.accordionHeader = [];
        this.requestListService.getSourceAndStatusValue().subscribe(data => {
          this.requestListService.sourceAndStatusMultiselectValues.next(data);
        });
        if (!this.sharedService.requestStatus) {
          this.toggle = false;
        }
        this.showHeader = false;
      }
    });

    this.onChanges();

    this.addlinkService.schErrorMsg.subscribe(value => {
      this.subHeaderSchErrorMsg = value;
    });

    /* Disable the Apply filter button if any error is present */
    this.requestListService.disableFilterButton.subscribe(value => {
      this.filterBtnDisable = value;
    });

    if (this.requests) {
      this.requests.length < this.pageObject.size ? this.itemDataLength = this.requests.length : this.itemDataLength = this.pageObject.size;
    }
  }

  ngAfterViewChecked() {
    const userType = this.sharedService.isInternalApp() ? this.getAdlConst.internalStatusTxt : this.getAdlConst.externalStatusTxt;
    const elementRef = this.el.nativeElement.querySelector('.p-multiselect-label');
    this.sharedService.setMultiSelectDefaultLabel(elementRef, this.requestListHeaderForm, userType, this.selectedOptions.length);
  }

  filterRequestColumns(): void {
    this.filteredRequestColumns = [];
    this.filteredExpandedRowColumns = [];
    for (const mainHeader of this.requestColumns) {

      this.filteredRequestColumns.push(mainHeader);
      if (this.isHidden && (mainHeader.field === this.getAdlConst.requestListMainHeaderFieldTxt.client || mainHeader.field === this.getAdlConst.internalStatusTxt)) {
        const index = this.filteredRequestColumns.findIndex(x => x.field === this.getAdlConst.requestListMainHeaderFieldTxt.client || x.field === this.getAdlConst.internalStatusTxt);
        this.filteredRequestColumns.splice(index, 1);
      } else if (!this.isHidden && mainHeader.field === this.getAdlConst.externalStatusTxt) {
        const index = this.filteredRequestColumns.findIndex(x => x.field === this.getAdlConst.externalStatusTxt);
        this.filteredRequestColumns.splice(index, 1);
      }
    }

    /*Pushed Sub headers into requestSubHeaderColumns*/
    for (const subHeader of this.requestSubHeaderColumns) {
      this.filteredExpandedRowColumns.push(subHeader);
    }

    /*Pushed Sub headers into requestDataRowColumns*/
    for (const subHeader of this.requestDataRowColumns) {
      this.dataRowListColumns.push(subHeader);
      /* eslint-disable-next-line max-len */
      if (this.isHidden && (subHeader.field === this.getAdlConst.requestListSubHeaderFieldTxt.liable407 || subHeader.field === this.getAdlConst.requestListSubHeaderFieldTxt.indexKey || subHeader.field === this.getAdlConst.requestListSubHeaderFieldTxt.commissionCode)) {
        /* eslint-disable-next-line max-len */
        const index = this.dataRowListColumns.findIndex(x => x.field === this.getAdlConst.requestListSubHeaderFieldTxt.liable407 || x.field === this.getAdlConst.requestListSubHeaderFieldTxt.indexKey || x.field === this.getAdlConst.requestListSubHeaderFieldTxt.commissionCode);
        this.dataRowListColumns.splice(index, 1);
      }
    }
  }

  sortColumnByField(e, field) {
    this.sharedService.clearErrorMessage();
    if (field === this.sortColumn) {
      this.sortOrder = this.sortOrder === 'desc' ? 'asc' : 'desc';
    } else {
      this.sortColumn = field;
      this.sortOrder = 'asc';
    }
    this.isSortingChanged = (this.defaultSortDataField.sortColumn === this.sortColumn && this.defaultSortDataField.sortOrder === this.sortOrder) ? false : true;
    this.requests = [];
    this.applyFilterCall();
  }

  resetPageObject() {
    this.pageObject = {
      size: this.pageSize,
      totalPages: 0,
      totalElements: 0,
      currentPageNumber: 0,
      numberOfElements: 0
    };
    this.requests = [];
    this.paginator.changePage(0);
    this.applyFilterCall();
  }

  refreshDataOnPageSizeChange() {
    this.sharedService.clearErrorMessage();
    this.resetPageObject();
  }

  public openRemarkPopup(e: any, requestId: string, accountNumber: string, status: string): void {
    this.remarksViewChild.openModal(e, requestId, accountNumber, status, this.showActionItems);
  }

  public isRequestActionable(request: any): boolean {
    const statusForActionable = this.isHidden ? request.status : request.internalStatus;
    if (statusForActionable === this.getAdlConst.statusLabel.onHold || statusForActionable === this.getAdlConst.statusLabel.validationMismatch || statusForActionable === this.getAdlConst.statusLabel.requestDenied || statusForActionable === this.getAdlConst.statusLabel.dbsActionNeeded) {
      return true;
    } else {
      return false;
    }
  }

  public isEligibleForEdit(request: any): boolean {
    if (request.action === this.getAdlConst.linkTxt) {
      if (request.internalStatus === this.getAdlConst.statusLabel.validationMismatch || (request.internalStatus === this.getAdlConst.statusLabel.onHold &&
        request.liable407 === 'Yes' && (request.indexKey === null || request.indexKey === '' || request.indexKey === 'null') && !this.isHidden)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  public editRequestPopup(e: any, request: any): void {
    const clientId = this.selectedClientId !== null ? this.selectedClientId : request.client.clientId;
    request.disableIcon = true;
    if (request.internalStatus === this.getAdlConst.statusLabel.validationMismatch) {
      this.editRequestsChild.openModal(e, request.requestId, request.acctNumber, request.orgId, request.authAgentId, request.masterAccountNbr,
        request.employeeFirstName, request.employeeLastName, request.accountOwnerFirstName, request.accountOwnerLastName, request.accountHolderAffiliation, request.source);
    } else {
      this.editIndexKeyChild.openModal(e, request.requestId, request.acctNumber, this.clientInfo.orgId);
    }
  }

  public enableResubmit(): boolean {
    if (this.selectedRequests && this.selectedRequests.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  public bulkSubmit(): void {
    /* Set isDTBtnClicked value to true, generate the data track value with 'linkid-lc' . */
    this.sharedService.isDTBtnClicked = true;
    this.sharedService.loadDataTrackValue('upload');
    this.sharedService.parentPage = '../request-list';
    this.router.navigate(['../addlink-upload'], {relativeTo: this.activatedRouter});
  }

  public takeActionPopup(event, actionValue): void {
    this.sharedService.clearErrorMessage();
    if (!this.sharedService.isInternalApp()) {
      this.sharedService.gutTracking('adobe-lc_resubmit|fireonce');
    } else if (!this.isDataTrackCalled) {
      this.isDataTrackCalled = true;
      this.sharedService.gutTracking('adobe-lc_takeaction|fireonce');
    }
    const requestIds: any[] = [];
    let validationMismatch = 0;
    let dbsActionNeeded = 0;
    let requestDenied = 0;
    let missingIndexKey = 0;
    for (const request of this.selectedRequests) {
      if (((actionValue === this.getAdlConst.takeActionMenuMsg.resubmitTxt && request.internalStatus !== this.getAdlConst.statusLabel.validationMismatch && request.internalStatus !== this.getAdlConst.statusLabel.dbsActionNeeded &&
        ((request.internalStatus === this.getAdlConst.statusLabel.onHold && (request.liable407 === 'Yes' && this.sharedService.isInternalApp() && request.indexKey !== null && request.indexKey !== '' && request.indexKey !== 'null') || request.liable407 === 'No') ||
          (request.internalStatus === this.getAdlConst.statusLabel.onHold && (request.liable407 === '' && !this.sharedService.isInternalApp() && (request.indexKey === null || request.indexKey === '' || request.indexKey === 'null'))))) ||
        (actionValue === this.getAdlConst.takeActionMenuMsg.denyTxt && request.internalStatus !== this.getAdlConst.statusLabel.requestDenied) ||
        actionValue === this.getAdlConst.takeActionMenuMsg.resolveTxt) && this.isRequestActionable(request)) {
        requestIds.push(request.requestId);
      } else if (request.internalStatus === this.getAdlConst.statusLabel.validationMismatch) {
        validationMismatch++;
      } else if (request.internalStatus === this.getAdlConst.statusLabel.dbsActionNeeded) {
        dbsActionNeeded++;
      } else if (request.internalStatus === this.getAdlConst.statusLabel.requestDenied) {
        requestDenied++;
      } else if (request.internalStatus === this.getAdlConst.statusLabel.onHold &&
        ((request.liable407 === 'Yes' && (request.indexKey === null || request.indexKey === '' || request.indexKey === 'null') && this.sharedService.isInternalApp()) ||
          (request.liable407 === '' && (request.indexKey === null || request.indexKey === '' || request.indexKey === 'null') && !this.sharedService.isInternalApp()))) {
        missingIndexKey++;
      }
    }
    setTimeout(() => {
      if (actionValue === this.getAdlConst.takeActionMenuMsg.resubmitTxt) {
        this.resubitRequestsChild.openModal(event, requestIds, validationMismatch, dbsActionNeeded, missingIndexKey, this.requestListTable, this.clientInfo.orgId);
      } else if (actionValue === this.getAdlConst.takeActionMenuMsg.denyTxt) {
        this.denyRequestsChild.openModal(event, requestIds, requestDenied, this.requestListTable, this.getAdlConst.denyRequestModalLabel, this.clientInfo.orgId);
      } else if (actionValue === this.getAdlConst.takeActionMenuMsg.resolveTxt) {
        this.denyRequestsChild.openModal(event, requestIds, 0, this.requestListTable, this.getAdlConst.resolveRequestModalLabel, this.clientInfo.orgId);
      }
      this.selectedAction = '';
    }, 1);
  }

  public updateSingleRequest(requestObject: any): void {
    for (const request of this.requests) {
      if (request.requestId === requestObject.requestId) {
        request.status = requestObject.status ? requestObject.status : request.status;
        request.internalStatus = requestObject.status ? requestObject.internalStatus : request.internalStatus;
        request.indexKey = requestObject.indexKey ? requestObject.indexKey : request.indexKey;
        request.employeeFirstName = requestObject.employeeFirstName ? requestObject.employeeFirstName : request.employeeFirstName;
        request.employeeLastName = requestObject.employeeLastName ? requestObject.employeeLastName : request.employeeLastName;
        request.accountOwnerFirstName = requestObject.accountOwnerFirstName ? requestObject.accountOwnerFirstName : request.accountOwnerFirstName;
        request.accountOwnerLastName = requestObject.accountOwnerLastName ? requestObject.accountOwnerLastName : request.accountOwnerLastName;
        request.accountHolderAffiliation = requestObject.accountHolderAffiliation ? requestObject.accountHolderAffiliation : request.accountHolderAffiliation;
        request.disableIcon = requestObject.disableIcon;

        this.isEligibleForEdit(request);
        if (requestObject.assignedToFirstName !== 'null' && requestObject.assignedToLastName !== 'null') {
          request.assignedToFirstName = requestObject.assignedToFirstName;
          request.assignedToLastName = requestObject.assignedToLastName;

        } else if (requestObject.assignedToFirstName === 'null' && requestObject.assignedToLastName === 'null') {
          request.assignedToFirstName = '';
          request.assignedToLastName = '';
        }
        if (requestObject.completedDate !== 'null' && requestObject.completedBy !== 'null') {
          request.completedDate = requestObject.completedDate;
          request.completedBy = requestObject.completedBy;
        } else if (requestObject.completedDate === 'null' && requestObject.completedBy === 'null') {
          request.completedDate = '';
          request.completedBy = '';

        }
        request.createdBy = requestObject.createdBy;
        request.createdDate = requestObject.createdDate;

        this.assignAuthAgents(request, requestObject);

        if (this.expandedrow) {
          const rows: ExpandedRowComponent[] = this.expandedrow.toArray();
          for (const row of rows) {
            row.filterRequest();
          }
        }
        break;
      }
    }
  }

  public updateResubmitRequests(requestObject: any): void {
    if (this.requests) {
      for (const request of this.requests) {
        if (request.requestId === requestObject.requestId) {
          request.status = this.isHidden ? requestObject.status : requestObject.internalStatus;
          request.internalStatus = this.isHidden ? requestObject.status : requestObject.internalStatus;
          this.assignAuthAgents(request, requestObject);

          if (requestObject.completedDate !== 'null' && requestObject.completedBy !== 'null') {
            request.completedDate = requestObject.completedDate;
            request.completedBy = requestObject.completedBy;
          } else if (requestObject.completedDate === 'null' && requestObject.completedBy === 'null') {
            request.completedDate = '';
            request.completedBy = '';

          }
          request.createdBy = requestObject.createdBy;
          request.createdDate = requestObject.createdDate;

          if (requestObject.employeeFirstName !== 'null' && requestObject.employeeLastName !== 'null'
            && requestObject.accountOwnerFirstName !== 'null' && requestObject.accountOwnerLastName !== 'null') {
            request.employeeFirstName = requestObject.employeeFirstName;
            request.employeeLastName = requestObject.employeeLastName;
            request.accountOwnerFirstName = requestObject.accountOwnerFirstName;
            request.accountOwnerLastName = requestObject.accountOwnerLastName;
          } else {
            request.employeeFirstName = '';
            request.employeeLastName = '';
            request.accountOwnerFirstName = '';
            request.accountOwnerLastName = '';
          }
          request.accountHolderAffiliation = requestObject.accountHolderAffiliation;
          if (requestObject.assignedToFirstName !== 'null' && requestObject.assignedToLastName !== 'null') {
            request.assignedToFirstName = requestObject.assignedToFirstName;
            request.assignedToLastName = requestObject.assignedToLastName;

          } else if (requestObject.assignedToFirstName === 'null' && requestObject.assignedToLastName === 'null') {
            request.assignedToFirstName = '';
            request.assignedToLastName = '';
          }
        }
        if (this.expandedrow) {
          const rows: ExpandedRowComponent[] = this.expandedrow.toArray();
          for (const row of rows) {
            row.filterRequest();
          }
        }
        this.selectedRequests = [];
      }
    }
  }

  public assignAuthAgents(request: any, requestObject: any): void {
    if (request.authAgent !== null) {
      request.authAgent.authAgentLastName = requestObject.authAgentLastName;
      request.authAgent.authAgentFirstName = requestObject.authAgentFirstName;
    }
  }

  toggleFctn() {
    this.toggle = !this.toggle;
    this.showHeader = !this.showHeader;
    if (this.accordionHeader.length !== 0) {
      this.showHeader = true;
    } else {
      this.showHeader = false;
    }
    this.requestListHeaderForm.patchValue({status: this.businessUnitClass === 'RBS' ? this.selectedOptions.slice(0, 1) : this.selectedOptions});
    this.requestListHeaderForm.patchValue({internalStatus: this.businessUnitClass === 'RBS' ? this.selectedOptions.slice(0, 1) : this.selectedOptions});
  }

  showFilterHeaders(rowData) {
    if (this.accordionHeader.indexOf(rowData) === -1) {
      this.accordionHeader.push(rowData);
      this.showHeader = true;
    } else {
      this.accordionHeader.splice(this.accordionHeader.indexOf(rowData), 1);
      if (this.accordionHeader.length === 0) {
        this.showHeader = false;
      }
    }
  }

  clickedClearBtn() {
    this.resetForm(false);
    this.resetPageObject();
  }

  resetForm(resetApiCall = true) {
    this.sortColumn = 'requestId';
    this.sortOrder = 'desc';
    this.isSortingChanged = false;
    this.sharedService.requestStatus = this.filterClicked ? '' : this.sharedService.requestStatus;
    this.requestListService.clearClicked.emit(true);
    this.sharedService.clearErrorMessage();
    this.requestListHeaderForm.reset();
    this.setDefaultValueToForm();
    this.filterClicked = false;
    if (resetApiCall) {
      this.resetPageObject();
    }
  }

  setDefaultValueToForm() {
    this.statusDropdownData = [];
    if (this.selectedOptions.length === 0) {
      this.requestListService.sourceAndStatusMultiselectValues.subscribe(data => {
        if (data !== null) {
          data.data.status.all.forEach(statusName => {
            const obj = {label: statusName, value: statusName};
            this.statusDropdownData.push(obj);
          });
          this.statusDropdownData.forEach((item) => this.selectedOptions.push(item.value));
        }
      });
    }
    const newObj = {label: this.getAdlConst.valueLabelAll, value: this.getAdlConst.valueLabelAll};
    this.requestListHeaderForm.patchValue({action: newObj});
    this.requestListHeaderForm.patchValue({status: this.businessUnitClass === 'RBS' ? this.selectedOptions.slice(0, 1) : this.selectedOptions});
    this.requestListHeaderForm.patchValue({internalStatus: this.businessUnitClass === 'RBS' ? this.selectedOptions.slice(0, 1) : this.selectedOptions});

    if (this.businessUnitClass === 'RBS') {
    this.requestListHeaderForm.get('internalStatus').disable();
    }
  }

  expandAll() {
    if (!this.isExpanded) {
      this.showHeader = true;
      this.requests.forEach(data => {
        this.expandedRows[data.requestId] = true;
        this.accordionHeader.push(data);
      });
    } else {
      this.showHeader = false;
      this.expandedRows = {};
      this.accordionHeader = [];
    }
    this.isExpanded = !this.isExpanded;
  }

  onRowExpand() {
    if (Object.keys(this.expandedRows).length === this.itemDataLength) {
      this.isExpanded = true;
    }
  }

  onRowCollapse() {
    if (Object.keys(this.expandedRows).length === 0) {
      this.isExpanded = false;
    }
  }

  onChanges(): void {
    this.requestListHeaderForm.get('requestId').valueChanges.subscribe((requestIdValue) => {
      if (requestIdValue && this.requestListHeaderForm.get('requestId').valid) {
        this.isDisabled = !this.isDisabled;
        this.filterState = 'disable';
      } else {
        this.isDisabled = !this.isDisabled;
        this.filterState = 'enable';
      }
      Object.keys(this.requestListHeaderForm.controls).forEach((controlName) => {
        if (controlName !== 'requestId') {
          this.requestListHeaderForm.controls[controlName][this.filterState]();
          if (this.filterState === 'disable') {
            this.requestListHeaderForm.controls[controlName].reset();
          }
        }
      });
      this.requestListService.disableForm.emit(this.filterState);
      if (this.filterState === 'disable') {
        this.setDefaultValueToForm();
      }
    });

    this.requestListHeaderForm.get('action').valueChanges.subscribe(value => {
      this.requestListService.sourceAndStatusMultiselectValues.subscribe(data => {
        this.statusDropdownData = [];
        if (data !== null) {
          if (value && value.value === this.getAdlConst.linkTxt) {
            data.data.status.link.forEach(statusName => {
              const obj = {label: statusName, value: statusName};
              this.statusDropdownData.push(obj);
            });
          } else if (value && value.value === this.getAdlConst.delinkMasterAccModal.delinkTxt) {
            data.data.status.delink.forEach(statusName => {
              const obj = {label: statusName, value: statusName};
              this.statusDropdownData.push(obj);
            });
          } else {
            data.data.status.all.forEach(statusName => {
              const obj = {label: statusName, value: statusName};
              this.statusDropdownData.push(obj);
            });
          }
          this.selectedOptions = [];
          this.statusDropdownData.forEach((item) => this.selectedOptions.push(item.value));
          this.requestListHeaderForm.patchValue({status: this.businessUnitClass === 'RBS' ? this.selectedOptions.slice(0, 1) : this.selectedOptions});
          this.requestListHeaderForm.patchValue({internalStatus: this.businessUnitClass === 'RBS' ? this.selectedOptions.slice(0, 1) : this.selectedOptions});
        }
      });
    });

    this.requestListHeaderForm.get('employeeId').valueChanges.subscribe(value => {
      this.filterBtnDisable = this.sharedService.isValueLengthGreaterThan2(value);
    });

    this.requestListHeaderForm.get('employeeName').valueChanges.subscribe(value => {
      this.filterBtnDisableForEmpName = this.sharedService.isValueLengthGreaterThan2(value);
    });

    this.requestListHeaderForm.get('masterAccountNbr').valueChanges.subscribe(value => {
      this.filterBtnDisableForMastAcctNum = this.acctNumValidationService.validateAccountNumber(value);
    });

    this.requestListHeaderForm.get('acctNumber').valueChanges.subscribe(value => {
      this.filterBtnDisableForAcctNum = this.acctNumValidationService.validateAccountNumber(value);
    });
  }

  clickedFilterBtn() {
    this.requestListService.filterClicked.emit(true);
    this.filterClicked = true;
  }

  applyFilter(event: any) {
    /* Clear Service Error */
    this.filterClicked = true;
    this.sharedService.requestStatus = '';
    this.sharedService.clearErrorMessage();
    const localUpdatedFormValues = this.requestListHeaderForm.getRawValue();
    const isStatusAll = this.statusDropdownData.every((val, index) => val.value === localUpdatedFormValues.internalStatus[index]);
    const isExtStatusAll = this.statusDropdownData.every((val, index) => val.value === localUpdatedFormValues.status[index]);
    const subHeaderFormValues = event;
    const fromDate = [];
    if (subHeaderFormValues.requestListSubHeaderForm.createdDate !== undefined && subHeaderFormValues.requestListSubHeaderForm.createdDate !== '' && subHeaderFormValues.requestListSubHeaderForm.createdDate !== null) {
      subHeaderFormValues.requestListSubHeaderForm.createdDate.forEach(date => {
        fromDate.push(date);
      });
    }
    const isSourceAll = subHeaderFormValues.sourceDropdownData.every((val, index) => val.value === subHeaderFormValues.requestListSubHeaderForm.source[index]);
    const formFilterObj: any = {
      acctNumber: localUpdatedFormValues.acctNumber ? localUpdatedFormValues.acctNumber.trim() : '',
      action: localUpdatedFormValues.action ? localUpdatedFormValues.action.value.trim() : '',
      orgId: this.clientInfo.orgId === 0 ? null : this.clientInfo.orgId,
      clientIds: [],
      fromDate: fromDate.length > 0 ? moment(fromDate[0]).format('MM/DD/YYYY') : '',
      liable407: subHeaderFormValues.requestListSubHeaderForm.liable407 ? subHeaderFormValues.requestListSubHeaderForm.liable407.value : '',
      masterAccountNbr: localUpdatedFormValues.masterAccountNbr ? localUpdatedFormValues.masterAccountNbr.trim() : '',
      requestId: localUpdatedFormValues.requestId ? localUpdatedFormValues.requestId : '',
      /* eslint-disable-next-line max-len */
      source: subHeaderFormValues.requestListSubHeaderForm.source ? (isSourceAll && (this.businessUnitClass === 'DBS' || this.businessUnitClass === 'SPS') ? subHeaderFormValues.requestListSubHeaderForm.source = [this.getAdlConst.valueLabelAll] : subHeaderFormValues.requestListSubHeaderForm.source) : [],
      /* eslint-disable-next-line max-len */
      status: (!this.isHidden && localUpdatedFormValues.internalStatus) ? (isStatusAll && (this.businessUnitClass === 'DBS' || this.businessUnitClass === 'SPS') ? localUpdatedFormValues.internalStatus = [this.getAdlConst.valueLabelAll] : localUpdatedFormValues.internalStatus) : (this.isHidden && localUpdatedFormValues.status) ? (isExtStatusAll ? localUpdatedFormValues.status = [this.getAdlConst.valueLabelAll] : localUpdatedFormValues.status) : [],
      toDate: fromDate.length > 0 ? moment(fromDate[1]).format('MM/DD/YYYY') : '',
      employeeId: localUpdatedFormValues.employeeId ? localUpdatedFormValues.employeeId : '',
      employeeLastName: localUpdatedFormValues.employeeName ? localUpdatedFormValues.employeeName.trim() : ''
    };
    this.updateformValues(formFilterObj);
    this.storeFilterObj = formFilterObj;
    this.pageObject.currentPageNumber = 0;
    this.paginator.changePage(0);
    this.applyFilterCall();
  }

  applyFilterCall() {
    if (this.sharedService.requestStatus) {      
      this.toggle = true;
      this.filterClicked = true;
      let fromDateValue = '';
      let toDateValue = '';
      let statusValue = this.sharedService.requestStatus;
      let actionValue = this.getAdlConst.valueLabelAll;
      let sourceValue = this.getAdlConst.valueLabelAll;
      if (this.sharedService.requestStatus === this.getAdlConst.statusLabel.requireIndexing || this.sharedService.requestStatus === this.getAdlConst.clientCentralLinks || this.sharedService.requestStatus === this.getAdlConst.clientCentralDelinks) {        
        const getCurrentDay = moment().tz('America/New_York').day();
        const getCurrentDate = new Date(moment().tz('America/New_York').format('MM-DD-YYYY'));
        if (this.sharedService.requestStatus === this.getAdlConst.statusLabel.requireIndexing) {
          if (getCurrentDay === 1) {
            fromDateValue = moment(getCurrentDate, 'MM/DD/YYYY').subtract(3, 'days').format('MM/DD/YYYY');
          } else {
            fromDateValue = moment(getCurrentDate, 'MM/DD/YYYY').subtract(1, 'days').format('MM/DD/YYYY');
          }
          toDateValue = moment(getCurrentDate, 'MM/DD/YYYY').subtract(1, 'days').format('MM/DD/YYYY');
        } else {
          fromDateValue = toDateValue = moment(getCurrentDate, 'MM/DD/YYYY').format('MM/DD/YYYY');
        }
        if (this.autoIndexEnabled === true && (statusValue === this.getAdlConst.statusLabel.requireIndexing)) {
          statusValue = this.getAdlConst.statusLabel.requireIndexing;
        } else {
          statusValue = this.getAdlConst.statusLabel.complete;
        }
        actionValue = this.sharedService.requestStatus === this.getAdlConst.clientCentralDelinks ? this.getAdlConst.delinkMasterAccModal.delinkTxt : this.getAdlConst.linkTxt;
        sourceValue = this.sharedService.requestStatus === this.getAdlConst.statusLabel.requireIndexing ? this.getAdlConst.sourceLabelTxt.aDDLinkDBS : this.getAdlConst.sourceLabelTxt.clientCentral;
      }
      if (this.sharedService.requestStatus === this.getAdlConst.statusLabel.onHold || this.sharedService.requestStatus === this.getAdlConst.statusLabel.validationMismatch || this.sharedService.requestStatus === this.getAdlConst.statusLabel.dbsActionNeeded) {
        this.requestListHeaderForm.markAsDirty()
      }
      this.sharedService.requestStatusFilterObj = {
        status: this.businessUnitClass === 'RBS' ? [this.getAdlConst.statusLabel.complete] : [statusValue],
        action: actionValue,
        source: this.businessUnitClass === 'RBS' ? [this.getAdlConst.sourceLabelTxt.clientCentral] : [sourceValue],
        liable407: this.sharedService.requestStatus === this.getAdlConst.statusLabel.requireIndexing ? 'Yes' : '',
        fromDate: fromDateValue,
        toDate: toDateValue,
        orgId: this.clientInfo.orgId === 0 ? null : this.clientInfo.orgId
      };
      const newObj = {
        label: this.sharedService.requestStatusFilterObj.action,
        value: this.sharedService.requestStatusFilterObj.action
      };
      this.requestListHeaderForm.patchValue({
        status: this.filterClicked ? this.sharedService.requestStatusFilterObj.status : this.getAdlConst.valueLabelAll,
        internalStatus: this.filterClicked ? this.sharedService.requestStatusFilterObj.status : this.getAdlConst.valueLabelAll,
        action: this.filterClicked ? newObj : this.getAdlConst.valueLabelAll
      });
      this.storeFilterObj = this.sharedService.requestStatusFilterObj;
    } else if (!this.filterClicked && (this.sharedService.requestStatus === '' || this.sharedService.requestStatus === undefined)) {
      const filterObj = {
        status: this.businessUnitClass === 'RBS' ? [this.getAdlConst.statusLabel.complete] : [this.getAdlConst.valueLabelAll],
        action: this.getAdlConst.valueLabelAll,
        source: this.businessUnitClass === 'RBS' ? [this.getAdlConst.sourceLabelTxt.clientCentral] : [this.getAdlConst.valueLabelAll],
        orgId: this.clientInfo.orgId === 0 ? null : this.clientInfo.orgId
      };
      this.storeFilterObj = filterObj;
    }
    if (this.hasADDLinkAccess) {
      this.requestListService.applyFilter(this.pageObject.currentPageNumber, this.pageSize, this.sortColumn, this.sortOrder, this.clientInfo.orgId, this.storeFilterObj).subscribe(result => {
        if (result.data !== null && result.data.pagerequests !== null) {
          this.populateData(result.data);
        } else {
          this.requests = result.data;
          this.noRecordFiltermessage = result.errorResponse.errorMessage;
          this.pageObject.totalElements = 0;
          this.paginationCount = 0;
        }
      });
    }
  }

  populateData(result) {
    this.requests = result.pagerequests.content;
    this.requests.forEach(item => {
      item.masterAccountNbr = (item.masterAccountLabel === null) ? ((item.masterAccountNbr !== null) ? ((item.masterAccountSubType !== null && item.masterAccountSubType !== undefined) ? item.masterAccountNbr + ' (' + item.masterAccountType + '-' + item.masterAccountSubType + ') '
        : item.masterAccountNbr + ' (' + item.masterAccountType + ') ') : '') : (item.masterAccountNbr !== null) ? (item.masterAccountLabel + '\n' + item.masterAccountNbr) : '';
      item.disableIcon = false;
    });
    this.pageObject.currentPageNumber = result.pagerequests.number;
    this.pageObject.totalElements = this.sharedService.totalElementsWithCommas(result.pagerequests.totalElements);
    this.paginationCount = result.pagerequests.totalElements;
    this.pageObject.totalPages = result.pagerequests.totalPages;
    this.pageObject.size = this.pageSize;
  }

  paginate(e) {
    this.showHeader = false;
    this.expandedRows = {};
    this.accordionHeader = [];
    this.isExpanded = false;
    if (this.pageObject.currentPageNumber !== e.page) {
      this.pageObject.currentPageNumber = e.page;
      this.sharedService.clearErrorMessage();
      this.applyFilterCall();
    }
  }

  public openNotesPopup(e: any, requestId: string, accountNumber: string, status: string): void {
    this.clientCentralNotesChild.openModal(e, requestId, accountNumber);
  }

  updateformValues(formVals) {
    this.sharedService.updatedDate.next(formVals.fromDate ? {name: 'Require Indexing', fromDate: formVals.fromDate, toDate: formVals.toDate } : '');
  }

  onActionMenuClick(event: any, rowData: any) {
    const actionItem = event.target.parentElement.innerText;
    this.sharedService.clearErrorMessage();
    if (actionItem === this.getAdlConst.requestListFilterFields.requestrulesLanding.client) {
      this.openNotesPopup(event, rowData.requestId, rowData.acctNumber, rowData.status);
    } else if (actionItem === this.getAdlConst.requestListFilterFields.requestrulesLanding.remarks) {
      this.openRemarkPopup(event, rowData.requestId, rowData.acctNumber, rowData.status);
    } else if (actionItem === this.getAdlConst.requestListFilterFields.requestrulesLanding.edit && !(!this.isEligibleForEdit(rowData) || rowData.disableIcon === true)) {
      this.editRequestPopup(event, rowData);
    }
  }

  formData(rowData) {
    const actionItem = RequestListActionMenuItems;
    actionItem.forEach(data => {
      if (data.label === 'Edit' && (!this.isEligibleForEdit(rowData) || rowData.disableIcon === true)) {
        data.disabled = true;
      } else {
        data.disabled = false;
      }
    });

    if (this.sharedService.isInternalApp()) {
      this.actionMenu = actionItem.filter(item => item.label !== this.getAdlConst.requestListFilterFields.requestrulesLanding.edit);
    } else {
      this.actionMenu = actionItem.filter(item => item.label === this.getAdlConst.requestListFilterFields.requestrulesLanding.remarks);
    }

    if (this.showActionItems) {
      actionItem.forEach(data => {
        if (data.label === 'Edit' && !data.disabled) {
          this.actionMenu.push(data);
        }
      });
    }
    return this.actionMenu;
  }

}
