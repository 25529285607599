import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, ActivatedRoute} from '@angular/router';
import {Injectable} from '@angular/core';
import {LocalStorageService} from './local-storage.service';
import {TokenService} from '@core_services/token.service';
import * as temp from 'moment';
const moment = temp['default'];
import { SharedService } from '@shared_library/public-api';
import {HttpResponse} from '@angular/common/http';

@Injectable()
export class ActivateRouteGuard implements CanActivate {

  constructor(
    private localStorageService: LocalStorageService,
    private router: Router,
    private tokenService: TokenService,
    private r: ActivatedRoute,
    public sharedService: SharedService
  ) {
  }

  currentPg = '/ems';

  canActivate(ng: ActivatedRouteSnapshot, router: RouterStateSnapshot) {
    const returnUrl: string = document.location.href.toString();

    this.currentPg = window.location.pathname;

    if (returnUrl.indexOf('code=') > 0) {
      const startIndex = returnUrl.indexOf('=');
      const endIndex = returnUrl.indexOf('&');
      const code = returnUrl.substring(startIndex + 1, endIndex + 1);
      this.tokenService.resolveAuthcode(code).subscribe((res: HttpResponse<any>) => {
        const token = res;
        const expiry = 'expiry';
        const extractToken = 'token';
        const getExpiryTime = ((parseInt(token[expiry], 10) - 30) / 60);
        const currentDate = new Date(moment().format('YYYY-MM-DDTHH:mm:ss'));
        const setExpiryTime = moment(currentDate).add(getExpiryTime, 'minutes').format('YYYY-MM-DDTHH:mm:ss');
        this.localStorageService.setExpiryDateTime(setExpiryTime);
        this.localStorageService.setToken(token[extractToken]);
        this.sharedService.removeHideUnauthorizedFlag();
        this.router.navigate([this.currentPg]);
        return true;
        }, error => {
              console.log('error getting eat token');
        });
      } else if (returnUrl.indexOf('#access_token=') > 0) {
      const token = returnUrl.substring(returnUrl.indexOf('=') + 1, returnUrl.indexOf('&token_type'));
      const expiryIn = parseInt(returnUrl.split('&expires_in')[1].substring(returnUrl.split('&expires_in')[1].indexOf('=') + 1, returnUrl.split('&expires_in')[1].indexOf('&scope')), 10);
      /********
       * subtracting 30 seconds from the expiry time received
       * Converting seconds to minutes
       * **********/
      const getExpiryTime = ((expiryIn - 30) / 60);
      const currentDate = new Date(moment().format('YYYY-MM-DDTHH:mm:ss'));
      const setExpiryTime = moment(currentDate).add(getExpiryTime, 'minutes').format('YYYY-MM-DDTHH:mm:ss');
      this.localStorageService.setToken(decodeURIComponent(token));
      this.localStorageService.setExpiryDateTime(setExpiryTime);
      this.sharedService.removeHideUnauthorizedFlag();
      window.location.href = encodeURI(this.currentPg);
      return true;
    } else {
      this.tokenService.getToken();
      return true;
    }
  }
}
