import {emtrConstant} from '../constants/emtrConstants';

export interface RuleListActionMenu {
  label?: string;
  icon?: string;
  routerLink?: any;
  disabled?: boolean;
}

export const RuleListActionMenuItems: RuleListActionMenu[] = [
  {label: emtrConstant.rulesConstant.ruleGlobalConst.override, icon: 'sch-transfer'},
  {label: emtrConstant.rulesConstant.ruleGlobalConst.restriction, icon: 'sch-transfer'},
  {label: emtrConstant.rulesConstant.rulesLanding.copy, icon: 'pi pi-copy'},
  {label: emtrConstant.rulesConstant.rulesLanding.history, icon: 'pi pi-clock'},
  {label: emtrConstant.rulesConstant.ruleGlobalConst.delete, icon: 'pi pi-trash'},
  {label: emtrConstant.rulesConstant.ruleGlobalConst.restore, icon: 'pi pi-undo'}
];

export const InstrumentGroupsListActionMenuItems: RuleListActionMenu[] = [
  {label: emtrConstant.rulesConstant.rulesLanding.history, icon: 'pi pi-clock'},
  {label: emtrConstant.rulesConstant.ruleGlobalConst.delete, icon: 'pi pi-trash'},
];

export const InstrumentGroupsListHistoryActionMenuItem: RuleListActionMenu[] = [
  {label: emtrConstant.rulesConstant.rulesLanding.history, icon: 'pi pi-clock'},
];

export const InstrumentGroupsListRestoreActionMenuItems: RuleListActionMenu[] = [
  {label: emtrConstant.rulesConstant.rulesLanding.history, icon: 'pi pi-clock'},
  {label: emtrConstant.rulesConstant.ruleGlobalConst.restore, icon: 'pi pi-undo'},
];
