<ems-confirm-message #confirmationMessage></ems-confirm-message>

<div class="heading-font sdps-display-inline"
     [innerHTML]="getConstant.instrumentGroups.instrumentGrpStep2.selectInstruments"></div>

<ul class="sdps-pull-right sdps-list--inline rule-info no-margin">
  <li>
    <span [innerHTML]="getSharedConstant.DBS.name+': '"></span>
    <strong [innerHTML]="addInstrumentFormData.name"></strong>
  </li>
</ul>
<p class="sdps-clearfix no-margin"></p>

<div class="step-border-box sdps-p-around_large select-rule-type">
  <div>
    <tr-securities-to-restrict (emitSelectedvalues)="updateMultiselectValues($event)" [isInstGrp]="true"
                               (securitiesChange)="updateIndividualSecurities($event)"
                               [autoCompleteSelectedData]="addInstrumentFormData.securityInfoList"
                               (throwErrorIndSec)="displayErrorAutocomplete($event)"></tr-securities-to-restrict>
  </div>
</div>

<ems-step-wizard-buttons [showCancelBtn]=true (closeBtn)="cancelCreateInstGroup()" [showContinueBtn]=true
                         [disableBtn]="!addInstrumentsForm.valid" (goToNext)="goToNextStep()" [showPreviousBtn]=true
                         (goToPrevious)="goToPreviousStep()">
</ems-step-wizard-buttons>
