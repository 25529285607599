import { Component, OnInit, OnDestroy } from '@angular/core';
import {Router} from '@angular/router';
import {SharedService} from '@ems/shared';

@Component({
  selector: 'tr-manage-master-groups',
  templateUrl: './manage-master-groups.component.html'
})
export class ManageMasterGroupsComponent implements OnInit, OnDestroy  {

  constructor(public router: Router, public sharedService: SharedService) {
    this.sharedService.deletedMastGrp = false;
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    this.sharedService.groupType = '';
    this.sharedService.deletedMastGrp = false;
  }
}
