export interface WorkflowStep {
  step?: string;
  valid?: boolean;
  name?: string;
}

export interface Workflows {
  rule?: WorkflowStep[];
  ruleGroup?: WorkflowStep[];
  editRule?: WorkflowStep[];
  manageEmpGrp?: WorkflowStep[];
  managePlans?: WorkflowStep[];
  instrumentGrps?: WorkflowStep[];
}

export const Workflow: Workflows = {
  ruleGroup: [
    {
      step: 'step1',
      valid: false,
      name: 'Create New Label'
    },
    {
      step: 'step2',
      valid: false,
      name: 'Assign'
    },
    {
      step: 'step3',
      valid: false,
      name: 'Review'
    }
  ],
  rule: [
    {
      step: 'step1',
      valid: false,
      name: 'Select Rule Type'
    },
    {
      step: 'step2',
      valid: false,
      name: 'Select Rule Attributes'
    },
    {
      step: 'step3',
      valid: false,
      name: 'Assign Rule'
    },
    {
      step: 'step4',
      valid: false,
      name: 'Review Rule'
    }
  ],
  editRule: [
      {
          step: 'step1',
          valid: false,
          name: 'Review Rule'
      },
      {
          step: 'step2',
          valid: false,
          name: ''

      }
  ],

  manageEmpGrp: [
    {
      step: 'step1',
      valid: false,
      name: 'Enter Group Details'
    },
    {
      step: 'step2',
      valid: false,
      name: 'Add Employees'
    },
    {
      step: 'step3',
      valid: false,
      name: 'Review and Save'
    }
  ],

  managePlans: [
    {
      step: 'step1',
      valid: false,
      name: 'Enter Plan Details'
    },
    {
      step: 'step2',
      valid: false,
      name: 'Add Employees'
    },
    {
      step: 'step3',
      valid: false,
      name: 'Review and Save'
    }
  ],

  instrumentGrps: [
    {
      step: 'step1',
      valid: false,
      name: 'Enter Group Details'
    },
    {
      step: 'step2',
      valid: false,
      name: 'Add Securities'
    },
    {
      step: 'step3',
      valid: false,
      name: 'Review and Save'
    }
  ]
};
