<div class="dropdown-check-list">
  <div class="anchor" [ngClass]="{'anchorDisabled' : isDisabled}" (click)="selectSecurity()">
          <span [ngClass]="(!showSelectedArray || showSelectedArray.length === 0) ? '' : 'security-select'">
              {{getConstant.multiselectText}}
          </span>
      <span class="dropdown-icon sdps-pull-right pi pi-caret-down security-arrow-icon"></span>
      <div class="security-selected-values" *ngIf="showSelectedArray && showSelectedArray.length">
          <div class="security-category-space" *ngFor="let data of showSelectedArray;let i = index">
              <span class="security-description">{{data.desc}}</span>
              <span *ngIf="i !== showSelectedArray.length-1">,</span>
          </div>
      </div>
  </div>
  <div id="items" class="items" *ngIf="showDropDown && !isDisabled">
      <label class="security-label dropdown-list" [ngClass]="{'highlight-security': highlightAll}">All
          <p-checkbox class="emtr-checkbox" [(ngModel)]="selectAllCheckbox" binary=false (ngModelChange)="selectedAll()"></p-checkbox>
      </label>
      <label class="security-label dropdown-list" *ngFor="let data of securityDropDownData;let i = index"
             [ngClass]="{'highlight-security': getClassByValue(data.code)}">{{data.desc}}
          <p-checkbox class="emtr-checkbox" [value]=data [(ngModel)]="selectedCategories" (ngModelChange)="checkboxChecked(data)" [inputId]=data></p-checkbox>
      </label>
  </div>
</div>
