import {Component, EventEmitter, Input, Output, ViewChild, Inject} from '@angular/core';
import {TableHeader, ViweHistoryTableHeader} from '../../models/view-history-table-header.model';
import {SharedConstant} from '../../constants/sharedConstants';
import {Paginator} from 'primeng/paginator';
import {HistoryMenu} from '../../models/history-menu.model';
import {SharedService} from '../../shared.service';

@Component({
  selector: 'ems-view-history',
  templateUrl: './view-history.component.html',
  styleUrls: ['./view-history.component.scss']
})
export class ViewHistoryComponent {

  sharedConstant = SharedConstant;
  searchHistoryInput: string;
  historyRecords: any = true;
  historyColumns: TableHeader[] = ViweHistoryTableHeader;
  @Input() historyData: any;
  @Output() paginateHistoryList: any = new EventEmitter();
  @Input() historyId: any;
  @ViewChild('p') paginator: Paginator;
  @Input() totalElements: any;
  @Input() historyTitle = '';
  @Input() getOrgId: any;
  @Input() ruleSubType: any;
  @Input() historyMessage: any;
  emptyMessage: any;
  selectedField: any;
  param = {
    pageNo: 0,
    pageSize: 15,
    sortField: '',
    orgId: ''
  };
  showDropDown = false;
  selectedCategories: any;
  categoryDropDownData: any;
  fieldNameArr: HistoryMenu[] = HistoryMenu;

  constructor(@Inject('commonConstants') public commonConstants, public sharedService: SharedService) {
  }

  paramObj(pageNumber, records, sortCol, clientOrgId) {
    return this.param = {
      pageNo: pageNumber,
      pageSize: records,
      sortField: sortCol,
      orgId: clientOrgId
    };
  }

  /*Close History Modal*/
  closeHistoryModal() {
    this.param.pageSize = 15;
    this.historyData = [];
    this.searchHistoryInput = '';
    this.sharedService.viewHistorytableDropdownVal.next(15);
  }

  updateSearch() {
    this.searchHistoryInput = this.searchHistoryInput ? this.searchHistoryInput.trim() : '';
  }

  /*Open History Modal*/
  openHistoryModal(message, type, id) {
    const title = (type === 'empaccount') ? id : this.historyTitle.split('-')[1];
    const msg = (type === 'empaccount') ? message : message + ' For ' + title;
    this.emptyMessage = msg;
    this.sharedService.openModal('view-history-modal');
  }

  receiveDataFromChild(e) {
    const pageNumber = 1;
    const sortField = '';
    this.param.pageSize = Number(e);
  }

  paginate(e) {
    const pageNo = e.page + 1;
    const sortField = '';
    const noOfRecords = this.param.pageSize;
    const orgId = this.getOrgId;
    this.paginateHistoryList.emit(this.paramObj(pageNo, noOfRecords, sortField, orgId));
  }

  selectCategory() {     /* Clicking Div to Open and close the Category DropDown */
    this.showDropDown = !this.showDropDown;
  }

  showSelectedCategory(data) {   /* To display the selected category in the dropdown  */
    this.selectedCategories = data;
    this.showDropDown = false;
  }

  checkTypeOf(val) {
    return val ? (typeof val) : '';
  }
}
