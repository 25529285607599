import {SharedConstant} from '../constants/sharedConstants';

export interface ManageEmployeeActionMenu {
  label?: string;
  icon?: string;
  routerLink?: any;
}

export interface ManageEmployeeOrPartipantActionMenu {
  DBS?: ManageEmployeeActionMenu[];
  SPS?: ManageEmployeeActionMenu[];
  RBS?: ManageEmployeeActionMenu[];
}

export const ManageEmployeeListActionMenuItems: ManageEmployeeActionMenu[] = [
  {label: SharedConstant.manageEmployeeActionItems.editText, icon: 'pi pi-pencil'},
  {label: SharedConstant.manageEmployeeActionItems.historyText, icon: 'pi pi-clock'},
  {label: SharedConstant.manageEmployeeActionItems.addEmployeeText, icon: 'sch-plus-circle'},
  {label: SharedConstant.deleteModal.removeEmployeeTxt, icon: 'sch-minus-square-o'},
];

export const ManageEmployeeOrParticpantListActionMenuItems: ManageEmployeeOrPartipantActionMenu = {
  DBS: [
    {label: SharedConstant.manageEmployeeActionItems.editText, icon: 'pi pi-pencil'},
    {label: SharedConstant.manageEmployeeActionItems.historyText, icon: 'pi pi-clock'},
    {label: SharedConstant.manageEmployeeActionItems.addEmployeeText, icon: 'sch-plus-circle'},
    {label: SharedConstant.deleteModal.removeEmployeeTxt, icon: 'sch-minus-square-o'},
  ],

  RBS: [
    {label: SharedConstant.manageEmployeeActionItems.editText, icon: 'pi pi-pencil'},
    {label: SharedConstant.manageEmployeeActionItems.historyText, icon: 'pi pi-clock'},
    {label: SharedConstant.RBS.addEmployeeOrParticipant, icon: 'sch-plus-circle'},
    {label: SharedConstant.RBS.removeEmployeeOrParticipant, icon: 'sch-minus-square-o'},
  ],

  SPS: [
    {label: SharedConstant.manageEmployeeActionItems.editText, icon: 'pi pi-pencil'},
    {label: SharedConstant.manageEmployeeActionItems.historyText, icon: 'pi pi-clock'},
    {label: SharedConstant.manageEmployeeActionItems.addEmployeeText, icon: 'sch-plus-circle'},
    {label: SharedConstant.deleteModal.removeEmployeeTxt, icon: 'sch-minus-square-o'},
  ]
};
