import {Component, ElementRef, EventEmitter, Inject, Input, OnInit, Output, ViewChild} from '@angular/core';
import {DefaultValues, emtrConstant} from '../../constants/emtrConstants';
import {InstrumentGroupService} from '../../services/instrument-group-service/instrument-group.service';
import {Table} from 'primeng/table';
import {
  AutoCompleteSecuritiesTableHead,
  searchSecuritiesTableHeads
} from '../../models/search-securities-table-header.model';
import {RulesSelectInstrumentGroupTableHeader} from '../../models/instrument-group-header';
import {SharedService, FormDataService} from '@ems/shared';
import {ViewSymbolsModalComponent} from '../view-symbols-modal/view-symbols-modal.component';

@Component({
  selector: 'tr-search-instrument-groups',
  templateUrl: './search-instrument-groups.component.html',
  styleUrls: ['./search-instrument-groups.component.scss']
})
export class SearchInstrumentGroupsComponent implements OnInit {

  getConstant = emtrConstant;
  searchInstrumentGrps: any;
  @Output() throwErrorIndSec: EventEmitter<any> = new EventEmitter<any>();
  @Output() instrumentGrpChange: EventEmitter<any[]> = new EventEmitter<any[]>();
  @Output() emitSelectedvalues: EventEmitter<string> = new EventEmitter<string>();
  @Input() autoCompleteSelectedData: any[] = [];
  @Input() countOfIndSec: any;

  text: string;
  results: any;
  showErr = false;
  errorMsg: any;
  getInstrumentGrps = true;
  gridData: any = [];
  selectedData: any[] = [];
  totalRecords: any;
  noOfRecords: any = DefaultValues.defaultTableRecords;
  cols: any[];
  loadViewSymbolModal = false;
  attributeData : any; 
  attributesFormData:any;

  @ViewChild(Table, { static: true }) tableComponent: Table;
  @ViewChild('child', { static: true }) viewSymbol: ViewSymbolsModalComponent;

  constructor(public instrumentGrpService: InstrumentGroupService, public formDataService: FormDataService, private el: ElementRef, @Inject('commonConstants') public commonConstants, public sharedService: SharedService, @Inject('entitlementConst') public entitlementConst) {
    this.attributeData = { ...this.formDataService.getFormData() };
    this.attributesFormData = this.attributeData;
  }

  ngOnInit() {
    this.cols = RulesSelectInstrumentGroupTableHeader;
    this.tableComponent.reset();
    this.gridData = (this.autoCompleteSelectedData) ? this.gridData.concat(this.autoCompleteSelectedData) : [];
    this.selectedData = (this.autoCompleteSelectedData) ? this.selectedData.concat(this.autoCompleteSelectedData) : [];
    this.totalRecords = this.gridData.length;
    this.attributesFormData.rulesCategory = (this.attributesFormData.override === 'Y') ? 'Override' : this.attributesFormData.rulesCategory;
  }

  commonResponseHandler(securities) {
    this.results = securities.data.content;
    /*Condition to show error message if API gives no response*/
    if (this.results && this.results.length > 0) {
      this.showErr = false;
      this.errorMsg = '';
      this.throwErrorIndSec.emit({msg: '', component: 'instGrp'});
    } else {
      this.showErr = true;
      this.throwErrorIndSec.emit({msg: this.getConstant.rulesConstant.autoComplete.noRecords, component: 'instGrp'});
    }
    return this.results;
  }

  highlightTxt(displaytxt, searchtxt) {
    if (displaytxt !== null) {
      searchtxt = this.sharedService.searchTextInAutoComplete(searchtxt);
      return displaytxt.replace(new RegExp(searchtxt, 'gi'), match => {
        return '<strong>' + match + '</strong>';
      });
    }
  }

  updateMultiselectValues(selectedValues) {
    this.searchInstrumentGrps = selectedValues;
    this.emitSelectedvalues.emit(selectedValues);
  }

  autocompleteSearch(event) {
    let query = event.query;
    query = encodeURIComponent(`${query}`);
    const formFilterObj: any = {
      searchType: 'AUTOCOMPLETE',
      page: 1,
      size: 8,
      searchValue: query ? query : '',
      sortField: [
        'name,asc'
      ],
      securityType: []
    };

    const defaultObj: any = {
      name: '',
      description: '',
      businessUnit: '',
      orgId: '',
      lastUpdatedFromDate: '',
      lastUpdatedToDate: ''
    };
    if (this.getInstrumentGrps) {
      this.instrumentGrpService.getInstrumentGroupList(defaultObj, formFilterObj).subscribe(securities => {
          this.sharedService.clearErrorMessage();
          this.commonResponseHandler(securities);
        }, error => {
          this.showErr = false;
          this.errorMsg = '';
          this.throwErrorIndSec.emit({msg: '', component: 'instGrp'});
        }
      );
    }
    this.getInstrumentGrps = true;
  }

  selectedVal(event) {
    /*Clear the search text*/
    this.text = '';

    /*condition to check if same entry already present in data Grid*/
    if (this.gridData) {
      if (this.gridData.filter(item => (item.id === event.id && item.name === event.name)).length === 0) {
        this.setGridData(event);
      } else {
        /*show Error Message*/
        this.showErr = true;
        this.errorMsg = this.getConstant.rulesConstant.autoComplete.instrumentGroupDuplicateRecord;
        this.throwErrorIndSec.emit({msg: this.errorMsg, component: 'instGrp'});
      }
    }
    this.instrumentGrpChange.emit(this.selectedData);
  }

  setGridData(event) {
    this.showErr = false;
    this.errorMsg = '';
    this.throwErrorIndSec.emit({msg: '', component: 'instGrp'});

    this.gridData.push(event);
    this.selectedData.push(event);
    if (this.selectedData.length <= 1) {
      /*Function to trigger click event of table to highlight selectAll checkbox,
      Since table loaded at first and we are pushing data later.*/
      this.el.nativeElement.querySelector('.p-paginator-bottom button').click();
    }
    this.gridData = [...this.gridData];
    this.selectedData = [...this.selectedData];
    this.totalRecords = this.gridData.length;
  }

  updateInstrumentGrps() {
    this.instrumentGrpChange.emit(this.selectedData);
  }

  receiveDataFromChild(e) {
    this.noOfRecords = parseInt(e, 10);
    this.gridData = [...this.gridData];
  }

  public openViewSymbolModal(rowData): void {
    this.loadViewSymbolModal = true;
    this.viewSymbol.openModal(rowData);
  }

  clearShowErr() {
    if (this.text === '') {
      this.throwErrorIndSec.emit({msg: '', component: 'instGrp'});
      this.showErr = false;
    }
  }
}
