export const AddlinkConstant = {
  reqDetailPollingInterval: 2000 /*time in ms*/,
  addLkGlobal: {
    noRecords: 'No Records Found.',
    multi: 'Multi',
    standard: 'Standard',
    internal: 'Internal',
    external: 'External',
    noMasterAccountToDelink: 'Unable to process this Delink request. The Master Account relationship does not exist.',
    bulkUploadAddlink: 'Bulk Upload - ADDLink'
  },
  characterLimit: 'Char. Limit:',
  pleaseCorrectFollowingError: 'Please correct following error.',
  confirmationMessage: {
    cancelBtnClickMsg: 'Are you sure you want to Cancel? This will cancel your session and unsaved changes will be lost.',
    menuClickMsg: 'You are about to leave this page and unsaved changes will not be saved. Are you sure you want to leave and Cancel?',
  },
  bulkUpload: {
    uploadTxt: 'Upload',
    addlinkTemplate: 'ADDLink_Bulk_Upload_Template'
  },
  noCientSelected: 'Please Select a Client to Continue.',
  authAgent: {
    authAgentErrorMessage: 'Authorized Agent already exists',
    authAgentFirstName: 'Auth Agent First Name',
    authAgentLastName: 'Auth Agent Last Name'
  },
  requestListFilterButtons: {
    cancelButton: 'Cancel',
    submitButton: 'Submit',
    clearAllButton: 'Clear',
    applyFilterButton: 'Apply',
    saveButton: 'Save'
  },
  requestListFilterFields: {
    requestId: 'Request ID',
    masterAccount: 'Master Account',
    accountNumber: 'Account Number',
    action: 'Action',
    status: 'Status',
    liable: '407 Liable',
    source: 'Source',
    submittedDate: 'Submitted Date',
    completedDate: 'Completed Date',
    toDateLabel: 'To',
    fromDateLabel: 'From',
    requestrulesLanding: {
      client: 'Client Central Notes',
      remarks: 'Remarks',
      edit: 'Edit',

    }
  },
  actionData: [{value: 'All', label: 'All'}, {value: 'Delink', label: 'Delink'}, {value: 'Link', label: 'Link'}],
  liableData: [{value: 'All', label: 'All'}, {value: 'No', label: 'No'}, {value: 'Yes', label: 'Yes'}],
  statusData: [
    {value: 'Complete', label: 'Complete'},
    {value: 'DBS Action Needed', label: 'DBS Action Needed'},
    {value: 'Ineligible Request', label: 'Ineligible Request'},
    {value: 'On Hold', label: 'On Hold'},
    {value: 'Received', label: 'Received'},
    {value: 'Request Denied', label: 'Request Denied'},
    {value: 'Validating', label: 'Validating'},
    {value: 'Validation Mismatch', label: 'Validation Mismatch'}
  ],
  sourceData: [
    {value: 'ADDLink(DBS)', label: 'ADDLink(DBS)'},
    {value: 'Client Central', label: 'Client Central'},
    {value: 'SCT (Client)', label: 'SCT (Client)'}
  ],
  dateError: {
    inValidPastDateEntered: 'The Maximum filter period is 90 Days. Please adjust your filter dates to be within 90 Days from Current Date.',
    inValidDateMessage: 'Please enter a valid date MM/DD/YYYY.',
    futureFromDateMessage: 'From Date should be Current Date or less than End Date.',
    futureToDateMessage: 'End Date should be Current Date or greater than From Date.',
    endDateError: 'End Date should be greater than From Date.',
    dateRangeError: 'Date Range is limited to 1 year.',
    invalidDate: 'Invalid Date'
  },
  textFieldErrorMsg: {
    invalidCharacterEnteredNumericValue: 'Invalid Character Entered - Please enter numeric values 0-9',
    accountNumbershouldBeNumeric: 'Account Number should be numeric. Please Use Valid Characters from 0-9.',
    invalidCharacterForName: 'Invalid Character Entered - Please Use Valid Characters Only A-Z, a-z, 0-9, ., -,_, \' (apostrophe), , (comma) and space. The name cannot start with special characters, spaces, any number, or end with special characters.',
    accountNumberShouldContain8Digits: 'Account Number should contain 8 digits.',
    employeeIdErrorMsg: 'Invalid Character Entered - Please Use Valid Characters Only A-Z, a-z, 0-9,- . \' (apostrophe).',
    invalidName: 'Invalid Character Entered - Please Use Valid Characters Only A-Z, a-z, 0-9, ., -,_, \' (apostrophe), , (comma) and space. The name cannot start with special characters, spaces, any number, or end with special characters.',
    masterAcctNumShouldContain8Digits: 'Master Account Number should contain 8 digits.'
  },
  requestListFilterDropDown: [{value: 'Link', label: 'Link'}, {value: 'Delink', label: 'Delink'}],
  takeActionMenuMsg: {
    takeActionTxt: 'Take Action',
    takeActionMenuTxt: [{label: 'Deny', value: 'Deny'}, {label: 'Resolve', value: 'Resolve'}, {label: 'Resubmit', value: 'Resubmit'}],
    denyTxt: 'Deny',
    resubmitTxt: 'Resubmit',
    resolveTxt: 'Resolve',
    resubmitRequestModalMsg: {
     missingIndexKeyMsg: 'Requests with a Missing Index Key must be edited before they can be resubmitted.',
     validationMismatchMsg: 'Requests with a status of Validation Mismatch must be edited before they can be resubmitted.',
     dBSActionNeededMsg: 'Requests with a status of DBS Action Needed cannot be resubmitted.'
    }
  },
  delinkMasterAccModal: {
    title: 'Delink Master Accounts',
    displayTxt: 'Select the Master Account(s) to Delink',
    cancelTxt: 'Cancel',
    delinkTxt: 'Delink',
    dualTxt: 'Dual',
    multiTxt: 'Multi',
    resetTxt: 'Reset',
    submitTxt: 'Submit'
  },
  requestsAlreadyInRequestDeniedStatusMsg: 'Requests already in Request Denied status will not be processed.',
  requestWillBeCancelled: 'The following Request(s) will be cancelled:',
  confirmBtnLabel: 'Confirm',
  requestWillBeResolved: 'The following Request(s) will be Resolved:',
  resolveRequestModalLabel: 'Resolve Request(s)',
  denyRequestModalLabel: 'Deny Request(s)',
  statusLabel: {
    onHold: 'On Hold',
    validationMismatch: 'Validation Mismatch',
    dbsActionNeeded: 'DBS Action Needed',
    requestDenied: 'Request Denied',
    complete: 'Complete',
    resolved: 'Resolved',
    validating: 'Validating',
    received: 'Received',
    ineligibleRequest: 'Ineligible Request',
    requireIndexing: 'Require Indexing'
  },
  sourceLabelTxt: {
    aDDLinkDBS: 'ADDLink(DBS)',
    clientCentral: 'Client Central'
  },
  valueLabelAll: 'All',
  linkTxt: 'Link',
  internalStatusTxt: 'internalStatus',
  externalStatusTxt: 'status',
  requestListSubHeaderFieldTxt: {
    acctHolderName: 'acctHolderName',
    accountHolderAffiliation: 'accountHolderAffiliation',
    authAgentName: 'authAgentName',
    commissionCode: 'commissionCode',
    assignedTo: 'assignedTo',
    source: 'source',
    createdBy: 'createdBy',
    createdDate: 'createdDate',
    completedBy: 'completedBy',
    completedDate: 'completedDate',
    liable407: 'liable407',
    indexKey: 'indexKey'
  },
  requestListMainHeaderFieldTxt: {
    requestId: 'requestId',
    action: 'action',
    employeeId: 'employeeId',
    employeeName: 'employeeName',
    acctNumber: 'acctNumber',
    client: 'client',
    masterAccountNbr: 'masterAccountNbr'
  },
  requestListHeaderLabel: 'Request List',
  customizeViewTxt: 'Customize View',
  totalTxt: 'Total:',
  showTxt: 'Show',
  recordsTxt: 'Records',
  completeStatus: 'Complete',
  clientCentralNotes: {
    title: 'Client Central Notes',
    requestID: 'Request ID:',
    accountNo: 'Account No:',
    noClientMsg: 'There are no Client Central Notes for this Account.',
    errorMsg: 'Client Central Notes are Not Available.'
  },
  clientCentralLinks: 'Client Central Links',
  clientCentralDelinks: 'Client Central Delinks'
};
