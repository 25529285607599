<div class="toastController">
  <p-toast (onClose) = "onClose()">
    <ng-template let-message pTemplate="message">
      <sdps-notification type="error" class="sdps-m-top_none" class="toast-confirmation" *ngIf="message.severity === 'error'">
        <div class="sdps-notification__text" [innerHTML]="message.detail"></div>
      </sdps-notification>
      <sdps-notification type="warning" class="toast-confirmation sdps-m-top_none" *ngIf="message.severity === 'warn'">
        <div class="sdps-notification__text" [innerHTML]="message.detail"></div>
      </sdps-notification>
      <sdps-notification type="information" class="sdps-m-top_none" class="toast-confirmation" *ngIf="message.severity === 'info'">
        <div class="sdps-notification__text" [innerHTML]="message.detail"></div>
      </sdps-notification>
      <sdps-notification type="confirmation" class="toast-confirmation sdps-m-top_none" *ngIf="message.severity === 'success'">
        <div class="sdps-notification__text" [innerHTML]="message.detail"></div>
      </sdps-notification>
    </ng-template>
  </p-toast>
</div>
