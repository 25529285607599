import {Directive, ElementRef} from '@angular/core';
import {NG_VALIDATORS} from '@angular/forms';

@Directive({
  selector: '[emsChangeRequestIdPatterncheck]',
  providers: [
    {provide: NG_VALIDATORS, useExisting: ChangeRequestIdPatterncheckDirective, multi: true}
  ]
})
export class ChangeRequestIdPatterncheckDirective {

  newExp: any;

  constructor(public el: ElementRef) {
  }

  validate() {
    this.newExp = new RegExp('^[a-zA-Z0-9\\d\\-_\\s]*$');
    const elementVal = this.el.nativeElement.value;
    if (this.newExp.test(elementVal)) {
      return null;
    } else {
      return {
        emsRequestIdPattern: false
      };
    }
  }
}
