<sdps-modal id='beta-resubmit-request-modal' [sdpsTitle]="title" [size]="'flex--small'">

  <div slot="modal-body">

    <p class="adl-modal__heading"><strong>Eligible Request(s) selected for Resubmission:</strong></p>

    <div class="adl-modal__body">
      <div *ngFor="let requestId of requestIds">#{{requestId}}</div>
    </div>

    <p *ngIf="validationMismatch > 0"
       [innerHTML]="addLinkConstant.takeActionMenuMsg.resubmitRequestModalMsg.validationMismatchMsg"></p>
    <p *ngIf="dbsActionNeeded > 0"
       [innerHTML]="addLinkConstant.takeActionMenuMsg.resubmitRequestModalMsg.dBSActionNeededMsg"></p>
    <p *ngIf="missingIndexKey > 0"
       [innerHTML]="addLinkConstant.takeActionMenuMsg.resubmitRequestModalMsg.missingIndexKeyMsg"></p>

  </div>

  <div slot="modal-footer">
    <button class="buttons" type="reset" (click)="sharedService.closeModal('beta-resubmit-request-modal')">Cancel</button>
    <button type="button" class="buttons button-save" [disabled]="this.requestIds && this.requestIds.toString() === ''" (click)="resubmitRequests()">Resubmit</button>
  </div>

</sdps-modal>
