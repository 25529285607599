import {Component, EventEmitter, Inject, Input, OnInit, Output} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {ManageEmployeeDataService, SharedConstant, SharedService} from '@ems/shared';
import {emtrConstant} from '../../constants/emtrConstants';

@Component({
  selector: 'tr-edit-employee-modal',
  templateUrl: './edit-employee-modal.component.html',
  styleUrls: ['./edit-employee-modal.component.scss']
})

export class EditEmployeeModalComponent implements OnInit {

  public title = '';
  @Input() editData: any;
  @Input() orgId: any;
  @Output() emitData = new EventEmitter();
  isModalOpen: boolean;
  public employee: any;
  public showmsgFlag = false;
  public apiMsgDesc: any;
  public accountNumber: string;
  public newEmpId: string;
  getConstant = emtrConstant;
  getSharedConstant = SharedConstant;
  empIDTxt = 'employeeId';
  showCharLimit: any;
  showEmpIdCharLimit: boolean;
  serviceErrorMsg: string;
  oldEmpId: string;
  businessUnit: any;
  invalidEmpId = false;

  editRequestsForm: UntypedFormGroup = this.formBuilder.group({
    employeeId: [null],
    employeeFirstName: [null, [Validators.required]],
    employeeLastName: [null, [Validators.required]],
    accountHolderSameAsEmployee: [true],
    accountHolderFirstName: [{value: '', disabled: true}],
    accountHolderLastName: [{value: '', disabled: true}],
    employeeToAccountHolderAffiliation: [{value: '', disabled: true}]
  });


  constructor(
    private formBuilder: UntypedFormBuilder,
    private service: ManageEmployeeDataService,
    public sharedService: SharedService,
    @Inject('commonConstants') public commonConstants
  ) {
  }

  /* Initialize component variables */
  ngOnInit() {
    this.sharedService.clientInformation.subscribe(clientInfo => {
      if (clientInfo) {
          this.businessUnit = clientInfo.businessUnit;
      }
    });
    this.onChanges();
  }

  onChanges(): void {
    this.editRequestsForm.get('employeeId').valueChanges.subscribe((empId) => {
      const regExp = new RegExp('^[a-zA-Z0-9-.\' ]*$');
        this.invalidEmpId = !regExp.test(empId);
    });
  }

  setEmployeeDetailsValues(employee) {
    this.editRequestsForm.patchValue({
      employeeId: employee.empId.trim(),
      employeeFirstName: employee.firstName.trim(),
      employeeLastName: employee.lastName.trim(),
    });
    this.editRequestsForm.updateValueAndValidity();
  }

  public openModal(empData) {
    this.serviceErrorMsg = '';
    this.oldEmpId = empData.empId;
    if (this.sharedService.addOrEditEmployee === 'addEployee') {
      this.title = this.getSharedConstant[this.businessUnit].addEmployeeOrParticipant;
      this.editRequestsForm.reset();
      this.editRequestsForm.controls[this.empIDTxt].setValidators(Validators.required);
      this.editRequestsForm.get(this.empIDTxt).updateValueAndValidity();
    } else {
      this.title = this.getSharedConstant[this.businessUnit].editEmployeeOrParticipant;
      this.setEmployeeDetailsValues(empData);
      this.editRequestsForm.controls[this.empIDTxt].clearValidators();
      this.editRequestsForm.get(this.empIDTxt).updateValueAndValidity();
    }
    this.employee = empData;
    this.accountNumber = empData.subAcctNum;
    this.showmsgFlag = false;
    this.sharedService.openModal('edit-employee-modal');
  }

  /* Closes The Modal */
  public closeModal(): void {
    // this.sharedService.isModalOpened.next(false);
    // this.isModalOpen = false;
    this.sharedService.clearErrorMessage();
  }

  public updateEmployee() {
    this.showmsgFlag = true;
    const formInfo = this.editRequestsForm.value;
    if (this.sharedService.addOrEditEmployee !== 'addEployee') {
      const EmpIdObj = {
        orgId: this.orgId,
        subAcctNum: this.employee.subAcctNum,
        oldEmpId: this.oldEmpId,
        newEmpId: this.editRequestsForm.get('employeeId').value.trim(),
        firstName: formInfo.employeeFirstName.trim().toUpperCase(),
        lastName: formInfo.employeeLastName.trim().toUpperCase()
      };
      this.service.updateEmployee(EmpIdObj).subscribe((response) => {
        if (response.errorResponse === null) {
          this.closeModal();
          this.service.sendMessage(response.message);
          this.emitData.emit();
        }
      }, error => {
          this.serviceErrorMsg = error.error.message;
      });
    } else {
      const addEmpObj = {
        orgId: this.orgId,
        subAcctNum: this.employee.subAcctNum,
        empId: formInfo.employeeId.trim(),
        firstName: formInfo.employeeFirstName.trim().toUpperCase(),
        lastName: formInfo.employeeLastName.trim().toUpperCase()
      };
      this.service.addEmployee(addEmpObj).subscribe((response) => {
        if (response) {
          this.service.sendMessage(response.message);
          this.serviceErrorMsg = '';
          this.closeModal();
          this.emitData.emit();
        }
      }, error => {
        this.serviceErrorMsg = error.error.errorResponse.errorMessage;
      });
    }
    this.sharedService.closeModal('edit-employee-modal');
  }
}
