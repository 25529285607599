<form>

  <sdps-modal id='delink-master-acct-modal' [sdpsTitle]="title" [size]="'flex--medium'" (close)="masterAccs=[]" [backDropCloseDisabled]="true">

    <div slot="modal-body">
      <p class="adl-modal__heading">
        <span><strong>{{ addLinkConstant.delinkMasterAccModal.displayTxt }}</strong></span>
      </p>
      <form class="container" [formGroup]="delinkMasterAccount">

      <div class="sdps-m-bottom_small sdps-p-around_small ems-checkbox">
        <div *ngFor="let masterAccounts of masterAccountsList; let i = index;" class="sdps-p-bottom_x-small">
          <p-checkbox name="group1" class="sdps-p-bottom_xx-small" formControlName="delinkMasterAccountSelected" id="{{masterAccounts.value}}" binary=true (onChange)="selectMasterAccs(masterAccounts)"></p-checkbox>
          <label for="{{masterAccounts.value}}" class="vertical-align-middle sdps-inline-block sdps-p-left_xx-small">{{masterAccounts.label}}</label>
        </div>
      </div>
      </form>
    </div>

    <div slot="modal-footer">
      <button class="buttons" type="reset" (click)="masterAccs=[];sharedService.closeModal('delink-master-acct-modal')">{{ addLinkConstant.delinkMasterAccModal.cancelTxt }}</button>
      <button type="button" class="buttons button-save" (click)="processAcc()"
                [disabled]="masterAccs.length === 0">{{ addLinkConstant.delinkMasterAccModal.delinkTxt }}</button>
    </div>
  </sdps-modal>
</form>
