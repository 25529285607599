<div class="ems-multiselect" [ngClass]="{'add-multiselect': changeHeight}">

  <div class="anchor" [ngClass]="{'disabled': disabled}" (click)="toggleDropdown()">

    <label [ngClass]="{'active': value !== null && value.length > 0,'labelDisabled': disabled}">{{label}}<em *ngIf="highlightColor && !disabled" class="highlight-color">*</em></label>

    <span [ngClass]="{'labelDisabled': disabled}" class="dropdown-icon sdps-pull-right pi pi-caret-down security-arrow-icon"></span>

    <div class="items-selected">

      <div *ngIf="value !== null && value.length < 3">

        <span class="item-selected" *ngFor="let data of value">
          {{ data.label }}
        </span>

      </div>

      <div class="item-selected" *ngIf="value !== null && value.length >= 3">
        {{value.length}} Selected
      </div>

    </div>

  </div>

  <div class="items" *ngIf="dropdownVisible" [ngClass]="{'active': dropdownVisible}">

    <div #parentItems style="width:max-content; min-width: 100%;">

      <label *ngIf="searchable">
        <input type="text" class="filter" placeholder="Filter" [(ngModel)]="filterValue" (keyup)="filterOptions()" />
      </label>

      <label *ngIf="!singleSelect && optionsToShow.length > 0">

        <div [ngClass]="{'active': selectAll, 'item-label': true}">
          All
          <p-checkbox #checkboxElement class="emtr-checkbox" [value]='selectAll' (onChange)="selectAllItems()" [(ngModel)]='selectAll'
            binary=true>
          </p-checkbox>
        </div>

      </label>

      <label *ngFor="let option of optionsToShow; let i = index">

        <div [ngClass]="{'active': isSelected(option), 'item-label': true}">
          {{ option.label }}
          <p-checkbox class="emtr-checkbox" [ngStyle]="singleSelect && {'display': 'none'}" [value]="option"
            (onChange)="onChange(option)" [(ngModel)]='value'></p-checkbox>
        </div>

      </label>

    </div>

  </div>

</div>
