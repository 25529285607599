import {EventEmitter, Inject, Injectable, Output, Injector} from '@angular/core';
import {Observable, ReplaySubject, BehaviorSubject} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {ConfigUrlService, SharedService, SharedConstant} from '@ems/shared';
import * as temp from 'moment';
const moment = temp['default'];
import {ActivatedRoute, Router} from '@angular/router';
import { reportListTableHeaders } from '@shell_components/model/report-list-table-header';
import { ShellConstant } from '@shell_components/constants/shellConstants';
import { commonConstants } from '@shell_components/constants/commonConstants';

@Injectable({
  providedIn: 'root'
})
export class ReportsService {

  orgId: any;
  clientName: string;
  clientId: any;
  businessUnit: any;
  private subject = new ReplaySubject<any>();
  configURL = this.config.setApplicationUrl();
  reportsArray = new BehaviorSubject<any>(null);
  schErrorMsg = new EventEmitter<string[]>();
  selectedReport: any;
  selectedSnapshotReport: any = false;
  isroutedAfterApi: any = false;
  getConstant = ShellConstant;
  getCommConst = commonConstants;
  sourceAndStatusMultiselectValues = new BehaviorSubject(null);
  sharedConstant = SharedConstant;
  errorInEmpLastName = false;
  errorInAccntHolderLastName = false;
  invalidCusip = false;
  invalidSymbol = false;
  userPolicy: any;
  accessToOnlyRbs = false;
  accessToOnlySPS = false;

  constructor(private injector: Injector, private activatedRoute: ActivatedRoute, public router: Router, private httpService: HttpClient, @Inject('env') public env, public config: ConfigUrlService, public sharedService: SharedService) {
  }

  getReports(pageObj): Observable<any> {
    let schwabClientHeader = [];
    schwabClientHeader = this.sharedService.setSchwabClientHeaders(this.sharedService.applicationInfo.data.queryParamMap.orgId, this.orgId, schwabClientHeader);
    const url = this.configURL.bffReportTypeEndPointV1 + '&category=PRESET';
    const filterField = (pageObj.filterField) ? '&filterField=' + pageObj.filterField : '';
    if (this.orgId && this.orgId !== 0) {
      return this.httpService.get(url + '&pageNumber=' + (pageObj.pageNo + 1) + '&pageSize=' + pageObj.pageSize + filterField + '&Schwab-Client-Ids= ' + schwabClientHeader);
    } else {
      return this.httpService.get(url + '&pageNumber=' + (pageObj.pageNo + 1) + '&pageSize=' + pageObj.pageSize + filterField);
    }
  }

  runReporWithName(empGroupReport, localInput): Observable<any> {
    let schwabClientHeader = [];
    schwabClientHeader = this.sharedService.setSchwabClientHeaders(this.sharedService.applicationInfo.data.queryParamMap.orgId, this.orgId, schwabClientHeader);
    if (empGroupReport) {
      return this.httpService.post(this.configURL.bffReportESMBatchEndPointV1 + '&Schwab-Client-Ids= ' + schwabClientHeader, localInput, {observe: 'response'});
    } else {
      if (this.orgId && this.orgId !== 0) {
        return this.httpService.post(this.configURL.bffReportExecutionEndPointV1 + '&Schwab-Client-Ids= ' + schwabClientHeader, localInput, {observe: 'response'});
      } else {
        return this.httpService.post(this.configURL.bffReportExecutionEndPointV1, localInput, {observe: 'response'});
      }
    }
  }

  downloadReports(reportObj, snapshot): Observable<any> {
    return this.httpService.get(this.configURL.reportEndPoint + '/download/' +  reportObj.trackId + '?format=.csv');
  }

  sendMessage(message: any) {
    this.subject.next({text: message});
  }

  getMessage(): Observable<any> {
    return this.subject.asObservable();
  }

  clearMessage() {
    this.subject.next({text: ''});
  }

  getEmployeeGroupDropdownData(): Observable<any> {
    let schwabClientHeader = [];
    schwabClientHeader = this.sharedService.setSchwabClientHeaders(this.sharedService.applicationInfo.data.queryParamMap.orgId, this.orgId, schwabClientHeader);
    return this.httpService.post(this.configURL.bffGroupSummaryEndpointV1 + '&Schwab-Client-Ids= ' + schwabClientHeader, {orgId : this.orgId, active: true});
  }

  formatDate(date) {
    const formatDate = moment(date, 'MM/DD/YYYY').format('YYYY-MM-DD');
    return formatDate;
  }

  formParametersArray(localInput, selectedReportName, tradeAttemptTxt) {
    const parametersArray = {};
    let firstLoad = true;
    Object.keys(localInput).forEach((key, index) => {
      /* Forming URL only with keys having Values*/
      if (localInput[key] || (key === 'employeeStatus' && selectedReportName !== tradeAttemptTxt && localInput[key] !== undefined)) {
        if (firstLoad) {
          parametersArray[key] = localInput[key];
          firstLoad = false;
        } else {
          if ((selectedReportName === 'ADDLink Usage' && key === 'status') || (selectedReportName === 'ADDLink Requests' && (key === 'status' || key === 'source'))) {
            localInput[key].map(item => {
              parametersArray[key] = localInput[key];
            });
          } else {
            parametersArray[key] = localInput[key];
          }
        }
      }
    });
    return parametersArray;
  }

  moveToReportPage(parent) {
    this.sharedService.disableClientSelector.next(false);
    this.isroutedAfterApi = true;
    parent.expandClicked = false;
    parent.reportListHeaders = reportListTableHeaders;
    parent.reportName = '';
    parent.getReportsList();
    parent.getSaveReportMessage(this.getConstant.reports.presetReports.runReportSuccessAndRedirectMsg);
    this.removeReportName();
    this.router.navigate(['/ems/reports/preset-reports']);
    setTimeout(() => {
      this.sharedService.loadDataTrackValue('client_redirect');
      this.router.navigate(['/ems/reports/reports-list']);
    }, 2000);
  }

  serviceError(e, parent) {
    if (e.status === this.getCommConst.http_status.code_400) {
      parent.reportOptionalFieldsError = e.error.errors;
    }
  }

  handleReportResponse(res, appService, parent) {
    if (res.status === this.getCommConst.http_status.code_200 && !res.body.success) {
      this.selectedSnapshotReport = false;
      if (appService.handleErrorMessage(res.body.message)) {
        const errMessage: any = {
          msg: commonConstants.errorMessage
        };
        parent.showErrorMessage(errMessage.msg);
      } else {
        parent.showErrorMessage(res.body.message);
      }
    } else {
      this.moveToReportPage(parent);
    }
  }

  makeFormDirty(form, parent) {
    form.valueChanges.subscribe((value: any) => {
      if (form.dirty) {
        parent.reportFormIsDirty = true;
      }
    });
  }

  formErrorMessage(reportDetails, parent, type, inputField, errorMsg) {
    reportDetails.get(inputField).valueChanges.subscribe((data) => {
      if (data && reportDetails.get(inputField).invalid) {
        if (parent.schErrorMsg.indexOf(errorMsg) === -1) {
          if (type) {
            type.clearErrorMsgAllGroups();
          }
          parent.schErrorMsg.push(errorMsg);
        }
      } else {
        if (parent.schErrorMsg.indexOf(errorMsg) >= 0) {
          parent.schErrorMsg.splice(parent.schErrorMsg.indexOf(errorMsg), 1);
        }
        if (type) {
          const empGroupNamevalue = reportDetails.get('empGroupName').value;
          type.validateFields(empGroupNamevalue);
        }
      }
    });
  }

  formCharLimitErrorMessage(reportDetails, parent, inputField, length, errorMsg) {
    reportDetails.get(inputField).valueChanges.subscribe((data) => {
      if (data && reportDetails.get(inputField).value.length < length) {
        if (parent.schErrorMsg.indexOf(errorMsg) === -1) {
          parent.schErrorMsg.push(errorMsg);
        }
      } else {
        if (parent.schErrorMsg.indexOf(errorMsg) >= 0) {
          parent.schErrorMsg.splice(parent.schErrorMsg.indexOf(errorMsg), 1);
        }
      }
    });
  }
  disableReportField(formGrp, data, disableField){
    if (data && data.length > 0 ) {
      formGrp.get(disableField).disable({emitEvent: false});
    } else {
        formGrp.get(disableField).enable({emitEvent: false});
    }
    formGrp.get(disableField).updateValueAndValidity({emitEvent: false});
}

  executeReports(parent, localInput, selectedReport, reportTxt, runReportFor, passReportName, appService, type) {
    /* Removing already existing error message while clicking run report */
    parent.reportOptionalFieldsError = '';
    const finalAppendedUrl = this.formParametersArray(localInput, selectedReport, reportTxt);
    const finalPayload = {
      reportType: passReportName,
      reportInfo: finalAppendedUrl
    };
    this.runReporWithName(type, finalPayload).subscribe(res => {
        this.handleReportResponse(res, appService, parent);
      },
      (err) => {
        this.selectedSnapshotReport = false;
        this.serviceError(err, parent);
      });
  }

  cancelReport(reportDetails, parent) {
    /* Perform cancel if form is dirty*/
    if (reportDetails.dirty) {
      parent.cancelRunReport(true);
    } else {
      /*Perform cancel if form is not dirty,closing the details section */
      this.sharedService.disableClientSelector.next(this.sharedService.ClientCount === this.sharedConstant.clientCount);
      this.removeReportName();
      parent.expandReportDetailsSection();
    }
  }

  getReportName() {
    return localStorage.getItem('report');
  }

  setReportName(report) {
    localStorage.setItem('report', report);
  }

  removeReportName() {
    localStorage.removeItem('report');
  }

  getReportsList(showSnapshotDetails, pageObj, formFilterObj): Observable<any> {
    let schwabClientHeader = [];
    schwabClientHeader = this.sharedService.setSchwabClientHeaders(this.sharedService.applicationInfo.data.queryParamMap.orgId, this.orgId, schwabClientHeader);
    let url = '';
    let endpoint = this.configURL.bffReportListEndPointV1;
    const sortField = (pageObj.sortField) ? pageObj.sortField.map(data => '&sortField=' + data).join('') : '';
    if (showSnapshotDetails) {
      endpoint = this.configURL.bffReportListESMBatchEndPointV1;
    }
    if (this.orgId && this.orgId !== 0) {
      url = endpoint + '&pageNumber=' + (pageObj.pageNo) + '&pageSize=' + pageObj.pageSize + sortField + '&Schwab-Client-Ids= ' + schwabClientHeader;
    } else {
      url = endpoint + '&pageNumber=' + (pageObj.pageNo) + '&pageSize=' + pageObj.pageSize + sortField;
    }
    return this.httpService.post(url, formFilterObj);
  }

  public getSourceAndStatusValue(): Observable<any> {
    let schwabClientHeader = [];
    schwabClientHeader = this.sharedService.setSchwabClientHeaders(this.sharedService.applicationInfo.data.queryParamMap.orgId, this.orgId, schwabClientHeader);
    let url = '';
    if (this.orgId && this.orgId !== 0) {
      url = this.configURL.bffAddlinkFilterDataV1 + '&Schwab-Client-Ids= ' + schwabClientHeader;
    } else {
      url = this.configURL.bffAddlinkFilterDataV1;
    }
    return this.httpService.get(url);
  }

  public getReportDetails(showSnapshotDetails, reportId): Observable<any> {
    let schwabClientHeader = [];
    schwabClientHeader = this.sharedService.setSchwabClientHeaders(this.sharedService.applicationInfo.data.queryParamMap.orgId, this.orgId, schwabClientHeader);
    let endpoint = this.configURL.bffReportDetailEndPointV1;
    if (showSnapshotDetails) {
      endpoint = this.configURL.bffReportDetailESMBatchEndPointV1;
    }
    if (this.orgId && this.orgId !== 0) {
      return this.httpService.get(endpoint.replace('PassThrough?', `PassThrough/${reportId}?`) + '&Schwab-Client-Ids= ' + schwabClientHeader);
    } else {
      return this.httpService.get(endpoint.replace('PassThrough?', `PassThrough/${reportId}?`));
    }
  }

  public pollUploadsDetailsByReportId(showSnapshotDetails, reportID): Observable<any> {
    let schwabClientHeader = [];
    schwabClientHeader = this.sharedService.setSchwabClientHeaders(this.sharedService.applicationInfo.data.queryParamMap.orgId, this.orgId, schwabClientHeader);
    let endpoint = this.configURL.bffReportStatusEndPointV1;
    if (showSnapshotDetails) {
      endpoint = this.configURL.bffReportStatusESMBatchEndPointV1;
    }
    const reportIds = [];
    reportIds.push({id: reportID});

    const reportPayload = {
      reports: reportIds
    };

    if (this.orgId && this.orgId !== 0) {
      return this.httpService.post(endpoint + '&Schwab-Client-Ids= ' + schwabClientHeader, reportPayload);
    } else {
      return this.httpService.post(endpoint, reportPayload);
    }
  }

  public freezeGlobalClientSelector(reportForm) {
    reportForm.valueChanges.subscribe(value => {
      this.sharedService.disableClientSelector.next(true);
    });
  }
  public checkForAccess() {
    this.userPolicy = this.sharedService.getPolicyResponse('success');
    /* eslint-disable-next-line max-len */
    this.accessToOnlyRbs = (this.userPolicy.data.userPolicy.buPolicy && this.userPolicy.data.userPolicy.buPolicy.length === 1 && this.userPolicy.data.userPolicy.buPolicy.map(x => x.businessUnit).indexOf('RBS') !== -1) || (this.userPolicy.data.userPolicy.buPolicy === null && this.userPolicy.data.clients && this.userPolicy.data.clients.every(x => x.businessUnit === 'RBS'));
    /* eslint-disable-next-line max-len */
    this.accessToOnlySPS = (this.userPolicy.data.userPolicy.buPolicy === null && this.userPolicy.data.clients && this.userPolicy.data.clients.every(x => x.businessUnit === 'SPS')) || (this.userPolicy.data.userPolicy.buPolicy && this.userPolicy.data.userPolicy.buPolicy.length === 1 && this.userPolicy.data.userPolicy.buPolicy.map(x => x.businessUnit).indexOf('SPS') !== -1);
  }
}

