import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { Chart, DoughnutController } from 'chart.js';
import {ChartModule} from 'primeng/chart';
import { SharedConstant } from '../../constants/sharedConstants';
 
@Component({
  selector: 'ems-donut-chart',
  templateUrl: './donut-chart.component.html'
})
export class DonutChartComponent implements OnInit, OnChanges {
  @Input() labelVal: any;
  @Input() arrayVal: any;
  @Input() restrictBackgroundColorCode: any;
  @Input() chartTitle: any;
  donutOptions: any;
  public data: any;
  getSharedConstant = SharedConstant;
 
  constructor() { }
 
  ngOnInit() {
    this.loadChart();
  }
 
  ngOnChanges() {
    this.loadChart();
  }
 
  loadChart() {
    const localData = {
      labels: this.labelVal,
      datasets: [
        {
          data: this.arrayVal,
          backgroundColor: this.restrictBackgroundColorCode,
          hoverBackgroundColor: this.restrictBackgroundColorCode
        }],
        options: {
          responsive:true,
          legend: { reverse: true }
        },
        animation: {
          animateScale: true,
          animateRotate: true
        },
    };
    const localOptions = {
      legend: {
        align: 'center',
        verticalAlign: 'center',
        fullWidth: true,
        onClick: (e) => e.stopPropagation()
      },
      plugins: {
        datalabels: {
          color: 'white',
        },
        legend: {
          display: true,
          position: 'right',
          itemDistance: 2,
          symbolPadding: 1,
          reverse: true,
          labels: {
            boxWidth: 20,
            padding: 20,
            borderWidth: 0
          },
        },
        dataChart: this.chartTitle === 2
      }
    };
    this.data = localData;
    this.donutOptions = localOptions;
 
    Chart.register({
      id: 'dataChart',
      beforeDraw: (chart) => {
        const height = chart.chartArea.height;
        const ctx = chart.ctx;
        ctx.restore();
        const fontSize = 'bold 20px';
        ctx.font = fontSize + this.getSharedConstant.doughnutChartLabels.internalChartTxt;
        ctx.font = 'bold 13pt sans-serif';
        const text = this.getSharedConstant.doughnutChartLabels.daysTxt;
        const textX = 80;
        const textY = 75;
        ctx.fillText(text, textX, textY);
        ctx.save();
      }
    });
  }
}