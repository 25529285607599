import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ems-basic-datatable-header-icon',
  templateUrl: './basic-datatable-header-icon.component.html',
  styleUrls: ['./basic-datatable-header-icon.component.scss']
})
export class BasicDatatableHeaderIconComponent implements OnInit {

  iconHeaderList: any;


  ngOnInit() {
    const iconHeader: any = [
      {
        icoName: 'print',
        icoClass: 'sch-print-2x'
      },
      {
        icoName: 'pdf',
        icoClass: 'sch-file-pdf-2x'
      },
      {
        icoName: 'excel',
        icoClass: 'sch-file-excel-2x'
      }
    ];

    this.iconHeaderList = iconHeader;
  }

}
