<form [formGroup]="restrictForm">
  <div class="display-flex sdps-p-top_medium sdps-p-left_xx-large sdps-p-bottom_medium">
      <div class="sdps-p-right_xx-large">
          <p-checkbox class="emtr-checkbox" binary="true" [label]="getConstant.rulesConstant.ruleTypeLabels[rulesCategory]+ ' ' +getConstant.rulesConstant.ruleTypeLabels.buys" formControlName="restrictBuys" (ngModelChange)="restrictTypeChanged()">
          </p-checkbox>
      </div>
      <div class="sdps-p-right_xx-large">
          <p-checkbox class="emtr-checkbox" binary="true" [label]="getConstant.rulesConstant.ruleTypeLabels[rulesCategory]+ ' ' +getConstant.rulesConstant.ruleTypeLabels.sells" formControlName="restrictSells" (ngModelChange)="restrictTypeChanged()"></p-checkbox>
      </div>
      <div class="sdps-p-right_xx-large" *ngIf="ruleType !== getConstant.rulesConstant.autoComplete.FNDISUTxt">
          <p-checkbox class="emtr-checkbox" binary="true" [label]="getConstant.rulesConstant.ruleTypeLabels[rulesCategory]+ ' ' +getConstant.rulesConstant.ruleTypeLabels.shortSells" formControlName="restrictShortSells"
                      [ngClass]="(buttonDisabled ?'on-check':'on-uncheck')" (ngModelChange)="restrictTypeChanged()">
          </p-checkbox>

          <sdps-tooltip
                  *ngIf="attributesFormData.ruleType === 'SECTYP' && (restrictForm.get('restrictShortSells').value===true)"
                  class="info-icon-button-border sdps-m-left_x-small" sdps-id="right-tooltip" position="right">
                  <span slot="tooltip-content" [innerHTML]="infoIconMsg"></span>
          </sdps-tooltip>
      </div>
  </div>
</form>

