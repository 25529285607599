import {Component, EventEmitter, OnDestroy, OnInit, Output, AfterViewInit, ViewChild, Inject, Input} from '@angular/core';
import {
  empOrParticipantTableSubHeader,
  ManageEmpOrParticipantHeader
} from '../../models/manage-employees-table-header';
import {Subject, Subscription} from 'rxjs';
import {SharedService} from '../../shared.service';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {SharedConstant} from '../../constants/sharedConstants';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {ManageEmployeeDataService} from '../../services/manage-employee/manage-employee-data.service';
import {ManageEmpGrpService} from '../../services/manage-emp-grp-service/manage-emp-grp.service';
import {Dropdown} from 'primeng/dropdown';

@Component({
  selector: 'ems-manage-employees-sub-header',
  templateUrl: './manage-employees-sub-header.component.html'
})
export class ManageEmployeesSubHeaderComponent implements OnInit, OnDestroy, AfterViewInit {

  @Output() hasGroupPlanValue = new EventEmitter<any>();
  @Output() hasShowRecordVal = new EventEmitter<any>();
  @Output() subHeaderFormObj = new EventEmitter<any>();

  public empAccntTblSubHeader: ManageEmpOrParticipantHeader = empOrParticipantTableSubHeader;

  empListSubHeaderForm: UntypedFormGroup;
  currentOrgSubscription: Subscription;
  searchInputUpdate = new Subject<any>();
  sharedConstant = SharedConstant;
  grpOrPlanSearchVal: string;
  nameDropdownInpVal: string;
  empAccSubHeaderCols: any[];
  grpOrPlanNameList: any[];
  getOrgId: any;
  param = {
    pageNo: 0,
    pageSize: 15,
    sortField: ['name,asc']
  };
  isClearClicked = true;
  @ViewChild('dropdown') groupDropdown: Dropdown;

  constructor(private fb: UntypedFormBuilder, public sharedService: SharedService, public manageEmployeeDataService: ManageEmployeeDataService,
              public manageEmpGrpService: ManageEmpGrpService, @Inject('entitlementConst') public entitlementConst) {
  }

  ngOnInit() {
    this.empListSubHeaderForm = this.fb.group({
      empGroupName: '',
    });


    const param = {
      paramObj: this.param,
      groupName: ''
    };

    this.currentOrgSubscription = this.sharedService.clientInformation.subscribe(clientInfo => {
      if (clientInfo !== null) {
        this.getOrgId = clientInfo.orgId;
        this.grpOrPlanSearchVal = this.nameDropdownInpVal = '';
        this.empAccSubHeaderCols = this.empAccntTblSubHeader[clientInfo.businessUnit];
        if (this.getOrgId !== 0 && this.manageEmployeeDataService.filterToggleClicked) {
          /* selectedGroupAndPlanNameInEmpGroup value will be set in 2 places --> 1. in employee groups/plans page when No. Of Accounts link is clicked.
          2. When value is selected in group name filter on employee/participants page (This is done to hold on to the selected value when user simply closes and re-opens the filter toggle) */
          if (this.sharedService.selectedGroupAndPlanNameInEmpGroup) {
            this.isClearClicked = false;
            this.nameDropdownInpVal = this.grpOrPlanSearchVal = this.sharedService.selectedGroupAndPlanIdInEmpGroup;
            param.groupName = this.sharedService.selectedGroupAndPlanNameInEmpGroup;
            this.searchInputUpdate.next(this.sharedService.selectedGroupAndPlanNameInEmpGroup);
            this.groupNameUpdated();

            const name = this.sharedService.selectedGroupAndPlanNameInEmpGroup ? this.sharedService.selectedGroupAndPlanNameInEmpGroup : this.nameDropdownInpVal ? this.nameDropdownInpVal : 'All';
            this.empListSubHeaderForm.get('empGroupName').setValue(name);
            const localUpdatedFormValues = this.empListSubHeaderForm.getRawValue();
            /* Call Apply filter in parent only if navigating from Manage Employee Groups/Plans page using no. of account
            link (this condition is added to avoid making the api call when Apply button is not clicked) */
            if (this.sharedService.navFromGrpOrPlan) {
              this.subHeaderFormObj.emit({empListSubHeaderForm: localUpdatedFormValues});
              this.sharedService.navFromGrpOrPlan = false;
            }
            this.getEmployeeGroupList(param);
            this.manageEmployeeDataService.filterToggleClicked = false;
          } else {
            this.getEmployeeGroupList(param);
            this.manageEmployeeDataService.filterToggleClicked = false;
          }
        }
      }
    });

    this.searchInputUpdate
      .pipe(debounceTime(500))
      .pipe(distinctUntilChanged())
      .subscribe((results) => {
        const paramObj = {
          paramObj: this.param,
          groupName: results ? results : ''
        };
        if (this.getOrgId !== undefined && this.getOrgId !== 0) {
          this.getEmployeeGroupList(paramObj);
        }
      });

    this.manageEmployeeDataService.clearClicked.subscribe(val => {
      if (val) {
        this.isClearClicked = true;
        this.clearFilterForm();
      }
    });
  }

  getEmployeeGroupList(param) {
    const filterObj = {orgId: this.getOrgId, groupName: param.groupName, active: true};
    this.manageEmpGrpService.getEmployeeGrpOrPlanList(param.paramObj, filterObj).subscribe((value: any) => {
      this.grpOrPlanNameList = [];
      this.grpOrPlanNameList.unshift({
        label: this.sharedConstant.groupNameAll,
        value: null
      });
      value.data.content.forEach(empGroupName => {
        const data = {label: empGroupName.name, value: empGroupName.id};
        this.grpOrPlanNameList.push(data);
      });
      this.empListSubHeaderForm.get('empGroupName').setValue(this.grpOrPlanNameList[0].label);
    });
  }

  searchedText(event, data) {
    if (data === this.sharedConstant.groupName) {
      this.grpOrPlanSearchVal = event.target.value;
      this.searchInputUpdate.next(this.grpOrPlanSearchVal);
    }
  }

  clearSearch(dropdown: Dropdown) {
    if (!this.grpOrPlanSearchVal) {
      dropdown.resetFilter();
    }
  }

  groupNameUpdated() {
    if (this.isClearClicked && this.sharedService.selectedGroupAndPlanNameInEmpGroup === '') {
      this.nameDropdownInpVal = '';
      this.isClearClicked = false;
    }
    this.hasGroupPlanValue.emit(this.nameDropdownInpVal);
  }

  clearFilterForm(): void {
    this.empListSubHeaderForm.reset();
    if (this.grpOrPlanSearchVal) {
      this.grpOrPlanSearchVal = '';
      this.searchInputUpdate.next('');
    }
    this.nameDropdownInpVal = '';
    this.hasGroupPlanValue.emit(this.nameDropdownInpVal);
  }

  ngOnDestroy(): void {
    if (this.currentOrgSubscription) {
      this.currentOrgSubscription.unsubscribe();
    }
  }

  ngAfterViewInit() {
    if (this.groupDropdown && this.nameDropdownInpVal) {
      this.groupDropdown.filterValue = this.nameDropdownInpVal;
    }
  }
}
