import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot, RoutesRecognized } from '@angular/router';
import { Observable } from 'rxjs';
import { WorkflowService } from './workflow.service';
import {Workflow, Workflows} from '../../models/workflow.model';
import {SharedService} from '../../shared.service';

@Injectable({
  providedIn: 'root'
})
export class WorkflowGuard implements CanActivate {
    public workflowStep: Workflows = Workflow;
    constructor(private router: Router, private workflowService: WorkflowService, private sharedService: SharedService) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        this.sharedService.clearErrorMessage();
        const path: string = route.routeConfig.path;
        const rPath = route.data.workflow;
        const basePath: string = state.url.substring(0, state.url.lastIndexOf('/') + 1);
        const parentPath = basePath.split('/').slice(0, 4).toString().replace(/,/g, '/');
        return this.router.navigated ? this.verifyWorkFlow(path, rPath, basePath) : this.router.navigate([parentPath]);
    }

    verifyWorkFlow(path, rPath, basePath): boolean {
        // If any of the previous steps is invalid, go back to the first invalid step
        const firstPath = this.workflowService.getFirstInvalidStep(path, rPath);
        if (firstPath.length > 0) {
            const url = basePath + firstPath;
            this.router.navigate([url]);
            return false;
        }
        return true;
    }
}


