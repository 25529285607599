import {Component, OnInit, Output, Input, EventEmitter, Inject} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import {emtrConstant} from '../../constants/emtrConstants';
import {RuleService} from '../../services/rule-service/rule.service';

@Component({
  selector: 'tr-description-and-notes',
  templateUrl: './description-and-notes.component.html',
  styleUrls: ['./description-and-notes.component.scss']
})
export class DescriptionAndNotesComponent implements OnInit {

  @Output() editTextArea = new EventEmitter<boolean>();
  @Input() descriptionForm: UntypedFormGroup = this.fb.group({
    description: '',
    notes: '',
    changeRequestId: ''
  });
  @Input() isRuleExpired: any;
  getConstant = emtrConstant;
  showDescCharLimit: boolean;
  showNotesCharLimit: boolean;

  constructor(private fb: UntypedFormBuilder, @Inject('commonConstants') public commonConstants, private ruleService: RuleService) { }

  goToEditPage() {
    this.editTextArea.emit();
  }

  ngOnInit() {
    this.onChanges();
  }

  onChanges() {
    this.descriptionForm.get('changeRequestId').valueChanges.subscribe(changeRequestIdValue => {
      if (changeRequestIdValue) {
        const regExp = new RegExp('^[a-zA-Z0-9\\d\\-_\\s]*$');
        if (changeRequestIdValue && !regExp.test(changeRequestIdValue)) {
          this.ruleService.showRequestIdError.emit(true);
        } else {
          this.ruleService.showRequestIdError.emit(false);
        }
      } else {
        this.ruleService.showRequestIdError.emit(false);
      }
    });
  }

}
