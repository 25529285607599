import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {AddlinkConstant} from '../../../../constants/addlinkConstants';
import {Table} from 'primeng/table';
import {SharedService} from '@ems/shared';
import {CommonService} from '../../../services/common.service';
import {RequestListService} from '../../../services/request-list.service';

@Component({
  selector: 'addlink-deny-requests-modal',
  templateUrl: './deny-requests-modal.component.html',
  encapsulation: ViewEncapsulation.None
})
export class DenyRequestsModalComponent implements OnInit {

  addLinkConstant = AddlinkConstant;

  public title = '';
  public requestIds: any[];
  public requestDenied = 0;
  public requestListTable: Table;
  public intervalJobs: any = {};
  wholeRecordArr = [];
  orgId: any;

  constructor(public sharedService: SharedService, private commonService: CommonService, private requestListService: RequestListService) {
  }

  /* Initialize component variables */
  ngOnInit() {
  }

  /* Opens The Modal */
  public openModal(e: Event, requestIds: any, requestDeniedCount: number, requestListTable: Table, title: string, orgId: any): void {
    e.stopPropagation();
    this.title = title;
    this.requestIds = requestIds;
    this.requestDenied = requestDeniedCount;
    this.requestListTable = requestListTable;
    this.requestIds.sort();
    this.requestIds.reverse();
    this.orgId = orgId;
    this.sharedService.openModal('beta-deny-request-modal');
  }

  takeRequestAction() {
    const action = this.title === this.addLinkConstant.denyRequestModalLabel ? 'DENY' : 'RESOLVE';
    this.requestListTable.selection = [];
    const count = 0;
    this.requestListService.addlinkRequestPutEndPoint(this.requestIds, '', action, this.orgId).subscribe((res) => {
      this.requestIds.forEach(requestId => {
        const localObj = {reqId: requestId, count};
        this.wholeRecordArr.push(localObj);
        this.intervalJobs[requestId] = setInterval(() => {
          this.requestListService.getRequestStatus(requestId, this.intervalJobs);
          this.wholeRecordArr.forEach((data) => {
            if (data.reqId === requestId) {
              data.count++;
              if (data.count > 4) {
                window.clearInterval(this.intervalJobs[data.reqId]);
              }
            }
          });
        }, this.addLinkConstant.reqDetailPollingInterval);
      });
    });
    this.sharedService.closeModal('beta-deny-request-modal');
  }
}
