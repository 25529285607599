import {SharedConstant} from '../constants/sharedConstants';

export interface ConfirmMessage {
    header?: string;
    message?: string;
    acceptBtn?: string;
    rejectBtn?: string;
}

export const DefaultConfirmMessage: ConfirmMessage = {
    header: SharedConstant.confirmationDialog.title,
    message: '',
    acceptBtn: SharedConstant.confirmationDialog.acceptBtn,
    rejectBtn: SharedConstant.confirmationDialog.rejectBtn
};
