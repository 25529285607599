import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ManageEmployeeDataService, WorkflowService } from '@ems/shared';
import { AddlinkRoutingModule } from './addlink-routing.module';
import { AddlinkComponent } from './addlink.component';
import { PrimeNgModule } from './common_modules/prime-ng/prime-ng.module';
import { DelinkComponent } from './core/delink/delink.component';
import { LinkComponent } from './core/link/link.component';
import { RequestListComponent } from './core/request-list/request-list.component';
import { UploadComponent } from './core/upload/upload.component';
import { ExpandedRowComponent } from './shared/components/expanded-row/expanded-row.component';
import { AddAuthorizedAgentModalComponent } from './shared/components/modals/add-authorized-agent-modal/add-authorized-agent-modal.component';
import { ClientCentralNotesComponent } from './shared/components/modals/client-central-notes/client-central-notes.component';
import { DenyRequestsModalComponent } from './shared/components/modals/deny-requests-modal/deny-requests-modal.component';
import { EditIndexKeyModalComponent } from './shared/components/modals/edit-index-key-modal/edit-index-key-modal.component';
import { EditRequestsModalComponent, AfterIfDirective } from './shared/components/modals/edit-requests-modal/edit-requests-modal.component';
import { RemarksModalComponent } from './shared/components/modals/remarks-modal/remarks-modal.component';
import { ResubmitRequestsModalComponent } from './shared/components/modals/resubmit-requests-modal/resubmit-requests-modal.component';
import { DelinkMasterAccountModalComponent } from './shared/components/modals/delink-master-account-modal/delink-master-account-modal.component';
import { CommonService } from './shared/services/common.service';
import { DelinkService } from './shared/services/delink.service';
import { LinkService } from './shared/services/link.service';
import { RequestListService } from './shared/services/request-list.service';
import {SharedModule} from '@ems/shared';
import { RequestListSubHeaderComponent } from './shared/components/request-list-sub-header/request-list-sub-header.component';
import { AddlinkDatepickerComponent } from './shared/addlink-datepicker/addlink-datepicker.component';
import { AddlinkFromToDatepickerComponent } from './shared/components/addlink-from-to-datepicker/addlink-from-to-datepicker.component';

@NgModule({
  declarations: [
    AddlinkComponent,
    UploadComponent,
    ResubmitRequestsModalComponent,
    DenyRequestsModalComponent,
    EditRequestsModalComponent,
    EditIndexKeyModalComponent,
    RemarksModalComponent,
    DelinkMasterAccountModalComponent,
    AddAuthorizedAgentModalComponent,
    ClientCentralNotesComponent,
    ExpandedRowComponent,
    DelinkComponent,
    LinkComponent,
    RequestListComponent,
    AfterIfDirective,
    RequestListSubHeaderComponent,
    AddlinkDatepickerComponent,
    AddlinkFromToDatepickerComponent
  ],
  imports: [
    AddlinkRoutingModule,
    CommonModule,
    PrimeNgModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  providers: [
    LinkService,
    DelinkService,
    RequestListService,
    CommonService,
    WorkflowService,
    ManageEmployeeDataService
  ],
  exports: [
    AddlinkComponent,
    UploadComponent,
    PrimeNgModule,
  ]
})
export class AddlinkModule {
}
