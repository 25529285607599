export interface EmtrPagination {
  pageSize?: number;
  pageNo?: number;
  sortField?: any;
}

export const EmtrPaginationValues: EmtrPagination = {
  pageSize: 15,
  pageNo: 0,
  sortField: ''
};
