import {EventEmitter, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ConfigUrlService} from '@ems/shared';
import {Observable} from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class HeaderService {

  emitClient = new EventEmitter<string>();
  configURL = this.config.setApplicationUrl();

  constructor(private httpService: HttpClient, public config: ConfigUrlService) {

  }
  getClientProfile() {
    return this.httpService.post(this.configURL.bffClientsSummaryEndpointV1 + '&sortField=clientName,asc', '');
  }
  getAutoSearchClientProfile(pageSize: number, pageNumber: number, query: string): Observable<any> {
    return this.httpService.post(this.configURL.bffClientsSummaryEndpointV1 + '&pageNumber=' + pageNumber + '&pageSize=' + pageSize + '&searchName=' + (query ? query.trim() : '') + '&sortField=clientName,asc', '');
  }
  getManageUserBUProfile(pageSize: number, pageNumber: number, query: string) {
    return this.httpService.post(this.configURL.bffClientsSummaryEndpointV1 + '&pageNumber=' + pageNumber + '&pageSize=' + pageSize + '&searchName=' + (query ? query.trim() : '') + '&sortField=clientName,asc' + '&adminOperation=true', '');
  }
  getManageUserClientProfile() {
    return this.httpService.post(this.configURL.bffClientsSummaryEndpointV1 + '&sortField=clientName,asc' + '&adminOperation=true', '');
  }
}
