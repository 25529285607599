import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable, ReplaySubject} from 'rxjs';
import { ConfigUrlService } from '@ems/shared';
import {SharedService} from '@ems/shared';
import {ShellConstant} from '../../constants/shellConstants';

@Injectable({
  providedIn: 'root'
})
export class ManageClientService {

  orgId: string;
  public editMode: boolean;
  public getCustId = 0;
  private subject = new ReplaySubject<any>();
  configURL = this.configService.setApplicationUrl();
  shellConstant = ShellConstant;

  constructor(private sharedService: SharedService, private httpService: HttpClient, @Inject('env') public env, private configService: ConfigUrlService) {
  }

  getManageClients(pageObj, formFilterObj): Observable<any> {
    let schwabClientHeader = [];
    if (formFilterObj.orgId) {
      schwabClientHeader = this.sharedService.setSchwabClientHeaders(this.sharedService.applicationInfo.data.queryParamMap.orgId, formFilterObj.orgId, schwabClientHeader);
    }
    const clientFilterInput = formFilterObj;
    this.sharedService.stateValues.filterObj = formFilterObj;
    this.sharedService.stateValues.pageObj.pageNo = pageObj.pageNo;
    this.sharedService.stateValues.pageObj.pageSize = pageObj.pageSize;
    this.sharedService.setStateVal(JSON.stringify(this.sharedService.stateValues));
    this.sharedService.tableDropdownVal.next(pageObj.pageSize);
    const sortField = (pageObj.sortField) ? pageObj.sortField.map(data => '&sortField=' + data).join('') : '';
    const endPoint = this.configURL.bffClientsSummaryEndpointV1 + '&pageNumber=' + (pageObj.pageNo) + '&pageSize=' + pageObj.pageSize + sortField + '&Schwab-Client-Ids= ' + schwabClientHeader;
    return this.httpService.post(endPoint, clientFilterInput);
  }

  getMasterAccounts(custId, buSelected): Observable<any> {
    let schwabClientHeader = [];
    schwabClientHeader = this.sharedService.setSchwabClientHeaders(this.sharedService.applicationInfo.data.queryParamMap.orgId, this.getCustId, schwabClientHeader);
    let appSource = '';
    if (buSelected === this.shellConstant.rbsBUText) {
      appSource = '&appSource=RBSMASTER';
      schwabClientHeader = this.sharedService.setSchwabClientHeaders(this.sharedService.applicationInfo.data.queryParamMap.masterAccount, custId, schwabClientHeader);
    } else {
      appSource = '&appSource=CAT';
      schwabClientHeader = this.sharedService.setSchwabClientHeaders(this.sharedService.applicationInfo.data.queryParamMap.custId, custId, schwabClientHeader);
    }
    return this.httpService.get(this.configURL.bffMasterAccountEndpointV1 + appSource + '&Schwab-Client-Ids= ' + schwabClientHeader);
  }

  sendMessage(message: any) {
    this.subject.next({text: message});
  }

  getMessage(): Observable<any> {
    return this.subject.asObservable();
  }

  clearMessage() {
    this.subject.next({text: ''});
  }

  getBusinessUnitDropdownValue(): Observable<any> {
    return this.httpService.get(this.configURL.bffBusinessUnitEndpointV1 + '&type=client');
  }

  saveClient(clientObj): Observable<any> {
    return this.httpService.post(this.configURL.bffClientEndpointV1, clientObj);
  }

  updateClient(clientObj): Observable< any> {
    let schwabClientHeader = [];
    schwabClientHeader = this.sharedService.setSchwabClientHeaders(this.sharedService.applicationInfo.data.queryParamMap.orgId, clientObj.orgId, schwabClientHeader);
    return this.httpService.put(this.configURL.bffClientEndpointV1 + '&Schwab-Client-Ids= ' + schwabClientHeader, clientObj);
  }


  getClientById(orgId: number): Observable< any> {
    let schwabClientHeader = [];
    schwabClientHeader = this.sharedService.setSchwabClientHeaders(this.sharedService.applicationInfo.data.queryParamMap.orgId, orgId, schwabClientHeader);
    return this.httpService.get(this.configURL.bffClientEndpointV1 + '&Schwab-Client-Ids= ' + schwabClientHeader);
  }

}
