export interface EmpGrpAccTableHead {
  field?: string;
  header?: string;
}

export interface ManageEmpAccntOrParticipantHeader {
  DBS?: EmpGrpAccTableHead[];
  SPS?: EmpGrpAccTableHead[];
  RBS?: EmpGrpAccTableHead[];
  MGP?: EmpGrpAccTableHead[];
}

export const manageEmpGrpAccTableHeader: EmpGrpAccTableHead[] = [
  { field: 'empId', header: 'Employee ID' },
  { field: 'subAcctNum', header: 'Account Number' },
  { field: 'firstName', header: 'First Name' },
  { field: 'lastName', header: 'Last Name' },
  { field: 'acctHolderName', header: 'Account Holder Name' },
];

export const empAccntOrParticipantTableHeader: ManageEmpAccntOrParticipantHeader = {
  DBS: [
    { field: 'empId', header: 'Employee ID' },
    { field: 'subAcctNum', header: 'Account Number' },
    { field: 'firstName', header: 'First Name' },
    { field: 'lastName', header: 'Last Name' },
    { field: 'acctHolderName', header: 'Account Holder Name' }
  ],

  SPS: [
    { field: 'empId', header: 'Employee ID' },
    { field: 'subAcctNum', header: 'Account Number' },
    { field: 'firstName', header: 'First Name' },
    { field: 'lastName', header: 'Last Name' },
    { field: 'acctHolderName', header: 'Account Holder Name' }
  ],

    RBS: [
      { field: 'subAcctNum', header: 'Account Number' },
      { field: 'firstName', header: 'First Name' },
      { field: 'lastName', header: 'Last Name' },
      { field: 'acctHolderName', header: 'Account Holder Name' },
    ],

  MGP: [
    {field: 'firstName', header: 'Client / Registration Name'},
    {field: 'subAcctNum', header: 'Master Account'}
  ]
};
