import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {EmpGroupFormDataService} from '../emp-group-form-data/emp-group-form-data.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ManageEmpGrpService, SharedConstant, SharedService} from '@ems/shared';
import {emtrConstant} from '../../../constants/emtrConstants';
import {EmtrService} from '../../../emtr.service';
import { Observable, Observer } from 'rxjs';

@Component({
  selector: 'tr-step2-add-employees',
  templateUrl: './step2-add-employees.component.html'
})
export class Step2AddEmployeesComponent implements OnInit, OnDestroy {

  getConstant = emtrConstant;
  getSharedConstant = SharedConstant;
  employeeGroups: any[] = [];
  employeeAccounts: any[] = [];
  addEmployeeFormData: any;
  selectedValues: any;
  initialLoad = true;
  message: any;
  noOfRecords: any = 15;
  cancelButton = true;
  selectedArr: any = [];
  selectedArrAccount: any = [];
  orgId = '';
  employees: any[];
  empIds: any[] = [];
  subAccNumbers: any[] = [];
  ruleType: string;
  parent = 0;
  child = 0;
  localObserver: any;
  confirmMessage: any;
  setPreviousStep = false;
  param = {
    orgId: '',
    pageNumber: 1,
    pageSize: 1,
    sortField: ''
  };
  is10b5Client = false;
  loadedModuleType = 'EmployeeGroup';
  mergedArr: any[];
  isEmitted: any = false;
  subAccntNumbers: any[] = [];
  newlyAssignedEmployeeAccounts: any = {};
  modifiedSelectedRecords: any = {};
  businessUnit: any;
  initialEditGroupAssignInfoData: any = [];
  @ViewChild('confirmationMessage', { static: true }) confirmationMessage: any;
  @ViewChild('uploadList', { static: true }) uploadList: ElementRef;

  constructor(public sharedService: SharedService, public formDataService: EmpGroupFormDataService, private router: Router, private r: ActivatedRoute, private el: ElementRef, private manageEmpService: ManageEmpGrpService, public emtrService: EmtrService) {
    this.addEmployeeFormData = {...this.formDataService.getFormData()};
    this.sharedService.clientInformation.subscribe(clientInfo => {
      if (clientInfo) {
        this.businessUnit = this.sharedService.groupType === this.getSharedConstant.manageMasterGroups.groupTypeMGP ? this.sharedService.groupType : clientInfo.businessUnit;
        this.loadedModuleType = this.sharedService.groupType === this.getSharedConstant.manageMasterGroups.groupTypeMGP ? this.sharedService.groupType : 'EmployeeGroup';
      }
    });
  }

  ngOnInit() {
    this.orgId = this.manageEmpService.orgId;
    if (this.addEmployeeFormData.employeeGroupAssignInfo.employeeAccounts) {
      this.selectedArr = this.addEmployeeFormData.employeeGroupAssignInfo.employeeAccounts ? [...this.addEmployeeFormData.employeeGroupAssignInfo.employeeAccounts] : [];
      this.empIds = this.addEmployeeFormData.employeeGroupAssignInfo.empIds ? [...this.addEmployeeFormData.employeeGroupAssignInfo.empIds] : [];
      this.subAccNumbers = this.addEmployeeFormData.employeeGroupAssignInfo.subAccNumbers ? [...this.addEmployeeFormData.employeeGroupAssignInfo.subAccNumbers] : [];
      if (this.loadedModuleType === 'EmployeeGroup' && this.addEmployeeFormData.mode === 'EDIT') {
        this.initialEditGroupAssignInfoData = this.addEmployeeFormData.employeeGroupAssignInfo ? { ...this.addEmployeeFormData.employeeGroupAssignInfo } : [];
        console.log(this.initialEditGroupAssignInfoData, 'this.initialEditGroupAssignInfoData 72')
        console.log(this.sharedService.empGrpStep2InitialEmpData, 'this.initialEditGroupAssignInfoData 72')
      }
    }
    this.confirmMessage = this.confirmationMessage.confirmMessage;
    this.confirmMessage.message = this.getConstant.rulesConstant.confirmationMessage.menuClickMsg;
    this.sharedService.schErrorMsg.subscribe(value => {
      this.emtrService.schErrorMsg.emit(value);
      if (value.length > 0) {
        this.emtrService.showSchError.emit(true);
      } else {
        this.emtrService.showSchError.emit(false);
      }
    });
    this.is10b5Client = this.sharedService.get10b5ClientDetails();

    if (this.uploadList) {
      this.uploadList.nativeElement.focus();
    }
  }

  modifySelectedArray() {
    if (this.addEmployeeFormData.employeeGroupAssignInfo.newSelectedRecords && this.addEmployeeFormData.employeeGroupAssignInfo.newSelectedRecords.length) {
      this.addEmployeeFormData.employeeGroupAssignInfo.newSelectedRecords.forEach(newSel => {
        if ((this.selectedArr.findIndex(sele => sele.empId === newSel.empId && sele.subAcctNum === newSel.subAcctNum) === -1)) {
          this.selectedArr.push(newSel);
        }
      });
    }

    if (this.addEmployeeFormData.employeeGroupAssignInfo.removedExistingSelected && this.addEmployeeFormData.employeeGroupAssignInfo.removedExistingSelected.length) {
      this.addEmployeeFormData.employeeGroupAssignInfo.removedExistingSelected.forEach(remSel => {
        const remIdx = this.selectedArr.findIndex(sele => sele.empId === remSel.empId && sele.subAcctNum === remSel.subAcctNum);
        if (remIdx !== -1) {
          this.selectedArr.splice(remIdx, 1);
        }
      });
    }
  }

  canDeactivate(event, observer) {
    this.confirmMessage.message = (this.confirmMessage.message !== undefined && this.confirmMessage.message) ? this.confirmMessage.message : this.getConstant.rulesConstant.confirmationMessage.menuClickMsg;
    if ((this.cancelButton && this.addEmployeeFormData.mode === 'EDIT') || (this.cancelButton && this.addEmployeeFormData.mode !== 'EDIT' && (this.selectedArrAccount.length > 0 || this.selectedArr.length > 0))) {
      this.localObserver = observer;
      return this.confirmationMessage.showConfirmMsg(this.confirmMessage, observer);
    }
    return true;
  }

  storePageInfo(type) {
    if (this.addEmployeeFormData.mode === 'EDIT') {
      this.mergedArr = this.isEmitted ? this.selectedArr : this.addEmployeeFormData.employeeGroupAssignInfo.employeeAccounts;
    } else {
      this.mergedArr = this.selectedArr;
    }
    const wholeArray = this.mergedArr;
    const localSelectedItem = this.sharedService.setSelectionItem(wholeArray);

    let formObj = {};
    if (this.loadedModuleType === 'EmployeeGroup' && this.addEmployeeFormData.mode === 'EDIT') {
      this.modifiedSelectedRecords.assignEmpIds = [];
      this.modifiedSelectedRecords.assignSubAcctNumbers = [];
      this.modifiedSelectedRecords.unAssignEmpIds = [];
      this.modifiedSelectedRecords.unAssignSubAcctNumbers = [];
      if (this.newlyAssignedEmployeeAccounts.newSelectedRecords && this.newlyAssignedEmployeeAccounts.newSelectedRecords.length) {
        this.newlyAssignedEmployeeAccounts.newSelectedRecords.forEach(newSel => {
          if (newSel.empId && newSel.parent && !this.modifiedSelectedRecords.assignEmpIds.includes(newSel.empId)) {
            this.modifiedSelectedRecords.assignEmpIds.push(newSel.empId);
          }
          if (!newSel.parent && newSel.child) {
            this.modifiedSelectedRecords.assignSubAcctNumbers.push({ empId: newSel.empId || '', subAcctNum: newSel.subAcctNum });
          }
        });
      }
      if (this.newlyAssignedEmployeeAccounts.removedExistingSelected && this.newlyAssignedEmployeeAccounts.removedExistingSelected.length) {
        this.newlyAssignedEmployeeAccounts.removedExistingSelected.forEach(remSel => {
          if (remSel.empId && remSel.empLevelGroupIds && remSel.empLevelGroupIds.indexOf(this.addEmployeeFormData.id) !== -1) {
            console.log(this.modifiedSelectedRecords.unassignedEmpIds, 'this.modifiedSelectedRecords.unassignedEmpIds 155');
            
            this.modifiedSelectedRecords.unAssignEmpIds.push(remSel.empId);
          }
          if (remSel.groupIds.indexOf(this.addEmployeeFormData.id) !== -1) {
            this.modifiedSelectedRecords.unAssignSubAcctNumbers.push({ empId: remSel.empId || '', subAcctNum: remSel.subAcctNum });
          }
        })
      }
    }

    if (this.addEmployeeFormData.mode === 'EDIT') {
      formObj = {
        empIds: this.setPreviousStep ? [] : this.empIds,
        subAccntNumbers: this.setPreviousStep ? [] : this.subAccntNumbers,
        employeeAccounts: this.mergedArr,
        newSelectedRecords: this.newlyAssignedEmployeeAccounts.newSelectedRecords,
        removedExistingSelected: this.newlyAssignedEmployeeAccounts.removedExistingSelected,
        assignEmpIds: this.modifiedSelectedRecords.assignEmpIds,
        assignSubAcctNumbers: this.modifiedSelectedRecords.assignSubAcctNumbers,
        unAssignEmpIds: this.modifiedSelectedRecords.unAssignEmpIds,
        unAssignSubAcctNumbers: this.modifiedSelectedRecords.unAssignSubAcctNumbers,
        subAccntNewlySelected: this.newlyAssignedEmployeeAccounts.subAccntNewlySelected,
        removedExistingEmpId: this.newlyAssignedEmployeeAccounts.removedExistingEmpId,
      };
    } else {
      formObj = {
        empIds: this.empIds,
        subAccntNumbers: this.subAccNumbers,
        employeeAccounts: this.mergedArr
      };
    }
    if (type === 'falseStep2') {
      if (this.loadedModuleType === 'EmployeeGroup' && this.addEmployeeFormData.mode === 'EDIT') {
        this.sharedService.isPreviousBtnClickedOnEdit = true;
        console.log(this.initialEditGroupAssignInfoData.newSelectedRecords, 'this.initialEditGroupAssignInfoData.newSelectedRecords 183');
        this.sharedService.empGrpStep2InitialEmpData = this.initialEditGroupAssignInfoData.newSelectedRecords ? [...this.initialEditGroupAssignInfoData.newSelectedRecords] : [];
      } else {
        this.sharedService.empGrpStep2InitialEmpData = this.addEmployeeFormData.employeeGroupAssignInfo.employeeAccounts ? [...this.addEmployeeFormData.employeeGroupAssignInfo.employeeAccounts] : [];
      }
      this.formDataService.setStep2(formObj, false);
    } else {
      this.sharedService.isPreviousBtnClickedOnEdit = false;
      this.formDataService.setStep2(formObj, true);
    }
  }

  goToPreviousStep() {
    this.setPreviousStep = true;
    this.cancelButton = false;
    this.storePageInfo('falseStep2');
    this.navigateTo('../step1');
  }

  goToNextStep() {
    this.storePageInfo('');
    this.cancelButton = false;
    this.sharedService.empGrpStep2InitialEmpData = [];
    this.navigateTo('../step3');
  }

  navigateTo(path) {
    this.router.navigate([path], {relativeTo: this.r});
  }

  cancelCreateEmpGroup() {
    this.confirmMessage.message = this.getConstant.rulesConstant.confirmationMessage.cancelBtnClickMsg;
    this.router.navigate([this.getSharedConstant[this.businessUnit].toLandingPage]);
  }

  uploadEmployeeList() {
    this.sharedService.clearErrorMessage();
    /* To update the pagename when bulk upload btn is clicked. */
    this.confirmMessage.message = this.getConstant.rulesConstant.confirmationMessage.menuClickMsg;
    this.sharedService.loadDataTrackValue('client_redirect');
    this.cancelButton = this.compareEmployeeObj(this.selectedArr, [...this.addEmployeeFormData.employeeGroupAssignInfo.employeeAccounts]) ? false : true;
    this.sharedService.parentPage = '/empgroups';
    this.router.navigate([this.getSharedConstant[this.businessUnit].groupOrPlanBulkUploadRoute]);
  }

  compareEmployeeObj(arr1, arr2) {
    /*To compare selectedArr array with its previous value in order to check whether any changes are made in edit flow */
    let flag = false;
    if (arr1.length !== arr2.length) {
      return flag;
    }
    for (let index = 0; index < arr1.length; index++) {
      const val = arr1[index];
      if ((arr2.findIndex(ele => ele.empId === val.empId && ele.subAcctNum === val.subAcctNum) === -1)) {
        flag = false;
        break;
      } else {
        flag = true;
      }
    }
    return flag;
  }

  updateSelectedArray(e) {
    console.log(e, 'step 2 193 line number');
    
    if (this.initialLoad) {
      this.selectedValues = e;
      this.initialLoad = false;
    }
    this.selectedArr = e;
    this.isEmitted = true;
  }

  updateNewlySelectedArray(e) {
    this.newlyAssignedEmployeeAccounts = e;
    console.log(this.newlyAssignedEmployeeAccounts, 'this.newlyAssignedEmployeeAccounts');
    
    const assignedData = this.sharedService.setSelectionItem(e.newSelectedRecords);
    let finalLocalUnselected;
    let multipEmployeeSelection;
    let empIdAlreadyRemoved;
    // if (e.localAlreadyRemovedArr && e.removedExistingSelected.length > 0) {
    //   const localFinalLocalUnselected = this.sharedService.setSelectionItem(e.localAlreadyRemovedArr);
    //   finalLocalUnselected = localFinalLocalUnselected ? localFinalLocalUnselected : [];
    // }

    if (e.removedExistingSelected && e.removedExistingSelected.length > 0) {
      const localFinalLocalUnselected = this.sharedService.setSelectionItem(e.removedExistingSelected);
      finalLocalUnselected = localFinalLocalUnselected ? localFinalLocalUnselected : [];
    }

    if (e.subAccntNewlySelected && e.subAccntNewlySelected.length > 0) {
      const localMultipEmployeeSelection = this.sharedService.setSelectionItem(e.subAccntNewlySelected);
      console.log(localMultipEmployeeSelection, 'localMultipEmployeeSelection 218');
      console.log(finalLocalUnselected, 'finalLocalUnselected 29');
      
      multipEmployeeSelection = localMultipEmployeeSelection ? localMultipEmployeeSelection : [];
    }

    if (e.removedExistingEmpId && e.removedExistingEmpId.length > 0) {
      const localEmpIdAlreadyRemoved = this.sharedService.setSelectionItem(e.removedExistingEmpId);
      empIdAlreadyRemoved = localEmpIdAlreadyRemoved ? localEmpIdAlreadyRemoved : [];
    }
console.log(this.sharedService.formData(assignedData, multipEmployeeSelection, 'subAccntNumbers'), '(this.sharedService.formData(assignedData, multipEmployeeSelection, `subAccntNumbers`))');

    this.modifiedSelectedRecords = {
      assignEmpIds: this.sharedService.removeDuplicates(this.sharedService.formData(assignedData, multipEmployeeSelection, 'empIds')),
      assignSubAcctNumbers: this.sharedService.removeDuplicates(this.sharedService.formData(assignedData, multipEmployeeSelection, 'subAccntNumbers')),
      unAssignEmpIds: this.sharedService.removeDuplicates(this.sharedService.formData(finalLocalUnselected, empIdAlreadyRemoved, 'empIds')),
      unAssignSubAcctNumbers: (finalLocalUnselected !== undefined) ? this.sharedService.removeDuplicates(finalLocalUnselected.subAccntNumbers) : [],
    };
    console.log(this.modifiedSelectedRecords, 'this.modified selected records');
  }

  updateSelectedAccountsArray(e) {
    this.selectedArrAccount = e;
  }

  ngOnDestroy(): void {
    this.sharedService.clearValidationFields();
  }

  /* Confirmation popup for Employee which has multiple accounts (Joint account) */
  confirmationPopup() {
    if (this.sharedService.groupType === this.getSharedConstant.manageMasterGroups.groupTypeMGP && this.addEmployeeFormData.mode === 'EDIT') {
      this.goToNextStep();
    } else {
      let initialEmpData = [];
      let checkData;
      if (this.loadedModuleType === 'EmployeeGroup' && this.addEmployeeFormData.mode === 'EDIT') {
        initialEmpData = this.sharedService.isPreviousBtnClickedOnEdit ? (this.sharedService.empGrpStep2InitialEmpData || []) : (this.initialEditGroupAssignInfoData.newSelectedRecords || []);
        console.log(initialEmpData, 'initialEmpData 319');
        console.log(this.sharedService.empGrpStep2InitialEmpData, 'this.sharedService.empGrpStep2InitialEmpData 319');
        console.log(this.empIds, 'this.empIds');
        
        checkData = this.sharedService.checkChanges(this.newlyAssignedEmployeeAccounts.newSelectedRecords || [], [...initialEmpData], this.empIds);
        console.log(checkData, 'checkData 322');
        console.log(initialEmpData, 'initialEmpData 322');


        const initialRemovedExistingData = this.initialEditGroupAssignInfoData.removedExistingSelected || [];
        const currentRemovedExistingData = this.newlyAssignedEmployeeAccounts.removedExistingSelected || [];
        if (initialRemovedExistingData && currentRemovedExistingData && initialRemovedExistingData.length > currentRemovedExistingData.length) {
          currentRemovedExistingData.forEach(ele => (ele.empId && (checkData.extraEmpIds.indexOf(ele.empId) !== -1)) ? checkData.extraEmpIds.splice(checkData.extraEmpIds.indexOf(ele.empId), 1) : '');
          const againSelectedAlreadyRemoved = initialRemovedExistingData.filter(initialEle =>
            (currentRemovedExistingData.findIndex(currentEle => currentEle.empId === initialEle.empId && currentEle.subAcctNum === initialEle.subAcctNum) === -1));
          console.log(againSelectedAlreadyRemoved, 'againSelectedAlreadyRemoved 309');
          againSelectedAlreadyRemoved.forEach(ele => (ele.empId && !checkData.extraEmpIds.includes(ele.empId)) ? checkData.extraEmpIds.push(ele.empId) : '');
        }
      } else {
        initialEmpData = this.sharedService.empGrpStep2InitialEmpData.length ? this.sharedService.empGrpStep2InitialEmpData : this.addEmployeeFormData.employeeGroupAssignInfo.employeeAccounts;
        checkData = this.sharedService.checkChanges(this.selectedArr, [...initialEmpData], this.empIds);
      }
      console.log(this.initialEditGroupAssignInfoData.removedExistingSelected, 'this.initialEditGroupAssignInfoData.removedExistingSelected')
      console.log(this.newlyAssignedEmployeeAccounts.removedExistingSelected, 'this.newlyAssignedEmployeeAccounts.removedExistingSelected')

      const payload = {
        // orgId: this.orgId,
        empIdList: checkData.allEmpIds
      }
      console.log(checkData, 'checkData 236');
      // return;

      if ((checkData.extraData.length && checkData.extraEmpIds.length) || (checkData.extraEmpIds.length && this.addEmployeeFormData.mode === 'EDIT')) {
        const confirmObj = {
          header: `${this.getSharedConstant.confirmationDialog.title}`,
          message: this.getSharedConstant.employeeAccountSelectionMessage,
          acceptBtn: this.getSharedConstant.confirmationDialog.acceptBtn,
          rejectBtn: this.getSharedConstant.confirmationDialog.rejectBtn,
          closeIconButton: false
        };
        new Observable((observer: Observer<boolean>) => {
          this.confirmationMessage.showConfirmMsg(confirmObj, observer);
        }).subscribe(accept => {
          if (accept) {
            if (this.addEmployeeFormData.mode === 'EDIT') {
              this.assignAndUnassignEmployees(true, checkData);
            } else {
              this.addAccountsToEmployeeArray(payload, checkData);
            }
          } else {
            if (this.addEmployeeFormData.mode === 'EDIT') {
              this.assignAndUnassignEmployees(false, checkData);
            } else {
              this.empIds = checkData.updatedEmpIds;
              this.updateSubAccNumbers(checkData.updatedEmpIds);
              this.goToNextStep();
            }
          }
        });
      } else {
        if (this.addEmployeeFormData.mode === 'EDIT') {
          this.assignAndUnassignEmployees(false, checkData);
        } else {
          this.empIds = checkData.updatedEmpIds;
          this.updateSubAccNumbers(checkData.updatedEmpIds);
          this.goToNextStep();
        }
      }
    }
  }

  /* Update the subaccount Number array for payload */
  updateSubAccNumbers(updatedEmpIds) {
    this.selectedArr.forEach(ele => {
      if (!ele.empId || (ele.empId && !updatedEmpIds.includes(ele.empId))) {
        this.subAccNumbers.push({ empId: ele.empId || '', subAcctNum: ele.subAcctNum });
        ele.parent = false;
        ele.child = true;
      }
    });
  }

  /* API call for empIds to get the all linked accounts */
  addAccountsToEmployeeArray(payload, data) {
    this.empIds = data.allEmpIds;
    this.selectedArr.forEach(ele => !ele.empId ? this.subAccNumbers.push({ empId: ele.empId || '', subAcctNum: ele.subAcctNum }) : '');
    /* new API call then */
    this.sharedService.getEmployeeList({ pageNo: 0, pageSize: 500 }, payload, null, null).subscribe((response) => {
      const data = response.data ? response.data.content : response.data;;
      data.forEach(element => {
        const empIdx = this.selectedArr.findIndex(sel => (element.subAcctNum === sel.subAcctNum && element.empId === sel.empId));
        if (empIdx === -1) {
          element['parent'] = element['child'] = element['selectedEmployee'] = true;
          this.selectedArr.push(element);
        } else {
          this.selectedArr[empIdx]['parent'] = this.selectedArr[empIdx]['child'] = element['selectedEmployee'] = true;
        }
      });
      this.goToNextStep();
    });
  }

  assignAndUnassignEmployees(empLevel , checkData) {
    console.log('checkData in assignAndUnassignEmployees : 368 ', checkData)
    console.log('empLevel in assignAndUnassignEmployees :369 ', empLevel)
    console.log('newlyAssignedEmployeeAccounts in assignAndUnassignEmployees :370 ', this.newlyAssignedEmployeeAccounts)
    this.empIds = empLevel ? checkData.extraEmpIds : [];
    
    checkData.extraData.forEach(emp => {
      const subAct = this.subAccntNumbers.findIndex(subAcct => subAcct.subAcctNum === emp.subAcctNum);
      if (subAct === -1 && (!emp.empId || (emp.empId && !empLevel))) {
        this.subAccntNumbers.push({empId: emp.empId || '', subAcctNum: emp.subAcctNum});
      }
    });

    const unassignedSubAccts = [];
    const unassignedEmpIds = [];
    checkData.removedData.forEach(emp => {
      /*if (!emp.empId || (emp.empId && (!emp.empLevelRuleIds || (emp.empLevelRuleIds && !emp.empLevelRuleIds.includes(this.assignFormData.id))))) {
        unassignedSubAccts.push({ empId: emp.empId || '', subAcctNum: emp.subAcctNum });
      } else if (emp.empId && emp.empLevelRuleIds && emp.empLevelRuleIds.indexOf(this.assignFormData.id) !== -1) {
        !unassignedEmpIds.includes(emp.empId) ? unassignedEmpIds.push(emp.empId) : '';
      }*/
      unassignedSubAccts.push({ empId: emp.empId || '', subAcctNum: emp.subAcctNum });
      if (emp.empId && emp.empLevelGroupIds && emp.empLevelGroupIds.indexOf(this.addEmployeeFormData.id) !== -1) {
        !unassignedEmpIds.includes(emp.empId) ? unassignedEmpIds.push(emp.empId) : '';
      }
    });

    /*this.employeeAccounts.forEach(emp => {
      const empInx = checkData.extraData.findIndex(sel => emp.empId === sel.empId && emp.subAcctNum !== sel.subAcctNum);
      // if (empInx ==! -1 && empLevel) {
      //   unassignedSubAccts.push({ empId: emp.empId || '', subAcctNum: emp.subAcctNum });
      // }

      // if (emp.empId && unassignedEmpIds.includes(emp.empId)) {
      //   this.subAccntNumbers.push({ empId: emp.empId || '', subAcctNum: emp.subAcctNum });
      // }
    });*/

    // this.modifiedSelectedRecords = {
    //   assignEmpIds: this.empIds,
    //   assignSubAcctNumbers: this.subAccntNumbers,
    //   unAssignEmpIds: unassignedEmpIds,
    //   unAssignSubAcctNumbers: unassignedSubAccts,
    // };
    // this.newlyAssignedEmployeeAccounts = { 
    //   newSelectedRecords: checkData.extraData,
    //   removedExistingSelected: checkData.removedData
    // }

    console.log(checkData, 'checkData 363')
    if (empLevel) {
      const payload = {
        // orgId: this.orgId,
        empIdList: checkData.extraEmpIds
      }
      /* new API call then */
      this.sharedService.getEmployeeList({ pageNo: 0, pageSize: 500 }, payload, null, null).subscribe((response) => {
        const data = response.data ? response.data.content : response.data;;
        data.forEach(element => {
          this.modifyAssignUnassignOnYes(element);
          // const eleIdx = this.newlyAssignedEmployeeAccounts.newSelectedRecords.findIndex(sel => (element.subAcctNum === sel.subAcctNum && element.empId === sel.empId));
          // const eleData = eleIdx !== -1 ? this.newlyAssignedEmployeeAccounts.newSelectedRecords[eleIdx] : null;
          // if (!eleData && ((element.groupIds && !element.groupIds.includes(this.addEmployeeFormData.id)) || !element.groupIds)) {
          //   element['parent'] = element['child'] = element['selectedEmployee'] = true;
          //   this.newlyAssignedEmployeeAccounts.newSelectedRecords.push(element);
          // } else if (eleData){
          //   eleData['parent'] = eleData['child'] = true;
          //   eleData['parent'] = eleData['child'] = element['selectedEmployee'] = true;
          // }
          // this.addRemoveInNewlyAssignedEmployeeAccounts(ele);
          // if (this.newlyAssignedEmployeeAccounts.newSelectedRecords && this.newlyAssignedEmployeeAccounts.newSelectedRecords.length) {
          //   const newEmpIdx = this.newlyAssignedEmployeeAccounts.newSelectedRecords.findIndex(sel => (element.subAcctNum === sel.subAcctNum && element.empId === sel.empId));
          //   if (newEmpIdx === -1) {
          //     this.newlyAssignedEmployeeAccounts.newSelectedRecords.push(element)
          //   } else {
          //     this.newlyAssignedEmployeeAccounts.newSelectedRecords[newEmpIdx]['parent'] = this.selectedArr[empIdx]['child'] = true;
          //   }
          // }

        });
        this.goToNextStep();
      });
    } else {
      if (this.newlyAssignedEmployeeAccounts.newSelectedRecords && this.newlyAssignedEmployeeAccounts.newSelectedRecords.length) {
        checkData.extraData.forEach(extEle => {
          const newIdx = this.newlyAssignedEmployeeAccounts.newSelectedRecords.findIndex(newEle => newEle.empId === extEle.empId && newEle.subAcctNum === extEle.subAcctNum);
          if (newIdx !== -1) {
            this.newlyAssignedEmployeeAccounts.newSelectedRecords[newIdx]['parent'] = false;
            extEle['parent'] = false;
          }
        });
        this.modifyAssignUnassignOnNo();
      }
      console.log(checkData.extraData, 'checkData.extraData 467')
      console.log(this.newlyAssignedEmployeeAccounts, 'modified selected records 468')
      this.goToNextStep();
    }
  }

  modifyAssignUnassignOnNo() {
    const initialNewlySelectedData = this.initialEditGroupAssignInfoData.newSelectedRecords || [];
    const currentNewlySelectedData = this.newlyAssignedEmployeeAccounts.newSelectedRecords || [];
    const empIdsToSetEmpLevelFalse = [];
    if (currentNewlySelectedData.length || initialNewlySelectedData) {
      initialNewlySelectedData.forEach(initialEle => {
        if (currentNewlySelectedData.findIndex(currentEle => currentEle.empId === initialEle.empId && currentEle.subAcctNum === initialEle.subAcctNum) === -1) {
          (initialEle.empId && this.empIds.includes(initialEle.empId)) ? this.empIds.splice(this.empIds.indexOf(initialEle.empId), 1) : '';
          empIdsToSetEmpLevelFalse.push(initialEle.empId);
        }
      });
      this.newlyAssignedEmployeeAccounts.removedExistingSelected.forEach(exitEle => {
        if (currentNewlySelectedData.findIndex(currentEle => currentEle.empId === exitEle.empId && currentEle.subAcctNum === exitEle.subAcctNum) === -1) {
          (exitEle.empId && this.empIds.includes(exitEle.empId)) ? this.empIds.splice(this.empIds.indexOf(exitEle.empId), 1) : '';
          (exitEle.empId && !empIdsToSetEmpLevelFalse.includes(exitEle.empId)) ? empIdsToSetEmpLevelFalse.push(exitEle.empId) : '';
        }
      });
      console.log(empIdsToSetEmpLevelFalse, 'empIdsToSetEmpLevelFalse 507');
      
      empIdsToSetEmpLevelFalse.forEach(empIdEle => {
        this.selectedArr.forEach(selEle => {
          if (selEle.empId === empIdEle) {
            selEle['parent'] = false;
          }
        });
        this.newlyAssignedEmployeeAccounts.newSelectedRecords.forEach(newEle => {
          if (newEle.empId === empIdEle) {
            newEle['parent'] = false;
          }
        });
      })
    }
  }

  modifyAssignUnassignOnYes(dataEle) {
    const empIdx = this.selectedArr.findIndex(sel => (dataEle.subAcctNum === sel.subAcctNum && dataEle.empId === sel.empId));
    if (empIdx === -1) {
      dataEle['parent'] = dataEle['child'] = dataEle['selectedEmployee'] = true;
      this.selectedArr.push(dataEle);
    } else {
      this.selectedArr[empIdx]['parent'] = this.selectedArr[empIdx]['child'] = true;
    }
    const eleIdx = this.newlyAssignedEmployeeAccounts.newSelectedRecords.findIndex(sel => (dataEle.subAcctNum === sel.subAcctNum && dataEle.empId === sel.empId));
    const eleData = eleIdx !== -1 ? this.newlyAssignedEmployeeAccounts.newSelectedRecords[eleIdx] : null;
    if (!eleData && ((dataEle.groupIds && !dataEle.groupIds.includes(this.addEmployeeFormData.id)) || !dataEle.groupIds)) {
      dataEle['parent'] = dataEle['child'] = dataEle['selectedEmployee'] = true;
      this.newlyAssignedEmployeeAccounts.newSelectedRecords.push(dataEle);
    } else if (eleData){
      eleData['parent'] = eleData['child'] = true;
      eleData['parent'] = eleData['child'] = eleData['selectedEmployee'] = true;
      this.newlyAssignedEmployeeAccounts.newSelectedRecords[eleIdx] = eleData;
    }

    const rmEleIdx = this.newlyAssignedEmployeeAccounts.removedExistingSelected.findIndex(sel => (dataEle.subAcctNum === sel.subAcctNum && dataEle.empId === sel.empId));
    if (rmEleIdx !== -1) {
      this.newlyAssignedEmployeeAccounts.removedExistingSelected.splice(rmEleIdx, 1);
    }
  }
}
