import {AddlinkConstant} from '../constants/addlinkConstants';

export interface RequestListActionMenu {
  label?: string;
  icon?: string;
  routerLink?: any;
  disabled?: boolean;
}

export const RequestListActionMenuItems: RequestListActionMenu[] = [
  {label: AddlinkConstant.requestListFilterFields.requestrulesLanding.client, icon: 'pi pi-list'},
  {label: AddlinkConstant.requestListFilterFields.requestrulesLanding.remarks, icon: 'sch-chat'},
  {label: AddlinkConstant.requestListFilterFields.requestrulesLanding.edit, icon: 'pi pi-pencil'},

];
