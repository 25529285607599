<ems-confirm-message #confirmationMessage></ems-confirm-message>

<div class="rule-heading sdps-p-bottom_small">Rule Type</div>

<div class="step-border-box sdps-p-around_large select-rule-type">
  <form [formGroup]="createRuleForm">
    <!-- Rule Name Textbox -->
      <div class="sdps-grid-container sdps-wrap" *ngIf="!isRbsBU">
        <div class="sdps-row">
          <div class="rule-type-textbox sdps-p-around_medium col-6" [ngClass]="{'invalidField': ((createRuleForm.get('ruleName').value && createRuleForm.get('ruleName').invalid)),
          'ruleWarning': (createRuleForm.get('ruleName').value && (ruleNameExists && !sharedService.closeToast))}">
            <span class="p-float-label">
              <input type="text" name="ruleName" [maxlength]="100" formControlName="ruleName" #focusName
                     emsAlphaNumericSpecialCharacterValidation emsCharacterLimit
                     [charMaxLimit]="commonConstants.charLimitValues.hundredMaxLimit"
                     (emsCharacterLimitCheck)="showCharLimit = $event" pInputText />
                <label [ngClass]="{'ruleNameWarningColor': (createRuleForm.get('ruleName').value && (ruleNameExists && !sharedService.closeToast))}">
                  {{getConstant.rulesConstant.ruleNameLbl}}
                  <span class="sdps-text-market-red" [innerHTML]="commonConstants.asterix"></span>
                </label>
            </span>
          </div>
        </div>
          <p class="col-6 sdps-text-right sdps-p-around_none sdps-m-around_none sdps-text-small" *ngIf="createRuleForm.get('ruleName')?.value.trim()?.length > showCharLimit">{{getConstant.rulesConstant.characterLimit}}
            <span class="character-limit">{{createRuleForm.get('ruleName').value.trim().length}}/{{commonConstants.charLimitValues.hundredMaxLimit}}</span>
          </p>
      </div>

    <div class="sdps-grid-container">
      <div class="col-full rule-type-radio sdps-p-top_medium">
        <!-- Rule Type Radio button -->
        <div class="heading">
          <strong [innerHTML]="getConstant.rulesConstant.ruleType"></strong>
          <span class="sdps-text-market-red" [innerHTML]="commonConstants.asterix"></span>
        </div>
        <div class="emtr-radio sdps-grid-container sdps-m-around_none sdps-p-vertical_large col-8" [ngClass]="(disableRuleType ? 'on-select':'on-unselect')">
          <p-radioButton class="col-3" name="rulesCategory" value="RESTRICT_LST_ENG" label="Restriction" formControlName="rulesCategory"></p-radioButton>
          <p-radioButton class="col-3" name="rulesCategory" value="WATCH_LST_ENG" label="Watchlist" formControlName="rulesCategory"></p-radioButton>
          <p-radioButton class="col-3" name="rulesCategory" value="Override" label="Override" formControlName="rulesCategory"></p-radioButton>
        </div>

        <!-- Sub-type Radio button -->
        <div class="heading">
          <strong [innerHTML]="getConstant.rulesConstant.subTypeLbl"></strong>
          <span class="sdps-text-market-red" [innerHTML]="commonConstants.asterix"></span>
        </div>
        <div class="emtr-radio sdps-grid-container sdps-m-around_none sdps-p-vertical_large col-8" [ngClass]="(disableRuleType ? 'on-select':'on-unselect')">
          <p-radioButton class="col-3" name="ruleType" value="INDSEC" label="Individual Security" formControlName="ruleType"></p-radioButton>
          <p-radioButton class="col-3" name="ruleType" value="SECTYP" label="Security Type" formControlName="ruleType"></p-radioButton>
          <p-radioButton class="col-3" name="ruleType" value="FNDFMLY" label="Fund Family" formControlName="ruleType"
                         [disabled]="(createRuleForm.get('rulesCategory').value === 'WATCH_LST_ENG')"  [ngClass]="((createRuleForm.get('rulesCategory').value === 'WATCH_LST_ENG') || disableRuleType  ? 'on-select':'on-unselect')" *ngIf="!is10b5Client"></p-radioButton>
        </div>


        <!-- Justification for Override -->
          <div *ngIf="createRuleForm.get('rulesCategory').value === 'Override'">
            <p class="heading">
              <strong [innerHTML]="getConstant.rulesConstant.ruleGlobalConst.justification"></strong>
            </p>
            <div class="emtr-radio sdps-grid-container sdps-m-around_none sdps-p-vertical_small">
              <div class="sdps-grid sdps-m-around_none sdps-p-left_none" *ngFor="let justification of sharedConstant[businessUnit].overrideJustification">
                <p-radioButton class="col-2 sdps-p-top_small radio-button" name="justification" [value]=justification [label]="justification" formControlName="justification"></p-radioButton>
              </div>
              <span class="col-3 p-float-label rule-type-textbox sdps-p-around_small" [ngClass]="{'input-disabled': createRuleForm.value.justification !== 'Other'}">
                  <input type="text" name="others" maxlength="35" formControlName="otherInput" #otherinput pInputText />
                </span>
            </div>
          </div>

           <!-- Global Rule -->
        <div class="heading" *ngIf="(createRuleForm.get('rulesCategory').value === 'RESTRICT_LST_ENG' || createRuleForm.get('rulesCategory').value === 'Override') && createRuleForm.get('ruleType').value === 'SECTYP' && sharedService.checkEntitlements(entitlementConst.navUUID.emtrAccess.manageGlobalRule.uuid,'','')">
          <strong [innerHTML]="getConstant.rulesConstant.globalRuleLbl"></strong>
          <span class="sdps-m-left_large">
            <p-checkbox class="emtr-checkbox" name="globalRule" value="true" formControlName="globalRule" binary="true"
                        [disabled]="isEditMode || isGlobalCreateFlow"></p-checkbox>
          </span>
        </div>

        <!-- 10b51 checkbox and PlanId Textbox -->
        <div *ngIf="(createRuleForm.get('rulesCategory').value === 'RESTRICT_LST_ENG' && is10b5Client && !isEditMode) ||
          (isEditMode && createRuleForm.get('enable10b5Checkbox').value)">
          <p class="heading">
            <strong [innerHTML]="getConstant.rule10b5Msg.restrictionCategoryTxt"></strong>
          </p>
          <div class="emtr-radio sdps-grid-container sdps-p-top_small sdps-m-left_small">
            <div class="col sdps-grid sdps-m-around_none sdps-p-left_none">
              <div class="sdps-m-bottom_xx-large sdps-p-top_small sdps-p-right_xx-large">
                <p-checkbox class="emtr-checkbox" label="10b5-1" formControlName="enable10b5Checkbox" (onChange)="selected10b5()"   [disabled]="(isEditMode || isDraftEdit)" binary="true"></p-checkbox>
              </div>
              <div class="sdps-display-inline-block sdps-m-left_xx-large rule-type-textbox sdps-p-around_medium"
                   [ngClass]="{'invalidField': (createRuleForm.get('planId').value && (createRuleForm.get('planId').invalid)), 'input-disabled': (isEditMode || isDraftEdit) }"
                   *ngIf="createRuleForm.get('enable10b5Checkbox').value">
                <span class="p-float-label">
                  <input type="text" [attr.disabled]="(isEditMode || isDraftEdit) ? true : null" name="planId" formControlName="planId" (blur)="removeLeadingZeroes()"
                         emsNumberPatterncheck pInputText/>
                  <label>{{getConstant.rule10b5Msg.planIdTxt}}
                    <span class="sdps-text-market-red" [innerHTML]="commonConstants.asterix"></span>
                  </label>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
<ems-step-wizard-buttons [disableBtn]="createRuleForm?createRuleForm.invalid || (createRuleForm.get('ruleName').value && createRuleForm.get('ruleName').value.trim().length === 0) :''" [showCancelBtn]="true" [showContinueBtn]="true" (goToNext)="goToNextStep()" (closeBtn)="cancelCreateRule()"></ems-step-wizard-buttons>
