import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { defineCustomElements } from '@schwab/web-components/dist/loader';
defineCustomElements(window);

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';


enableProdMode();

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
