import {
  Component,
  ElementRef,
  EventEmitter, HostListener,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {MessageService} from 'primeng/api'
import {Paginator} from 'primeng/paginator';
import {Observable, Observer, Subscription} from 'rxjs';
import {SharedConstant} from '../../constants/sharedConstants';
import {EmployeeDetails} from '../../models/EmployeeDetails';
import {empOrParticipantTableHeader, ManageEmpOrParticipantHeader} from '../../models/manage-employees-table-header';
import {SharedService} from '../../shared.service';
import * as temp from 'moment';
const moment = temp['default'];
import {ManageEmployeeDataService} from '../../services/manage-employee/manage-employee-data.service';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {AccountNumberValidationService} from '../../services/accountNumberValidation/account-number-validation.service';
import {ManageEmployeeOrParticpantListActionMenuItems} from '../../models/manage-employee-action-menu';
import { CustomSortService } from '../../services/custom-sort/custom-sort.service';

@Component({
  selector: 'ems-manage-employees',
  templateUrl: './shared-manage-employees.component.html',
  styleUrls: ['./shared-manage-employees.component.scss']
})

export class SharedManageEmployeesComponent implements OnInit, OnChanges, OnDestroy {
  constructor(public sharedService: SharedService, private el: ElementRef, public manageEmployeeDataService: ManageEmployeeDataService, @Inject('entitlementConst') public entitlementConst, public fb: UntypedFormBuilder,
              public accountNumberValidationService: AccountNumberValidationService,
              public customSortService: CustomSortService, public messageService: MessageService) {
    this.sharedService.setHistoryResponse.subscribe(value => {
      this.empHistory.empHistoryData = value.data.content;
      this.empHistory.historyRecords = value.data.totalElements;
      this.empHistory.historyMessage = value.message;
    });
  }

  empHistory = {
    employeeId: '',
    historyId: '',
    empSubType: '',
    empHistoryData: '',
    historyRecords: '',
    historyMessage: '',
    empName: ''
  };
  landingPageMsg: any;
  currentOrgSubscription: Subscription;
  getOrgId: any;
  @Input() clientName: string;
  @Input() businessUnit: string;
  @Input() showColumns: any = true;
  @ViewChild('p', { static: true }) paginator: Paginator;
  @Output() editEvent = new EventEmitter<EmployeeDetails>();
  @ViewChild('viewHistory', { static: true }) viewHistoryData: any;
  @ViewChild('toastMessage') msgToast: any;
  @ViewChild('confirmationMessage', { static: true }) confirmationMessage: any;
  @Output() emitData = new EventEmitter();

  employees: any[];
  totalRecords: any;
  noOfRecords: any = 15;
  selectedArr: any = [];
  pageVal: any = 1;
  sortData: any;
  empIds: any[] = [];
  getSharedConstant = SharedConstant;
  public empAccntTblHeader: ManageEmpOrParticipantHeader = empOrParticipantTableHeader;
  currentParentNode: any;
  empAccTableCols: any[];
  subAccntNumbers: any[] = [];
  cancelButton = true;
  chkCompanyGrp: any;
  child = 0;
  parent = 0;
  moment = moment;
  bulkUploadClicked = false;
  sortField: any;
  pageNo: any = 1;
  param = {
    orgId: '',
    pageNumber: 1,
    pageSize: 1,
    sortField: ''
  };

  toggle = false;
  errMsg = [];
  filterClicked = false;
  filterBtnDisableForFN = false;
  filterBtnDisableForLN = false;
  filterBtnDisableForEmpId = false;
  filterBtnDisableForacctHolderFN = false;
  filterBtnDisableForacctHolderLN = false;
  invalidEmpId = false;
  invalidFN = false;
  invalidLN = false;
  invalidacctHolderFN = false;
  invalidacctHolderLN = false;
  manageEmpForm: UntypedFormGroup;
  isAccNumInvalid = false;
  schErrorMsg: string;
  showSchError = false;
  subAcctNum: any;
  empId: any;
  showActionMenu: boolean;
  actionMenu: any[];
  empHistoryData: any;
  deleteMsg: any;
  showToast = false;
  externalAccess = false;
  hasEMTRAccess = false;
  showActionItems = false;
  multiSortMeta: any = [];
  hasGrpOrPlanVal = '';
  downloadTemplate = '';
  userGuideName = this.getSharedConstant.userGuide.manageEmpGU.manageEmpUserGuideTxt;
  ugGutTrackValue = 'adobe-dl_manageemployeesuserguide|fireonce';
  filterGutTrackValue = 'adobe-dl_manageemployeesuserguide|fireonce';
  isSortingChanged = false;
  defaultSortDataField = {field: 'updTs', order: -1};
  initialLoad = false;

  ngOnChanges(changes: SimpleChanges): void {
    this.selectedArr = [];
  }

  ngOnDestroy(): void {
    if (this.currentOrgSubscription) {
      this.currentOrgSubscription.unsubscribe();
    }
    /* Clear selectedGroupAndPlanNameInEmpGroup value when moving out of manage employees/participants page */
    this.sharedService.selectedGroupAndPlanNameInEmpGroup = '';
    this.sharedService.selectedGroupAndPlanIdInEmpGroup = '';
  }

  paramObj(pageNo, records, sortCol) {
    return this.param = {
      orgId: this.getOrgId,
      pageNumber: pageNo,
      pageSize: records,
      sortField: sortCol
    };
  }

  ngOnInit() {
    this.manageEmpForm = this.fb.group({
      empId: [''],
      lastName: [''],
      firstName: [''],
      acctHolderLn: [''],
      acctHolderFn: [''],
      subAcctNum: [''],
      status: [''],
      ruleIds: [''],
      updTs: ['']
    });
    this.empIds = [];
    this.subAccntNumbers = [];
    const pageNo = 1;
    const sortField = '';
    const noOfRecords = this.noOfRecords;
    this.multiSortMeta.push(this.defaultSortDataField);
    this.customSortService.sortArr = [];

    this.currentOrgSubscription = this.sharedService.clientInformation.subscribe(clientInfo => {
      if (clientInfo !== null) {
        this.showActionItems = this.sharedService.checkEntitlements(this.entitlementConst.navUUID.addLinkAppAccess.manageEmployee.uuid, '', '');
        this.hasEMTRAccess = this.sharedService.checkEntitlements(this.entitlementConst.navUUID.emtrAccess.ruleSummary.uuid, '', '');
        this.getOrgId = clientInfo.orgId;
        this.empAccTableCols = this.empAccntTblHeader[clientInfo.businessUnit];
        clientInfo.externalAccess && clientInfo.externalAccess === 'Y' ? this.externalAccess = true : this.externalAccess = false;
        this.toggle = false;
        this.multiSortMeta = [this.defaultSortDataField];
        this.messageService.clear();
        this.resetForm();
        /* Clear selectedGroupAndPlanNameInEmpGroup value when ever the client is changed */
        if (this.sharedService.isClientChanged) {
          this.sharedService.selectedGroupAndPlanNameInEmpGroup = '';
          this.sharedService.selectedGroupAndPlanIdInEmpGroup = '';
        }
        this.sharedService.isClientChanged = false;
        if (this.sharedService.selectedGroupAndPlanNameInEmpGroup) {
          this.toggleFunction();
        }
        // if (this.sortField !== undefined) {
        //   console.log("In ngOnInit");
        //   this.getEmpAccntResponse(this.paramObj(pageNo, noOfRecords, this.sortField), true);
        // }
        /* for user guide download */
        if (clientInfo.businessUnit) {
          this.downloadTemplate = this.getSharedConstant.userGuide.manageEmpGU[clientInfo.businessUnit].value;
          this.filterGutTrackValue = this.getSharedConstant[clientInfo.businessUnit].empParticipantFilterGutTrackValue;
        }
      }
    });
    this.onChange();
    this.sharedService.schErrorMsg.subscribe(value => {
      if (value.length > 0) {
        this.showSchError = true;
        this.schErrorMsg = value;
      } else {
        this.showSchError = false;
      }
    });
    this.getSuccessMessage();
  }

  getEmpAccntResponse(param, initialLoad) {
    const localUpdatedFormValues = this.manageEmpForm.getRawValue();
    const filterObj: any = {
      empId: localUpdatedFormValues.empId ? localUpdatedFormValues.empId.trim().toUpperCase() : null,
      accountNum: localUpdatedFormValues.subAcctNum ? localUpdatedFormValues.subAcctNum : null,
      firstName: localUpdatedFormValues.firstName ? localUpdatedFormValues.firstName.trim().toUpperCase() : null,
      lastName: localUpdatedFormValues.lastName ? localUpdatedFormValues.lastName.trim().toUpperCase() : null,
      accountHolderFN: localUpdatedFormValues.acctHolderFn ? localUpdatedFormValues.acctHolderFn.trim().toUpperCase() : null,
      accountHolderLN: localUpdatedFormValues.acctHolderLn ? localUpdatedFormValues.acctHolderLn.trim().toUpperCase() : null,
      groupId: this.hasGrpOrPlanVal ? this.hasGrpOrPlanVal : null
    };


    if (this.hasEMTRAccess && this.getOrgId !== undefined && this.getOrgId !== 0) {
      if (this.filterClicked) {
        this.sharedService.getEmployeeList(param, filterObj, null, null).subscribe((response) => {
          this.setEmployeeAccntListData(response);
        });
      } else {
        if (!this.sharedService.selectedGroupAndPlanNameInEmpGroup) {
          this.sharedService.getEmployeeList(param, '', null, null).subscribe((response) => {
            this.setEmployeeAccntListData(response);
          });
        }
      }
    }
  }

  setEmployeeAccntListData(response) {
    this.sharedService.clearErrorMessage();
    this.employees = response.data ? response.data.content : response.data;
    this.totalRecords = response.data ? response.data.totalElements : 0;
  }

  receiveDataFromChild(e) {
    this.pageNo = 1;
    this.sortField = this.param.sortField;
    this.noOfRecords = Number(e);
    this.paginator.changePage(0);
    this.messageService.clear();
    this.getEmpAccntResponse(this.paramObj(this.pageNo, this.noOfRecords, this.sortField), false);
  }

  customSort(event) {
    if (event.multiSortMeta) {
      this.paginator.changePage(0);
      if (this.sortField !== this.customSortService.customSort(event)) {
        this.sortField = this.customSortService.customSort(event);
        this.isSortingChanged = (JSON.stringify(event.multiSortMeta) === JSON.stringify([this.defaultSortDataField])) ? false : true;
        const noOfRecords = this.noOfRecords;
        if (this.sharedService.empListMultiSortMeta !== event.multiSortMeta && !this.initialLoad) {
          this.sharedService.empListMultiSortMeta = event.multiSortMeta;
          this.getEmpAccntResponse(this.paramObj(this.pageNo, noOfRecords, this.sortField), false);
          this.initialLoad = true;
        } else if (this.initialLoad && this.sharedService.empListMultiSortMeta !== event.multiSortMeta) {
          this.messageService.clear();
          this.sharedService.empListMultiSortMeta = event.multiSortMeta;
          this.getEmpAccntResponse(this.paramObj(this.pageNo, noOfRecords, this.sortField), false);
        }
      }
    }
  }

  paginate(e) {
    if (this.pageNo !== e.page + 1) {
      this.messageService.clear();
      this.pageNo = e.page + 1;
      this.sortField = this.param.sortField;
      const noOfRecords = this.noOfRecords;
      this.getEmpAccntResponse(this.paramObj(this.pageNo, noOfRecords, this.sortField), false);
    }
  }

  addOrEditEmp(empData: EmployeeDetails, empId: any, param) {
    /* created deep copy of rowdata for bug #15232*/
    const empDataClone = JSON.parse(JSON.stringify(empData));
    if (empId) {
      empDataClone.empId = empId;
    } else {
      empDataClone.empId = '';
    }
    this.sharedService.addOrEditEmployee = param;
    this.editEvent.emit(empDataClone);
  }

  updateEmployeeList(param) {
    param.sortField = this.param.sortField;
    this.paginator.changePage(0);
    this.getEmpAccntResponse(this.paramObj(param.pageNumber, param.pageSize, param.sortField), false);
  }

  uploadEmployeeList() {
    /* Set isDTBtnClicked value to true, generate the data track value with 'linkid-lc' . */
    this.sharedService.isDTBtnClicked =  true;
    this.sharedService.loadDataTrackValue('manageemployees_bulkupload');
    this.cancelButton = false;
    this.sharedService.parentPage = '/ems/emtr/manage-employees';
    this.bulkUploadClicked = true;
  }

  loadEmpHistory(ruleData) {
    this.empHistory.historyId = ruleData.subAcctNum;
    this.empHistory.employeeId = ruleData.empId;
    this.empHistory.empSubType = ruleData.type;
    this.empHistoryData = ruleData;
    this.empHistory.empName = this.getSharedConstant[this.businessUnit].employeeOrParticipantHistory + ' - ' + ruleData.acctHolderLN + ' ' + ruleData.acctHolderFN;
    this.sharedService.loadViewHistory(this.getOrgId, ruleData, 'empaccount', this.viewHistoryData);
  }

  getHistoryPagination(subAcctNum, data) {
    this.sharedService.getHistoryPaginatedValues(this.empHistoryData, 'empaccount', data);
  }

  onChange() {
    this.manageEmpForm.get('subAcctNum').valueChanges.subscribe(value => {
      /* check Account Number contains 8 digits */
      this.isAccNumInvalid = this.accountNumberValidationService.validateAccountNumber(value);
      this.sharedService.setErrorMessage(this.isAccNumInvalid, this.errMsg);
    });

    /* Validate the field and set the error message */
    this.manageEmpForm.get('empId').valueChanges.subscribe(value => {
      this.manageEmpForm.get('empId').setValue(value ? value.toUpperCase() : '', { emitEvent: false });
      this.filterBtnDisableForEmpId = this.sharedService.validationFields.filterBtnDisableForEmpId = this.sharedService.isValueLengthGreaterThan2(value);
      this.invalidEmpId = this.sharedService.validationFields.invalidEmpId = this.sharedService.isValidOrInvalid(value, 'empId', this.manageEmpForm);
      this.sharedService.setErrorMessage(this.isAccNumInvalid, this.errMsg);
    });

    this.manageEmpForm.get('firstName').valueChanges.subscribe(value => {
      this.manageEmpForm.get('firstName').setValue(value ? value.toUpperCase() : '', { emitEvent: false });
      this.filterBtnDisableForFN = this.sharedService.validationFields.filterBtnDisableForFN = this.sharedService.isValueLengthGreaterThan2(value);
      this.invalidFN = this.sharedService.validationFields.invalidFN = this.sharedService.isValidOrInvalid(value, 'firstName', this.manageEmpForm);
      this.sharedService.setErrorMessage(this.isAccNumInvalid, this.errMsg);
    });

    this.manageEmpForm.get('lastName').valueChanges.subscribe(value => {
      this.manageEmpForm.get('lastName').setValue(value ? value.toUpperCase() : '', { emitEvent: false });
      this.filterBtnDisableForLN = this.sharedService.validationFields.filterBtnDisableForLN = this.sharedService.isValueLengthGreaterThan2(value);
      this.invalidLN = this.sharedService.validationFields.invalidLN = this.sharedService.isValidOrInvalid(value, 'lastName', this.manageEmpForm);
      this.sharedService.setErrorMessage(this.isAccNumInvalid, this.errMsg);
    });

    this.manageEmpForm.get('acctHolderFn').valueChanges.subscribe(value => {
      this.manageEmpForm.get('acctHolderFn').setValue(value ? value.toUpperCase() : '', { emitEvent: false });
      this.filterBtnDisableForacctHolderFN = this.sharedService.validationFields.filterBtnDisableForacctHolderFN = this.sharedService.isValueLengthGreaterThan2(value);
      this.invalidacctHolderFN = this.sharedService.validationFields.invalidacctHolderFN = this.sharedService.isValidOrInvalid(value, 'acctHolderFn', this.manageEmpForm);
      this.sharedService.setErrorMessage(this.isAccNumInvalid, this.errMsg);
    });

    this.manageEmpForm.get('acctHolderLn').valueChanges.subscribe(value => {
      this.manageEmpForm.get('acctHolderLn').setValue(value ? value.toUpperCase() : '', { emitEvent: false });
      this.filterBtnDisableForacctHolderLN = this.sharedService.validationFields.filterBtnDisableForacctHolderLN = this.sharedService.isValueLengthGreaterThan2(value);
      this.invalidacctHolderLN = this.sharedService.validationFields.invalidacctHolderLN = this.sharedService.isValidOrInvalid(value, 'acctHolderLn', this.manageEmpForm);
      this.sharedService.setErrorMessage(this.isAccNumInvalid, this.errMsg);
    });
  }

  toggleFunction() {
    this.toggle = !this.toggle;
    this.manageEmployeeDataService.filterToggleClicked = this.toggle;
  }

  clickedClearButton(resetSortFromUI?) {
    this.resetForm(resetSortFromUI);
    this.pageNo = 1;
    this.sortField = this.param.sortField;
    const noOfRecords = this.noOfRecords;
    this.getEmpAccntResponse(this.paramObj(this.pageNo, noOfRecords, this.sortField), false);
  }

  resetForm(resetSortFromUI?) {
    if (resetSortFromUI) {
      this.multiSortMeta = [this.defaultSortDataField];
    }
    this.manageEmployeeDataService.clearClicked.emit(true);
    this.manageEmpForm.reset();
    this.paginator.changePage(0);
    this.filterClicked = false;
    this.isAccNumInvalid = false;
    this.sharedService.schErrorMsg.emit('');
  }

  applyFilter() {
    this.paginator.changePage(0);
    this.filterClicked = true;
    this.pageNo = 1;
    this.sortField = this.param.sortField;
    const noOfRecords = this.noOfRecords;
    this.messageService.clear();
    this.getEmpAccntResponse(this.paramObj(this.pageNo, noOfRecords, this.sortField), false);
  }

  deleteEmployee(ruleData) {
    const confirmObj = {
      header: `${this.getSharedConstant[this.businessUnit].removeEmployeeOrParticipant} - ${this.getSharedConstant[this.businessUnit].empOrParticipantsID}: ${ruleData.empId}`,
      /* eslint-disable-next-line max-len */
      message: `${this.getSharedConstant[this.businessUnit].removeEmployeeOrParticipantAlert}<br><strong>Account Number: </strong>${ruleData.subAcctNum}<br><strong>First Name: </strong>${ruleData.firstName}<br><strong>Last Name: </strong>${ruleData.lastName}<br>`,
      acceptBtn: this.getSharedConstant.deleteModal.yesTxt,
      rejectBtn: this.getSharedConstant.deleteModal.noTxt
    };
    const deleteFormData = {
      orgId: this.getOrgId,
      empId: ruleData.empId,
      subAccountNumber: ruleData.subAcctNum,
      forceDelete: true
    };
    Observable.create((observer: Observer<boolean>) => {
      this.confirmationMessage.showConfirmMsg(confirmObj, observer);
    }).subscribe(accept => {
      if (accept) {
        this.showToast = false;
        this.sharedService.errorMessage.next(false);
        this.sharedService.deleteEmpAccount(deleteFormData).subscribe(data => {
            this.showToast = true;
            if (this.businessUnit !== 'RBS') {
              /* eslint-disable-next-line max-len */
              this.deleteMsg = '<strong>' + 'Employee ID: ' + ruleData.empId + '</strong> <strong>' + 'Account Number: ' + ruleData.subAcctNum + '</strong> - <strong>' + this.getSharedConstant.deleteModal.employeeTxt + '&nbsp;' + ruleData.lastName + ',' + ruleData.firstName + '</strong>&nbsp;' + this.getSharedConstant.deleteModal.deleteSuccessMsg;
            } else {
              /* eslint-disable-next-line max-len */
              this.deleteMsg = '<strong>' + 'Participant ID: ' + ruleData.empId + '</strong> <strong>' + 'Account Number: ' + ruleData.subAcctNum + '</strong> - <strong>' + this.getSharedConstant.deleteModal.participantTxt + '&nbsp;' + ruleData.lastName + ',' + ruleData.firstName + '</strong>&nbsp;' + this.getSharedConstant.deleteModal.deleteSuccessMsg;
            }
            this.msgToast.pushMessage('success', this.deleteMsg);
            this.emitData.emit();
          },
          (err) => {
            if (err.status === 400) {
              this.sharedService.clearErrorMessage();
              const failurMessage = err.error.message;
              this.msgToast.pushMessage('error', failurMessage);
            }
          });
      }
    });
  }

  formData(rowData) {
    this.actionMenu = ManageEmployeeOrParticpantListActionMenuItems[this.businessUnit].filter(item => item.label);
    if (!rowData.empId) {
      this.removeMenuItem(this.getSharedConstant[this.businessUnit].addEmployeeOrParticipant);
      this.removeMenuItem(this.getSharedConstant[this.businessUnit].removeEmployeeOrParticipant);
    }

    if (!this.showActionItems) {
      this.removeMenuItem(this.getSharedConstant[this.businessUnit].addEmployeeOrParticipant);
      this.removeMenuItem('Edit');
      this.removeMenuItem(this.getSharedConstant[this.businessUnit].removeEmployeeOrParticipant);
    }
    return this.actionMenu;
  }

  removeMenuItem(removeItem) {
    const index = this.actionMenu.findIndex(x => x.label === removeItem);
    if (index !== -1) {
      this.actionMenu.splice(index, 1);
    }
  }

  @HostListener('document:click', [])
  onClick() { /* Capture the Click Event outside the function */
    if (!this.showActionMenu) {
      this.empId = '';
      this.subAcctNum = '';
    }
    this.showActionMenu = false;
  }

  onActionMenuClick(event: any, ruleData: any) {
    const actionItem = event.target.parentElement.innerText;
    this.messageService.clear();
    this.sharedService.clearErrorMessage();
    if (actionItem === 'Edit') {
      this.addOrEditEmp(ruleData, ruleData.empId, 'editEmployee');
    } else if (actionItem === 'History') {
      this.loadEmpHistory(ruleData);
    } else if (actionItem === this.getSharedConstant[this.businessUnit].addEmployeeOrParticipant) {
      this.addOrEditEmp(ruleData, ruleData.empId, 'addEployee');
    } else if (actionItem === this.getSharedConstant[this.businessUnit].removeEmployeeOrParticipant) {
      this.deleteEmployee(ruleData);
    }
  }

  getSuccessMessage() {
    this.manageEmployeeDataService.getMessage().subscribe((data) => {
      this.landingPageMsg = data.text ? data.text : '';
      if (this.landingPageMsg && this.landingPageMsg !== null && this.landingPageMsg !== '') {
        setTimeout(() => {
          if (this.landingPageMsg && this.landingPageMsg !== undefined) {
            this.msgToast.pushMessage('success', this.landingPageMsg);
          }
        }, 1);
      }
    });
  }
}
