import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { EmtrComponent } from './emtr.component';
import { RulesListComponent } from './rules/rules-list/rules-list.component';
import { EditRuleComponent } from './rules/two-step-edit-rule/edit-rule.component';
import { EditRuleStep1Component } from './rules/two-step-edit-rule/edit-rule-step1/edit-rule-step1.component';
import { EditRuleStep2Component } from './rules/two-step-edit-rule/edit-rule-step2/edit-rule-step2.component';
import { RulesComponent } from './rules/rules.component';
import {RulesBulkUploadComponent} from './rules-bulk-upload/rules-bulk-upload.component';
import {CreateRuleComponent} from './rules/create-rule/create-rule.component';
import {RulesStep1Component} from './rules/create-rule/rules-step1-rule-type/rules-step1.component';
import {RulesStep4Component} from './rules/create-rule/rules-step4-review-rule/rules-step4.component';
import {RulesStep2Component} from './rules/create-rule/rules-step2-select-attributes/rules-step2.component';
import {RulesStep3Component} from './rules/create-rule/rules-step3-assign-employees/rules-step3.component';
import {CanDeactivateGuard, WorkflowGuard, RolePermissionGuard} from '@ems/shared';
import {ManageEmployeesComponent} from './manage-employees/manage-employees.component';
import {EmployeeListComponent} from './manage-employees/employee-list/employee-list.component';
import {EmpGroupBulkUploadComponent} from './emp-group-bulk-upload/emp-group-bulk-upload.component';
import {ManageEmployeeGroupsComponent} from './manage-employee-groups/manage-employee-groups.component';
import {EmployeeGroupListComponent} from './manage-employee-groups/employee-group-list/employee-group-list.component';
import {CreateEmployeeGroupComponent} from './manage-employee-groups/create-employee-group/create-employee-group.component';
import {Step1EnterGroupDetailsComponent} from './manage-employee-groups/create-employee-group/step1-enter-group-details/step1-enter-group-details.component';
import {Step2AddEmployeesComponent} from './manage-employee-groups/create-employee-group/step2-add-employees/step2-add-employees.component';
import {Step3ReviewAndSaveComponent} from './manage-employee-groups/create-employee-group/step3-review-and-save/step3-review-and-save.component';
import {InstrumentGroupsComponent} from './instrument-groups/instrument-groups.component';
import {InstrumentGroupListComponent} from './instrument-groups/instrument-group-list/instrument-group-list.component';
import {CreateInstrumentGroupComponent} from './instrument-groups/create-instrument-group/create-instrument-group.component';
import {Step1EnterInstrumentGroupDetailsComponent} from './instrument-groups/create-instrument-group/step1-enter-instrument-group-details/step1-enter-instrument-group-details.component';
import {Step2AddInstrumentsComponent} from './instrument-groups/create-instrument-group/step2-add-instruments/step2-add-instruments.component';
import {Step3ReviewAndSaveInstrumentGroupComponent} from './instrument-groups/create-instrument-group/step3-review-and-save-instrument-group/step3-review-and-save-instrument-group.component';
import {MasterGroupsListComponent} from './manage-master-groups/master-groups-list/master-groups-list.component';
import {ManageMasterGroupsComponent} from './manage-master-groups/manage-master-groups.component';

const emtrRoutes: Routes = [
  {
    path: '', component: EmtrComponent,

    children: [
      { path: '', redirectTo: 'rules', pathMatch: 'full' },
      {
        path: 'manage-employees', component: ManageEmployeesComponent,
        children: [
          {path: 'employee-list', component: EmployeeListComponent, canActivate: [RolePermissionGuard], data: {entitlementName: 'EMPLOYEE_SUMMARY'}},
          {path: 'emp-bulk-upload', component: EmpGroupBulkUploadComponent, canDeactivate: [CanDeactivateGuard], canActivate: [RolePermissionGuard], data: {entitlementName: 'MANAGE_EMPLOYEES'}},
          {
            path: '',
            redirectTo: '/ems/emtr/manage-employees/employee-list',
            pathMatch: 'full'
          }
        ]
      },
      {
        path: 'manage-participants', component: ManageEmployeesComponent,
        children: [
          {path: 'participant-list', component: EmployeeListComponent, canActivate: [RolePermissionGuard], data: {entitlementName: 'EMPLOYEE_SUMMARY'}},
          {path: 'participant-bulk-upload', component: EmpGroupBulkUploadComponent, canDeactivate: [CanDeactivateGuard], canActivate: [RolePermissionGuard], data: {entitlementName: 'MANAGE_EMPLOYEES'}},
          {
            path: '',
            redirectTo: '/ems/emtr/manage-participants/participant-list',
            pathMatch: 'full'
          }
        ]
      },
      {
        path: 'manage-employee-groups', component: ManageEmployeeGroupsComponent,
        children: [
          {path: 'employee-group-list', component: EmployeeGroupListComponent, canActivate: [RolePermissionGuard], data: {entitlementName: 'EMPLOYEE_SUMMARY'}},
          {path: 'emp-group-bulk-upload', component: EmpGroupBulkUploadComponent, canDeactivate: [CanDeactivateGuard], canActivate: [RolePermissionGuard], data: {entitlementName: 'MANAGE_EMP_GROUP'}},
          {
            path: 'create-employee-group', component: CreateEmployeeGroupComponent,
            children: [
              {
                path: 'step1',
                component: Step1EnterGroupDetailsComponent,
                data: {workflow: 'manage-employee-groups', entitlementName: 'EMPLOYEE_SUMMARY'},
                canActivate: [WorkflowGuard, RolePermissionGuard],
                canDeactivate: [CanDeactivateGuard]
              },
              {
                path: 'step2',
                component: Step2AddEmployeesComponent,
                data: {workflow: 'manage-employee-groups', entitlementName: 'EMPLOYEE_SUMMARY'},
                canActivate: [WorkflowGuard, RolePermissionGuard],
                canDeactivate: [CanDeactivateGuard]
              },
              {
                path: 'step3',
                component: Step3ReviewAndSaveComponent,
                data: {workflow: 'manage-employee-groups', entitlementName: 'EMPLOYEE_SUMMARY'},
                canActivate: [WorkflowGuard, RolePermissionGuard],
                canDeactivate: [CanDeactivateGuard]
              },
              {
                path: '',
                redirectTo: '/ems/emtr/manage-employee-groups/create-employee-group/step1',
                pathMatch: 'full'
              }
            ]
          },
          {
            path: '',
            redirectTo: '/ems/emtr/manage-employee-groups/employee-group-list',
            pathMatch: 'full'
          }
        ]
      },
      {
        path: 'manage-plans', component: ManageEmployeeGroupsComponent,
        children: [
          {path: 'plans-list', component: EmployeeGroupListComponent, canActivate: [RolePermissionGuard], data: {entitlementName: 'EMPLOYEE_SUMMARY'}},
          {path: 'emp-plan-bulk-upload', component: EmpGroupBulkUploadComponent, canDeactivate: [CanDeactivateGuard], canActivate: [RolePermissionGuard], data: {entitlementName: 'MANAGE_EMP_GROUP'}},
          {
            path: 'create-plan', component: CreateEmployeeGroupComponent,
            children: [
              {
                path: 'step1',
                component: Step1EnterGroupDetailsComponent,
                data: {workflow: 'manage-employee-groups', entitlementName: 'EMPLOYEE_SUMMARY'},
                canActivate: [WorkflowGuard, RolePermissionGuard],
                canDeactivate: [CanDeactivateGuard]
              },
              {
                path: 'step2',
                component: Step2AddEmployeesComponent,
                data: {workflow: 'manage-employee-groups', entitlementName: 'EMPLOYEE_SUMMARY'},
                canActivate: [WorkflowGuard, RolePermissionGuard],
                canDeactivate: [CanDeactivateGuard]
              },
              {
                path: 'step3',
                component: Step3ReviewAndSaveComponent,
                data: {workflow: 'manage-employee-groups', entitlementName: 'EMPLOYEE_SUMMARY'},
                canActivate: [WorkflowGuard, RolePermissionGuard],
                canDeactivate: [CanDeactivateGuard]
              },
              {
                path: '',
                redirectTo: '/ems/emtr/manage-plans/create-plan/step1',
                pathMatch: 'full'
              }
            ]
          },
          {
            path: '',
            redirectTo: '/ems/emtr/manage-plans/plans-list',
            pathMatch: 'full'
          }
        ]
      },
      {
        path: 'rules', component: RulesComponent,
        children: [
          { path: 'rules-list', component: RulesListComponent, canActivate: [RolePermissionGuard], data: {entitlementName: 'EMTR_APP'}},
          {path: 'rules-bulk-upload', component: RulesBulkUploadComponent, canDeactivate: [CanDeactivateGuard], canActivate: [RolePermissionGuard], data: {entitlementName: 'EMTR_APP'}},
          {
            path: 'edit-rule', component: EditRuleComponent,
            children: [
              { path: 'step1', component: EditRuleStep1Component, canActivate: [WorkflowGuard, RolePermissionGuard], data: {workflow: 'editRule', entitlementName: 'EMTR_APP'}, canDeactivate: [CanDeactivateGuard]},
              { path: 'step2', component: EditRuleStep2Component, canActivate: [WorkflowGuard, RolePermissionGuard], data: {workflow: 'editRule', entitlementName: 'EMTR_APP'}, canDeactivate: [CanDeactivateGuard]},
              { path: '', redirectTo: '/ems/emtr/rules/edit-rule/step1', pathMatch: 'full' },
            ]
          },
          {
            path: 'create-rule', component: CreateRuleComponent,
            children: [
              { path: 'step1', component: RulesStep1Component, data: {workflow: 'rule', entitlementName: 'EMTR_APP'}, canActivate: [WorkflowGuard, RolePermissionGuard], canDeactivate: [CanDeactivateGuard]},
              { path: 'step2', component: RulesStep2Component, data: {workflow: 'rule', entitlementName: 'EMTR_APP'}, canActivate: [WorkflowGuard, RolePermissionGuard], canDeactivate: [CanDeactivateGuard]},
              { path: 'step3', component: RulesStep3Component, data: {workflow: 'rule', entitlementName: 'EMTR_APP'}, canActivate: [WorkflowGuard, RolePermissionGuard], canDeactivate: [CanDeactivateGuard]},
              { path: 'step4', component: RulesStep4Component, data: {workflow: 'rule', entitlementName: 'EMTR_APP'}, canActivate: [WorkflowGuard, RolePermissionGuard], canDeactivate: [CanDeactivateGuard]},
              { path: '', redirectTo: '/ems/emtr/rules/create-rule/step1', pathMatch: 'full' },
            ]
          },
          { path: '', redirectTo: '/ems/emtr/rules/rules-list', pathMatch: 'full' },
        ]
      },
      {
        path: 'master-groups', component: ManageMasterGroupsComponent,
        children: [
          {
            path: 'master-groups-list',
            component: MasterGroupsListComponent,
            canActivate: [RolePermissionGuard],
            data: {entitlementName: 'MASTERGROUP_SUMMARY'}
          },
          {
            path: 'create-master-group', component: CreateEmployeeGroupComponent,
            children: [
              {
                path: 'step1',
                component: Step1EnterGroupDetailsComponent,
                data: {workflow: 'master-groups', entitlementName: 'MASTERGROUP_SUMMARY'},
                canActivate: [WorkflowGuard, RolePermissionGuard],
                canDeactivate: [CanDeactivateGuard]
              },
              {
                path: 'step2',
                component: Step2AddEmployeesComponent,
                data: {workflow: 'master-groups', entitlementName: 'MASTERGROUP_SUMMARY'},
                canActivate: [WorkflowGuard, RolePermissionGuard],
                canDeactivate: [CanDeactivateGuard]
              },
              {
                path: 'step3',
                component: Step3ReviewAndSaveComponent,
                data: {workflow: 'master-groups', entitlementName: 'MASTERGROUP_SUMMARY'},
                canActivate: [WorkflowGuard, RolePermissionGuard],
                canDeactivate: [CanDeactivateGuard]
              },
              {
                path: '',
                redirectTo: '/ems/emtr/master-groups/create-master-group/step1',
                pathMatch: 'full'
              }
            ]
          },
          {
            path: '',
            redirectTo: '/ems/emtr/master-groups/master-groups-list',
            pathMatch: 'full'
          }
        ]
      },
      {
        path: 'instrument-groups', component: InstrumentGroupsComponent,
        children: [
          {
            path: 'instrument-group-list',
            component: InstrumentGroupListComponent,
            canActivate: [RolePermissionGuard],
            data: {entitlementName: 'INSTRUMENTS_SUMMARY'}
          },
          {
            path: 'create-instrument-group', component: CreateInstrumentGroupComponent,
            children: [
              {
                path: 'step1',
                component: Step1EnterInstrumentGroupDetailsComponent,
                data: {workflow: 'instrument-groups', entitlementName: 'INSTRUMENTS_SUMMARY'},
                canActivate: [WorkflowGuard, RolePermissionGuard],
                canDeactivate: [CanDeactivateGuard]
              },
              {
                path: 'step2',
                component: Step2AddInstrumentsComponent,
                data: {workflow: 'instrument-groups', entitlementName: 'INSTRUMENTS_SUMMARY'},
                canActivate: [WorkflowGuard, RolePermissionGuard],
                canDeactivate: [CanDeactivateGuard]
              },
              {
                path: 'step3',
                component: Step3ReviewAndSaveInstrumentGroupComponent,
                data: {workflow: 'instrument-groups', entitlementName: 'INSTRUMENTS_SUMMARY'},
                canActivate: [WorkflowGuard, RolePermissionGuard],
                canDeactivate: [CanDeactivateGuard]
              },
              {
                path: '',
                redirectTo: '/ems/emtr/instrument-groups/create-instrument-group/step1',
                pathMatch: 'full'
              }
            ]
          },
          {
            path: '',
            redirectTo: '/ems/emtr/instrument-groups/instrument-group-list',
            pathMatch: 'full'
          }
        ]
      }
    ]
  },

];

@NgModule({
  imports: [RouterModule.forChild(emtrRoutes)],
  exports: [RouterModule]
})
export class EmtrRoutingModule {
}
