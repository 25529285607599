export interface TableHeader {
    header?: string;
    field?: string;
    enum?: string;
}
export const InstrumentGroupTableHeader: TableHeader[] = [
    { field: 'name', header: 'Name', enum: 'textbox' },
    { field: 'description', header: 'Description', enum: 'textbox' },
    { field: 'businessUnit', header: 'Business Unit', enum: 'dropdown' },
    { field: 'updTs', header: 'Last Updated', enum: 'dateRange' },
    { field: 'action', header: 'Action', enum: 'heading' }
];

export const RulesSelectInstrumentGroupTableHeader: TableHeader[] = [
  {field: 'name', header: 'Instrument Group Name'},
  {field: 'description', header: 'Group Description'},
  {field: 'tickerSymbols', header: 'Ticker Symbol(s)'}
];

export const InstrumentGroupTableSubHeader: TableHeader[] = [
  { field: 'showRecords', header: 'Show Records', enum: 'dropdown' }
];
