export interface TableHeader {
    header?: string;
    level?: string;
}
export const SecurityTableHeader: TableHeader[] = [
    { header: 'Level 1', level: 'SC1' },
    { header: 'Level 2', level: 'SC2' },
    { header: 'Level 3', level: 'SC3' }];

export const AssetGroupsTableHeader: TableHeader[] = [
  { header: 'Level 1', level: 'SC1' },
  { header: 'Level 2', level: 'SC2' },
  { header: 'Level 2', level: 'SC2' },
  { header: 'Level 2', level: 'SC2' },
  { header: 'Level 2', level: 'SC2' }];
