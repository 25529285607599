<ul class="sdps-pull-right sdps-list--inline rule-info">
    <li *ngIf="showRuleName">
        <span>Rule Name:&nbsp;</span>
        <strong>{{ruleName}}</strong>
    </li>

    <li>
        <span>Rule Type:&nbsp;</span>
        <strong>{{(ruleType === 'RESTRICT_LST_ENG') ? getConstant.rulesConstant.ruleGlobalConst.restriction : (ruleType === 'Override') ? getConstant.rulesConstant.ruleGlobalConst.override : getConstant.rulesConstant.ruleGlobalConst.watchList}}</strong>
    </li>

</ul>
<p class="sdps-clearfix no-margin"></p>
