<header *ngIf="authService.userAuthentication | async">
  <ems-confirm-message *ngIf="sharedService.isConfirmationRequiredClientChange" #confirmationMessageForHeader></ems-confirm-message>
  <div class="sdps-grid-container sdps-wrap">
    <div class="col-content">
      <div class="col-1 t-logo">
        <img src="assets/img/schwab-logo.png" alt="Charles Schwab">
      </div>
    </div>
    <div class="col col-head">
      <div class="sdps-grid-container sdps-wrap no-margin">
        <div class="col-7">
          <app-navigation class="col-12" *ngIf="showMenu.navigation"></app-navigation>
        </div>
        <div class="col-5 sdps-text-right right-menu">
          <ul class="sdps-list--inline no-margin" *ngIf="showMenu.clientDrpdown">
            <li class="sdps-m-right_none">
              <div class="sdps-display-inline-block sdps-p-top_xx-small sdps-p-right_small client-dropdown client-selection-dropdown">
                <p-dropdown #dropdown [emptyFilterMessage]="emptyDisplayMessage" [options]="clientRecords" placeholder="Select a Client" [virtualScroll]="true" optionLabel="k4ClientName" [ngModelOptions]="{standalone: true}" filter="true"
                            (onChange)="onClientChange($event.value);" (input)="searchedText($event)" dataKey="clientId"  [(ngModel)]="selectedClient" (onShow)= "onDropdownItemShow()" [disabled]="disableClientDropdown"  [itemSize]="15" resetFilterOnHide="true" (onHide)="resetFilterValue()">
                  <ng-template pTemplate="selectedItem">
                    <div class="display-image">
                      <div class="image-block" [ngClass]="{'dbs': selectedClient && selectedClient?.businessUnit === 'DBS', 'rbs': selectedClient && selectedClient?.businessUnit === 'RBS', 'sps': selectedClient && selectedClient?.businessUnit === 'SPS'}">
                        <span>{{selectedClient?.businessUnit || ''}}</span>
                      </div>

                      <div class="display-client-text">
                        <span *ngIf="selectedClient && selectedClient?.k4ClientName?.length<=innerWidth" title="{{selectedClient?.k4ClientName}}">
                          {{selectedClient?.k4ClientName}}
                        </span>
                        <!-- Adding ellipsis after 17 characters -->
                        <span class="common-truncate--ellipses" *ngIf="selectedClient && selectedClient?.k4ClientName?.length>innerWidth" title="{{selectedClient?.k4ClientName}}">
                          {{selectedClient?.k4ClientName}}
                        </span>
                      </div>
                    </div>
                  </ng-template>
                  <ng-template let-clientRecords pTemplate="item" *ngIf="!dataLoading">
                    <div *ngIf="clientRecords" class="ui-helper-clearfix display-image global-client-dropdown" (click)="stopDisabledAllClick($event, clientRecords.k4ClientName)" [ngClass]="{'disabled-option': (clientRecords.k4ClientName === allClientInfo.clientName && (sharedService.specificClientView | async))}">
                      <div class="image-block" [ngClass]="{'dbs': clientRecords.businessUnit === 'DBS', 'rbs': clientRecords.businessUnit === 'RBS', 'sps': clientRecords.businessUnit === 'SPS'}">
                        <span>{{clientRecords.businessUnit}}</span>
                      </div>
                      <div class="display-client-text">
                        <span *ngIf="clientRecords.k4ClientName && clientRecords.k4ClientName.length<=innerWidth" title="{{clientRecords.k4ClientName}}">
                          {{clientRecords.k4ClientName}}
                        </span>
                        <!-- Adding ellipsis after 17 characters -->
                        <span class="common-truncate--ellipses" *ngIf="clientRecords.k4ClientName && clientRecords.k4ClientName.length>innerWidth" title="{{clientRecords.k4ClientName}}">
                          {{clientRecords.k4ClientName}}
                        </span>
                      </div>
                    </div>
                  </ng-template>
                </p-dropdown>
              </div>
            </li>
            <li class="sdps-m-right_none">
              <div>
                <span class="user-icon-background"><em class="pi pi-user sdps-p-around_xx-small user-icon"></em></span>
                <span class="text-white sdps-p-around_x-small" [innerHTML]="userName"></span>
              </div>
            </li>

            <li class="sdps-m-right_none" *ngIf="showMenu.logout">
              <span class="user-name-right-border sdps-m-right_none">&nbsp;</span>
              <span class="cursor text-white sdps-p-left_x-small" (click)="logout()">Log Out</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</header>
