import { Component, Inject, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { emtrConstant } from '../../constants/emtrConstants';

@Component({
  selector: 'tr-datepicker',
  templateUrl: './emtr-datepicker.component.html'
})
export class EmtrDatepickerComponent implements OnChanges {
  @Input() dateForm: UntypedFormGroup;
  @Input() dateLabel: any;
  @Input() minDate: Date;
  @Input() showErr: any;
  @Input() readonlyInput: any;
  @Input() disabled: boolean;
  @Input() maxDateValue: Date;
  @Input() showAsterisk: true;
  @Output() calendarValidationEvent = new EventEmitter<any>();
  @Output() calendarValidationEventOnChange = new EventEmitter<any>();
  showError: any;
  getConstant = emtrConstant;
  errorFlag = {
    isInValidDate: false,
    isPastDate: false,
    isFutureDate: false,
    dateLabel: ''
  };


  constructor(@Inject('commonConstants') public commonConstants) {
  }

  ngOnChanges() {
    this.onError();
  }

  onCloseMethod(event, dateEntered) {
    const dateSelected = dateEntered.el.nativeElement.querySelector('input').value;
    this.calendarValidationEventOnChange.emit({value: dateSelected, dateLabel: this.dateLabel});
    if (dateSelected) {
      const selectedDate = new Date(dateSelected);
      this.ValidateDate(selectedDate);
    } else {
      this.clearErrorFlag();
      this.onError();
    }
  }

  changeValue(event) {
    this.calendarValidationEventOnChange.emit({value: event.target.value, dateLabel: this.dateLabel});
  }

  ValidateDate(selectedDate) {
    this.errorFlag.dateLabel = this.dateLabel;
    let minDate = this.minDate;
    if (minDate) {
      minDate = new Date(minDate.toDateString());
    }
    const maxDateValue = new Date(this.maxDateValue);
    if (selectedDate.toString() === this.getConstant.invalidDate) {
      this.errorFlag.isInValidDate = true;
      this.errorFlag.isPastDate = false;
      this.errorFlag.isFutureDate = false;
      this.onError();
      this.calendarValidationEvent.emit(this.errorFlag);
      return;
    } else if (selectedDate < minDate) {
      this.errorFlag.isPastDate = true;
      this.errorFlag.isInValidDate = false;
      this.errorFlag.isFutureDate = false;
    } else if (selectedDate > maxDateValue) {
      this.errorFlag.isPastDate = false;
      this.errorFlag.isInValidDate = false;
      this.errorFlag.isFutureDate = true;
    } else {
      this.errorFlag.isPastDate = false;
      this.errorFlag.isInValidDate = false;
      this.errorFlag.isFutureDate = false;
    }
    this.onError();
    this.calendarValidationEvent.emit(this.errorFlag);
  }

  onError() {
    if (this.showErr || this.errorFlag.isInValidDate || this.errorFlag.isPastDate || this.errorFlag.isFutureDate) {
      this.showError = true;
    } else {
      this.showError = false;
    }
  }

  clearErrorFlag() {
    this.errorFlag = {
      isInValidDate: false,
      isPastDate: false,
      isFutureDate: false,
      dateLabel: this.dateLabel
    };
    this.calendarValidationEvent.emit(this.errorFlag);
    this.showError = false;
  }
}
