<sdps-notification type="error" class="sdps-m-top_none" *ngIf="showSchError">
  <div class="sdps-notification__text">
    <p [innerHTML]="getConstant.rulesConstant.pleaseCorrectFollowingError"></p>
    <ul class="sdps-navigation--horizontal__list error-list">
      <li *ngFor='let msg of schErrorMsg' [innerHTML]="msg"></li>
    </ul>
  </div>
</sdps-notification>

<ems-toast-message *ngIf="toastMsg" #toastMessage></ems-toast-message>

<div class="add-new-rule-group">
  <h2 *ngIf="this.instGrpData.mode !== 'EDIT'" class="page-title sdps-m-bottom_large"
    [innerHTML]="getConstant.instrumentGroups.instrumentGrpStep1.addNewInstrumentGroupHeading"></h2>
  <h2
    *ngIf="this.instGrpData.mode === 'EDIT' && (sharedService.checkEntitlements(entitlementConst.navUUID.emtrAccess.manageInstruments.uuid, '', '') && !instrumentGrpService.deletedIG)"
    class="page-title sdps-m-bottom_large"
    [innerHTML]="getConstant.instrumentGroups.instrumentGrpStep1.editInstrumentGroupHeading"></h2>
  <h2
    *ngIf="this.instGrpData.mode === 'EDIT' && !((sharedService.checkEntitlements(entitlementConst.navUUID.emtrAccess.manageInstruments.uuid, '', '') && !instrumentGrpService.deletedIG))"
    class="page-title sdps-m-bottom_large"
    [innerHTML]="getConstant.instrumentGroups.instrumentGrpStep1.viewInstrumentGroupHeading"></h2>
    
  <ems-step-wizard [stepObj]="items" *ngIf="items"></ems-step-wizard>
</div>
