<div class="expanded-row">

  <div [ngClass]="{'expanded-row-item': true, 'expanded-row-item-large': isHidden}"
       *ngFor="let col of filteredRequest; let i = index;">
    <div class="er-item-header" title="{{col.header}}" [ngClass]="{}">{{col.header}}</div>
    <!-- Adding span to implement ellipses for acoountHolderName Field -->
    <div class="er-item-data" title="{{col.field}}" [ngClass]="{}" *ngIf=" (col.header !== 'Account Holder Name' || (col.header === 'Account Holder Name' && request.source === 'Client Central')) ">{{col.field}}</div>
    <div class="er-item-data" title="{{col.field}}" [ngClass]="{}" *ngIf=" col.header === 'Account Holder Name' && request.source && request.source !== 'Client Central'">
      <span class="sdps-datepicker__range">
        <span class="sdps-display-inline-block sdps-truncate--ellipses employeeNameWidth">
        {{request.accountOwnerLastName}}
        </span>
        <span class="sdps-display-inline-block">,</span>
      </span>
      <span class="sdps-display-inline-block sdps-truncate--ellipses employeeNameWidth">
          {{request.accountOwnerFirstName}}
        </span>
    </div>
  </div>
</div>
