<div class="step-action-buttons sdps-p-top_medium">
  <div class="step-action-right sdps-pull-right">
    <button type="button" class="buttons" (click)="goToPreviousStep()" [disabled]="disablePrevBtn" *ngIf="showPreviousBtn">Previous</button>
    <button type="button" class="buttons" (click)="revertChanges()" [disabled]="revertDisable" *ngIf="showRevertBtn">Revert</button>
    <button type="button" class="buttons button-save" (click)="goToRuleList()" *ngIf="showCloseBtn">Close</button>
    <button type="reset" class="buttons" *ngIf="showCancelBtn" (click)="goToRuleList()">Cancel</button>
    <button type="button" class="buttons button-save" (click)="goToNextStep()" [disabled]="disableBtn" *ngIf="showContinueBtn">Continue</button>
    <button type="button" class="buttons button-save" (click)="saveData()" [disabled]="disableBtn" *ngIf="showSaveBtn">Save</button>
    <button type="button" class="buttons button-save" (click)="saveAndCloseData()" [disabled]="disableBtn" *ngIf="showSaveCloseBtn">Save &amp; Close</button>
    <button type="button" class="buttons button-save activate-btn" (click)="activateRule()" [disabled]="disableBtn" *ngIf="showActivateBtn">Activate</button>
    <button type="button" class="buttons button-save activate-btn" (click)="activateRule()" [disabled]="disableBtn" *ngIf="showSaveActivateBtn">Save &amp; Activate</button>
    <button type="button" class="buttons button-save" (click)="goToNextStep()" [disabled]="disableBtn" *ngIf="showSaveContinueBtn">Save &amp; Continue</button>
    <button type="button" class="buttons button-save" (click)="runSelectedReports()" [disabled]="disableBtn" *ngIf="showRunReportBtn">Run Report</button>
  </div>
  <p class="sdps-clearfix">&nbsp;</p>
</div>
