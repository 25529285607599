import {Injectable, Inject} from '@angular/core';
import {Observable, ReplaySubject} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {ConfigUrlService, SharedConstant, SharedService} from '@ems/shared';
import {EmtrService} from '../../emtr.service';
import {emtrConstant} from '../../constants/emtrConstants';

@Injectable({
  providedIn: 'root'
})
export class EmployeeService {

  getConstant = emtrConstant;
  sharedConstant = SharedConstant;
  private subject = new ReplaySubject<any>();
  orgId: string;
  configURL = this.config.setApplicationUrl();

  constructor(private httpService: HttpClient, public sharedService: SharedService, public emtrService: EmtrService, @Inject('env') public env, public config: ConfigUrlService) {
    this.env = this.emtrService.getEnvPath();
    this.sharedService.selectedClientInfo.subscribe(clientInfo => {
      if (clientInfo) {
      this.orgId = clientInfo && clientInfo.orgId;
      }
    });
  }

  getEmpGroupData(groupId: number) {
    let schwabClientHeader = [];
    schwabClientHeader = this.sharedService.setSchwabClientHeaders(this.sharedService.applicationInfo.data.queryParamMap.grpId, groupId, schwabClientHeader);
    if (this.sharedService.groupType === this.sharedConstant.manageMasterGroups.groupTypeMGP) {
      schwabClientHeader = this.sharedService.setSchwabClientHeaders(this.sharedService.applicationInfo.data.queryParamMap.type, this.sharedService.groupType, schwabClientHeader);
    } else {
      schwabClientHeader = this.sharedService.setSchwabClientHeaders(this.sharedService.applicationInfo.data.queryParamMap.orgId, this.orgId, schwabClientHeader);
    }
    return this.httpService.get(this.configURL.bffGroupEndpointV1 + '&Schwab-Client-Ids= ' + schwabClientHeader);
  }

  deleteEmployeeGrp(empGrpId: number, forceDel: boolean) {
    let schwabClientHeader = [];
    schwabClientHeader = this.sharedService.setSchwabClientHeaders(this.sharedService.applicationInfo.data.queryParamMap.grpId, empGrpId, schwabClientHeader);
    if (this.sharedService.groupType === this.sharedConstant.manageMasterGroups.groupTypeMGP) {
      return this.httpService.delete(this.configURL.bffGroupEndpointV1 + '&forceDelete=' + forceDel + '&Schwab-Client-Ids= ' + schwabClientHeader);
    } else {
      schwabClientHeader = this.sharedService.setSchwabClientHeaders(this.sharedService.applicationInfo.data.queryParamMap.orgId, this.orgId, schwabClientHeader);
      return this.httpService.delete(this.configURL.bffGroupEndpointV1 + '&forceDelete=' + forceDel + '&Schwab-Client-Ids= ' + schwabClientHeader);
    }
  }
}
