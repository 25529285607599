import {Inject, Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ConfigUrlService {

  private jfkUrl: string = this.environment && this.environment.prototype ? this.environment.prototype.jfkURL : '';
  bffKey = 'tradeOrder.EmployerManagedGateway';
  bffEmtrKey = 'tradeOrder.EmployerManagedTradeRulesInternal';
  bffAddlinkKey =  'tradeOrder.DesignatedBrokerAccountLinkageServiceInternal';
  bffEsmKey = 'tradeOrder.EmployerManagedSecurityMasterInternal';
  bffEsmUser = 'tradeOrder.EmployerManagedUserManagmentInternal';
  bffEmtrBatchKey = 'tradeOrder.EmployerManagedBatchManagementInternal';
  bffEsmBatch = 'tradeOrder.EmployerManagedBatchManagementInternal';
  bffEsmReport = 'tradeOrder.EmployerManagedReportManagementInternal';
  key = 'tradeOrder.DesignatedBrokerServices';
  addlinkKey = 'tradeOrder.DesignatedBrokerAccountLinkage';
  esmUser = 'tradeOrder.EmployerManagedUserManagment';

  bffInterfaceLayer = {
    passThroughV1: 'PassThroughV1'
  };

  bffPortLayer = {
    passThrough: 'PassThrough'
  };

  interfaceLayer = {
    rulesServiceInterface: 'RulesServiceInterface',
    reportServiceInterface: 'ReportServiceInterface',
    userMgmtServiceInterface: 'UserMgmtServiceInterface',
    clientMgmtServiceInterface: 'ClientMgmtServiceInterface',
    addLinkServiceInterface: 'ADDLinkServiceInterface',
    workbenchServiceInterface: 'WorkbenchServiceInterface',
    esmServiceInterface: 'ESMServiceInterface',
    emsBatchServiceInterface: 'EMSBatchServiceInterface',
    applicationDataServiceV1 : 'ApplicationServiceV1',
    ruleServiceV1: 'RuleServiceV1',
    clientServiceV1: 'ClientV1',
    workbenchServiceV1: 'WorkbenchServiceV1',
    employeeAccountServiceV1: 'AccountServiceV1',
    employeeServiceV1: 'EmployeeServiceV1',
    roleServiceV1: 'RoleServiceV1',
    linkageServiceV1: 'LinkageV1',
    clientV1: 'ClientV1',
    securityMasterServiceV1: 'SecurityMasterServiceV1',
    batchUploadV1: 'BatchFileV1',
    reportServiceV1: 'ReportV1',
    historyServiceV1: 'HistoryV1',
    reportUtilV1: 'ReportUtilV1',
    batchServiceV1: 'BatchV1',
    userServiceV1: 'UserV1',
    batchReportV1: 'BatchReportV1',
    rolesV1: 'RoleV1',
    securityGroupV1: 'SecurityGroupV1',
    assetGroupV1: 'AssetGroupsV1'
  };

  constructor(@Inject('env') public environment) {
  }

  setApplicationUrl() {
    const bffUrl = this.jfkUrl + '/' + this.bffKey + '/';
    const serviceUrl = this.jfkUrl + '/' + this.key + '/';
    const addlinkServiceUrlVl = this.jfkUrl + '/' + this.addlinkKey + '/';
    const esmUserServiceUrlVl = this.jfkUrl + '/' + this.esmUser + '/';
    const bffInterfaceAndPort = bffUrl + this.bffInterfaceLayer.passThroughV1 + '/' + this.bffPortLayer.passThrough + '?kip=';

    const _URL = {
      rulesServiceUrl: serviceUrl + this.interfaceLayer.rulesServiceInterface,
      reportsUrl: serviceUrl + this.interfaceLayer.reportServiceInterface,
      userMgmtUrl: serviceUrl + this.interfaceLayer.userMgmtServiceInterface,
      addlinkUrl: serviceUrl + this.interfaceLayer.addLinkServiceInterface,
      workbenchUrl: serviceUrl + this.interfaceLayer.workbenchServiceInterface,
      batchServiceUrl: serviceUrl + this.interfaceLayer.emsBatchServiceInterface,
      linkageUrlV1: addlinkServiceUrlVl + this.interfaceLayer.linkageServiceV1,
      userServiceUrlV1: esmUserServiceUrlVl + this.interfaceLayer.userServiceV1,
      bffUserServiceUrlV1: bffInterfaceAndPort + this.bffEsmUser + '-' + this.interfaceLayer.userServiceV1,
      bffApplicationDataUrlV1:  bffInterfaceAndPort + this.bffEmtrKey + '-' + this.interfaceLayer.applicationDataServiceV1,
      bffClientServiceUrlV1: bffInterfaceAndPort + this.bffEsmUser + '-' + this.interfaceLayer.clientV1,
      bffRulesServiceUrlV1: bffInterfaceAndPort + this.bffEmtrKey + '-' + this.interfaceLayer.ruleServiceV1,
      bffLinkageUrlV1: bffInterfaceAndPort + this.bffAddlinkKey + '-' + this.interfaceLayer.linkageServiceV1,
      bffRolesServiceUrlV1: bffInterfaceAndPort + this.bffEsmUser + '-' + this.interfaceLayer.rolesV1,
      bffAddlinkClientServiceUrlV1: bffInterfaceAndPort + this.bffAddlinkKey + '-' + this.interfaceLayer.clientV1,
      bffBatchUploadUrlV1: bffInterfaceAndPort + this.bffEsmBatch + '-' + this.interfaceLayer.batchUploadV1,
      bffEsmUrlV1: bffInterfaceAndPort + this.bffEsmKey + '-' + this.interfaceLayer.securityMasterServiceV1,
      bffEmployeeServiceUrlV1: bffInterfaceAndPort + this.bffEmtrKey + '-' + this.interfaceLayer.employeeServiceV1,
      bffEmployeeAccountServiceUrlV1: bffInterfaceAndPort + this.bffEmtrKey + '-' + this.interfaceLayer.employeeAccountServiceV1,
      bffHistoryServiceUrlV1: bffInterfaceAndPort + this.bffEsmReport + '-' + this.interfaceLayer.historyServiceV1,
      bffSecurityGroupServiceUrlV1: bffInterfaceAndPort + this.bffEsmKey + '-' + this.interfaceLayer.securityGroupV1,
      bffAssetGroupServiceUrlV1: bffInterfaceAndPort + this.bffEsmKey + '-' + this.interfaceLayer.assetGroupV1,
      bffPresetRulesServiceUrlV1: bffInterfaceAndPort + this.bffEmtrKey + '-' + this.interfaceLayer.ruleServiceV1,
      bffReportServiceUrlV1: bffInterfaceAndPort + this.bffEsmReport + '-' + this.interfaceLayer.reportServiceV1,
      bffBatchReportServiceUrlV1: bffInterfaceAndPort + this.bffEsmBatch + '-' + this.interfaceLayer.batchReportV1,
    };

    const _CONF = {
      empGroupEndPoint: _URL.rulesServiceUrl + '/' + 'EmpGroupServicePort',
      rulesEndPoint: _URL.rulesServiceUrl + '/' + 'RulesServicePort',
      reportEndPoint: _URL.reportsUrl + '/' + 'ReportServicePort',
      userMgmtEndPoint: _URL.userMgmtUrl + '/' + 'UserMgmtServicePort',
      userMgmtRolesEndPoint: _URL.userMgmtUrl + '/' + 'RoleServicePort',
      workbenchEndPoint: _URL.workbenchUrl + '/' + 'WorkbenchServicePort',
      linkageEndPoint: _URL.addlinkUrl + '/' + 'LinkageServicePort',
      addlinkRequestEndPoint: _URL.addlinkUrl + '/' + 'ADDLinkReportServicePort',
      sposEndPoint: _URL.batchServiceUrl + '/' + 'SposServicePort',
      addlinkBusinessUnitExceptionEndPointV1: _URL.linkageUrlV1 + '/' + 'AuthorizedAgentException',
      userAgreementEndPointV1: _URL.userServiceUrlV1 + '/' + 'Agreement',
      bffUserProfileEndPointV1: _URL.bffUserServiceUrlV1 + '-' + 'Profile',
      bffApplicationDataEndPointV1: _URL.bffApplicationDataUrlV1 + '-' + 'ApplicationData',
      bffClientsSummaryEndpointV1: _URL.bffClientServiceUrlV1 + '-' + 'ClientsSummary',
      bffRulesCountEndPointV1: _URL.bffRulesServiceUrlV1 + '-' + 'RulesCount',
      bffMasterAccountEndpointV1: _URL.bffClientServiceUrlV1 + '-' + 'MasterAccount',
      bffAddlinkRequestStatusCountEndPointV1: _URL.bffLinkageUrlV1 + '-' + 'LinkRequestStatusCount',
      bffAddlinkAuthAgentEndPointV1: _URL.bffLinkageUrlV1 + '-' + 'AuthAgent',
      bffBusinessUnitEndpointV1: _URL.bffClientServiceUrlV1 + '-' + 'BusinessUnit',
      bffClientEndpointV1: _URL.bffClientServiceUrlV1 + '-' + 'Client',
      bffUserFilterEndPointV1: _URL.bffUserServiceUrlV1 + '-' + 'Filter',
      bffUserListEndPointV1 : _URL.bffUserServiceUrlV1 + '-' + 'List',
      bffUserRolesEndPointV1: _URL.bffRolesServiceUrlV1 + '-' + 'Role',
      bffUserEndPointV1: _URL.bffUserServiceUrlV1 + '-' + 'User',
      bffAddlinkRequestEndPointV1: _URL.bffLinkageUrlV1 + '-' + 'LinkRequest',
      bffAddlinkFilterDataV1: _URL.bffLinkageUrlV1 + '-' + 'LinkRequestFilter',
      bffAddlinkClientCentralNoteEndPointV1: _URL.bffLinkageUrlV1 + '-' + 'LinkRequestNote',
      bffAddlinkRequestRemarkEndPointV1: _URL.bffLinkageUrlV1 + '-' + 'LinkRequestRemark',
      bffAddlinkSequenceIdEndPointV1: _URL.bffLinkageUrlV1 + '-' + 'LinkRequestType',
      bffAddlinkClientMasterAccountEndPointV1: _URL.bffAddlinkClientServiceUrlV1 + '-' + 'MasterAccount',
      bffAuthAgentEndpointV1: _URL.bffClientServiceUrlV1 + '-' + 'AuthorizedAgent',
      bffDownloadFileEndPointV1: _URL.bffBatchUploadUrlV1 + '-' + 'Download',
      bffBatchUploadListEndpointV1 : _URL.bffBatchUploadUrlV1 + '-' + 'List',
      bffBatchUploadStatusEndpointV1 : _URL.bffBatchUploadUrlV1 + '-' + 'Status',
      bffBatchUploadExportEndpointV1 : _URL.bffBatchUploadUrlV1 + '-' + 'Upload',
      bffBatchUploadTypeEndpointV1 : _URL.bffBatchUploadUrlV1 + '-' + 'UploadsTypes',
      bffRulesSummaryEndPointV1: _URL.bffRulesServiceUrlV1 + '-' + 'RulesSummary',
      bffRulesEndPointV1: _URL.bffRulesServiceUrlV1 + '-' + 'Rules',
      bffRulesAggregateEndPointV1: _URL.bffRulesServiceUrlV1 + '-' + 'RulesAggregate',
      bffRulesAssignmentEndPointV1: _URL.bffRulesServiceUrlV1 + '-' + 'RulesAssignment',
      bffClassificationEndPointV1 : _URL.bffEsmUrlV1 + '-' + 'Classification',
      bffSearchAssetTypeEndPointV1 : _URL.bffEsmUrlV1 + '-' + 'AssetType',
      bffSecurityEndPointV1 : _URL.bffEsmUrlV1 + '-' + 'Security',
      bffRuleHistoryEndPointV1: _URL.bffHistoryServiceUrlV1 + '-' + 'Rule',
      bffEmployeeEndpointV1: _URL.bffEmployeeAccountServiceUrlV1 + '-' + 'Employee',
      bffEmployeeAccountEndpointV1: _URL.bffEmployeeAccountServiceUrlV1 + '-' + 'EmployeeAccount',
      bffEmployeeAccountSummaryEndpointV1: _URL.bffEmployeeAccountServiceUrlV1 + '-' + 'EmployeeAccountSummary',
      bffAccountHistoryEndPointV1: _URL.bffHistoryServiceUrlV1 + '-' + 'Account',
      bffGroupSummaryEndpointV1: _URL.bffEmployeeServiceUrlV1 + '-' + 'GroupSummary',
      bffGroupEndpointV1: _URL.bffEmployeeServiceUrlV1 + '-' + 'Group',
      bffEmployeeGroupHistoryEndPointV1: _URL.bffHistoryServiceUrlV1 + '-' + 'EmployeeGroup',
      bffSecurityGroupListEndPointV1: _URL.bffSecurityGroupServiceUrlV1 + '-' + 'SecurityGroupSummary',
      bffSecurityGroupSaveEndPointV1: _URL.bffSecurityGroupServiceUrlV1 + '-' + 'SecurityGroup',
      bffSecurityGroupDeleteEndPointV1: _URL.bffSecurityGroupServiceUrlV1 + '-' + 'SecurityGroup',
      bffInstGroupHistoryEndPointV1: _URL.bffHistoryServiceUrlV1 + '-' + 'InstrumentGroup',
      bffAssetGroupEndPointV1: _URL.bffAssetGroupServiceUrlV1 + '-' + 'AssetGroups',
      bffPresetRulesEndPointV1: _URL.bffPresetRulesServiceUrlV1 + '-' + 'PresetRules',
      bffReportListEndPointV1: _URL.bffReportServiceUrlV1 + '-' + 'ReportList',
      bffReportDetailEndPointV1: _URL.bffReportServiceUrlV1 + '-' + 'ReportDetail',
      bffReportFileEndPointV1: _URL.bffReportServiceUrlV1 + '-' + 'ReportFile',
      bffReportListESMBatchEndPointV1: _URL.bffBatchReportServiceUrlV1 + '-' + 'List',
      bffReportDetailESMBatchEndPointV1: _URL.bffBatchReportServiceUrlV1 + '-' + 'Detail',
      bffReportTypeEndPointV1: _URL.bffReportServiceUrlV1 + '-' + 'ReportType',
      bffReportStatusEndPointV1: _URL.bffReportServiceUrlV1 + '-' + 'ReportStatus',
      bffReportExecutionEndPointV1: _URL.bffReportServiceUrlV1 + '-' + 'ReportExecution',
      bffReportESMBatchEndPointV1: _URL.bffBatchReportServiceUrlV1 + '-' + 'Report',
      bffReportStatusESMBatchEndPointV1: _URL.bffBatchReportServiceUrlV1 + '-' + 'Status',
      bffBatchUploadEndpointV1 : _URL.bffBatchUploadUrlV1 + '-' + 'File',
    };
    return _CONF;
  }
}
