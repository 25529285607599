import {Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {SharedConstant, SharedService, WorkflowService} from '@ems/shared';
import {emtrConstant} from '../../constants/emtrConstants';
import {ActivatedRoute} from '@angular/router';
import {RuleService} from '../../services/rule-service/rule.service';
import {InstrumentGroupFormDataService} from '../create-instrument-group/instrument-group-form-data/instrument-group-form-data.service';
import { InstrumentGroupService } from '../../services/instrument-group-service/instrument-group.service';


@Component({
  selector: 'tr-create-instrument-group',
  templateUrl: './create-instrument-group.component.html',
})

export class CreateInstrumentGroupComponent implements OnInit, OnDestroy {

  items: any;
  instGrpData: any;
  sharedConstant = SharedConstant;
  getConstant = emtrConstant;
  schErrorMsg: string[];
  showSchError = false;
  toastMsg: string;
  toastType: string;
  @ViewChild('toastMessage') msgToast: any;

  constructor(public route: ActivatedRoute, public workflowService: WorkflowService, public sharedService: SharedService,
              public ruleService: RuleService, public instrumentGrpService: InstrumentGroupService, public instrumentGrpFormDataService: InstrumentGroupFormDataService, @Inject('entitlementConst') public entitlementConst) {
    this.route.data.subscribe(data => {
      this.items = this.workflowService.getWorkflowSteps('instrumentGrps');
    });
    this.instGrpData = this.instrumentGrpFormDataService.getFormData();
  }

  ngOnInit() {
    this.sharedService.disableClientSelector.next(true);

    this.ruleService.schErrorMsg.subscribe(value => {
      this.schErrorMsg = value ? value : [];
    });

    this.ruleService.showSchError.subscribe(value => {
      this.showSchError = value;
    });

    this.ruleService.toastType.subscribe(value => {
      this.toastType = value ? value : '';
    });

    this.ruleService.toastMsg.subscribe(value => {
      this.toastMsg = value ? value : '';
      if (this.toastMsg && this.toastMsg !== undefined) {
        setTimeout(() => {
          if (this.toastMsg !== '' && this.toastMsg !== undefined) {
            this.msgToast.pushMessage(this.toastType, this.toastMsg);
          }
        }, 1);
      }
    });
  }

  ngOnDestroy() {
    this.sharedService.disableClientSelector.next(this.sharedService.ClientCount === this.sharedConstant.clientCount);
  }
}
