export interface ReportLandingPageTableHeader {
  field?: string;
  header?: string;
  enum?: string;
}
export const ReportLandingPageTableHeaders: ReportLandingPageTableHeader[] = [
  { field: 'reportId', header: 'Report ID', enum: 'textbox' },
  { field: 'clientName', header: 'Client Name', enum: '' },
  { field: 'reportType', header: 'Report Type', enum: 'dropdown' },
  { field: 'status', header: 'Status', enum: 'multiselect' },
  { field: 'submittedDate', header: 'Submitted Date', enum: 'dateRange' },
  { field: 'submittedBy', header: 'Submitted By', enum: 'textbox' },
  { field: 'action', header: 'Actions', enum: '' },
];
