<ems-confirm-message #confirmationMessage></ems-confirm-message>

<div class="heading sdps-clearfix ">
    <span class="select-attributes">Select Rule Attributes</span>
    <tr-rule-name-type *ngIf="attributesFormData" [ruleName]="attributesFormData.name" [showRuleName]="businessUnit !== getSharedConstant.rbsBUText"
                        [ruleType]="attributesFormData.rulesCategory"></tr-rule-name-type>
</div>

<form [formGroup]="attributesForm" novalidate>
    <ems-accordion
            [label]="(attributesFormData.ruleType === getConstant.rulesConstant.autoComplete.FNDISUTxt) ? getConstant.rulesConstant.ruleTypeLabels[attributesFormData.rulesCategory]+ ' ' + getConstant.rulesConstant.ruleTypeLabels.ruleTypeFundFamilyTitle
            : getConstant.rulesConstant.ruleTypeLabels[attributesFormData.rulesCategory]+ ' ' + getConstant.rulesConstant.ruleTypeLabels.ruleTypeTitle">
        <tr-restrict-checkbox [restrictForm]="attributesForm" [rulesCategory]="attributesFormData.rulesCategory" [ruleType]="attributesFormData.ruleType"
                               (emitRestrictTypeChanged)="restrictTypeChanged()"></tr-restrict-checkbox>
    </ems-accordion>

  <!-- Select Securities Section-->
  <ems-accordion [label]="(attributesFormData.ruleType === getConstant.rulesConstant.autoComplete.FNDISUTxt && attributesFormData.rulesCategory === getConstant.rulesConstant.autoComplete.RESTRICT_LST_ENGTxt) ? getConstant.rulesConstant.autoComplete.fundFamilesRestrictTxt : 
  (attributesFormData.ruleType === getConstant.rulesConstant.autoComplete.FNDISUTxt && attributesFormData.rulesCategory === getConstant.rulesConstant.ruleGlobalConst.override) ? getConstant.rulesConstant.autoComplete.fundFamilesOverrideTxt :
   (attributesFormData.ruleType === 'INDSEC' && attributesFormData.rulesCategory === getConstant.rulesConstant.autoComplete.RESTRICT_LST_ENGTxt && businessUnit !== getSharedConstant.spsBUText) && sharedService.checkEntitlements(this.entitlementConst.navUUID.emtrAccess.instrumentsSummary.uuid, '', '') ? getConstant.rulesConstant.autoComplete.securityInstrumentGrpRestrictText : 
   (attributesFormData.ruleType === 'INDSEC' && (attributesFormData.rulesCategory === getConstant.rulesConstant.ruleGlobalConst.override) && businessUnit !== getSharedConstant.spsBUText) && sharedService.checkEntitlements(this.entitlementConst.navUUID.emtrAccess.instrumentsSummary.uuid, '', '') ? getConstant.rulesConstant.ruleTypeLabels.securityInstrumentGrpOverrideText : getConstant.rulesConstant.ruleTypeLabels['security_'+attributesFormData.rulesCategory]">
    <!-- Individual Securities or Fund Family Section-->
    <div *ngIf="!showIndividualSecurity && sharedService.checkEntitlements(entitlementConst.navUUID.emtrAccess.manageRule.uuid, '', '')">
      <!-- Applies To -->
      <tr-security-restrict (appliesToChange)="updateAppliesto($event)" [countOfIndSec]="attributesForm.get('indSecs').value && attributesForm.get('indSecs').value.length"
                                   [appliesType]=appliesType *ngIf="attributesFormData.ruleType !== getConstant.rulesConstant.autoComplete.FNDISUTxt"></tr-security-restrict>
      <!-- Search and Select Securities to Restrict -->
      <tr-securities-to-restrict [multiselectSelectedData]=multiselectSelectedData
                              (securitiesChange)="updateIndividualSecurities($event)"
                              (emitSelectedvalues)="updateMultiselectValues($event)"
                              (throwErrorIndSec)="displayErrorAutocomplete($event)"
                              [autoCompleteSelectedData]="(attributesFormData.ruleType === getConstant.rulesConstant.autoComplete.FNDISUTxt) ? attributesFormData.fndFmlyInfo  : attributesFormData.indSecs"
                              [rulesCategory]="attributesFormData.rulesCategory"
                              [ruleType]="attributesFormData.ruleType"
                              [countOfInstGrp]="attributesForm.get('instGrps').value && attributesForm.get('instGrps').value.length"
                              [selectedRulesCategory]="attributesFormData.rulesCategory"></tr-securities-to-restrict>
        </div>

    <tr-security-type #child [selectedValues]="multiLevelSecurityType" [securityRulesCategory]="attributesFormData.rulesCategory"
                      [modalId]="'choose-security-modal'"
                      (emitSecurityTypeIndividual)="updateSecurityValues($event)"></tr-security-type>

    <span *ngIf="showIndividualSecurity && displayRadioButtonsForRestSecType" class="sdps-p-left_large sdps-m-left_medium">Select only one of the following methods below</span>
    <div *ngIf="showIndividualSecurity && displayRadioButtonsForRestSecType" class="left-padding-60px">
      <p-radioButton class="radio_header sdps-row" name="secSelection" label="Security Level Lists" value="secLevel" [(ngModel)] ="showSecurityOrPreset" [ngModelOptions]="{standalone: true}" (click)="clearFormOnRadioSelection()"></p-radioButton>
      <div class="security-type-holder" *ngIf="showSecurityOrPreset === 'secLevel' && sharedService.checkEntitlements(entitlementConst.navUUID.emtrAccess.manageRule.uuid, '', '')">
        <div class="choose-security-box" (click)="openSecurityType()">
            <div class="security-box-content pi pi-list"></div>
            <span>Choose Security Types from List</span>
        </div>
        <div class="security-selected-result" *ngIf="selectedCapacity(multiLevelUpdatedList)">
            <div class="sdps-p-top_medium sdps-datepicker__range"
                 *ngFor='let item of multiLevelUpdatedList | keyvalue '>
                <div class="security-type-text"><span class="sch-check-circle security-highlight"></span><span
                        class="sdps-p-left_medium security-level-text">{{item.key}}</span></div>
                <div class="whole-container">
                    <div *ngFor="let first of item.value | slice:0;">
                          <span *ngIf="first.data.level === 'SC1'">Level 1 {{first.data.desc}} ({{first.data.code}})
                              <span *ngIf="first.data.selected">({{first.data.selected}})</span>
                          </span>
                        <span *ngIf="first.data.level === 'SC2'">Level 2 {{first.data.desc}}
                            ({{first.data.code}})
                              <span *ngIf="first.data.selected">({{first.data.selected}})</span>
                          </span>
                        <span *ngIf="first.data.level === 'SC3'">Level 3 {{first.data.desc}}
                            ({{first.data.code}})
                              <span *ngIf="first.data.selected">({{first.data.selected}})</span>
                          </span>
                    </div>
                </div>
            </div>
        </div>
        <div class="sdps-p-top_small"
             *ngIf="!selectedCapacity(multiLevelUpdatedList)">{{getConstant.rulesConstant.ruleTypeLabels.selectSecuritiesWarning}}</div>
    </div>
      <p-radioButton class="radio_header sdps-row" name="secSelection" [label]="presetRuleRadioBtnLabel" value="presetRules" [(ngModel)] ="showSecurityOrPreset" [ngModelOptions]="{standalone: true}" (click)="clearFormOnRadioSelection()"></p-radioButton>
      <div
      *ngIf="showSecurityOrPreset === 'presetRules'">
      <tr-preset-rules-and-asset-group [presetRuleIdInput]="attributesFormData.presetRuleId" [assetGroupsInput]="assetGroupsInput"
                                       [micAGSelectedFlag]="attributesFormData.micAGSelectedFlag" (customValue)="setCustomValue($event)"
                                       (presetRuleChange)="setSelectedPresetRule($event)" (selectedActionDropdownValue)="setSelectedDropdownVal($event)"
                                       (presetRuleKey)="setSelectedPresetRuleKey($event)" [globalRuleFlag]="attributeData.globalRule"
                                       (assetGroupChange)="setSelectedAssetGroups($event)" (micAssetGroupSelectedFlag)="setMicAGFlag=$event"
                                       (emitWarningMsg)="showWarningMsg($event)"></tr-preset-rules-and-asset-group>
    </div>
    </div>
    <!-- Security Type Section-->
    <div class="security-type-holder" *ngIf="showIndividualSecurity && !displayRadioButtonsForRestSecType && sharedService.checkEntitlements(entitlementConst.navUUID.emtrAccess.manageRule.uuid, '', '')">
            <div class="choose-security-box" (click)="openSecurityType()">
                <div class="security-box-content pi pi-list"></div>
                <span>Choose Security Types from List</span>
            </div>
            <div class="security-selected-result" *ngIf="selectedCapacity(multiLevelUpdatedList)">
                <div class="sdps-p-top_medium sdps-datepicker__range"
                     *ngFor='let item of multiLevelUpdatedList | keyvalue '>
                    <div class="security-type-text"><span class="sch-check-circle security-highlight"></span><span
                            class="sdps-p-left_medium security-level-text">{{item.key}}</span></div>
                    <div class="whole-container">
                        <div *ngFor="let first of item.value | slice:0;">
                              <span *ngIf="first.data.level === 'SC1'">Level 1 {{first.data.desc}} ({{first.data.code}})
                                  <span *ngIf="first.data.selected">({{first.data.selected}})</span>
                              </span>
                            <span *ngIf="first.data.level === 'SC2'">Level 2 {{first.data.desc}}
                                ({{first.data.code}})
                                  <span *ngIf="first.data.selected">({{first.data.selected}})</span>
                              </span>
                            <span *ngIf="first.data.level === 'SC3'">Level 3 {{first.data.desc}}
                                ({{first.data.code}})
                                  <span *ngIf="first.data.selected">({{first.data.selected}})</span>
                              </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="sdps-p-top_small"
                 *ngIf="!selectedCapacity(multiLevelUpdatedList)">{{getConstant.rulesConstant.ruleTypeLabels.selectSecuritiesWarning}}</div>
        </div>

    <!-- Select Instrument Groups Section-->
    <div
      *ngIf="attributesFormData.ruleType === 'INDSEC' && attributesFormData.rulesCategory !== getConstant.rulesConstant.ruleTypeLabels.WATCH_LST_ENGText && (this.viewIG || sharedService.checkEntitlements(entitlementConst.navUUID.emtrAccess.instrumentsSummary.uuid, '', ''))">
      <div
        *ngIf="!showIndividualSecurity && sharedService.checkEntitlements(entitlementConst.navUUID.emtrAccess.manageRule.uuid, '', '')">
        <tr-search-instrument-groups (instrumentGrpChange)="updateInstrumentGroups($event)"
          (emitSelectedvalues)="updateMultiselectInstGrpValues($event)"
          (throwErrorIndSec)="displayErrorAutocomplete($event)" [countOfIndSec]="attributesForm.get('indSecs').value.length"
          [autoCompleteSelectedData]="attributesFormData.instrumentGroups"></tr-search-instrument-groups>
      </div>
    </div>
  </ems-accordion>

  <!-- Select From and To Date Section-->
  <ems-accordion label='{{getConstant.rulesConstant.selectDates}}'>
        <div class="display-flex sdps-p-left_medium align-items-flex-start">
            <p-radioButton name="selectDate" [disabled] = "fromDateDisabled" [(ngModel)]="dateSelectionType" [ngModelOptions]="{standalone: true}"
                           value='date' class="emtr-radio sdps-p-top_small">
            </p-radioButton>
            <tr-from-to-datepicker  (calendarValidation)="calendarValidation($event)" [calendarForm]="attributesForm"
                                        (throwDateError)="displayErrorDate($event)"></tr-from-to-datepicker>
        </div>
  </ems-accordion>

  <!-- Description and Note Section-->
  <ems-accordion label='{{getConstant.rulesConstant.descriptionNotesTitle}}'>
    <tr-description-and-notes [descriptionForm]="attributesForm"></tr-description-and-notes>
  </ems-accordion>
</form>

<ems-step-wizard-buttons [disableBtn]="(attributesFormData.ruleType === getConstant.rulesConstant.autoComplete.FNDISUTxt ? !attributesForm.valid : checkIfFormIsValid()) || (dateError || (errorMsg.length === 1 && errorMsg[0] !== getConstant.rulesConstant.autoComplete.noRecords) || errorMsg.length > 1) || (attributesForm.get('changeRequestId').value && attributesForm.get('changeRequestId').invalid)"
                         [showCancelBtn]="true" [showSaveContinueBtn]="true" [showPreviousBtn]="true"
                         [disablePrevBtn]="(attributesForm.get('changeRequestId').value && attributesForm.get('changeRequestId').invalid) || (errorMsg.length === 1 && errorMsg[0] !== getConstant.rulesConstant.autoComplete.noRecords) || errorMsg.length > 1"
                         (closeBtn)="navigateTo('/ems/emtr/rules/rules-list', getConstant.rulesConstant.confirmationMessage.cancelBtnClickMsg)"
                         (goToNext)="goToNextStep()"
                         (goToPrevious)="goToPreviousStep();"></ems-step-wizard-buttons>

