import { Component, OnInit, Input } from '@angular/core';
@Component({
  selector: 'ems-step-wizard',
  templateUrl: './step-wizard.component.html',
  styleUrls: ['./step-wizard.component.scss']
})
export class StepWizardComponent implements OnInit {
  @Input() stepObj: any;
  public stepwidth: any;
  constructor() { }

  ngOnInit() {
    this.stepwidth = 100 / this.stepObj.length;
  }

}
