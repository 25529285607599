<div [formGroup]="timeForm" class="timePickerForm" *ngIf="timeForm">
  <div class="sdps-grid-container sdps-p-around_none">
    <div class="col-6">
      <p-dropdown editable="true" placeholder={{timeLabel}} [options]="timeData" class="editableTimepicker" emsTimePatternValidation formControlName="time" id="time" name="time" [ngClass]="{'time-errorHighlight':showErr}">{{showErr}}
      </p-dropdown>
    </div>
    <div class="sdps-dropdown sdps-dropdown--lightGrey emtr-dropdown col-6">
      <select class="sdps-dropdown__select sdps-dropdown--lightGrey__select" formControlName="timeMeridian">
        <option value="AM">AM</option>
        <option value="PM">PM</option>
      </select>
      <span class="sdps-dropdown__icon sdps-dropdown--lightGrey__icon">
        <span class="sch-caret-down"></span>
      </span>
    </div>
  </div>
</div>
