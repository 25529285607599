<form [formGroup]="remarksFormGroup">

<sdps-modal id='beta-remarks-modal' [sdpsTitle]="title" [size]="'flex--medium'" (close)="resetForm()">

  <div slot="modal-body">

    <p class="adl-modal__heading">
      <span><strong>Request ID: {{ requestId }}</strong></span>
      <span class="sdps-pull-right"><strong>Account No: {{ accountNumber }}</strong></span>
    </p>

    <div *ngIf="remarksList && remarksList.length > 0" class="adl-textarea remarks-list sdps-m-bottom_small sdps-p-around_small">
      <div *ngFor="let remark of remarksList; let i = index;">
        <strong>{{ remark.createdDate }}, {{remark.createdBy}}: </strong>
        <strong *ngIf="isHiddenFromClient(i)" class="sdps-text-market-red sdps-m-right_xxx-small" [innerHTML]="commonConstants.asterix"></strong>
        <span>{{ remark.remark }}</span>
      </div>
    </div>

    <p class="sdps-m-bottom_large" *ngIf="!isHidden">Asterisk(<strong class="sdps-text-market-red">*</strong>) indicates remark is not visible to the client</p>

    <p class="adl-modal__heading" *ngIf="showActionItems"><strong>Enter Comments:</strong></p>
    <textarea class="adl-textarea remarks-textarea" type="text" maxlength="1000" formControlName="remarksEnterComments"
              *ngIf="showActionItems" [ngClass]="{'disabled':status === getConstant.statusLabel.complete}"
              name="remarksEnterComments" emsCharacterLimit (emsCharacterLimitCheck)="showRemarksCharLimit = $event"
              [charMaxLimit]="commonConstants.charLimitValues.thousandMaxLimit">
    </textarea>

    <p class="col-6 sdps-text-right sdps-p-around_none sdps-m-around_none sdps-text-small"
       *ngIf="showActionItems && (remarksFormGroup.get('remarksEnterComments').value && remarksFormGroup.get('remarksEnterComments').value.length > showRemarksCharLimit)">{{getConstant.characterLimit}}
      <span class="character-limit">{{remarksFormGroup.get('remarksEnterComments').value.length}}/{{commonConstants.charLimitValues.thousandMaxLimit}}</span>
    </p>

    <div class="sdps-display-inline-block sdps-m-right_xx-large ems-checkbox" *ngIf="!isHidden && showActionItems">
      <p-checkbox formControlName="hideFromClient" [disabled]="!remarksFormGroup.get('remarksEnterComments').value"
                  label="Do not show this remark to Client" binary="true"></p-checkbox>
    </div>

    <div class="sdps-display-inline-block ems-checkbox" *ngIf="!isHidden && showActionItems">
      <p-checkbox formControlName="qaReviewed" [disabled]="isQaReviewedFlag === 'Y'"
                  label="QA Reviewed" binary="true"></p-checkbox>
    </div>
  </div>

  <div slot="modal-footer">
    <button class="buttons" type="reset" (click)="sharedService.closeModal('beta-remarks-modal');resetForm()">Cancel</button>
    <button *ngIf="showActionItems" type="button" class="buttons button-save" (click)="saveComments()"
              [disabled]="(!remarksFormGroup.get('remarksEnterComments').value) && (!remarksFormGroup.get('qaReviewed').value || isQaReviewedFlag === 'Y')">Save</button>
  </div>
</sdps-modal>
</form>
