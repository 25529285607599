import {Component, Inject, Input, OnDestroy, OnInit, Output, EventEmitter} from '@angular/core';
import {SharedService} from '../../shared.service';
import {SharedConstant} from '../../constants/sharedConstants';
import {HttpResponse} from '@angular/common/http';
import {Subscription} from 'rxjs';
import {MessageService} from 'primeng/api';
@Component({
  selector: 'ems-download-user-guide',
  templateUrl: './download-user-guide.component.html'
})
export class DownloadUserGuideComponent implements OnInit, OnDestroy {

  currentOrgSubscription: Subscription;

  getSharedConstant = SharedConstant;
  businessUnit: string;
  @Input() downloadTemplate: any;
  @Input() userGuideName: any;
  @Input() gutTrackValue: any;

  constructor(public sharedService: SharedService, @Inject('entitlementConst') public entitlementConst, private messageService: MessageService) {
  }

  ngOnInit() {
    this.currentOrgSubscription = this.sharedService.clientInformation.subscribe(clientInfo => {
      if (clientInfo !== null) {
        this.businessUnit = clientInfo.businessUnit ? clientInfo.businessUnit : 'All';
        this.sharedService.getOrgId = clientInfo.orgId;
      }
    });
  }

  downloadUserGuide() {
    this.sharedService.gutTracking(this.gutTrackValue);
    this.messageService.clear();
    this.sharedService.clearErrorMessage();
    this.sharedService.downloadFileSystem(this.downloadTemplate, 'userGuide').subscribe((response: HttpResponse<Blob>) => {
      let filename = '';
      if (this.downloadTemplate === this.getSharedConstant.userGuide.workbenchUG[this.businessUnit].value) {
        filename = this.getSharedConstant.userGuide.workbenchUG[this.businessUnit].fileName;
      } else if (this.downloadTemplate === this.getSharedConstant.userGuide.ruleUG[this.businessUnit].value) {
        filename = this.getSharedConstant.userGuide.ruleUG[this.businessUnit].fileName;
      } else if (this.downloadTemplate === this.getSharedConstant.userGuide.manageEmpGU[this.businessUnit].value) {
        filename = this.getSharedConstant.userGuide.manageEmpGU[this.businessUnit].fileName;
      } else if (this.downloadTemplate === this.getSharedConstant.userGuide.mangeEmpGroupUG[this.businessUnit].value) {
        filename = this.getSharedConstant.userGuide.mangeEmpGroupUG[this.businessUnit].fileName;
      }

      this.sharedService.savePdfFile(response.body, filename);
    });
  }

  ngOnDestroy() {
    if (this.currentOrgSubscription) {
      this.currentOrgSubscription.unsubscribe();
    }
  }
}
