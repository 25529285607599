import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {SharedService} from '@ems/shared';
import {emtrConstant} from '../constants/emtrConstants';

@Component({
  selector: 'tr-instrument-groups',
  templateUrl: './instrument-groups.component.html',
})
export class InstrumentGroupsComponent implements OnInit, OnDestroy {

  getEmtrConstants = emtrConstant;

  constructor(public router: Router, public sharedService: SharedService) {
    this.sharedService.specificClientView.next(false);
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    /* Clearing sessionStorage value for other routes for State management */
    if (this.router.routerState.snapshot.url.indexOf(this.getEmtrConstants.instrumentGroups.instrumentGroupList.mainRoute) === -1) {
      this.sharedService.clearLocalStorage();
    }
  }
}
