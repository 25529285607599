import {Component, Output, EventEmitter, Input, Inject} from '@angular/core';
import {emtrConstant} from '../../constants/emtrConstants';

@Component({
    selector: 'tr-security-restrict',
    templateUrl: './security-restrict.component.html',
    styleUrls: ['./security-restrict.component.scss']
})
export class SecurityRestrictComponent {
    @Input() appliesType: any = {};
    @Input() countOfIndSec: any = {};
    @Input() countOfInstGrp: any = {};
    @Output() appliesToChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    emtrConstant = emtrConstant;

    constructor(@Inject('commonConstants') public commonConstants) {

    }
    updateAppliesTo() {
        this.appliesToChange.emit(this.appliesType);
    }
}
