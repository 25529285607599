import {Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {SharedConstant, SharedService } from '@ems/shared';
import {UploadsService} from '@core_services/uploads/uploads.service';
import {ShellConstant} from '@shell_components/constants/shellConstants';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-uploads-modal',
  templateUrl: './uploads-modal.component.html',
  styleUrls: ['./uploads-modal.component.scss']
})
export class UploadsModalComponent implements OnInit, OnDestroy {
  sharedConstant = SharedConstant;
  shellConstant = ShellConstant;
  cols: any;
  uploadErrorMsg: any = [];
  uploadID: any;
  displayFileName: any;
  modalTitle: any;
  pageNumber: any = 1;
  pageSize: any = 15;
  loadedCompleteData: any = false;
  scrolledEle: any;
  totalPages: any;
  type: any;
  radioVals: any;
  selectedVal: any;
  disableExportBtn = false;
  landingPageMsg: any;
  exportStatus: any;
  @Output() exportID = new EventEmitter<any>();
  isCitiClient: any;
  batchCitiOrg: number;
  uploadPageMoreThanSevenDaysErrorMsg: string;
  clientsInfo: any;
  clientInformationSubscribe: Subscription;

  constructor(public sharedService: SharedService, public uploadsService: UploadsService) {
    this.modalTitle = this.sharedConstant.bulkUpload.uploadErrorsTxt;
    this.cols = [
      {field: 'rowNumber', header: 'Row Number'},
      {field: 'failMessages', header: 'failMessages'},
      {field: 'rawData', header: 'rawData'},
    ];
    this.radioVals = [{name: 'All', key: 'All', count: 0}, {name: 'Error', key: 'Errors', count: 0}];
    this.selectedVal = this.radioVals[0].name;
  }

  ngOnInit() {
    this.clientInformationSubscribe =  this.sharedService.clientInformation.subscribe(clientInfo => {
      if (clientInfo) {
        this.clientsInfo = clientInfo;
        this.sharedService.getOrgId = clientInfo.orgId;
      }
    });
    this.batchCitiOrg =  this.sharedService.applicationInfo.data.batchCitiOrg;
  }

  ngOnDestroy(): void {
    this.uploadsService.clearMessage();
    if (this.clientInformationSubscribe) {
      this.clientInformationSubscribe.unsubscribe();
    }
  }

  selectDatatype(data) {
    this.uploadErrorMsg = [];
    this.pageNumber = 1;
    this.loadedCompleteData = false;
    this.getUploadData(data);
  }

  getUploadData(data) {
    this.uploadPageMoreThanSevenDaysErrorMsg = '';
    const param = 'pageNumber=' + this.pageNumber + '&pageSize=' + this.pageSize;
    this.type = data ?  data : 'All';
    this.sharedService.requestListProcessing(this.uploadID, this.type, param).subscribe(res => {
      if (res.data.message !== this.shellConstant.uploadListPage.message && ((this.batchCitiOrg === this.clientsInfo.orgId && res.data.message) || res.data.message)) {
        this.uploadPageMoreThanSevenDaysErrorMsg = res.data.message;
      } else {
        this.uploadPageMoreThanSevenDaysErrorMsg = res.data.message;
      }
      if (this.pageNumber < 2) {
        this.radioVals[0].count = res.data.recordCount;
        this.radioVals[1].count = res.data.errorCount;
      }
      if (res.data.dataList && res.data.dataList !== null) {
        this.uploadErrorMsg = this.uploadErrorMsg.concat(res.data.dataList);
        this.pageNumber++;
        this.loadedCompleteData = this.pageNumber >= 34 || !(res.data.dataList.length > 0);
      } else {
        this.loadedCompleteData = true;
      }
    }, error => this.uploadPageMoreThanSevenDaysErrorMsg = null );
  }


  openModal(uploadID, fileName, totalCount, errorCount, status, exportStatus) {
    this.sharedService.openModal('upload-modal-id');
    this.uploadID = uploadID;
    this.displayFileName = fileName;
    this.exportStatus = exportStatus;
    this.pageNumber = 1;
    this.uploadErrorMsg = [];
    this.loadedCompleteData = false;
    this.isCitiClient = ((status === 'Error') && (fileName.toLowerCase().includes('citifeed')));
    this.selectedVal = status === 'Error' ? this.radioVals[1].name : this.radioVals[0].name;
    this.getUploadData(this.selectedVal );
  }

  onScroll(event: any) {
    this.scrolledEle = event.target;
    if (this.scrolledEle.scrollHeight === this.scrolledEle.scrollTop + this.scrolledEle.offsetHeight &&  !this.loadedCompleteData) {
      this.getUploadData(this.type);
    }
  }

  exportErrors(uploadId) {
    this.landingPageMsg = this.shellConstant.uploadListPage.exportSucessMsg;
    this.uploadsService.exportUploadErrors('ExportUpload', uploadId)
      .subscribe(res => {
        if (res.success === true) {
          this.exportID.emit(uploadId);
          this.uploadsService.showErrorMsg = false;
          this.uploadsService.sendMessage(this.landingPageMsg);
        } else {
          this.uploadsService.showErrorMsg = true;
          this.uploadsService.clearMessage();
        }
      });
    this.sharedService.closeModal('upload-modal-id');
  }
}
