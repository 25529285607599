import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { FormDataService } from '@ems/shared';
import {UntypedFormBuilder} from '@angular/forms';
import {emtrConstant} from '../../constants/emtrConstants';

@Component({
  selector: 'tr-restrict-checkbox',
  templateUrl: './restrict-checkbox.component.html'
})
export class RestrictCheckboxComponent implements OnInit {

  checkboxDisableSell: any;
  buttonDisabled = false;
  getConstant = emtrConstant;
  attributesFormData: any;
  /* eslint-disable-next-line max-len */
  infoIconMsg = this.getConstant.rulesConstant.ruleTypeLabels.shortSellInfo;

  constructor(private fb: UntypedFormBuilder, public formDataService: FormDataService) {
      this.attributesFormData = this.formDataService.getFormData();
  }

  @Output() emitRestrictTypeChanged: EventEmitter<string> = new EventEmitter<string>();
  @Input() restrictForm = this.fb.group({
      restrictBuys: '',
      restrictSells: '',
      restrictShortSells: ''
  });
  @Input() rulesCategory: any;
  @Input() ruleType: any;
  ngOnInit() {}
  restrictTypeChanged() {
      this.emitRestrictTypeChanged.emit();
  }

}
