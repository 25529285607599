import { Component, OnInit, ViewEncapsulation, EventEmitter, Output, Inject } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { RequestListService } from '../../../services/request-list.service';
import { AddlinkService } from '../../../../addlink.service';
import { SharedService } from '@ems/shared';
import { AddlinkConstant } from '../../../../constants/addlinkConstants';
import { CommonService } from '../../../../shared/services/common.service';

interface Affiliation {
  name: string;
  id: number;
}

@Component({
  selector: 'addlink-edit-index-key-modal',
  templateUrl: './edit-index-key-modal.component.html',
  styleUrls: ['./edit-index-key-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EditIndexKeyModalComponent implements OnInit {
  addLinkConstant = AddlinkConstant;

  public title: string;
  public requestId: string;
  public accountNumber: string;
  public indexKey: string;
  intervalJobs: any;
  editRequestInfo: any = {};
  orgId: any;

  constructor(private commonService: CommonService, private formBuilder: UntypedFormBuilder, private requestListService: RequestListService, private addlinkService: AddlinkService, public sharedService: SharedService, @Inject('entitlementConst') public entitlementConst) {
  }

  @Output() udpateIndexKeyRequest = new EventEmitter<any>();

  /* Initialize component variables */
  ngOnInit() {
    this.indexKey = '';
    this.title = 'Edit Request';
  }

  public isIndexKeyValid(): boolean {
    if (this.indexKey) {
      return true;
    }
    return false;
  }

  public editIndexkeySubmit(): void {
    const requestIdsArr = [this.requestId];
    let count = 0;
    this.requestListService.addlinkRequestPutEndPoint(requestIdsArr, this.indexKey, 'INDEXKEY',  this.orgId).subscribe((data) => {
      if (data.data.status === 20001) {
        this.requestListService.sendMessage(data.data.message);
        this.intervalJobs = setInterval(() => {
          this.getRequestStatus(this.requestId);
          count++;
          if (count > 4) {
            window.clearInterval(this.intervalJobs);
          }
        }, this.addLinkConstant.reqDetailPollingInterval);
      } else {
        this.requestListService.showRequestListError.next(true);
        this.requestListService.showRequestListMessage = data.data.message;
      }
    });

    this.closeModal();
  }

  /* Opens The Modal */
  public openModal(e: Event, requestId: string, accountNumber: string, orgId: any): void {
    this.requestId = requestId;
    this.accountNumber = accountNumber;
    e.stopPropagation();
    this.orgId = orgId;
    this.sharedService.openModal('beta-edit-index-modal');
  }

  /* Closes The Modal */
  public closeModal(): void {
    this.indexKey = '';
    this.sharedService.closeModal('beta-edit-index-modal');
  }

  getRequestStatus(requestIds) {
    this.sharedService.setRefresh.emit(true);
    this.commonService.pollRequestDetailsByRequestId(requestIds, this.orgId).subscribe((res) => {
      this.editRequestInfo = res.data.requestInfo;
      this.editRequestInfo.disableIcon = true;
      const status = this.sharedService.isInternalApp() ? res.data.requestInfo.internalStatus : res.data.requestInfo.status;
      if (status !== 'Validating' && status !== 'Received') {
        this.editRequestInfo.disableIcon = false;
        clearInterval(this.intervalJobs);
        this.sharedService.setRefresh.emit(false);
      }
      this.udpateIndexKeyRequest.emit(this.editRequestInfo);
    }, error => {
      clearInterval(this.intervalJobs);
    });
  }

  public resetDisableIcon() {
    this.editRequestInfo.disableIcon = false;
    this.editRequestInfo.requestId = this.requestId;
    this.udpateIndexKeyRequest.emit(this.editRequestInfo);
  }
}
