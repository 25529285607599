import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Router, ActivatedRoute} from '@angular/router';
import {ChangeDetectorRef} from '@angular/core';
import {RuleService} from '../../../services/rule-service/rule.service';
import {FormDataService, SharedService} from '@ems/shared';
import {emtrConstant} from '../../../constants/emtrConstants';
import {SharedConstant} from '@ems/shared';
@Component({
    selector: 'tr-rules-step1-rule-type',
    templateUrl: './rules-step1.component.html',
    styleUrls: ['./rules-step1.component.scss']
})
export class RulesStep1Component implements OnInit {

    createRuleForm: UntypedFormGroup;
    ruleNameExists = false;
    draftRuleName: string;
    public draftRuleId: any;
    sessionObj = this.formDataService.getLoginInfo();
    orgId = '';
    override = 'N';
    getConstant = emtrConstant;
    sharedConstant = SharedConstant;
    message: string;
    targetElem: any;
    cancelButton = false;
    continueToNavigate = false;
    disableRuleType = false;
    confirmMessage: any;
    showCharLimit: boolean;
    errorMsg = [];
    ruleNameExistsMsg = '';
    @ViewChild('focusName', { static: true }) inputEl: ElementRef;
    @ViewChild('confirmationMessage', { static: true }) confirmationMessage: any;
    is10b5Client = false;
    isEditMode = false;
    isDraftEdit = false;
    isGlobalCreateFlow = false;
    planIDTxt = 'planId';
    showInvalidError = '';
    isFirstTimeValidation = true;
    step1FormData: any;
    isRuleNameModified = false;
    businessUnit: string;
    isRbsBU = false;
    oldFormObject: any;


  constructor(private router: Router, private fb: UntypedFormBuilder, public sharedService: SharedService,
              private ruleService: RuleService, private formDataService: FormDataService, private r: ActivatedRoute,
              private cd: ChangeDetectorRef, @Inject('commonConstants') public commonConstants, @Inject('entitlementConst') public entitlementConst) {
    this.ruleService.clearMessage();
    this.step1FormData = this.formDataService.getFormData();
    this.ruleService.duplicateRuleName.subscribe(value => {
      this.ruleNameExists = this.continueToNavigate = true;
      this.cancelButton = false;
    });
  }

    ngOnInit() {
        this.sharedService.clientInformation.subscribe(clientInfo => {
          this.businessUnit = clientInfo && clientInfo.businessUnit;
        });
        this.is10b5Client = this.sharedService.get10b5ClientDetails();
        let enable10b5Checkbox;
        if (this.step1FormData.mode === 'EDIT') {
          this.isEditMode = true;
        } else {
          this.isEditMode = false;
        }

        if (this.step1FormData.planId) {
          enable10b5Checkbox = true;
        } else {
          enable10b5Checkbox = false;
        }
        /* Disabling the restriction Category if rule is saved as "DRAFT" */
        if (this.step1FormData.mode !== 'EDIT' && this.is10b5Client && this.step1FormData.isSavedAsDraft) {
          this.isDraftEdit = true;
        } else {
          this.isDraftEdit = false;
        }
        if (this.step1FormData.mode !== 'EDIT' && (this.step1FormData.isSavedAsDraft || this.step1FormData.mode === 'Copy')) {
          this.isGlobalCreateFlow = true;
        } else {
          this.isGlobalCreateFlow = false;
        }

        this.disableRuleType = !!this.step1FormData.mode || (this.step1FormData.isSavedAsDraft && this.step1FormData.globalRule);
        this.draftRuleName = this.step1FormData.name;
        this.draftRuleId = this.step1FormData.id;
        this.createRuleForm = this.fb.group({
            ruleName: [this.step1FormData.name, {validators: [Validators.required]}],
            rulesCategory: [{value: (this.step1FormData.override === 'Y') ? 'Override' : this.step1FormData.rulesCategory, disabled: this.disableRuleType}, Validators.required],
            ruleType: [{value: this.step1FormData.ruleType, disabled: this.disableRuleType},  Validators.required],
            justification: [this.step1FormData.justification, Validators.required],
            otherInput: [this.step1FormData.otherInput],
            enable10b5Checkbox: [enable10b5Checkbox],
            planId: [this.step1FormData.planId],
            globalRule: [this.step1FormData.globalRule]
        });

        this.oldFormObject = this.createRuleForm.getRawValue();

        this.onChanges();
        this.ruleNameExists = false;
        this.confirmMessage = this.confirmationMessage.confirmMessage;
        if ( this.businessUnit === this.sharedConstant.rbsBUText ) {
          this.createRuleForm.get('ruleName').reset();
          this.createRuleForm.get('ruleName').clearValidators();
          this.isRbsBU = true;
        } else  {
          this.createRuleForm.get('ruleName').setValidators([Validators.required]);
          this.isRbsBU = false;
        }
        this.createRuleForm.get('ruleName').updateValueAndValidity();
    }

    canDeactivate(event, observer) {
        this.confirmMessage.message = this.confirmMessage.message ? this.confirmMessage.message : this.getConstant.rulesConstant.confirmationMessage.menuClickMsg;
        if (this.cancelButton === false && (this.createRuleForm.dirty || this.createRuleForm.get('ruleName').value)) {
          return this.confirmationMessage.showConfirmMsg(this.confirmMessage, observer);
        }
        return true;
    }

    onChanges(): void {
        if (this.createRuleForm.get('rulesCategory').value !== 'Override') {
            this.createRuleForm.get('justification').disable();
        }
        if (this.createRuleForm.get('justification').value !== 'Other') {
            this.createRuleForm.get('otherInput').disable();
        }
        this.createRuleForm.get('globalRule').valueChanges.subscribe((globalValue) => {
        this.setFormValueForSecuritySection();
      });
        this.createRuleForm.get('rulesCategory').valueChanges.subscribe((rulesCategoryValue) => {
          /* Clearing planId if Rule Type is changed */
          if (this.is10b5Client) {
            this.createRuleForm.patchValue({
              enable10b5Checkbox: '',
              planId: ''
            });
          }
          /* ReSetting the rule subType if fundFamily is already Selected and switches between 'Override' and 'WatchList' */
          if (rulesCategoryValue === 'WATCH_LST_ENG' || rulesCategoryValue === 'Override') {
            this.createRuleForm.get('ruleType').reset();
            this.createRuleForm.get('globalRule').reset();
          }
          this.setFormValueForSecuritySection();
        });

        this.createRuleForm.get('ruleType').valueChanges.subscribe((rulesType) => {
          /* Resetting the global rule checkbox if 'Security Type' is not selected */
          if (rulesType !== 'SECTYP') {
            this.createRuleForm.get('globalRule').reset();
          }
          this.setFormValueForSecuritySection();
        });

        this.createRuleForm.get('ruleName').valueChanges.subscribe((ruleNameValue) => {
          this.ruleNameExists = this.continueToNavigate = false;

          /*To clear the rulename exists warning msg*/
          this.ruleService.toastMsg.emit('');
          this.ruleService.toastType.emit('');
          this.sharedService.closeToast = false;

          const regExp = new RegExp('^[a-zA-Z0-9+_()\[\\],.\\- ]*$');
          if (ruleNameValue && !regExp.test(ruleNameValue)) {
            if (this.errorMsg.indexOf(this.getConstant.rulesConstant.ruleNameMsg.invalidCharacter) === -1) {
              this.errorMsg.push(this.getConstant.rulesConstant.ruleNameMsg.invalidCharacter);
            }
            this.ruleService.showSchError.emit(true);
          } else {
            if (this.errorMsg.indexOf(this.getConstant.rulesConstant.ruleNameMsg.invalidCharacter) !== -1) {
              this.isFirstTimeValidation = true;
              this.errorMsg.splice(this.errorMsg.indexOf(this.getConstant.rulesConstant.ruleNameMsg.invalidCharacter), 1);
            }
          }
          /*Hide the error block when errorMsg is empty*/
          if (this.errorMsg.length === 0) {
            this.ruleService.showSchError.emit(false);
          }
          this.ruleService.schErrorMsg.emit(this.errorMsg);

          if (!this.isFirstTimeValidation) {
            this.isRuleNameModified = true;
          }
        });
        this.createRuleForm.get('planId').valueChanges.subscribe((value) => {
          if (value && this.createRuleForm.get('planId').invalid && this.errorMsg.indexOf(this.getConstant.rule10b5Msg.invalidCharacterEnteredNumericValue) === -1) {
            this.errorMsg.push(this.getConstant.rule10b5Msg.invalidCharacterEnteredNumericValue);
          } else {
            if ((value === '' && this.showInvalidError !== 'emp-grp') || (this.errorMsg.indexOf(this.getConstant.rule10b5Msg.invalidCharacterEnteredNumericValue) !== -1 &&
              !this.createRuleForm.get('planId').invalid)) {
              if (this.errorMsg.indexOf(this.getConstant.rule10b5Msg.invalidCharacterEnteredNumericValue) > -1) {
                this.errorMsg.splice(this.errorMsg.indexOf(this.getConstant.rule10b5Msg.invalidCharacterEnteredNumericValue), 1);
              }
              if (this.showInvalidError === 'planId' || this.showInvalidError === 'both') {
                const planIdTxt = 'planId';
                this.createRuleForm.controls[planIdTxt].setErrors({incorrect: false});
              }
            }
          }

          /* Removing groupName existed error if new value is entered */
          if (this.showInvalidError === 'planId' && this.errorMsg.length > 0 && this.errorMsg.indexOf(this.getConstant.rule10b5Msg.duplicatePlanId) !== -1
            && this.is10b5Client && !this.createRuleForm.get('planId').invalid) {
            this.errorMsg.splice(this.errorMsg.indexOf(this.getConstant.rule10b5Msg.duplicatePlanId), 1);
            this.showInvalidError = '';
          }

          /* Removing both(groupName & planId) existed error if new value is entered  in both fields*/
          if (this.showInvalidError === 'both' && this.errorMsg.indexOf(this.getConstant.rule10b5Msg.duplicateRuleNameAndPlanId) !== -1 &&
            this.is10b5Client && !this.createRuleForm.get('planId').invalid) {
            this.errorMsg.splice(this.errorMsg.indexOf(this.getConstant.rule10b5Msg.duplicateRuleNameAndPlanId), 1);
            this.showInvalidError = '';
          }

          this.ruleService.schErrorMsg.emit(this.errorMsg);
          if (this.errorMsg.length === 0) {
            this.ruleService.showSchError.emit(false);
          } else {
            this.ruleService.showSchError.emit(true);
          }
        });
        this.enableInput('rulesCategory', 'Override', 'justification');
        this.enableInput('justification', 'Other', 'otherInput');
    }

    enableInput(param1, param2, param3) {
        this.createRuleForm.get(param1).valueChanges.subscribe((value) => {
            if (value === param2) {
                this.createRuleForm.get(param3).enable();
            } else {
                this.createRuleForm.get(param3).reset();
                this.createRuleForm.get(param3).disable();
            }
        });
    }

    goToNextStep() {
        this.cancelButton = true;
        const formObj =  {
            ruleName: this.createRuleForm.get('ruleName').value ? this.createRuleForm.get('ruleName').value.trim() : this.step1FormData.name ? this.step1FormData.name : '',
            rulesCategory: this.createRuleForm.get('rulesCategory').value,
            ruleType: this.createRuleForm.get('ruleType').value,
            justification: this.createRuleForm.get('justification').value,
            otherInput: this.createRuleForm.get('otherInput').value,
            enable10b5Checkbox: this.createRuleForm.get('enable10b5Checkbox').value,
            planId: this.createRuleForm.get('planId').value,
            globalRule: this.createRuleForm.get('globalRule').value
        };

        if (JSON.stringify(this.oldFormObject) !== JSON.stringify(formObj)) {
            this.ruleService.checkIfChangesMadeInStep1 = true;
        } else {
            this.ruleService.checkIfChangesMadeInStep1 = false;
        }

        if (this.isRbsBU || (this.draftRuleName === formObj.ruleName && this.draftRuleId) || (this.continueToNavigate && !this.is10b5Client)) {
            this.ruleNameExists = false;
            this.navigateTo(formObj);
        } else {
          this.checkWhetherRuleNameExists(formObj);
        }
    }

    checkWhetherRuleNameExists(ruleObj) {
      this.orgId = this.ruleService.orgId;
      let planIdValue = '';
      if (this.is10b5Client && this.createRuleForm.get('planId').value && !this.isEditMode) {
        planIdValue = this.createRuleForm.get('planId').value;
      } else {
        planIdValue = '';
      }
      this.ruleService.checkRuleNameExists(ruleObj.ruleName.trim(), this.orgId, planIdValue).subscribe(res => {
        this.sharedService.decodeUrl = true;
        this.continueToNavigate = !(res.data);
        const resposeValidation = this.sharedService.setResponseMessage(res, this.is10b5Client, this.createRuleForm.get('ruleName').value.trim(), planIdValue, this.getConstant, 'Rules');
        const validatedField = this.ruleService.validateNameAndPlanId(resposeValidation.data.message, this.createRuleForm);
        this.showInvalidError = validatedField;
        /* Making Form Dirty if 'planId' Field is invalid */
        if (validatedField !== 'ruleName') {
          this.cancelButton = false;
        }
        /* Removing Error Message if duplicateRuleName is true */
        if (resposeValidation.data.message !== this.getConstant.rule10b5Msg.duplicateRuleName &&
          this.errorMsg.indexOf(this.getConstant.rule10b5Msg.duplicateRuleNameAndPlanId) !== -1 && this.is10b5Client) {
          this.errorMsg.splice(this.errorMsg.indexOf(this.getConstant.rule10b5Msg.duplicateRuleNameAndPlanId), 1);
        }
        if (this.isRuleNameModified) {
          this.isFirstTimeValidation = true;
          this.isRuleNameModified = false;
        }
        this.showValidationErrors(resposeValidation);
        this.ruleService.schErrorMsg.emit(this.errorMsg);
        if ((!this.isFirstTimeValidation && resposeValidation.data.message === this.getConstant.rule10b5Msg.duplicateRuleName) ||
          resposeValidation.data.message === this.getConstant.rule10b5Msg.ruleNameNotExists) {
          const planIdTxt = 'planId';
          this.cancelButton = true;
          this.createRuleForm.controls[planIdTxt].setValidators(null);
          this.createRuleForm.controls[planIdTxt].updateValueAndValidity();
          this.navigateTo(ruleObj);
        }
        this.isFirstTimeValidation = false;
        /* res.data.message is  'Rule must be created with a unique plan id'  setting the 'isFirstTimeValidation' as true for Navigation to Step 2*/
        if (resposeValidation.data.message === this.getConstant.rule10b5Msg.duplicateRuleNameAndPlanId || this.isRuleNameModified) {
          this.isFirstTimeValidation = true;
        }
      });
    }

    showValidationErrors(res) {
      if (this.errorMsg.indexOf(res.data.message) === -1 && (res.data.message !== this.getConstant.rule10b5Msg.duplicateRuleName &&
        res.data.message !== this.getConstant.rule10b5Msg.ruleNameNotExists)) {
        /*To clear the rulename exists warning msg*/
        this.ruleService.toastMsg.emit('');
        this.ruleService.toastType.emit('');
        this.sharedService.closeToast = false;
        this.ruleNameExists = false;
        this.errorMsg.push(res.data.message);
        this.ruleService.showSchError.emit(true);
      } else if (this.errorMsg.indexOf(res.data.message) !== -1) {
        this.errorMsg.splice(this.errorMsg.indexOf(res.data.message), 1);
        this.ruleService.showSchError.emit(false);
      }
    }

    navigateTo(steps) {
        const ruleObj = {
            ruleName: steps.ruleName.trim(),
            rulesCategory: (steps.rulesCategory === 'Override') ? 'RESTRICT_LST_ENG' : steps.rulesCategory,
            ruleType: steps.ruleType,
            override: (steps.rulesCategory === 'Override') ? 'Y' : 'N',
            orgId: this.ruleService.orgId,
            justification: steps.justification,
            otherInput: steps.otherInput,
            ruleSubCategory: steps.enable10b5Checkbox ? '10b5-1' :  '',
            planId: steps.planId,
            globalRule: steps.globalRule
        };
        this.formDataService.setStep1(ruleObj, true);

        /*To clear the rulename exists warning msg*/
        this.ruleService.toastMsg.emit('');
        this.ruleService.toastType.emit('');
        this.sharedService.closeToast = false;

        this.router.navigate(['../step2'], {relativeTo: this.r});
    }

    cancelCreateRule() {
        this.confirmMessage.message = this.getConstant.rulesConstant.confirmationMessage.cancelBtnClickMsg;
        this.router.navigate(['/ems/emtr/rules/rules-list']);
    }

    onClose() {
        this.cd.detectChanges();
        this.inputEl.nativeElement.focus();
        this.ruleNameExists = false;
    }

    /* If '10b5' checkbox is checked/unchecked adding planId Validators */
    selected10b5() {
      this.sharedService.set10b5Validators(this.createRuleForm, this.planIDTxt);
    }

  setFormValueForSecuritySection() {
    if (this.ruleService.previousStep && this.businessUnit !== this.sharedConstant.spsBUText) {
    const objVal = {
      chooseSecurityType: '',
      secCatgs1: [],
      secCatgs2: [],
      secCatgs3: [],
      assetGroups: [],
      presetRuleKey: '',
      presetRuleId: '',
      instrumentGroups: [],
      indSecs: [],
      appliesTo: []
    };
    this.formDataService.setSecurityValue(objVal);
    }
  }

  removeLeadingZeroes() {
    const value = this.createRuleForm.getRawValue().planId.replace(/^0+/, '');
    this.createRuleForm.get('planId').setValue(value);
    if (value === '0') {
      this.createRuleForm.get('planId').reset();
      this.selected10b5();
    }
  }
}
