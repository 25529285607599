<sdps-modal id='upload-modal-id' location="center" [sdpsTitle]="modalTitle" [size]="'flex--medium'">
  <div slot="modal-body">
    <!-- Upload Id and File Name -->
    <div class="sdps-p-vertical_x-small sdps-p-left_small overflow-hide">
      <p class="sdps-pull-left sdps-font-weight-bold">{{sharedConstant.bulkUpload.uploadID}}<span class=" sdps-font-weight-bold">{{uploadID}}</span></p>
      <p class="sdps-pull-right sdps-font-weight-bold">{{sharedConstant.bulkUpload.fileName}}<span class=" sdps-font-weight-bold">{{displayFileName}}</span></p>
    </div>

    <!-- All and Error radio button -->
    <div class="sdps-p-vertical_x-small sdps-p-left_small" *ngIf="uploadPageMoreThanSevenDaysErrorMsg === shellConstant.uploadListPage.message || uploadPageMoreThanSevenDaysErrorMsg === null">
     <span *ngFor="let category of radioVals; let i = index">
      <p-radioButton class="emtr-radio ems-inline" [inputId]="category.key" [ngClass]="i > 0 ? 'sdps-p-left_small' : ''" name="groupname" value="{{category.name}}" [(ngModel)]="selectedVal" (onClick)="selectDatatype(category.name)"></p-radioButton>
        <label class="sdps-font-weight-bold sdps-p-left_x-small sdps-display-inline-block vertical-align-middle" [for]="category.key">{{category.key}} ({{category.count}})</label>
      </span>
    </div>

    <div class="sdps-text-center" *ngIf="uploadPageMoreThanSevenDaysErrorMsg !== shellConstant.uploadListPage.message">
      <span>{{uploadPageMoreThanSevenDaysErrorMsg}}</span>
    </div>

    <!-- Table header section -->
    <div *ngIf="uploadErrorMsg && uploadErrorMsg.length > 0">
      <p-table [value]="uploadErrorMsg" [columns]="cols" class="common-data-table" dataKey="rowNumber">
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th *ngFor="let col of columns; let i = index">
              <span *ngIf="col.field === 'failMessages'">Status</span>
              <span *ngIf="col.field === 'rowNumber'">{{col.header}}</span>
            </th>
          </tr>
        </ng-template>
      </p-table>
    </div>

    <!-- Table body section -->
    <div *ngIf="uploadErrorMsg && uploadErrorMsg.length > 0" (scroll)="onScroll($event)"
         [ngClass]="{'show-scroll' : uploadErrorMsg.length}">
      <p-table [value]="uploadErrorMsg" [columns]="cols" class="common-data-table" dataKey="rowNumber">
        <ng-template pTemplate="body" let-expanded="expanded" let-rowData let-columns="columns">
          <tr class="sdps-truncate--ellipses">
            <td *ngFor="let col of columns">
              <a [pRowToggler]="rowData" *ngIf="col.field === 'rowNumber'" class="caret-color">
                <em [ngClass]="expanded ? 'pi pi-caret-down' : 'pi pi-caret-right'"></em>
                <span class="sdps-p-left_x-small vertical-align">{{rowData[col.field]}}</span>
              </a>
                      <span class="status sdps-p-around-xx-large" *ngIf="col.field === 'failMessages'" [ngClass]="rowData['failMessages'] && rowData['failMessages'] !== null && rowData['failMessages'].length > 0 ? 'expired' : 'active'">
                          <span class="sdps-m-left_large">{{rowData['failMessages'] !== null && rowData[col.field].length > 0 ? 'ERROR' : 'COMPLETE'}}</span>
                        </span>

            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="rowexpansion" let-rowData let-columns="columns">
          <tr>
            <td [attr.colspan]="columns.length">
              <div class="sdps-p-vertical_x-small sdps-m-left_medium" *ngIf="rowData.rawData !== null">
                <span [innerHTML]="rowData.rawData"></span>
              </div>
              <div class="sdps-m-left_medium">
                <div class="sdps-p-vertical_x-small sdps-font-weight-bold" *ngIf='rowData.failMessages !== null && rowData.failMessages.length > 0' [ngClass]="{'failmsg-border-color': rowData.rawData !== null}">
                  {{sharedConstant.bulkUpload.failedMsgTxt}}
                </div>
                <div class="p-col-12 sdps-p-top_medium sdps-p-bottom_x-small sdps-p-horizontal_x-small" *ngIf="rowData.failMessages !== null && rowData.failMessages.length > 0">
                  <ul class="upload-errors-holder sdps-p-horizontal_x-small">
                    <li class="uploadDataList" *ngFor="let item of rowData.failMessages; let i = index">
                      <div class="contentHolder">
                        <span>{{item}}</span>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </td>
          </tr>
        </ng-template>

      </p-table>
    </div>
  </div>

  <!-- Close and export button -->
  <div slot="modal-footer">
    <div class="upload-buttons-alignment">
      <button type="button" class="buttons" [innerHTML]="sharedConstant.bulkUpload.exportTxt" (click)="exportErrors(this.uploadID)"
      [disabled]="exportStatus === shellConstant.uploadListPage.complete || exportStatus === shellConstant.uploadListPage.started || isCitiClient || uploadPageMoreThanSevenDaysErrorMsg !== shellConstant.uploadListPage.message" [title]="exportStatus === shellConstant.uploadListPage.complete ? shellConstant.uploadListPage.fileExportedMsg : ''">
      </button>
      <button type="button" class="buttons button-save" (click)="sharedService.closeModal('upload-modal-id')"
              [innerHTML]="sharedConstant.bulkUpload.closeTxt"></button>
    </div>
  </div>
</sdps-modal>
