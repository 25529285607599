import {AfterViewChecked, Component, ElementRef, Injector, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {PresetReportsComponent} from '@shell_components/reports/preset-reports/preset-reports.component';
import { ShellConstant } from '@shell_components/constants/shellConstants';
import {ReportsService} from '@core_services/report/reports.service';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {DateRangeFilterComponent, SharedService} from '@ems/shared';
import {commonConstants} from '@shell_components/constants/commonConstants';
import {AppService} from '@core_services/app.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-addlink-usage',
  templateUrl: './addlink-usage.component.html'
})
export class AddlinkUsageComponent implements OnInit, AfterViewChecked, OnDestroy {

  parent: PresetReportsComponent;
  getShellConstant = ShellConstant;
  endDateLessThanStartDate = '';
  dateRangeConstarint = '';
  fromDate: any;
  toDate: any;
  dateError: any;
  submitterType: any;
  selectedOptionsOfStatus = [];
  selectedOptionsOfSource = [];
  sourceDropdownData = [];
  statusDropdownData = [];
  public reportName: any;
  public getOrgId: any;
  businessUnit: any;
  public showClientMsg: any = false;
  getCommConst = commonConstants;
  addlinkReportForm: UntypedFormGroup;
  @ViewChild('range') rangeCalendar: DateRangeFilterComponent;
  clientInfoSubscribe: Subscription;

  constructor(public reportsService: ReportsService, private injector: Injector, private fb: UntypedFormBuilder, private el: ElementRef, public sharedService: SharedService, private appService: AppService) {
    this.reportName = this.reportsService.getReportName();
    this.parent = this.injector.get(PresetReportsComponent);
    this.reportsService.checkForAccess();
  }

  ngOnInit() {
    this.addlinkReportForm = this.fb.group({
      dateTo: this.fb.group({
        date: ['', Validators.required],
      }),
      dateFrom: this.fb.group({
        date: ['']
      }),
      submitterType: '',
      source: [this.selectedOptionsOfSource],
      status: [this.selectedOptionsOfStatus]
    });

    this.clientInfoSubscribe = this.sharedService.clientInformation.subscribe(clientInfo => {
      if (clientInfo && clientInfo !== null) {
        this.getOrgId = clientInfo.orgId;
        this.reportsService.orgId = clientInfo.orgId;
        this.businessUnit = clientInfo.businessUnit;
      }

      this.reportsService.getSourceAndStatusValue().subscribe(data => {
        this.reportsService.sourceAndStatusMultiselectValues.next(data);
      });
      const newObj = {label: 'All', value: 'All'};
      this.addlinkReportForm.patchValue({
        submitterType: ((this.reportsService.accessToOnlyRbs  || this.reportsService.accessToOnlySPS) || this.businessUnit === 'RBS' || this.businessUnit === 'SPS') ? this.getShellConstant.manageUserPage.manageUserFilter.userType[1] : newObj,
        source: newObj,
        status: newObj
      });

      if ((this.reportsService.accessToOnlyRbs || this.reportsService.accessToOnlySPS) || this.businessUnit === 'RBS' || this.businessUnit === 'SPS') {
        this.addlinkReportForm.get('submitterType').disable();
      } else {
        this.addlinkReportForm.get('submitterType').enable();
      }
      if (this.reportsService.accessToOnlyRbs || this.businessUnit === 'RBS') {
        this.addlinkReportForm.get('source').disable();
        this.addlinkReportForm.get('status').disable();
      } else {
        this.addlinkReportForm.get('status').enable();
        this.addlinkReportForm.get('source').enable();
      }
    });

    this.reportsService.reportsArray.subscribe((data: any) => {
      if (data !== null) {
        data.map((res) => {
          if (res.name === this.reportName) {
            res.accessType === 'CLIENT' ? this.getOrgId > 0 ? this.showClientMsg = false : this.showClientMsg = true : this.showClientMsg = false;
          }
        });
      }
    });
    this.selectedOptionsOfStatus = [];
    this.statusDropdownData = [];
    this.reportsService.sourceAndStatusMultiselectValues.subscribe((data) => {
      this.sourceDropdownData = [];
      this.statusDropdownData = [];
      if (data !== null) {
        data.data.sources.forEach(sourceName => {
          const sourceObj = {label: sourceName, value: sourceName};
          this.sourceDropdownData.push(sourceObj);
        });

        data.data.status.all.forEach(statusName => {
          const statusObj = {label: statusName, value: statusName};
          this.statusDropdownData.push(statusObj);
        });

        this.selectedOptionsOfSource = [];
        this.selectedOptionsOfStatus = [];
        this.sourceDropdownData.forEach((sourceItem) => this.selectedOptionsOfSource.push(sourceItem.value));
        this.statusDropdownData.forEach((statusItem) => this.selectedOptionsOfStatus.push(statusItem.value));
        this.addlinkReportForm.patchValue({
          source: this.reportsService.accessToOnlyRbs || this.businessUnit === 'RBS' ? this.selectedOptionsOfSource.slice(0, 1) : this.selectedOptionsOfSource,
          status: this.reportsService.accessToOnlyRbs || this.businessUnit === 'RBS' ? this.selectedOptionsOfStatus.slice(0, 1) : this.selectedOptionsOfStatus,
        }, {onlySelf: true, emitEvent: false});
      }
    });

    this.reportsService.makeFormDirty(this.addlinkReportForm, this.parent);
  }

  ngAfterViewChecked() {
    const elementRefOfSource = this.el.nativeElement.querySelectorAll('.p-multiselect-label')[0];
    this.sharedService.setMultiSelectDefaultLabel(elementRefOfSource, this.addlinkReportForm, 'source', this.selectedOptionsOfSource.length);
    const elementRefOfStatus = this.el.nativeElement.querySelectorAll('.p-multiselect-label')[1];
    this.sharedService.setMultiSelectDefaultLabel(elementRefOfStatus, this.addlinkReportForm, 'status', this.selectedOptionsOfStatus.length);
  }

  calendarValidation(event) {
    this.parent.calendarValidation(event);
  }

  throwDateError(event) {
    this.endDateLessThanStartDate = event;
    this.parent.throwDateError(event);
  }

  throwDateRangeError(event) {
    this.dateRangeConstarint = event;
    this.parent.throwDateRangeError(event);
  }

  setDate(event) {
    this.fromDate = event.fromDate;
    this.toDate = event.toDate;
    this.dateError = event.dateError;
  }

  cancelReport() {
    this.reportsService.cancelReport(this.addlinkReportForm, this.parent);
  }

  runReports() {
    const localUpdatedFormValues = this.addlinkReportForm.getRawValue();
    const isSourceAll = this.sourceDropdownData.every((val, index) => val.value === localUpdatedFormValues.source[index]);
    const isStatusAll = this.statusDropdownData.every((val, index) => val.value === localUpdatedFormValues.status[index]);
    const localInput = {
      endDate: encodeURIComponent(this.reportsService.formatDate(this.toDate)),
      startDate: encodeURIComponent(this.reportsService.formatDate(this.fromDate)),
      submitterType: localUpdatedFormValues.submitterType ? localUpdatedFormValues.submitterType.value : '',
      source: localUpdatedFormValues.source && localUpdatedFormValues.source.length > 0 ? (isSourceAll && (this.businessUnit === 'DBS' || this.businessUnit === 'SPS') ?
      localUpdatedFormValues.source = [this.getShellConstant.allClientInfo.clientName] : localUpdatedFormValues.source) : [this.getShellConstant.allClientInfo.clientName],
      status: localUpdatedFormValues.status && localUpdatedFormValues.status.length > 0 ? (isStatusAll ? localUpdatedFormValues.status = [this.getShellConstant.allClientInfo.clientName] : localUpdatedFormValues.status) : [this.getShellConstant.allClientInfo.clientName],
      clientName: this.reportsService.clientName !== this.getShellConstant.allClientInfo.clientName ? this.reportsService.clientName : '',
      clientId: this.reportsService.clientId ? this.reportsService.clientId : '',
      orgId: this.getOrgId ? this.getOrgId : ''
    };

    this.reportsService.executeReports(this.parent, localInput, this.reportsService.selectedReport, this.getShellConstant.reports.ADDLinkUsage.name, this.getShellConstant.reports.presetReports.reportNames.addlinkUsageTxt,
      this.getShellConstant.reports.presetReports.reportNames.addlinkUsageReportName, this.appService, '');
  }

  ngOnDestroy() {
    if (this.clientInfoSubscribe) {
      this.clientInfoSubscribe.unsubscribe();
    }
  }
}
