import {Component, Injector, OnDestroy, OnInit} from '@angular/core';
import {ReportsService} from '@core_services/report/reports.service';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {AccountNumberValidationService, SharedConstant, SharedService} from '@ems/shared';
import {ShellConstant} from '@shell_components/constants/shellConstants';
import {commonConstants} from '@shell_components/constants/commonConstants';
import {PresetReportsComponent} from '@shell_components/reports/preset-reports/preset-reports.component';
import {AppService} from '@core_services/app.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-rule-audit',
  templateUrl: './rule-audit.component.html'
})
export class RuleAuditComponent implements OnInit, OnDestroy {
  ruleAuditReportForm: UntypedFormGroup;
  parent: PresetReportsComponent;
  getShellConstant = ShellConstant;
  sharedConstant = SharedConstant;
  getCommConst = commonConstants;
  public getOrgId: any;
  public businessUnit: any;
  public reportName: any;
  fromDate: any;
  toDate: any;
  dateError: any;
  endDateLessThanStartDate = '';
  dateRangeConstarint = '';
  k4ClientName = '';
  clientInfoSubscribe: Subscription;

  constructor(private fb: UntypedFormBuilder, public sharedService: SharedService, public reportsService: ReportsService, private injector: Injector, private appService: AppService,
              private acctNumValidationService: AccountNumberValidationService) {
    this.reportName = this.reportsService.getReportName();
    this.parent = this.injector.get(PresetReportsComponent);
  }

  ngOnInit() {
    this.ruleAuditReportForm = this.fb.group({
      dateTo: this.fb.group({
        date: ['', Validators.required],
      }),
      dateFrom: this.fb.group({
        date: ['']
      }),
      masterAccountNumber: ''
    });

    this.clientInfoSubscribe = this.sharedService.clientInformation.subscribe(clientInfo => {
      if (clientInfo) {
        this.businessUnit = clientInfo.businessUnit ? clientInfo.businessUnit : '';
        this.getOrgId = clientInfo.orgId ? clientInfo.orgId : 0;
        this.k4ClientName = clientInfo.k4ClientName ? clientInfo.k4ClientName : '';
        this.disableMasterAccount();
      }
    });
    this.reportsService.makeFormDirty(this.ruleAuditReportForm, this.parent);
    this.onChanges();
  }

  disableMasterAccount() {
    if (this.getOrgId && this.getOrgId !== 0) {
      /* Slicing the master account number from the K4ClientName to display the selected client master account value in the Master Account input field */
      const masterAcctVal = this.k4ClientName && this.k4ClientName.slice(0, 8);
      this.ruleAuditReportForm.get('masterAccountNumber').setValue(masterAcctVal);
      this.ruleAuditReportForm.get('masterAccountNumber').disable();
    }
  }

  calendarValidation(event) {
    this.parent.calendarValidation(event);
  }

  throwDateError(event) {
    this.endDateLessThanStartDate = event;
    this.parent.throwDateError(event);
  }

  setDate(event) {
    this.fromDate = event.fromDate;
    this.toDate = event.toDate;
    this.dateError = event.dateError;
  }

  throwDateRangeError(event) {
    this.dateRangeConstarint = event;
    this.parent.throwDateRangeError(event);
  }

  cancelReport() {
    this.reportsService.cancelReport(this.ruleAuditReportForm, this.parent);
  }

  runReports() {
    const localUpdatedFormValues = this.ruleAuditReportForm.getRawValue();
    const orgId = [];
    orgId.push(this.getOrgId);
    const localInput = {
      masterAccountNumber: this.ruleAuditReportForm.get('masterAccountNumber').value ? this.ruleAuditReportForm.get('masterAccountNumber').value.trim() : '',
      endDate: encodeURIComponent(this.reportsService.formatDate(this.toDate)),
      startDate: encodeURIComponent(this.reportsService.formatDate(this.fromDate)),
      clientName: this.reportsService.clientName !== this.getShellConstant.allClientInfo.clientName ? this.reportsService.clientName : '',
      orgIds: this.getOrgId ? orgId : '',
    };
    this.reportsService.executeReports(this.parent, localInput, this.reportsService.selectedReport, this.getShellConstant.reports.RuleAudit.name, '',
      this.getShellConstant.reports.presetReports.reportNames.ruleAuditReportName, this.appService, '');

  }

  onChanges() {
    this.reportsService.formCharLimitErrorMessage(this.ruleAuditReportForm, this.parent, 'masterAccountNumber', 8, this.getShellConstant.manageClients.clientDetailsPage.errorMsg.masterAcctNumShouldContain8Digits);
  }

  ngOnDestroy() {
    if (this.clientInfoSubscribe) {
      this.clientInfoSubscribe.unsubscribe();
    }
  }
}
