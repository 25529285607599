import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {SharedConstant} from '../../constants/sharedConstants';
import {SharedService} from '../../shared.service';

@Component({
  selector: 'ems-table-record-count',
  templateUrl: './table-record-count.component.html',
  styleUrls: ['./table-record-count.component.scss']
})
export class TableRecordCountComponent implements OnInit {
  @Input() count: any;
  @Input() selectedCount: any;
  @Output() emitter: EventEmitter<any> = new EventEmitter<any>();
  @Input() empIds: any;
  @Input() showHeaderIcons: boolean;
  @Input() viewHistory: boolean;
  @Input() showTotal: any;
  @Input() showTotalForInstrumentGrps: any;
  @Input() groupTotal: any;
  @Input() storedVal: any;
  @Input() showRecords = true;
  @Input() selectedDropDownHistoryVal: any;

  selectedDropDownVal: any = 15;
  dataList: any = [];
  sharedConst = SharedConstant;
  businessUnit: any;
  constructor(private sharedService: SharedService) {
    this.sharedService.tableDropdownVal.subscribe(data => {
      this.selectedDropDownVal = data ? data : 15;
    });

    this.sharedService.viewHistorytableDropdownVal.subscribe(data => {
      this.selectedDropDownHistoryVal = data ? data : 15;
    });

    this.sharedService.selectedClientInfo.subscribe(clientInfo => {
      if (clientInfo && clientInfo.businessUnit) {
        this.businessUnit = clientInfo.businessUnit;
      }
    });
  }

  ngOnInit() {
    this.dataList = this.sharedConst.showRecordsCount;
  }

  noOfRecordsToDisplay(event) {
    this.sharedService.clearErrorMessage();
    this.emitter.emit(event);
  }
}
