import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {ManageEmpGrpService, SharedConstant, SharedService} from '@ems/shared';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {EmpGroupFormDataService} from '../emp-group-form-data/emp-group-form-data.service';
import {empAccntOrParticipantTableHeader} from '../../../models/magage-emp-grp-acc-table-header';
import {Observable, Observer, Subject} from 'rxjs';
import {Paginator} from 'primeng/paginator';
import {emtrConstant} from '../../../constants/emtrConstants';
import {EmtrService} from '../../../emtr.service';
import {EmtrPagination, EmtrPaginationValues} from '../../../models/pagination-model';

@Component({
  selector: 'tr-step3-review-and-save',
  templateUrl: './step3-review-and-save.component.html'
})
export class Step3ReviewAndSaveComponent implements OnInit {

  private draftRuleName: string;
  public draftRuleId: any;

  pageObject: EmtrPagination = EmtrPaginationValues;
  reviewEmpGrpRuleForm: UntypedFormGroup;
  getConstant = emtrConstant;
  getSharedConstant = SharedConstant;
  currentParentNode: any;
  verifyData: any;
  cols: any;
  revertDisable = true;
  message: string;
  groupNameExists = false;
  orgId: string;
  child = 0;
  parent = 0;
  toolTipMessage: any;
  reviewRuleGroupData: any;
  localObserver = new Subject<boolean>();
  showToast = false;
  cancelButton = true;
  employee: any;
  noOfRecords: any = 15;
  noOfRecordsPanel: any = 15;
  confirmMessage: any;
  showGroupNameCharLimit: boolean;
  showGroupDescCharLimit: boolean;
  errorMsg = [];
  empGroupNameExistsErrMsg = '';
  totalRecords: any;
  tableCountData: any = {
    empCount: 0, empSelected: 0,
    subAccCount: 0, subAccSelected: 0
  };
  param = {
    orgId: '',
    pageNumber: 1,
    pageSize: 1,
    sortField: ''
  };
  is10b5Client = false;
  planIDTxt = 'planId';
  empIds: any = [];
  subAccntNumbers: any = [];
  isEditMode = false;
  prevMessage: string;
  showInvalidError: string;
  showUpdatedData: any;
  showEmployeePanel = false;
  businessUnit: any;
  showActionItems = false;
  clientList = [];
  addedOrRemoveMasterAcctList = [];

  @ViewChild('confirmationMessage', { static: true }) confirmationMessage: any;
  @ViewChild('p') paginator: Paginator;

  constructor(private fb: UntypedFormBuilder, private router: Router, private r: ActivatedRoute, public emtrService: EmtrService,
              public formDataService: EmpGroupFormDataService, private elementRef: ElementRef, @Inject('entitlementConst') public entitlementConst,
              private manageEmpGrpService: ManageEmpGrpService, public sharedService: SharedService, @Inject('commonConstants') public commonConstants) {
    this.reviewRuleGroupData = this.formDataService.getFormData();
    this.sharedService.selectedClientInfo.subscribe(clientInfo => {
      this.businessUnit = this.sharedService.groupType === this.getSharedConstant.manageMasterGroups.groupTypeMGP ? this.getSharedConstant.manageMasterGroups.groupTypeMGP : clientInfo.businessUnit;
      this.sharedService.businessUnit = clientInfo.businessUnit;
      this.sharedService.tableDropdownVal.next(15);
    });
  }

  ngOnInit() {
    console.log(this.reviewRuleGroupData, 'this.reviewRuleGroupData hello 88 review check');
    
    /* eslint-disable-next-line max-len */
    this.showActionItems = this.sharedService.groupType !== this.getSharedConstant.manageMasterGroups.groupTypeMGP ? (this.sharedService.checkEntitlements(this.entitlementConst.navUUID.addLinkAppAccess.manageEmpGroup.uuid, '', '') && !this.sharedService.deletedGrpOrPlan) : (this.sharedService.checkEntitlements(this.entitlementConst.navUUID.emtrAccess.manageMasterAccountGroup.uuid, '', '') && !this.sharedService.deletedMastGrp);
    this.pageObject.pageNo = 0;
    this.pageObject.pageSize = 15;
    this.cols = empAccntOrParticipantTableHeader[this.businessUnit];
    if (this.sharedService.groupType === this.getSharedConstant.manageMasterGroups.groupTypeMGP) {
      this.reviewRuleGroupData.orgId = 0;
      this.reviewRuleGroupData.employeeGroupAssignInfo.orgId = 0;
      this.reviewRuleGroupData.type = this.getSharedConstant.manageMasterGroups.groupTypeMGP;
    }
    if (this.reviewRuleGroupData.mode === 'EDIT') {
      this.isEditMode = true;
      this.showEmployeeSection();
      this.populateSelectedEmployeeData();
    } else {
      this.showEmployeePanel = true;
      this.isEditMode = false;
      this.getSelectedCount(this.reviewRuleGroupData.employeeGroupAssignInfo.employeeAccounts);
      this.modifyResponse();
    }
    this.reviewEmpGrpRuleForm = this.fb.group({
      groupName: ['', {validators: [Validators.required]}],
      description: '',
      enable10b5Checkbox: [this.isCheckboxEnabled()],
      planId: [this.reviewRuleGroupData.planId]
    });
    if (this.reviewEmpGrpRuleForm.get('enable10b5Checkbox').value) {
      this.validate10b5();
    }
    this.draftRuleName = this.reviewRuleGroupData.name;
    this.draftRuleId = this.reviewRuleGroupData.id;
    this.orgId = this.reviewRuleGroupData.orgId;
    this.toolTipMessage = this.getConstant.ruleSteps.step4.descTooltip;
    this.populateData();
    this.onChanges();
    if (!this.showActionItems) {
      this.reviewEmpGrpRuleForm.get('groupName').disable();
      this.reviewEmpGrpRuleForm.get('description').disable();
    }
    this.confirmMessage = this.confirmationMessage.confirmMessage;
    this.confirmMessage.message = this.getConstant.rulesConstant.confirmationMessage.menuClickMsg;
    this.is10b5Client = this.sharedService.get10b5ClientDetails();
  }

  showEmployeeSection() {
    const localFormData = this.reviewRuleGroupData.employeeGroupAssignInfo;
    if (Object.keys(localFormData).length !== 0 && ((localFormData.newSelectedRecords !== undefined && localFormData.newSelectedRecords.length > 0) ||
      (localFormData.removedExistingSelected !== undefined && localFormData.removedExistingSelected.length > 0) ||
      (localFormData.subAccntNewlySelected !== undefined && localFormData.subAccntNewlySelected.length > 0))) {
      this.showEmployeePanel = true;
    } else {
      this.showEmployeePanel = false;
    }
  }

  populateSelectedEmployeeData() {
    this.pageObject.sortField = ['empId,desc'];
    this.sharedService.getEmployeeList(this.pageObject, this.param.orgId, this.reviewRuleGroupData.id, null).subscribe((empGrp: any) => {
      const res = empGrp.data;
      this.totalRecords = res.totalElements;
      const formData = this.reviewRuleGroupData;
      let empResponse = res.empAccounts ? res.empAccounts : res.content;
      if((this.reviewRuleGroupData.employeeGroupAssignInfo.newSelectedRecords && this.reviewRuleGroupData.employeeGroupAssignInfo.newSelectedRecords.length) || 
      (this.reviewRuleGroupData.employeeGroupAssignInfo.removedExistingSelected && this.reviewRuleGroupData.employeeGroupAssignInfo.removedExistingSelected.length)) {
        empResponse = this.reviewRuleGroupData.employeeGroupAssignInfo.employeeAccounts;
      }
      formData.employeeGroupAssignInfo = {
        employeeAccounts: empResponse,
        empIds: [],
        subAccntNumbers: [],
        newSelectedRecords: this.reviewRuleGroupData.employeeGroupAssignInfo.newSelectedRecords,
        removedExistingSelected: this.reviewRuleGroupData.employeeGroupAssignInfo.removedExistingSelected,
        step3EditSaved: this.reviewRuleGroupData.employeeGroupAssignInfo.step3EditSaved,
        assignEmpIds: this.reviewRuleGroupData.employeeGroupAssignInfo.assignEmpIds,
        assignSubAcctNumbers: this.reviewRuleGroupData.employeeGroupAssignInfo.assignSubAcctNumbers,
        unAssignEmpIds: this.reviewRuleGroupData.employeeGroupAssignInfo.unAssignEmpIds,
        unAssignSubAcctNumbers: this.reviewRuleGroupData.employeeGroupAssignInfo.unAssignSubAcctNumbers,
        empSelected: this.reviewRuleGroupData.employeeGroupAssignInfo.empSelected,
        subAccSelected: this.reviewRuleGroupData.employeeGroupAssignInfo.subAccSelected,
        subAccntNewlySelected: this.reviewRuleGroupData.employeeGroupAssignInfo.subAccntNewlySelected,
        removedExistingEmpId: this.reviewRuleGroupData.employeeGroupAssignInfo.removedExistingEmpId,
      };
      this.formDataService.setFormData(formData);
      this.getSelectedCount(this.reviewRuleGroupData.employeeGroupAssignInfo.employeeAccounts);
      this.modifyResponse();
    });
  }

  populateData() {
    this.employee = this.reviewRuleGroupData.employeeGroupAssignInfo.employeeAccounts;
    this.revertDisable = true;
    this.reviewEmpGrpRuleForm.patchValue({
      groupName: this.reviewRuleGroupData.name,
      description: this.reviewRuleGroupData.description
    });
  }

  editGroupNameAndDescription() {
    this.revertDisable = false;
  }

  canDeactivate(event, observer) {
    this.confirmMessage.message = (this.confirmMessage.message !== undefined && this.confirmMessage.message) ? this.confirmMessage.message : this.getConstant.rulesConstant.confirmationMessage.menuClickMsg;
    if (this.cancelButton && this.reviewEmpGrpRuleForm.dirty && !this.revertDisable) {
      this.localObserver = observer;
      return this.confirmationMessage.showConfirmMsg(this.confirmMessage, observer);
    }
    return true;
  }

  navigateTo(path) {
    this.router.navigate([path], {relativeTo: this.r});
  }

  paramObj(pageNo, records, sortCol) {
    return this.param = {
      orgId: this.orgId,
      pageNumber: pageNo,
      pageSize: records,
      sortField: sortCol
    };
  }

  saveEmpGroup(inputData) {
    this.cancelButton = false;
    const reviewDataCopy: any = {...this.reviewRuleGroupData};
    delete reviewDataCopy.employeeGroupAssignInfo.employeeAccounts;
    this.message = '<strong>' + this.reviewEmpGrpRuleForm.get('groupName').value.trim() + ' - ' + '</strong>';
    this.getFormValues();
    if (inputData) {
      reviewDataCopy.employeeGroupAssignInfo.subAccNumbers = reviewDataCopy.employeeGroupAssignInfo.subAccntNumbers;
      reviewDataCopy.name = this.reviewEmpGrpRuleForm.get('groupName').value.trim();
      reviewDataCopy.description = this.reviewEmpGrpRuleForm.get('description').value;
      if (this.is10b5Client) {
        reviewDataCopy.planId = this.reviewEmpGrpRuleForm.get('enable10b5Checkbox').value ? this.reviewEmpGrpRuleForm.get('planId').value : '';
        reviewDataCopy.subType = reviewDataCopy.planId ? '10b5-1' : '';
      }
      if (reviewDataCopy.mode === 'EDIT') {
        delete reviewDataCopy.employeeGroupAssignInfo.subAccntNumbers;
        delete reviewDataCopy.clear;
        delete reviewDataCopy.setData;
        delete reviewDataCopy.employeeGroupAssignInfo.newSelectedRecords;
        delete reviewDataCopy.employeeGroupAssignInfo.removedExistingSelected;
        delete reviewDataCopy.employeeGroupAssignInfo.step3EditSaved;
        delete reviewDataCopy.employeeGroupAssignInfo.employeeAccounts;
        delete reviewDataCopy.employeeGroupAssignInfo.empIds;
        delete reviewDataCopy.employeeGroupAssignInfo.subAccNumbers;
        delete reviewDataCopy.employeeGroupAssignInfo.removedExistingEmpId;
        delete reviewDataCopy.employeeGroupAssignInfo.subAccntNewlySelected;
        delete reviewDataCopy.employeeGroupAssignInfo.empSelected;
        delete reviewDataCopy.employeeGroupAssignInfo.subAccSelected;
        delete reviewDataCopy.selectedEmpCount;
        reviewDataCopy.employeeGroupAssignInfo.orgId = reviewDataCopy.orgId;
        reviewDataCopy.employeeGroupAssignInfo.id = reviewDataCopy.id;
        const pageNo = 0;
        const sortField = '';
        if (this.sharedService.groupType === this.getSharedConstant.manageMasterGroups.groupTypeMGP && reviewDataCopy.employeeGroupAssignInfo.assignSubAcctNumbers && reviewDataCopy.employeeGroupAssignInfo.assignSubAcctNumbers.length !== 0) {
          reviewDataCopy.employeeGroupAssignInfo.assignSubAcctNumbers = reviewDataCopy.employeeGroupAssignInfo.assignSubAcctNumbers.filter((el, i, a) => i === a.indexOf(a.find(f => f.subAcctNum === el.subAcctNum)));
        }
        this.saveEmpGroupData(reviewDataCopy);
      } else {
        reviewDataCopy.employeeGroupAssignInfo.assignEmpIds = reviewDataCopy.employeeGroupAssignInfo.empIds;
        reviewDataCopy.employeeGroupAssignInfo.assignSubAcctNumbers = reviewDataCopy.employeeGroupAssignInfo.subAccntNumbers;
        delete reviewDataCopy.employeeGroupAssignInfo.empIds;
        delete reviewDataCopy.employeeGroupAssignInfo.subAccntNumbers;
        delete reviewDataCopy.employeeGroupAssignInfo.subAccNumbers;
        delete reviewDataCopy.employeeGroupAssignInfo.selectedEmpCount;
        this.saveEmpGroupData(reviewDataCopy);
      }
    }
  }

  checkEmployeeGroupAssignInfo(reviewDataCopy) {
    const localAssignInfo = reviewDataCopy.employeeGroupAssignInfo;
    if (localAssignInfo.assignEmpIds === undefined && localAssignInfo.assignSubAcctNumbers === undefined && localAssignInfo.unAssignEmpIds === undefined &&
      localAssignInfo.unAssignSubAcctNumbers === undefined) {
      /* Removing employeeGroupAssignInfo */
      delete reviewDataCopy.employeeGroupAssignInfo;
    }
  }

  saveEmpGroupData(reviewDataCopy) {
    console.log(reviewDataCopy, 'reviewDataCopy 268')
    // return; 
    this.checkEmployeeGroupAssignInfo(reviewDataCopy);
    this.manageEmpGrpService.saveEmpGroup(reviewDataCopy).subscribe(res => {
      this.manageEmpGrpService.sendMessage(this.message, 'save');
      this.navigateTo(this.getSharedConstant[this.businessUnit].toLandingPage);
    });
  }

  cancelGroup() {
    this.router.navigate([this.getSharedConstant[this.businessUnit].toLandingPage]);
    this.confirmMessage.message = this.getConstant.rulesConstant.confirmationMessage.cancelBtnClickMsg;
    this.cancelButton = true;
  }

  getFormValues() {
    this.reviewRuleGroupData.name = this.reviewEmpGrpRuleForm.get('groupName').value.trim();
    this.reviewRuleGroupData.description = this.reviewEmpGrpRuleForm.get('description').value;
  }

  saveGroup() {
    this.cancelButton = false;
    if (this.is10b5Client) {
      this.validate10b5PlanId();
    } else {
      this.checkWhetherEmpGroupNameExists();
    }
  }

  receiveDataFromChildPanel(e) {
    this.noOfRecordsPanel = e;
    if (this.sharedService.groupType === this.getSharedConstant.manageMasterGroups.groupTypeMGP) {
      this.addedOrRemoveMasterAcctList = [...this.addedOrRemoveMasterAcctList];
    } else {
      this.showUpdatedData = [...this.showUpdatedData];
    }
  }

  receiveDataFromChild(e) {
    if (this.isEditMode) {
      this.pageObject.pageNo = 0;
      this.pageObject.pageSize = Number(e);
      this.paginator.changePage(0);
      this.populateSelectedEmployeeData();
    } else {
      this.noOfRecords = e;
      if (this.sharedService.groupType === this.getSharedConstant.manageMasterGroups.groupTypeMGP) {
        this.addedOrRemoveMasterAcctList = [...this.addedOrRemoveMasterAcctList];
      } else {
        this.verifyData = [...this.verifyData];
      }
    }
  }

  isParentNode(rowNode) {
    if (!rowNode.level) {
      this.currentParentNode = rowNode;
      return false;
    }
    return true;
  }

  removeAlreadyRemoved(newSelectedRecords, removedExistingSelected) {
    this.handleSubAccntLevel(newSelectedRecords, removedExistingSelected);
    const localNewlySelectedRecords = [...newSelectedRecords];
    let filteredData;
    const finalArr = [];
    if ((localNewlySelectedRecords && localNewlySelectedRecords.length > 0) && (removedExistingSelected && removedExistingSelected.length > 0)) {
      // localNewlySelectedRecords.forEach((newlySelected) => {
        // removedExistingSelected.forEach((removed) => {
          // if (removed.parent && newlySelected.empId === removed.empId) {
            // const localChildren = [...localNewlySelectedRecords];
            // const indexes = localNewlySelectedRecords.findIndex(x => x.empId === removed.empId);
            // if (indexes !== -1) {
            //   localNewlySelectedRecords.splice(indexes, 1);
            // }
            // localChildren.forEach((local) => {
            //   if (local) {
            //     filteredData = local.filter((filtered) => {
            //       return !removedExistingSelected.find((employee) => {
            //         return employee.data.empId === filtered.data.empId;
            //       });
            //     });
            //   }
            //   if (filteredData && filteredData.length > 0) {
            //     filteredData.forEach((data) => {
            //       finalArr.push(data);
            //     });
            //   }
            // });
            // newlySelected.children.forEach((childrens) => {
            //   const index = removedExistingSelected.findIndex(x => x.data.empId === childrens.data.empId);
            //   if (index !== -1) {
            //     removedExistingSelected.splice(index, 1);
            //   }
            // });
          // }
        // });
      // });
    }
    let newEmpAccData = [...localNewlySelectedRecords, ...removedExistingSelected, ...finalArr];
    
    if (this.sharedService.groupType !== this.getSharedConstant.manageMasterGroups.groupTypeMGP) {
      newEmpAccData = newEmpAccData.filter((v, i, a) => a.findIndex(t => (t.empId === v.empId && t.subAcctNum === v.subAcctNum)) === i);
    console.log(newEmpAccData, 'newEmpAccData 374');
    this.processResponse(newEmpAccData, 'local');
    } else {
    console.log(newEmpAccData, 'newEmpAccData 376');
    this.addedOrRemoveMasterAcctList = newEmpAccData;
    }
  }

  handleSubAccntLevel(newSelectedRecords, removedExistingSelected) {
    const localSubAccntLevelSelection = this.reviewRuleGroupData.employeeGroupAssignInfo.subAccntNewlySelected ? [...this.reviewRuleGroupData.employeeGroupAssignInfo.subAccntNewlySelected] : [];
    const removedExistingEmpId = this.reviewRuleGroupData.employeeGroupAssignInfo.removedExistingEmpId ? [...this.reviewRuleGroupData.employeeGroupAssignInfo.removedExistingEmpId] : [];

    if (removedExistingEmpId && removedExistingEmpId.length > 0) {
      let localArr;
      removedExistingEmpId.forEach((empLevel) => {
        if (empLevel.children && empLevel.children.length > 1) {
          localArr = empLevel.children.filter((removed) => {
            return !localSubAccntLevelSelection.find((employee) => {
              return employee.data.empId === removed.data.empId;
            });
          });
        }
      });

      if (localArr && localArr.length > 0) {
        localArr.forEach((removed) => {
          const index = removedExistingSelected.findIndex(x => x.data.empId === removed.data.empId);
          if (index === -1) {
            removedExistingSelected.push(removed);
          }
        });
      }
      if (removedExistingSelected && removedExistingSelected.length > 0) {
        removedExistingSelected.forEach((removed) => {
          removed.selectedEmployee = false;
        });
      }
    }
  }

  modifyResponse() {
    /* eslint-disable-next-line max-len */
    if (this.reviewRuleGroupData.employeeGroupAssignInfo.newSelectedRecords !== undefined || this.reviewRuleGroupData.employeeGroupAssignInfo.removedExistingSelected !== undefined) {
      this.removeAlreadyRemoved([...this.reviewRuleGroupData.employeeGroupAssignInfo.newSelectedRecords], [...this.reviewRuleGroupData.employeeGroupAssignInfo.removedExistingSelected]);
    }

    let empAccData = [];    
    if (this.reviewRuleGroupData.mode && !this.reviewRuleGroupData.employeeGroupAssignInfo.subAccntNumbers.length && !this.reviewRuleGroupData.employeeGroupAssignInfo.empIds.length) {
      // empAccData = this.formatEmployeeJson(JSON.parse(JSON.stringify(this.reviewRuleGroupData.employeeGroupAssignInfo.employeeAccounts)), '');
      empAccData = [...this.reviewRuleGroupData.employeeGroupAssignInfo.employeeAccounts];
      if (this.sharedService.groupType === this.getSharedConstant.manageMasterGroups.groupTypeMGP) {
        this.clientList = [...this.reviewRuleGroupData.employeeGroupAssignInfo.employeeAccounts];
      } else {
        this.processResponse(empAccData, '');
      }
    } else {

      empAccData = [...this.reviewRuleGroupData.employeeGroupAssignInfo.employeeAccounts];
      if (this.sharedService.groupType === this.getSharedConstant.manageMasterGroups.groupTypeMGP) {
        this.addedOrRemoveMasterAcctList = [...this.reviewRuleGroupData.employeeGroupAssignInfo.employeeAccounts];
      } else {
        this.processResponse(empAccData, 'local');
      }
    }
  }

  processResponse(empAccData, type) {

    // empAccData.forEach((key) => {
    //   if (key) {
    //     key.forEach((record) => {
    //       empAccData = empAccData.filter(item => item.empId !== record.empId);
    //       return empAccData;
    //     });
    //   }
    // });
    // if (empAccData) {
    //   empAccData = empAccData.map((record) => {
    //     if (record && record) {
    //       const recData = {
    //         data: record,
    //         children: [record],
    //         expanded: true
    //       };
    //       record = recData;
    //     }
    //     return record;
    //   });
    //   const parentEmpIds = empAccData.map(d => d.empId);
    //   empAccData = empAccData.filter((record, i) => {
    //     const empIndex = parentEmpIds.indexOf(record.empId);
    //     if (empIndex !== i) {
    //       empAccData[empIndex].children = empAccData[empIndex].children.concat(record);
    //       return false;
    //     }
    //     return record;
    //   });
    // }
    if (type) {
      this.showUpdatedData = empAccData;
    } else {
      this.verifyData = empAccData;
    }
  }

  formatEmployeeJson(data, type) {
    // return data.map((dataEle, i) => {
    //   if (dataEle !== null && dataEle !== undefined) {
    //     const children = dataEle.accounts;
    //     delete dataEle.accounts;
    //     if (type) {
    //       dataEle.empId = dataEle.empId + dataEle.subAcctNum;
    //     } else {
    //       if (dataEle.empId === null) {
    //         dataEle.empId = i + 'null';
    //       }
    //     }
    //     const obj = {
    //       data: dataEle
    //     };
    //     if (children) {
    //       /* eslint-disable @typescript-eslint/dot-notation */
    //       obj['children'] = this.formatEmployeeJson(children, 'children');
    //       /* eslint-disable @typescript-eslint/dot-notation */
    //       obj['expanded'] = true;
    //     }
    //     return obj;
    //   }
    // });
  }

  revertChanges() {
    this.reviewEmpGrpRuleForm.get('groupName').setValue(this.reviewRuleGroupData.name);
    this.reviewEmpGrpRuleForm.get('description').setValue(this.reviewRuleGroupData.description);
    if (!this.isEditMode) {
      this.reviewEmpGrpRuleForm.get('planId').setValue(this.reviewRuleGroupData.planId);
      this.reviewEmpGrpRuleForm.get('enable10b5Checkbox').setValue(this.isCheckboxEnabled());
    }
    this.revertDisable = true;
  }

  isCheckboxEnabled() {
    let enable10b5Chkbox;
    if (this.reviewRuleGroupData.planId) {
      enable10b5Chkbox = true;
    } else {
      enable10b5Chkbox = false;
    }
    return enable10b5Chkbox;
  }

  onChanges(): void {
    this.reviewEmpGrpRuleForm.get('groupName').valueChanges.subscribe((groupNameValue) => {
      this.groupNameExists = false;

      const regExp = new RegExp('^[a-zA-Z0-9+_()\[\\],.\\- ]*$');
      if (groupNameValue && !regExp.test(groupNameValue) && this.businessUnit !== 'RBS' && this.businessUnit !== this.getSharedConstant.manageMasterGroups.groupTypeMGP) {
        if (this.errorMsg.indexOf(this.getSharedConstant.textFieldErrorMsg.invalidCharacterForGrpOrRuleName) === -1) {
          this.errorMsg.push(this.getSharedConstant.textFieldErrorMsg.invalidCharacterForGrpOrRuleName);
        }
        this.emtrService.showSchError.emit(true);
      } else {
        if (this.errorMsg.indexOf(this.getSharedConstant.textFieldErrorMsg.invalidCharacterForGrpOrRuleName) !== -1) {
          this.errorMsg.splice(this.errorMsg.indexOf(this.getSharedConstant.textFieldErrorMsg.invalidCharacterForGrpOrRuleName), 1);
        }
      }

      /*To remove group name already exist error msg from the errorMsg array*/
      if (this.errorMsg.indexOf(this.empGroupNameExistsErrMsg) !== -1) {
        this.errorMsg.splice(this.errorMsg.indexOf(this.empGroupNameExistsErrMsg), 1);
      }

      /* Removing groupName existed error if new value is entered */
      if ((this.showInvalidError === 'emp-grp') && this.errorMsg.length > 0 && (this.errorMsg.indexOf(this.getConstant.manageEmployeeGroups.empGroup10b5Msg.empGrpExistTxt) !== -1) && this.is10b5Client && !this.reviewEmpGrpRuleForm.get('groupName').invalid) {
        this.errorMsg.splice(this.errorMsg.indexOf(this.getConstant.manageEmployeeGroups.empGroup10b5Msg.empGrpExistTxt), 1);
        this.showInvalidError = '';
      }

      /* Removing both(groupName & planId) existed error if new value is entered  in both fields*/
      if ((this.showInvalidError === 'both') && this.errorMsg.indexOf(this.getConstant.manageEmployeeGroups.empGroup10b5Msg.bothNameAndPlanId) !== -1 && this.is10b5Client && !this.reviewEmpGrpRuleForm.get('planId').invalid && !this.reviewEmpGrpRuleForm.get('groupName').invalid) {
        this.errorMsg.splice(this.errorMsg.indexOf(this.getConstant.manageEmployeeGroups.empGroup10b5Msg.bothNameAndPlanId), 1);
        this.showInvalidError = '';
      }

      /*Hide the error block when errorMsg is empty*/
      if (this.errorMsg.length === 0) {
        this.emtrService.showSchError.emit(false);
      }

      this.emtrService.schErrorMsg.emit(this.errorMsg);
    });
    this.reviewEmpGrpRuleForm.get('planId').valueChanges.subscribe((value) => {
      if (value && this.reviewEmpGrpRuleForm.get('planId').invalid && this.errorMsg.indexOf(this.getSharedConstant.textFieldErrorMsg.invalidNumericCharacterEntered) === -1) {
        this.errorMsg.push(this.getSharedConstant.textFieldErrorMsg.invalidNumericCharacterEntered);
      } else {
        if ((value === '' && this.showInvalidError !== 'emp-grp') || (this.errorMsg.indexOf(this.getSharedConstant.textFieldErrorMsg.invalidNumericCharacterEntered) !== -1 && !this.reviewEmpGrpRuleForm.get('planId').invalid)) {
          this.errorMsg.splice(this.errorMsg.indexOf(this.getSharedConstant.textFieldErrorMsg.invalidNumericCharacterEntered), 1);
          if (this.showInvalidError === 'planId' || this.showInvalidError === 'both') {
            const planIdTxt = 'planId';
            this.reviewEmpGrpRuleForm.controls[planIdTxt].setErrors({incorrect: false});
          }
        }
      }

      /* Removing groupName existed error if new value is entered */
      if ((this.showInvalidError === 'planId') && this.errorMsg.length > 0 && this.errorMsg.indexOf(this.getConstant.manageEmployeeGroups.empGroup10b5Msg.planIdExitsTxt) !== -1 && this.is10b5Client && !this.reviewEmpGrpRuleForm.get('planId').invalid) {
        this.errorMsg.splice(this.errorMsg.indexOf(this.getConstant.manageEmployeeGroups.empGroup10b5Msg.planIdExitsTxt), 1);
        this.showInvalidError = '';
      }

      /* Removing both(groupName & planId) existed error if new value is entered  in both field */
      if ((this.showInvalidError === 'both') && this.errorMsg.indexOf(this.getConstant.manageEmployeeGroups.empGroup10b5Msg.bothNameAndPlanId) !== -1 && this.is10b5Client && !this.reviewEmpGrpRuleForm.get('groupName').invalid && !this.reviewEmpGrpRuleForm.get('planId').invalid) {
        this.errorMsg.splice(this.errorMsg.indexOf(this.getConstant.manageEmployeeGroups.empGroup10b5Msg.bothNameAndPlanId), 1);
        this.showInvalidError = '';
      }

      this.emtrService.schErrorMsg.emit(this.errorMsg);
      if (this.errorMsg.length === 0) {
        this.emtrService.showSchError.emit(false);
      } else {
        this.emtrService.showSchError.emit(true);
      }
    });
  }

  checkWhetherEmpGroupNameExists() {
    if (this.reviewRuleGroupData.mode === 'EDIT' && this.reviewEmpGrpRuleForm.get('groupName').value.trim() === this.reviewRuleGroupData.currentEditedName) {
      this.saveEmpGroup('save');
    } else {
      const type = this.sharedService.groupType === this.getSharedConstant.manageMasterGroups.groupTypeMGP ? this.sharedService.groupType : '';
      const planIdValue = '';
      // const planIdValue = (this.reviewEmpGrpRuleForm.get('planId').value) ? this.reviewEmpGrpRuleForm.get('planId').value : '';
      this.manageEmpGrpService.checkEmployeeGroupNameExists(this.reviewEmpGrpRuleForm.get('groupName').value.trim(), this.orgId, planIdValue, type).subscribe(res => {
        this.sharedService.decodeUrl = true;
        if (res && res.data !== null) {
          /* eslint-disable-next-line max-len */
          this.empGroupNameExistsErrMsg = this.getConstant.rulesConstant.ruleNameMsg.the + '<strong>' + ' "' + this.reviewEmpGrpRuleForm.get('groupName').value.trim() + '" ' + '</strong>' + this.sharedService.validateNamePlanIdResponse(res, this.is10b5Client, this.reviewEmpGrpRuleForm.get('groupName').value.trim(), planIdValue, this.getConstant, 'EmpGrp');
          this.groupNameExists = true;
          this.cancelButton = false;

          /*To show group name already exist error msg*/
          if (this.errorMsg.indexOf(this.empGroupNameExistsErrMsg) === -1) {
            this.errorMsg.push(this.empGroupNameExistsErrMsg);
          }
          this.emtrService.schErrorMsg.emit(this.errorMsg);
          this.emtrService.showSchError.emit(true);

        } else {
          this.saveEmpGroup('save');
        }
      });
    }
  }

  validate10b5PlanId() {
    let planId;
    if (this.isEditMode) {
      planId = '';
    } else {
      planId = this.reviewEmpGrpRuleForm.get('planId').value;
    }
    if (this.reviewRuleGroupData.mode === 'EDIT' && this.reviewEmpGrpRuleForm.get('groupName').value.trim() === this.reviewRuleGroupData.currentEditedName) {
      this.saveEmpGroup('save');
    } else {
      this.manageEmpGrpService.validatePlanId(this.manageEmpGrpService.orgId, this.reviewEmpGrpRuleForm.get('groupName').value.trim(), planId).subscribe(res => {
        this.sharedService.decodeUrl = true;
        /* eslint-disable-next-line max-len */
        const validate10b5 = this.emtrService.nameAndPlanIdValidation(this.prevMessage, this.sharedService.setResponseMessage(res, this.is10b5Client, this.reviewEmpGrpRuleForm.get('groupName').value.trim(), planId, this.getConstant, 'EmpGrp'), this.errorMsg, this.reviewEmpGrpRuleForm, this.getConstant);
        this.showInvalidError = validate10b5;
        if (validate10b5 === 'emp-grp') {
          this.empGroupNameExistsErrMsg = this.getConstant.rulesConstant.ruleNameMsg.the + '<strong>' + ' "' + this.reviewEmpGrpRuleForm.get('groupName').value.trim() + '" ' + '</strong>' + this.getConstant.manageEmployeeGroups.empGroup10b5Msg.empGrpExistTxt;
        }
        if (validate10b5 === '') {
          this.saveEmpGroup('save');
        }
      });
    }
  }

  getSelectedCount(selectedArr) {
    this.parent = 0;
    this.child = 0;
    selectedArr.forEach(data => {
      if (data.parent) {
        this.child++;
      } else {
        this.parent++;
      }
    });
    this.tableCountData = {
      empSelected: this.parent,
      subAccSelected: this.child,
      empCount: this.parent,
      subAccCount: this.child
    };
  }

  goToPreviousStep(path) {
    if (this.reviewEmpGrpRuleForm.dirty && !this.revertDisable) {
      this.confirmMessage.message = this.getConstant.rulesConstant.confirmationMessage.previousBtnClickMsg;
      Observable.create((observer: Observer<boolean>) => {
        this.confirmationMessage.showConfirmMsg(this.confirmMessage, observer);
      }).subscribe(accept => {
        if (accept) {
          this.errorMsg = [];
          this.emtrService.schErrorMsg.emit(this.errorMsg);
          this.emtrService.showSchError.emit(false);
          const formObj = {
            groupName: this.reviewEmpGrpRuleForm.get('groupName').value.trim(),
            description: this.reviewEmpGrpRuleForm.get('description').value,
            orgId: this.manageEmpGrpService.orgId,
          };
          /* Adding 'planId' in form only if selected client is a '10b5' Client */
          if (this.is10b5Client) {
            formObj[this.planIDTxt] = this.reviewEmpGrpRuleForm.get(this.planIDTxt).value;
          }
          this.cancelButton = false;
          this.formDataService.setStep1(formObj, true);
          this.navigateTo(path);
        }
      });
    } else {
      this.formDataService.setStep3('', false);
      this.navigateTo(path);
    }
  }

  /* If '10b5' checkbox is checked/unchecked adding planId Validators */
  validate10b5() {
    this.sharedService.set10b5Validators(this.reviewEmpGrpRuleForm, this.planIDTxt);
  }

  paginate(e) {
    if (this.pageObject.pageNo !== e.page) {
      this.pageObject.pageNo = e.page;
      this.populateSelectedEmployeeData();
    }
  }

  removeLeadingZeroes() {
    const value = this.reviewEmpGrpRuleForm.getRawValue().planId.replace(/^0+/, '');
    this.reviewEmpGrpRuleForm.get('planId').setValue(value);
    if (value === '0') {
      this.reviewEmpGrpRuleForm.get('planId').reset();
    }
  }
}
