<ems-confirm-message #confirmationMessage></ems-confirm-message>

<div class="heading">
  <span class="select-attributes" [innerHTML]="reviewEditRuleData.mode"></span>
  <tr-rule-name-type [ruleName]="reviewEditRuleData.name"
                     [ruleType]="rulesCategory"></tr-rule-name-type>
</div>

<div class="review-rules">
  <form [formGroup]="reviewEditRuleForm" *ngIf="reviewEditRuleForm">
    <div class="sdps-p-bottom_large sdps-p-top_large step-border-box select-rule-type ">
      <div class="sdps-grid-container sdps-wrap">
        <div class="sdps-row">
          <div class="rule-type-textbox sdps-p-around_medium col-6" [ngClass]="{'invalidField': (reviewEditRuleForm.get('reviewRuleName').value && reviewEditRuleForm.get('reviewRuleName').invalid)}">
            <span class="p-float-label">
              <input type="text" name="ruleName" [maxlength]="100" formControlName="reviewRuleName" value="reviewEditRuleForm.get('reviewRuleName').value"
              emsAlphaNumericSpecialCharacterValidation emsCharacterLimit
                     [charMaxLimit]="commonConstants.charLimitValues.hundredMaxLimit"
                     (emsCharacterLimitCheck)="showCharLimit = $event" pInputText/>
              <label>{{emtrConst.rulesConstant.ruleName}}
                <span class="sdps-text-market-red" [innerHTML]="commonConstants.asterix"></span>
              </label>

            </span>
          </div>
        </div>
        <p class="col-6 sdps-text-right sdps-p-around_none sdps-m-around_none sdps-text-small" *ngIf="reviewEditRuleForm.get('reviewRuleName').value.trim().length > showCharLimit">{{emtrConst.rulesConstant.characterLimit}}
          <span class="character-limit">{{reviewEditRuleForm.get('reviewRuleName').value.trim().length}} /{{commonConstants.charLimitValues.hundredMaxLimit}}
          </span>
        </p>
      </div>
    </div>

    <!-- Display selected Justification for Override -->
    <ems-accordion [label]="emtrConst.rulesConstant.ruleGlobalConst.justification" class="disabled-button-icon" *ngIf="reviewEditRuleData.mode === this.emtrConst.rulesConstant.ruleGlobalConst.override">
      <div class="emtr-radio sdps-grid-container sdps-p-left_xx-large sdps-m-around_none sdps-p-vertical_small select-rule-type">
        <div class="sdps-grid sdps-m-around_none sdps-p-left_none" *ngFor="let justification of sharedConstant[businessUnit].overrideJustification">
          <p-radioButton class="col-2 sdps-p-top_small radio-button" name="justification" [value]=justification [label]=justification
            formControlName="justification" (ngModelChange)="editRuleNameDescriptionNotes()"></p-radioButton>
        </div>
        <span class="p-float-label rule-type-textbox sdps-p-around_small col-4" [ngClass]="{'input-disabled': reviewEditRuleForm.value.justification !== 'Other'}">
            <input type="text" name="others" maxlength="35" formControlName="otherInput" pInputText #step4_otherInput/>
          </span>
      </div>
    </ems-accordion>

    <ems-accordion label='{{emtrConst.rulesConstant.selectDates}}'>
      <div class="p-g sdps-p-left_medium display-flex align-items-flex-start">
        <p-radioButton name="selectDate" [(ngModel)]="dateSelectionType" [ngModelOptions]="{standalone: true}" value='date' class="emtr-radio sdps-p-top_small">
        </p-radioButton>
        <tr-from-to-datepicker (calendarValidation)="calendarValidation($event)" [calendarForm]="reviewEditRuleForm.get('calendarForm')"
                                             (throwDateError)="displayErrorDate($event)"></tr-from-to-datepicker>
      </div>
    </ems-accordion>

    <ems-accordion label='{{emtrConst.rulesConstant.ruleAssign}}'>
      <div class="step-border-box sdps-p-around_large">
        <p-tabView *ngIf="!(reviewEditRuleData?.globalRule)" class="emtr-tab" [activeIndex]="activeIndex" (onChange)="onChangeTab($event)">
          <p-tabPanel [header]=sharedConstant[businessUnit].employeeGrpOrPlan [disabled]="shouldDisabledGroupsOrPlans" >
          <ems-shared-manage-employee-group [showSelection]="true" [empGrpformData]="reviewEditRuleData.employeeGroups"  (initialCount)='getInitialCount($event)'
                                              (rowSelected)="employeeRowSelected(true)" [tableCountData]="tableCountData"
                                              [employees]="employees" (paginateEmployeeList)="paginateEmployeeList($event)" (employeeGroups)="getDataEmpGrp($event)">
            </ems-shared-manage-employee-group>
          </p-tabPanel>
          <p-tabPanel [header]=sharedConstant[businessUnit].employeeOrParticipant [disabled]="shouldDisabledEmployeesOrParticipants">
            <ems-shared-manage-employee-accounts [getOrgId]='orgId' [assignFormData]='reviewEditRuleData' [empAccformData]="reviewEditRuleData.employeeAccounts"
                                                          [moduleType]='loadedModuleType'
                                                          (rowSelected)="employeeRowSelected(true)"
                                                          (modifiedSubAccounts)="getSubAccnts($event)"
                                                          (selectedEmployeeAccounts)="getDataEmpAcc($event)"></ems-shared-manage-employee-accounts>
          </p-tabPanel>

        </p-tabView>
        <p-tabView *ngIf="reviewEditRuleData?.globalRule && sharedService.checkEntitlements(entitlementConst.navUUID.emtrAccess.manageGlobalRule.uuid,'','')" class="emtr-tab"
        [ngClass]="{'sdps-hide': !sharedService.checkEntitlements(entitlementConst.navUUID.emtrAccess.manageRule.uuid, '', '')}" [activeIndex]="activeIndex" (onChange)="onChangeTab($event)">
<!-- Master Group Section -->
<p-tabPanel
[header]=emtrConst.ruleSteps.step3.masterGrpTab [disabled]="shouldDisabledMasterGroups">
<ems-shared-master-groups [showMGPSelection]="true" [mstrGrpformData]="employeeGroups"
                          [showActionBtn] = "showActionBtn"
                          [masterAcctList]="employees"
                          (paginateMasterGrpList)="paginateEmployeeList($event)"
                          (employeeGroups)="updatedMasterGrpData($event)"
                          (rowSelected)="employeeRowSelected(true)"
                          (selectedMasterGrp)="masterGrpData($event)"
                          [tableCountData]="tableCountData">
</ems-shared-master-groups>
</p-tabPanel>

<!-- Master Accounts Section -->
<p-tabPanel
[header]=emtrConst.ruleSteps.step3.clientsTab [disabled]="shouldDisabledMasterAccounts">
<tr-client-selection-table (rowSelected)="employeeRowSelected(true)"
                           (selectedMasterAccounts)="updatedMasterAcc($event)"
                           (unAssignSelectedMstrAccts)="unAssignMstrAccts = $event"
                           (selectedClients)="getSelectedClientData($event)"></tr-client-selection-table>
</p-tabPanel>
</p-tabView>
      </div>
    </ems-accordion>
    <div class="security-description-container">
      <ems-accordion label={{emtrConst.rulesConstant.descriptionNotesTitle}}>
        <tr-description-and-notes class="description-notes" [descriptionForm]="reviewEditRuleForm.get('descriptionForm')"></tr-description-and-notes>
      </ems-accordion>
    </div>
  </form>
</div>

<ems-step-wizard-buttons [showPreviousBtn]="true" [showCancelBtn]="true" [showActivateBtn]="true" [disableBtn]="((employeeGroups && employeeGroups.length > 0 || employeeAccounts && employeeAccounts.length > 0) &&  !reviewEditRuleForm.invalid && (reviewEditRuleForm.get('reviewRuleName').value.trim().length !== 0) ? '' :true) || dateError"
                         [disablePrevBtn]="(reviewEditRuleForm.get('descriptionForm').get('changeRequestId').value && reviewEditRuleForm.get('descriptionForm').get('changeRequestId').invalid) || (dateError && dateError.length > 0)" (activate)="activateRule()" (goToPrevious)="goToPrevious()" (closeBtn)="cancelCreateRule()" [disablePrevBtn]="dateError || this.isInValidFromDate || this.isInValidToDate || this.isPastFromDate || this.isPastToDate || ruleNameError"></ems-step-wizard-buttons>
