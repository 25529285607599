<div class="custom-Calendar">
  <form [formGroup]="dateRangeForm">
  <p-calendar (onClose)="applyDate()" (onFocus)="openCalendar()" #myCalendar styleclass="p-column-filter" [(ngModel)]="dateValue" formControlName="dateSelected"
              selectionMode="range" [numberOfMonths]="2" [minDate]="minDateValue" [maxDate]="maxDateValue" [disabled]="disableForm"
              [readonlyInput]="limitCustomRangeTo6Months || limitCustomRangeTo1Month">
    <p-header>
      <div class="calendar-container">
        <div class="calendar-button-container" *ngFor="let name of ranges ; let i = index;">
            <button pButton pRipple type="button" label={{name}} [class.active]="isClicked === i" class="cursor p-button-raised p-button-text" (click)="isClicked = i; performRangeSelection(name)"></button>
        </div>
        <div class="step-action-buttons sdps-p-top_x-small sdps-is-absolute">
            <div class="step-action-right sdps-pull-right" [ngClass]="limitCustomRangeTo6Months || limitCustomRangeTo1Month ? 'calendar-submit-buttons-disable6months' : 'calendar-submit-buttons'">
              <button type="button" class="buttons button-save calendar-buttons" (click)="applyDate()">Apply</button>
              <button type="reset" class="buttons calendar-buttons" (click)="clearSelection()">Cancel</button>
            </div>
            <p class="sdps-clearfix">&nbsp;</p>
          </div>
      </div>
    </p-header>
  </p-calendar>
  </form>
</div>
