export interface ManageEmployeeTable {
  field?: string;
  header?: string;
  enum?: string;
}

export interface ManageEmpOrParticipantHeader {
  DBS?: ManageEmployeeTable[];
  SPS?: ManageEmployeeTable[];
  RBS?: ManageEmployeeTable[];
}

export const ManageEmployeesTableHeader: ManageEmployeeTable[] = [
  {field: 'empId', header: 'Employee ID'},
  {field: 'lastName', header: 'Last Name'},
  {field: 'firstName', header: 'First Name'},
  {field: 'acctHolderFn', header: 'Account Holder <br> First Name'},
  {field: 'acctHolderLn', header: 'Account Holder <br> Last Name'},
  {field: 'subAcctNum', header: 'Account Number'},
  {field: 'masterSubTypes', header: 'Master Account (SL)'},
  {field: 'status', header: 'Status'},
  {field: 'ruleIds', header: 'No. of Rules'},
  {field: 'updTs', header: 'Last Updated'},
  {field: 'actions', header: 'Action'}
];

export const empOrParticipantTableHeader: ManageEmpOrParticipantHeader = {
  DBS: [
    {field: 'empId', header: 'Employee ID'},
    {field: 'lastName', header: 'Last Name'},
    {field: 'firstName', header: 'First Name'},
    {field: 'acctHolderFN', header: 'Account Holder <br> First Name'},
    {field: 'acctHolderLN', header: 'Account Holder <br> Last Name'},
    {field: 'subAcctNum', header: 'Account Number'},
    {field: 'masterSubTypes', header: 'Master Account (SL)'},
    {field: 'status', header: 'Status'},
    {field: 'ruleIds', header: 'No. of Rules'},
    {field: 'updTs', header: 'Last Updated'},
    {field: 'actions', header: 'Action'}
  ],
  SPS: [
    {field: 'empId', header: 'Employee ID'},
    {field: 'lastName', header: 'Last Name'},
    {field: 'firstName', header: 'First Name'},
    {field: 'acctHolderFN', header: 'Account Holder <br> First Name'},
    {field: 'acctHolderLN', header: 'Account Holder <br> Last Name'},
    {field: 'subAcctNum', header: 'Account Number'},
    {field: 'masterSubTypes', header: 'Master Account (SL)'},
    {field: 'status', header: 'Status'},
    {field: 'ruleIds', header: 'No. of Rules'},
    {field: 'updTs', header: 'Last Updated'},
    {field: 'actions', header: 'Action'}
  ],

  RBS: [
    {field: 'lastName', header: 'Last Name'},
    {field: 'firstName', header: 'First Name'},
    {field: 'acctHolderFN', header: 'Account Holder <br> First Name'},
    {field: 'acctHolderLN', header: 'Account Holder <br> Last Name'},
    {field: 'subAcctNum', header: 'Account Number'},
    {field: 'masterSubTypes', header: 'Master Account'},
    {field: 'status', header: 'Status'},
    {field: 'ruleIds', header: 'No. of Rules'},
    {field: 'updTs', header: 'Last Updated'},
    {field: 'actions', header: 'Action'}
  ]
};

export const empOrParticipantTableSubHeader: ManageEmpOrParticipantHeader = {
  DBS: [
    {field: 'empGroupName', header: 'Group Name', enum: 'dropdown'}
  ],
  SPS: [
    {field: 'empGroupName', header: 'Group Name', enum: 'dropdown'}
  ],
  RBS: [
    {field: 'empGroupName', header: 'Plan Name', enum: 'dropdown'}
  ]
};
