<sdps-modal id='report-details-modal' location="center" [sdpsTitle]="modalTitle" [size]="'flex--medium'">
  <div slot="modal-body">
    <div>
      <p class="sdps-pull-left sdps-font-weight-bold">{{shellConstant.reports.reportsListPage.reportIdTitle}}<span
        class=" sdps-font-weight-bold">{{reportId}}</span></p>
      <p class="sdps-pull-right sdps-font-weight-bold">{{shellConstant.reports.reportsListPage.reportTypeTitle}}<span
        class=" sdps-font-weight-bold">{{reportType}}</span></p>
    </div>

    <div class="adl-textarea report-details-textarea sdps-m-bottom_large sdps-p-around_small" *ngIf="reportDetails">
      <div *ngFor="let item of reportDetails | keyvalue">
        <div class="sdps-p-vertical_xx-small" *ngIf="item.key !== 'reportCriteria'">
          <div *ngIf="item.key === 'clientName'">
            <strong>{{shellConstant.reports.reportsListPage.clientNameText}} : </strong>{{item.value}}
          </div>
          <div *ngIf="item.key === 'submittedBy'">
            <strong>{{shellConstant.reports.reportsListPage.submittedByText}} : </strong>{{item.value}}
          </div>
          <div *ngIf="item.key === 'submittedDate'">
            <strong>{{shellConstant.reports.reportsListPage.submittedDateText}} : </strong>{{item.value}}
          </div>
        </div>
      </div>
    </div>

    <div class="sdps-p-vertical_x-small sdps-p-left_x-small">
      <strong>{{shellConstant.reports.reportsListPage.reportCriteriaText}}</strong></div>

    <div class="adl-textarea report-details-textarea sdps-m-bottom_small sdps-p-around_small notes"
         *ngIf="reportDetails">
      <div>
        <div class="sdps-p-vertical_xx-small" *ngFor="let item of reportDetails.reportCriteria | keyvalue: returnZero">
          <strong>{{item.key}} : </strong>{{item.value}}</div>
      </div>
    </div>
  </div>

  <div slot="modal-footer">
    <button class="buttons" type="reset" (click)="sharedService.closeModal('report-details-modal')"
              [innerHTML]="sharedConstant.bulkUpload.closeTxt"></button>
  </div>
</sdps-modal>
