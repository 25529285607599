import {Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {AddlinkConstant} from '../../../constants/addlinkConstants';
import {AddlinkDatepickerComponent} from '../../addlink-datepicker/addlink-datepicker.component';
import * as temp from 'moment';
const moment = temp['default'];

@Component({
  selector: 'addlink-from-to-datepicker',
  templateUrl: './addlink-from-to-datepicker.component.html'
})
export class AddlinkFromToDatepickerComponent implements OnInit {

  @ViewChild('addlinkFromDatepicker', { static: true }) fromDate: AddlinkDatepickerComponent;
  @ViewChild('addlinkToDatepicker', { static: true }) toDate: AddlinkDatepickerComponent;

  @Input() calendarForm: UntypedFormGroup = this.fb.group({
    dateTo: this.fb.group({
      date: [''],
    }),
    dateFrom: this.fb.group({
      date: [''],
    }),
    datepickerDisabled: ''
  });

  @Input() dateLabel: any;
  @Input() minDate: Date;
  @Input() readonlyInput: any;
  @Input() disabled: any;
  @Input() maxDateValue: Date;

  @Output() calendarValidation = new EventEmitter<any>();
  @Output() throwDateError = new EventEmitter<any>();

  getConstant = AddlinkConstant;

  errorFlag = {
    isToDateEmpty: false,
    isFromDateEmpty: false,
    isDateRangeError: false,
    isEndDateLessThanFromDate: false,
    dateLabel: ''
  };

  constructor(private fb: UntypedFormBuilder, @Inject('commonConstants') public commonConstants) {
  }

  ngOnInit() {
    this.onChanges();
  }

  calendarValidationEvent(value) {
    this.calendarValidation.emit(value);
  }

  /*To validate the calender date*/
  onChanges(): void {
    this.calendarForm.valueChanges.subscribe(() => {

      this.errorFlag.dateLabel = this.dateLabel;

      /* To remove From/To Date error msg */
      this.errorFlag.isFromDateEmpty = this.errorFlag.isToDateEmpty = this.errorFlag.isEndDateLessThanFromDate = this.errorFlag.isDateRangeError = false;

      /* Check To date is less than From date only if valid date is entered */
      const fromDate = this.calendarForm.get('dateFrom').get('date').value;
      const toDate = this.calendarForm.get('dateTo').get('date').value;

      /*To show 'From Date is required if To date is entered' error msg*/
      if (fromDate && !toDate) {
        this.errorFlag.isToDateEmpty = true;
      } else if (!fromDate && toDate) {
        /*To show 'To Date is required if From date is entered' error msg*/
        this.errorFlag.isFromDateEmpty = true;
      }

      /*To show 'End Date should be greater than From Date' error msg*/
      if (toDate && fromDate > toDate) {
        this.errorFlag.isEndDateLessThanFromDate = true;
      }

      /* To show 'Date Range is limited to 1 year.' error msg */
      const get365Days = moment(fromDate).add(365, 'days');
      if (get365Days < toDate) {
        this.errorFlag.isDateRangeError = true;
      }
      this.throwDateError.emit(this.errorFlag);
    });
  }

  /* Clear the filterRequestListForm */
  public clearFromToErrorFlag(): void {
    this.calendarForm.reset();
    this.errorFlag = {
      isToDateEmpty: false,
      isFromDateEmpty: false,
      isDateRangeError: false,
      isEndDateLessThanFromDate: false,
      dateLabel: ''
    };
    this.throwDateError.emit(this.errorFlag);
    this.fromDate.clearErrorFlag();
    this.toDate.clearErrorFlag();
  }
}
