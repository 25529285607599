<div class="sdps-row">

  <div class="section-header col sdps-p-vertical_x-small" [ngClass]="headerTxt === 'List Of Authorized Agents' ? 'agent-title': ''">
    <div class="sdps-pull-left">
      <div class="">
        <span class="sch-document"></span>
        <span class="sdps-p-left_x-small font-bold sdps-display-inline-block vertical-align-middle" [innerHTML]="headerTxt"></span>
      </div>
    </div>
    <ul class="sdps-pull-right header-section-list grid-header-icon no-margin">
      <li class="sdps-m-right_x-small display-refresh sdps-display-inline-block cursor">
        <a class="sdps-button--text-small" [innerHTML]="lastUpdatedTime ? lastUpdatedTxt +'&nbsp;'+ lastUpdatedTime : lastUpdatedTxt"></a>
      </li>
      <li class="sdps-m-right_x-small display-refresh sdps-display-inline-block cursor">
        <a class="sdps-button--text-small icons">
          <em class="sdps-font-weight-bold sdps-text-small sch-refresh" (click)="refreshSection()" title="refresh"></em>
        </a>
      </li>
      <li class="sdps-m-right_x-small sdps-display-inline-block cursor">
        <a class="sdps-button--text-small icons">
          <em class="sdps-font-weight-bold sdps-text-small sch-print" title="print"></em>
        </a>
      </li>
      <li class="sdps-m-right_x-small sdps-display-inline-block cursor">
        <a class="sdps-button--text-small icons">
          <em class="sdps-font-weight-bold sdps-text-small sch-settings" title="settings"></em>
        </a>
      </li>
    </ul>
  </div>
</div>
