import { Injectable } from '@angular/core';
import { LocalStorageService } from '@core_services/local-storage.service';
import { HttpClient, HttpResponse } from '@angular/common/http';
import * as temp from 'moment';
const moment = temp['default'];
import { SharedService } from '@ems/shared';
import { ShellConstant } from '@shell_components/constants/shellConstants';
import {Observable, throwError} from 'rxjs';
import { Router } from '@angular/router';
import {commonConstants} from '@shell_components/constants/commonConstants';
import {AppService} from '@core_services/app.service';
import {DynamicEnvironment} from '@environment/dynamic-environment';

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  currentPg = '/ems';
  isRefreshingToken = false;
  shellConstant = ShellConstant;
  env = new DynamicEnvironment();

  constructor(private localStorageService: LocalStorageService, private httpService: HttpClient, private sharedService: SharedService,
              public router: Router, private appService: AppService) {
  }

  getEATToken(): Observable<any> {
    console.log('Inside EAT Token getEATToken() --------- ');
    return this.httpService.get(this.env.webAppService + '/v1/token');
  }

  getIWAToken(path) {
    const url = this.env.camUrl + window.location.origin + path + '&scope=api';
    window.location.href = encodeURI(url);
  }

  resolveAuthcode(code: string) {
    const url = this.env.webAppService + '/v1/toksvc?code=';
    return this.httpService.get(encodeURI(url) + code);
  }

  getToken() {
    const token = this.localStorageService.getToken();

    if (token === null) {
      return this.getNewToken();
    } else {
      const getExpiryTime = moment(this.localStorageService.getExpiryDateTime(), 'YYYY-MM-DDTHH:mm:ss');
      const currentTime = new Date(moment().format('YYYY-MM-DDTHH:mm:ss'));

      if (getExpiryTime.isBefore(currentTime) && this.sharedService.isInternalApp()) {
        return this.getNewToken();
      } else {
        return token;
      }
    }
  }

  getExternalAccessToken() {
    if (!this.isRefreshingToken) {
      this.isRefreshingToken = true;
      this.getEATToken().subscribe((res: HttpResponse<any>) => {
        const data = 'data';
        const errorCode = 'errorCode';
        this.isRefreshingToken = false;
        if (res[errorCode].errorCode === 302) {
          window.location.href = encodeURI(res[data]);
        }
      }, error => {
        this.router.navigate(['/unauthorized']);
      });
    }
  }


  getNewToken() {
    if (this.sharedService.isInternalApp()) {
      this.currentPg = window.location.pathname;
      this.getIWAToken(this.currentPg);
    } else {
      return this.getExternalAccessToken();
    }

  }

  isTokenExpired() {
    const expiryTime = moment(this.localStorageService.getExpiryDateTime()).subtract(this.shellConstant.sessionConstants.externalUserTimeoutInminutes, this.shellConstant.sessionConstants.minutesTxt).format(this.shellConstant.sessionConstants.calendarFormat);
    const currentTime = new Date();
    if (!this.sharedService.isInternalApp()) {
      if (moment(currentTime).format(this.shellConstant.sessionConstants.calendarFormat) >= expiryTime) {
        return true;
      } else {
        return false;
      }
    }
  }
}
