import {Component, OnInit, Output, EventEmitter, Input, Inject, ViewChild, ElementRef} from '@angular/core';
import {RuleService} from '../../services/rule-service/rule.service';
import {DefaultSecurityGridHeader, ModalSearchSecurity} from '../../models/search-security-modal.model';
import {emtrConstant} from '../../constants/emtrConstants';
import {FundFamilyTableHead, searchFundFamilyTableHead} from '../../models/search-fund-Family-table-header.model';
import {SharedConstant, SharedService} from '@ems/shared';
import {HttpResponse} from '@angular/common/http';

@Component({
  selector: 'tr-search-security-modal',
  templateUrl: './search-security-modal.component.html',
  styleUrls: ['./search-security-modal.component.scss']
})

export class SearchSecurityModalComponent implements OnInit {
  emtrConst = emtrConstant;
  sharedConst = SharedConstant;
  labels = '';
  gridData: any[] = [];
  uploadedSecurityList: any[] = [];
  columns: any[];
  inputSearchTextBox: string;
  selectedData: any[] = [];
  uploadedSecSelectedData: any[] = [];
  selectedMultiselectRecords: any[] = [];
  enteredSearchText: any;
  /*Retrieving the Columns from DefaultSecurityGridHeader Interface*/
  defaultRecord: ModalSearchSecurity[] = DefaultSecurityGridHeader;
  @Output() checkedRecords = new EventEmitter();
  @Output() FileUploadEmit: any = new EventEmitter();
  @Input() finalArray;
  @Input() selectedModalRulesCategory: any;
  @Input() ruleType: any;
  @Input() rulesCategory: any;
  @Input() isInstGrp = true;
  @Input() loadModal = false;
  gridRecords: any = true;
  scrolledEle: any;
  loadedCompleteData = false;
  pageNo: number;
  searchSecurityText: string;
  multiselectSelectedData: any[] = [];
  fundFamilyTableHeadCols: FundFamilyTableHead[] = searchFundFamilyTableHead;
  fileSize = this.sharedConst.bulkUpload.maxFileSize;
  uploadedFile: any[] = [];
  downloadTemplate: any;
  fileName: any;
  fileData: any;
  uploadedFilesize: any;
  uploadResponse: any = [];
  isFileUploaded = false;
  showError = false;
  showMaxCountError = false;
  invaildFileError = false;
  errorMessage = '';
  toastMsg = '';
  searchType = 'All';
  localSecuritytype = [];
  exactMatchErrorMsg = '';
  showNoRecordsErrorForAll = false;
  securityListSliceIndex = 0;

  @ViewChild('fileUploader', {static: true}) localUploader: ElementRef;

  constructor(private ruleService: RuleService, @Inject('commonConstants') public commonConstants, public sharedService: SharedService) { }
  /*openModal the Modal*/
  openModal(e: Event, securityTypeData, searchText) {
    this.sharedService.isModalOpened.next(true);
    this.gridRecords = true;
    this.loadModal = true;
    this.showNoRecordsErrorForAll = false;
    this.multiselectSelectedData = this.selectedMultiselectRecords = securityTypeData;
    this.inputSearchTextBox = searchText;
    this.showMaxCountError = this.showError = this.invaildFileError = this.isFileUploaded = false;
    this.toastMsg = this.exactMatchErrorMsg = '';
    this.searchType = 'All';
    if (searchText && searchText.trim() && this.selectedMultiselectRecords) {
      this.updateSearch();
    }
    this.sharedService.openModal('advance-search-security-modal');
  }

  /*closeModal the Modal*/
  closeModal() {
    this.sharedService.isModalOpened.next(false);
    this.gridData = [];
    this.selectedData = [];
    this.inputSearchTextBox = '';
    this.loadModal = false;
    this.showMaxCountError = this.showError = this.invaildFileError = this.isFileUploaded = false;
    this.toastMsg = this.exactMatchErrorMsg = '';
  }

  onScroll(event: any) {
      this.scrolledEle = event.target;
      /*Note the 1px from the bottom, adjust as required*/
      if (this.scrolledEle.scrollHeight - this.scrolledEle.scrollTop <= this.scrolledEle.clientHeight + 1 && !this.loadedCompleteData) {
        if (this.ruleService.scrollHeightValue !== this.scrolledEle.scrollHeight) {
          this.ruleService.scrollHeightValue = this.scrolledEle.scrollHeight;
          if (this.isFileUploaded && this.uploadedSecurityList.length > 50) {
            this.handleRecordsOnScroll(this.uploadedSecurityList.slice(this.securityListSliceIndex, this.securityListSliceIndex+50))
            this.securityListSliceIndex = this.securityListSliceIndex + 50;
          } else if (!this.isFileUploaded) {
            this.getSecurities();
          }
        }
      }
  }

  /*Initializing headerTitle & Grid Columns*/
  ngOnInit() {
    this.labels = 'Search Results';
    this.downloadTemplate = this.emtrConst.rulesConstant.autoComplete.downloadTempValue;
    this.columns = (this.ruleType === this.emtrConst.rulesConstant.autoComplete.FNDISUTxt) ? this.fundFamilyTableHeadCols : this.defaultRecord;
  }

  downloadFileSystem() {
    this.invaildFileError = false;
    this.showMaxCountError = this.showError = false;
    this.errorMessage = '';
    this.toastMsg = '';
    this.sharedService.downloadFileSystem(this.downloadTemplate, 'spos')
      .subscribe((response: HttpResponse<Blob>) => {
        const filename = this.emtrConst.rulesConstant.autoComplete.securityTempFileName;
        this.sharedService.saveFile(response.body, filename);
      });
  }

  /*Catching the Emitted Value from Multiselect Component*/
  getChildVal(element) {
    this.selectedMultiselectRecords = element;
  }

  /*Clicking the Update Button to Fetch the Grid Data*/
  updateSearch() {
    if (this.scrolledEle) {
      this.scrolledEle.scrollTop = 0;
    }
    this.gridData = [];
    this.pageNo = 0;
    this.loadedCompleteData = true;
    this.showMaxCountError = this.showError = this.invaildFileError = this.isFileUploaded = false;
    this.toastMsg = '';
    this.searchSecurityText = this.inputSearchTextBox ? this.inputSearchTextBox.trim() : '';
    this.getSecurities();
    this.selectedData = [];
  }

  /*Select Button in Search Modal */
  selectGridRecords() {
    if(this.isFileUploaded) {
      this.selectedData = [];
      this.uploadedSecurityList.forEach(secVal => {
        const data = secVal;
        if (secVal.securityFound) {
          this.selectedData.push(data);
        }
      });
    }
    this.checkedRecords.emit(this.selectedData);
    this.gridData = [];
    this.selectedData = [];
    this.inputSearchTextBox = '';
    this.loadModal = false;
    this.sharedService.isModalOpened.next(false);
    this.sharedService.closeModal('advance-search-security-modal');
  }

  getSecurities() {
    this.localSecuritytype = [];
    /*Retrieving only the code from the Emitted Array*/
    if (this.searchType === 'All') {
      if (this.ruleType !== this.emtrConst.rulesConstant.autoComplete.FNDISUTxt && this.selectedMultiselectRecords && this.selectedMultiselectRecords.length) { 
        this.selectedMultiselectRecords.forEach(element => {
          this.localSecuritytype.push(element.code);
        });
      }
    } else if (this.searchType === this.emtrConst.rulesConstant.autoComplete.exactTicker) {
      this.localSecuritytype.push(this.emtrConst.rulesConstant.autoComplete.exactMatchSymbol);
    } else {
      this.localSecuritytype.push(this.emtrConst.rulesConstant.autoComplete.exactMatchCusip);
    }
    this.enteredSearchText = this.inputSearchTextBox;
    this.exactMatchErrorMsg = '';
    if (this.ruleType !== this.emtrConst.rulesConstant.autoComplete.FNDISUTxt) {
      /*Forming the Input Object for the POST Call*/
      const inputJSON = {
        page: this.pageNo,
        securitySearch: this.searchSecurityText,
        securityType: this.localSecuritytype,
        size: 50
      };

      this.ruleService.getSearchSecurities(inputJSON).subscribe(securities => {
        this.handleRecordsOnScroll(securities);
      }, error  => {
        this.exactMatchErrorMsg = error.error.errorResponse.errorMessage;
        this.sharedService.clearErrorMessage();
      });
    } else {
      /*Forming the Input Object for the POST Call*/
      const searchJSON = {
        page: this.pageNo,
        securityType: ['FUNDFAMILY'],
        securitySearch: this.searchSecurityText,
        size: 50
      };
      this.ruleService.getSearchSecurities(searchJSON).subscribe(securities => {
        this.handleRecordsOnScroll(securities);
      }, error  => {
        this.exactMatchErrorMsg = error.error.errorResponse.errorMessage;
        this.sharedService.clearErrorMessage();
      });
    }
  }

  handleRecordsOnScroll(securities) {
    if (securities) {
      const secList = this.isFileUploaded ? securities : securities.data.content;
      this.gridRecords = true;
      this.gridData = this.gridData.concat(secList);
      this.showNoRecordsErrorForAll = this.searchType === 'All' && this.gridData && this.gridData.length === 0;
      if (!this.isFileUploaded) {
        this.pageNo = securities.data.number + 1;
        /*******
         * UNCOMMENT THE BELOW LINE AFTER THE API IS FIXED FOR THE PAGINATION ISSUE
         * REMOVE THE LINE 113
         * *******************/
        /*this.loadedCompleteData = this.pageNo > 40 || (this.pageNo * 50) >= securities.data.totalElements;*/
        this.loadedCompleteData = this.pageNo >= 40 || !(securities.data.content.length > 0);
      }
      this.gridData.forEach(secVal => {
        const data = secVal;
        if (this.localSecuritytype.indexOf(this.emtrConst.rulesConstant.autoComplete.exactMatchSymbol) !== -1 || this.localSecuritytype.indexOf(this.emtrConst.rulesConstant.autoComplete.exactMatchCusip) !== -1 || (this.isFileUploaded && data.securityFound)) {
          this.selectedData.indexOf(data) === -1 ? this.selectedData.push(data) : '';
        }
      });
    } else {
      this.gridRecords = false;
      this.showNoRecordsErrorForAll = false;
    }
  }

  fileSelection(e) {
    /* BELOW IF CONDITION explained
    e.files is called when we select using choose file button */
    if (e.files) {
      if (this.scrolledEle) {
        this.scrolledEle.scrollTop = 0;
      }
      this.fileName = e.files ? e.files[0].name : '';
      this.fileData = e.files ? e.files[0] : '';
      this.uploadedFilesize = e.files ? e.files[0].size : 0;
      this.inputSearchTextBox = '';
      this.exactMatchErrorMsg = '';
      this.showNoRecordsErrorForAll = false;
      this.searchType = 'All';
      if (this.fileName.split('.')[1] === 'csv') {
        this.invaildFileError = false;
        this.readFile();
        this.uploadResponse.success = false;
      } else {
        this.toastMsg = '';
        this.gridData = [];
        this.selectedData = [];
        this.invaildFileError = true;
        this.showMaxCountError = this.showError = false;
        this.errorMessage = this.emtrConst.rulesConstant.autoComplete.invalidFileFormatError;
      }
    }
  }

  readFile() {
    const cusipArr = [];
    const tickerArr = [];
    let fileData = '';
    const fileReader = new FileReader();
    /* onload event will be fired after the content is read so your logic should be inside the onLoad function */
    fileReader.onload = (e) => {
      /* Convert the file content to string format */
      fileData = fileReader.result.toString();
      /* Split the content of string into array based on new line */
      const arr = fileData.split('\n');
      if (arr[arr.length - 1] === '') {
        arr.splice(arr.length - 1, 1);
      }
      const validate = arr[0].split(',');
      /* BELOW IF CONDITION validate if the first two columns of the uploaded file contains title CUSIP & Ticker */
      /* eslint-disable-next-line max-len */
      if (arr.length > 1 && validate[0].trim().toLowerCase() === emtrConstant.rulesConstant.autoComplete.cusipText && (validate[1].replace(/(\r)/gm, '').trim().toLowerCase() === emtrConstant.rulesConstant.autoComplete.tickerText || validate[1].replace(/(\r)/gm, '').trim().toLowerCase() === emtrConstant.rulesConstant.autoComplete.tickerSymbolText)) {
        this.showMaxCountError = this.showError = false;
        arr.forEach(val => {
          /* Further split the content of string into array based on , separation */
          const localArr = val.split(',');
          if (localArr.length > 1) {
            /* The value read from the file may contain \r at the end. The \r value has to be remove hence replacing it with '' */
            const tickerSymVal = localArr[1].replace(/(\r)/gm, '');
            /* Push only unique values into the array */
            if (localArr[0] !== '' && localArr[0].trim().toLowerCase() !== emtrConstant.rulesConstant.autoComplete.cusipText && cusipArr.indexOf(localArr[0]) === -1) {
              cusipArr.push(localArr[0]);
            }
            /* eslint-disable-next-line max-len */
            if (tickerSymVal !== '' && tickerSymVal.trim().toLowerCase() !== emtrConstant.rulesConstant.autoComplete.tickerText && tickerSymVal.trim().toLowerCase() !== emtrConstant.rulesConstant.autoComplete.tickerSymbolText && tickerArr.indexOf(tickerSymVal) === -1) {
              tickerArr.push(tickerSymVal);
            }
          }
        });
        if (cusipArr.length + tickerArr.length > 300) {
          this.showMaxCountError = true;
          this.showError = this.invaildFileError = false;
          this.toastMsg = '';
          this.gridData = [];
          this.selectedData = [];
        }
      } else {
        /* If the first two columns of the uploaded file does not contains title CUSIP & Ticker then display an error */
        this.showError = true;
        this.showMaxCountError = this.invaildFileError = false;
        this.toastMsg = '';
        this.gridData = [];
        this.selectedData = [];
      }
      if (!this.showError && !this.invaildFileError && !this.showMaxCountError) {
        this.getUploadedSecurities(cusipArr, tickerArr);
      }
    };
    fileReader.readAsText(this.fileData);
  }

  getUploadedSecurities(cusipArr, tickerArr) {
    const uploadObj = {
      cusip: cusipArr,
      ticker: tickerArr
    };
    this.ruleService.uploadSecurities(uploadObj).subscribe(securities => {
      this.toastMsg = emtrConstant.rulesConstant.autoComplete.uploadSuccessMsg;
      if (securities.data) {
        this.isFileUploaded = true;
        this.selectedData = [];
        this.uploadedSecSelectedData = [];
        this.gridData = [];
        this.uploadedSecurityList = securities.data.content;
        if (this.uploadedSecurityList.length <= 50) {
          this.gridData = securities.data.content;
        } else {
          this.gridData = this.uploadedSecurityList.slice(0,50);
          this.securityListSliceIndex = +50;
        }
        this.gridData.forEach(secVal => {
          const data = secVal;
          if (secVal.securityFound) {
            this.selectedData.push(data);
          }
        });

        this.uploadedSecurityList.forEach(secVal => {
          const data = secVal;
          if (secVal.securityFound) {
            this.uploadedSecSelectedData.push(data);
          }
        });
      }
    }, error => {
      this.showError = true;
      this.toastMsg = '';
      this.invaildFileError = false;
    });
  }

  resetInputErrorField() {
    if (this.inputSearchTextBox === '') {
      this.showNoRecordsErrorForAll = false;
      this.exactMatchErrorMsg = '';
    }
  }
}

