import { Component, Inject, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedService, AccountNumberValidationService, LinkDelinkService } from '@ems/shared';
import { SelectItem } from 'primeng/api';
import { AddlinkService } from '../../addlink.service';
import { AddlinkConstant } from '../../constants/addlinkConstants';
import { delinkRequestColumns } from '../../constants/requestColumns';
import { AddAuthorizedAgentModalComponent } from '../../shared/components/modals/add-authorized-agent-modal/add-authorized-agent-modal.component';
import { RemarksModalComponent } from '../../shared/components/modals/remarks-modal/remarks-modal.component';
import { DelinkMasterAccountModalComponent } from '../../shared/components/modals/delink-master-account-modal/delink-master-account-modal.component';
import { CommonService } from '../../shared/services/common.service';
import { DelinkService } from '../../shared/services/delink.service';
import {Subscription} from 'rxjs';
import {RequestListService} from '../../shared/services/request-list.service';


export interface Request {
  requestId?;
  status?;
  accountNumber?;
  employeeFirstName?;
  employeeLastName?;
  employeeId?;
  masterAccountNumber?;
  accountSubType?;
  disableIcon?;
}

@Component({
  selector: 'addlink-delink',
  templateUrl: './delink.component.html',
  styleUrls: ['./delink.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DelinkComponent implements OnInit, OnDestroy {

  addLinkConstant = AddlinkConstant;
  public requests: Request[];
  public requestColumns: any[] = delinkRequestColumns;

  public delinkRequestForm: UntypedFormGroup;

  public authorizedAgents: SelectItem[];
  public masterAccountOptions: any[];
  public confirmMessage: any;

  public jobId: string;
  public clientId: string;
  public clientInfo: any;
  public clientName: string;
  public businessUnit: string[];
  public isAAExceptBU: boolean;
  public orgId: any;

  public remarksCount = 0;
  public previousAcctNumber: any;
  public employeeName: string;

  public isAuthAgentRole: boolean;
  public usertype: string;

  isAccNumInvalid = false;
  intervalJobs: any = {};
  RequestInterval: any;
  showRemarksTxtboxCharLimit: boolean;

  @ViewChild('addAuthAgent', { static: true }) addAuthorizedAgentViewChild: AddAuthorizedAgentModalComponent;
  @ViewChild('remarks', { static: true }) remarksViewChild: RemarksModalComponent;
  @ViewChild('masterAcc', { static: true }) masterAccViewChild: DelinkMasterAccountModalComponent;
  @ViewChild('toastMessage', { static: true }) msgToast: any;
  @ViewChild('confirmationMessage', { static: true }) confirmationMessage: any;

  checkStatus: any = '';
  public clientInfoSubscribe: Subscription;
  public linkType: any;
  masterAccounts: any = [];
  showMasterAccModal: any = false;
  disableAuthagent: any = true;
  showDelinkBtn: any = [];
  showInfoMsg: any = false;
  externalAccess = false;
  wholeRecordArr: any = [];
  hasADDLinkAccess = true;
  initalValues: any;
  cancelBtnGutTack = 'addlinkDelink_cancel';

  constructor(public formBuilder: UntypedFormBuilder, private commonService: CommonService,
              private delinkService: DelinkService, public addlinkService: AddlinkService,
              private router: Router, private activatedRouter: ActivatedRoute, public sharedService: SharedService,
              @Inject('entitlementConst') public entitlementConst , private accountNumberValidationService: AccountNumberValidationService, @Inject('commonConstants') public commonConstants, public requestListService: RequestListService,
              public linkDelinkService: LinkDelinkService) {

  }

  /* Initialize component variables */
  ngOnInit() {
    // Initial Setup
    this.setupDelinkRequestForm();
    this.sharedService.specificClientView.next(true);

    this.populateUserPolicyData();

    if (this.hasADDLinkAccess) {
      this.commonService.getJobIdSequence().subscribe(result => this.jobId = result.data.jobId);
    }

    // Contruct Component
    this.construct();

    // Subscribing for Client Changes
    this.clientInfoSubscribe = this.addlinkService.clientInformation.subscribe(clientInfo => {
      if (clientInfo !== null) {
        this.hasADDLinkAccess = this.sharedService.checkEntitlements(this.entitlementConst.navUUID.accountLinking.uuid, '', '');
        this.delinkRequestForm.get('authAgent').setValue([]);
        clientInfo.externalAccess && clientInfo.externalAccess === 'Y' ? this.externalAccess = true : this.externalAccess = false;
        this.updateClientInformation(clientInfo);
        this.showInfoMsg = '';
      }
    });

    this.delinkRequestForm.get('accountNumber').valueChanges.subscribe(() => {
      this.isAccNumInvalid = false;
    });

    // authAgent DBS Exception Validation
    this.linkDelinkService.authAgentValidation(this.delinkRequestForm);
    this.confirmMessage = this.confirmationMessage.confirmMessage;
    this.delinkRequestForm.valueChanges.subscribe(() => {
      this.sharedService.isConfirmationRequiredClientChange = this.formHasChanged();
    });
  }

  public populateUserPolicyData() {
    this.addlinkService.userType.subscribe(result => {
      this.usertype = result;
    });
    this.addlinkService.userPolicy.subscribe(result => {
      if (this.sharedService.checkEntitlements(this.entitlementConst.navUUID.addLinkAccess.uuid, '', '') && this.usertype !== 'Internal') {
        this.isAuthAgentRole = true;
        this.delinkRequestForm.controls.authAgent.setValidators([]);
        this.delinkRequestForm.controls.authAgent.updateValueAndValidity();
      } else {
        this.isAuthAgentRole = false;
      }
    });
  }

  public updateClientInformation(clientInfo: any): void {
    if (clientInfo == null) {
      return;
    } else {
      this.construct();
      this.clientId = clientInfo.clientId;
      this.clientName = clientInfo.k4ClientName;
      this.orgId = clientInfo.orgId;
      this.linkType = clientInfo.linkType;
      this.clientInfo = clientInfo;
      if (this.clientId !== null) {
        this.initialize();
      }
    }
  }

  /* Setup Edit Requests Form */
  public setupDelinkRequestForm(): void {

    this.delinkRequestForm = this.formBuilder.group({
      accountNumber: new UntypedFormControl('', Validators.required),
      firstName: new UntypedFormControl(''),
      lastName: new UntypedFormControl(''),
      remarks: new UntypedFormControl(''),
      doNotShowRemarkToClient: new UntypedFormControl(false),
      authAgent: new UntypedFormControl([], Validators.required),
      temporaryDelink: new UntypedFormControl(false)
    });
    this.delinkRequestForm.controls.authAgent.disable();
    // store initials form values
    this.initalValues = this.delinkRequestForm.getRawValue();
  }

  public initialize(): void {
    this.delinkRequestForm.enable();
    this.delinkRequestForm.controls.authAgent.disable();
    this.disableAuthagent = true;
    if (this.hasADDLinkAccess) {
      this.linkDelinkService.getAuthorizedAgents(this.orgId).subscribe(result => {
        this.businessUnit = result.data.authAgentExceptionBusinesUnits;
        this.populateAuthorizedAgents(result.data);
      });
    }
  }

  public construct() {
    // Initialize Variables
    this.requests = [];
    this.masterAccountOptions = [];
    this.previousAcctNumber = '';
    this.employeeName = '';
    this.resetDelinkRequestForm('');
    this.delinkRequestForm.disable();
  }

  /* Reset The Delink Request Form To Defualt Values. */
  public resetDelinkRequestForm(reset): void {
    //  clear API error messages
    this.sharedService.clearErrorMessage();
    // removing the info message while resetting form
    this.showInfoMsg = false;

    // Loop through all of the form controls.
    Object.keys(this.delinkRequestForm.controls).forEach(key => {

      switch (key) {
        case 'doNotShowRemarkToClient':
          this.delinkRequestForm.controls[key].setValue(false);
          break;
        case 'authAgent':
          if (reset) {
            this.delinkRequestForm.get('authAgent').setValue([]);
          }
          break;
        case 'temporaryDelink' :
          this.delinkRequestForm.controls[key].setValue(false);
          break;
        default:
          this.delinkRequestForm.controls[key].setValue('');
          break;
      }

    });
    this.previousAcctNumber = '';
    this.employeeName = '';

    // Maintaining DBS value
    if (this.delinkRequestForm.get('authAgent').value.length > 0 && this.delinkRequestForm.get('authAgent').value[0].label.indexOf('DBS') > -1) {
      this.delinkRequestForm.controls.remarks.setValidators(Validators.required);
      this.delinkRequestForm.controls.remarks.updateValueAndValidity();
      this.delinkRequestForm.controls.doNotShowRemarkToClient.setValue(true);
      this.delinkRequestForm.controls.doNotShowRemarkToClient.disable();
      this.delinkRequestForm.controls.doNotShowRemarkToClient.updateValueAndValidity();
    }

  }

  public populateMasterAccounts(event) {
    const acctNumber = this.delinkRequestForm.get('accountNumber').value;
    if (acctNumber.toString().length < 8) {
      this.delinkRequestForm.controls.authAgent.disable();
    } else {
        // event code added for handling control V event
        if (event.keyCode === 17) {
          return true;
        } else if (event.keyCode === 86) {
          this.getDelinkData(acctNumber);
        } else {
          this.getDelinkData(acctNumber);
        }
    }
  }

  getDelinkData(acctNumber) {
    this.delinkService.getDelinkMasterAccounts(this.orgId, acctNumber).subscribe(result => {
      this.masterAccountOptions = [];

      if (result.data !== null && result.data.masterAccountlist !== null && result.data.masterAccountlist.length > 0) {
        this.showDelinkBtn = result.data.masterAccountlist;
        this.clientInfo.businessUnit !== 'SPS' ? this.delinkRequestForm.controls.authAgent.enable() : this.delinkRequestForm.controls.authAgent.disable();
        this.disableAuthagent = false;
        this.showInfoMsg = false;
        for (const masterAccount of result.data.masterAccountlist) {
          const item = {
            label: masterAccount.accountLabel + ' - ' + masterAccount.itemName,
            value: masterAccount.itemName,
            accountType: masterAccount.itemType
          };
          if ((this.linkType === this.addLinkConstant.delinkMasterAccModal.dualTxt || this.linkType === this.addLinkConstant.delinkMasterAccModal.multiTxt) && result.data.masterAccountlist.length === 1) {
            if (this.masterAccounts.length >= 1) {
              this.masterAccounts.map(data => {
                if (data.value !== item.value) {
                  this.masterAccounts.push(item);
                }
              });
            } else {
              this.masterAccounts.push(item);
            }
          }
          this.masterAccountOptions.push(item);
        }
        this.masterAccountOptions.sort((a, b) => {
          const aType = a.label.split(' ')[1];
          const bType = b.label.split(' ')[1];

          if (aType < bType) {
            return -1;
          }

          if (aType > bType) {
            return 1;
          }

          return 0;
        });

      } else {
        this.employeeName = result.data;
        this.masterAccounts = [];
        this.showInfoMsg = true;
        this.delinkRequestForm.get('authAgent').setValue([]);
      }
      if (result.data !== null) {
        this.employeeName = result.data.employeeName;
      }
    });

  }

  public populateAuthorizedAgents(result): void {
    this.authorizedAgents = [];
    this.isAAExceptBU = this.linkDelinkService.populateAuthorizedAgents(this.businessUnit, this.clientInfo);
    if (this.isAAExceptBU) {
      // this.linkDelinkService.getAuthorizedAgents(this.clientId).subscribe( result => {
        this.authorizedAgents = this.linkDelinkService.getAuthAgents(this.clientId, result);
      // });
    } else {
      this.delinkRequestForm.controls.authAgent.disable();
    }
  }

  public getAuthorizedAgents(): void {
    this.linkDelinkService.getAuthorizedAgents(this.orgId).subscribe( result => {
      this.authorizedAgents = this.linkDelinkService.getAuthAgents(this.clientId, result.data);
    });
  }

  public displayAuthAgentPopup(e: any): void {
    if (this.orgId) {
      this.addAuthorizedAgentViewChild.openModal(e, this.orgId);
    }
  }

  public validForm(): boolean {
    if (this.delinkRequestForm.valid) {
      return true;
    }
    return false;
  }

  public openRemarkPopup(e: any, requestId: string, accountNumber: string, status: string): void {
    this.remarksViewChild.openModal(e, requestId, accountNumber, status, true);
  }

  public processWebsocketResponse(responseObject: any): void {

    for (const request of this.requests) {
      if (request.requestId !== null && request.requestId === responseObject.requestId) {
        request.status = responseObject.status;
        break;
      }
    }
  }

  delinkRequest() {
    this.sharedService.gutTracking('adobe-lc_submit|fireonce');

    if ((this.linkType !== this.addLinkConstant.delinkMasterAccModal.dualTxt  && this.linkType !== this.addLinkConstant.delinkMasterAccModal.multiTxt)) {
      this.masterAccViewChild.openModal();
    } else {
      if (this.masterAccountOptions.length > 1) {
        this.masterAccViewChild.openModal();
      } else {
        this.submitDelinkRequest();
      }
    }
  }

  public submitDelinkRequest(): void {
    /*To clear the toast message*/
    this.msgToast.pushMessage('', '');

    // Reset Form Elements
    this.masterAccountOptions = [];

    const requestObject: any = [];
    Object.keys(this.delinkRequestForm.controls).forEach(key => {
      requestObject[key] = this.delinkRequestForm.controls[key].value;
    });
    requestObject.masterAccounts =  this.masterAccounts;
    this.resetDelinkRequestForm('');

    // Create The Delink Request
    this.delinkService.saveMultiDelinkLinkage(requestObject, this.orgId, this.jobId, null).subscribe(result => {
      this.showMasterAccModal = false;
      this.showDelinkBtn = [];
      this.masterAccounts = [];
      // Populate Requests
      for (const entry of result.data.listOfMulitEntry) {
        const request = {
          requestId: entry.requestId,
          status: entry.internalStatus,
          accountNumber: entry.acctNumber,
          employeeFirstName: entry.employeeFirstName,
          employeeLastName: entry.employeeLastName,
          employeeId: entry.employeeId,
          masterAccountNumber: entry.masterAccountLabel + ' - ' + entry.masterAccountNbr,
          disableIcon: false
        };
        this.requests.push(request);
        let count = 0;
        const status = this.sharedService.isInternalApp() ? entry.internalStatus : entry.status;
        if (status === 'Validating' || status === 'Received') {
          const localObj = {requestId: request.requestId, count};
          this.wholeRecordArr.push(localObj);
          this.intervalJobs[request.requestId] = setInterval(() => {
            this.getRequestStatus(request.requestId);
            count++;
            this.wholeRecordArr.forEach((data) => {
              if (data.requestId === request.requestId) {
                data.count = count;
                if (data.count === 5) {
                  window.clearInterval(this.intervalJobs[request.requestId]);
                }
              }
            });
          }, this.addLinkConstant.reqDetailPollingInterval);
        }
      }
    });
  }

  getRequestStatus(requestIds) {
    this.sharedService.setRefresh.emit(true);
    this.commonService.pollRequestDetailsByRequestId(requestIds, this.orgId).subscribe( (res) => {
      const data = {status: this.sharedService.isInternalApp() ? res.data.requestInfo.internalStatus : res.data.requestInfo.status, requestId: res.data.requestInfo.requestId};
      this.processWebsocketResponse(data);
      this.checkStatus = data.status;
      if (data.requestId === requestIds && (data.status !== 'Validating' && data.status !== 'Received')) {
        res.data.requestInfo.disableIcon = false;
        clearInterval(this.intervalJobs[requestIds]);
        this.sharedService.setRefresh.emit(false);
      }
      this.updateSingleRequest(res.data.requestInfo);
    }, error => {
      clearInterval(this.intervalJobs[requestIds]);
    });
  }

  /*check Account Number contains 8 digits*/
  validateAccountNumber(event) {
    this.isAccNumInvalid = this.accountNumberValidationService.validateAccountNumber(event.target.value);
  }

  public bulkSubmit(): void {
    // Clear API error msgs
    this.sharedService.clearErrorMessage();
    /* Set isDTBtnClicked value to true, generate the data track value with 'linkid-lc' . */
    this.sharedService.isDTBtnClicked = true;
    this.sharedService.loadDataTrackValue('upload');
    this.sharedService.parentPage = '../delink';
    this.router.navigate(['../addlink-upload'], { relativeTo: this.activatedRouter });
  }

  ngOnDestroy() {
    this.sharedService.specificClientView.next(false);
    this.sharedService.setRefresh.emit(false);
    this.showInfoMsg = false;
    this.sharedService.isConfirmationRequiredClientChange = false;
    this.showDelinkBtn = [];
    if (this.clientInfoSubscribe) {
      this.clientInfoSubscribe.unsubscribe();
    }
  }

  reSubmitRequests(requestIds) {
    const requestIdArray = [];
    requestIds.disableIcon = true;
    requestIdArray.push(requestIds.requestId);
    this.requestListService.addlinkRequestPutEndPoint(requestIdArray, '', 'RESUBMIT', this.orgId).subscribe((data) => {
      requestIdArray.forEach(id => {
        this.intervalJobs[id] = setInterval(() => {
          this.getRequestStatus(id);
        }, this.addLinkConstant.reqDetailPollingInterval);
      });
    });
  }

  processMasterAcc(val) {
    if (val) {
      this.masterAccounts = val;
      this.submitDelinkRequest();
    }
  }

  public updateSingleRequest(requestObject: any): void {
    this.requests.forEach(request => {
      if (request.requestId === requestObject.requestId) {
        request.status = this.checkStatus ? this.checkStatus : request.status;
        request.disableIcon = requestObject.disableIcon;
      }
    });
  }
  formHasChanged(): boolean {
    if (this.initalValues) {
      const hasformChanged = Object.entries(this.initalValues).some(
        ([field, value]) => {
          if (Array.isArray(value)) {
            // check for Empty array as [] === [] will give you false
            if ((this.initalValues[field].length === 0 && this.delinkRequestForm.getRawValue()[field].length === 0)) {
              return false;
            } else {
              return value.length !== this.delinkRequestForm.getRawValue()[field].length;
            }
          } else {
            const initialValue = value || '';
            const formValue = this.delinkRequestForm.getRawValue()[field] || '';
            return initialValue !== formValue;
          }
        }
      );
      return hasformChanged;
    } else {
      return false;
    }
  }
  canDeactivate(event, observer) {
    if (this.confirmMessage) {
      this.confirmMessage.message = this.confirmMessage.message ? this.confirmMessage.message : this.addLinkConstant.confirmationMessage.menuClickMsg;
    }
    if (this.formHasChanged()) {
      this.sharedService.isLoadPage = true;
      // open modal
      this.confirmationMessage.cancelBtnRejectGutTack = this.cancelBtnGutTack;
      return this.confirmationMessage.showConfirmMsg(this.confirmMessage, observer);
    }
    return true;
  }
}
