import {Injectable} from '@angular/core';
import {FormData, Step1} from './formData.model';
import {Router} from '@angular/router';
import {WorkflowService} from '../workflow/workflow.service';

@Injectable({
    providedIn: 'root'
})
export class FormDataService {
    public formData: FormData = new FormData();

    constructor(private workflowService: WorkflowService, private router: Router) {
    }

    getStep1(): Step1 {
        /*Return the Rule Step 1 data*/
        const step1: Step1 = {
            name: this.formData.name,
            ruleType: this.formData.ruleType,
            rulesCategory: this.formData.rulesCategory,
            justification: this.formData.justification,
            otherInput: this.formData.otherInput,
            ruleSubCategory: this.formData.ruleSubCategory,
            planId: this.formData.planId,
            globalRule: this.formData.globalRule
        };
        return step1;
    }

    setStep1(data: any, valid: boolean) {
        /*Update the the Rule Step 1 data only when the the Rule Step 1 Form had been validated successfully*/
        this.formData.name = data.ruleName;
        this.formData.ruleType = data.ruleType;
        this.formData.rulesCategory = data.rulesCategory;
        this.formData.override = data.override;
        this.formData.orgId = data.orgId;
        this.formData.justification = data.justification;
        this.formData.otherInput = data.otherInput;
        this.formData.ruleSubCategory = data.ruleSubCategory;
        this.formData.planId = data.planId;
        this.formData.globalRule = data.globalRule;
        /*Validate Personal Step in Workflow*/
        this.workflowService.validateStep('step1', 'rule', valid);
    }

    setSecurityValue(data: any) {
        this.formData.chooseSecurityType = data.chooseSecurityType;
        this.formData.secCatgs1 = data.secCatgs1;
        this.formData.secCatgs2 = data.secCatgs2;
        this.formData.secCatgs3 = data.secCatgs3;
        this.formData.assetGroups = data.assetGroups;
        this.formData.presetRuleKey = data.presetRuleKey;
        this.formData.presetRuleId = data.presetRuleId;
        this.formData.instrumentGroups = data.instrumentGroups;
        this.formData.indSecs = data.indSecs;
        this.formData.appliesTo = data.appliesTo;
    }

    setStep2(data: any, valid: boolean) {
        /*Update the the Rule Step 1 data only when the the Rule Step 1 Form had been validated successfully*/
        this.formData.appliesTo = data.appliesTo;
        this.formData.desc = data.description;
        this.formData.endDt = data.endDt;
        this.formData.indSecs = data.indSecs;
        this.formData.instrumentGroups = data.instrumentGroups;
        this.formData.note = data.notes;
        this.formData.changeRequestId = data.changeRequestId;
        this.formData.rstrctBuy = data.restrictBuys;
        this.formData.rstrctSell = data.restrictSells;
        this.formData.rstrctShortSell = data.restrictShortSells;
        this.formData.startDt = data.startDt;
        this.formData.securityType = data.multiselectSelectedData;
        this.formData.autoCompleteData = data.autoCompleteData;
        this.formData.secCatgs1 = data.secCatgs1;
        this.formData.secCatgs2 = data.secCatgs2;
        this.formData.secCatgs3 = data.secCatgs3;
        this.formData.chooseSecurityType = data.chooseSecurityType;
        this.formData.fndFmly = data.fndFmly;
        this.formData.fndFmlyInfo = data.fndFmlyInfo;
        this.formData.presetRuleId = data.presetRuleId;
        this.formData.presetRuleKey = data.presetRuleKey;
        this.formData.assetGroups = data.assetGroups;
        this.formData.micAGSelectedFlag = data.micAGSelectedFlag;
        this.formData.global = data.global;
        // this.formData.name = data.name;
        /*Validate Step in Workflow*/
        this.workflowService.validateStep('step2', 'rule', valid);
    }

    setId(id: any) {
        this.formData.id = id;
    }

    setEmpGrpModified(value) {
      this.formData.isEmpGrpModified = value;
    }

    setIdForCopy(value) {
      this.formData.oldRuleIdForCopy = value;
    }

    setRuleName(name) {
      this.formData.name = name;
    }

    setStep3(data: any, valid: boolean) {
        /*Update the the Rule Step 1 data only when the the Rule Step 1 Form had been validated successfully*/
        this.formData.employeeAccounts = data.employeeAccounts;
        this.formData.employeeGroups = data.employeeGroups;
        this.formData.empIds = data.empIds;
        this.formData.subAccntNumbers = data.subAccntNumbers;
        this.formData.newSelectedRecords = data.newSelectedRecords;
        this.formData.removedExistingSelected = data.removedExistingSelected;
        this.formData.assignEmpIds = data.assignEmpIds;
        this.formData.assignSubAcctNumbers = data.assignSubAcctNumbers;
        this.formData.unAssignEmpIds = data.unAssignEmpIds;
        this.formData.unAssignSubAcctNumbers = data.unAssignSubAcctNumbers;
        this.formData.subAccntNewlySelected = data.subAccntNewlySelected;
        this.formData.removedExistingEmpId = data.removedExistingEmpId;
        this.formData.unassignOrgIds = data.unassignOrgIds;
        this.formData.assignOrgIds = data.assignOrgIds;
        this.formData.assignBU = data.assignBU;
        this.formData.unAssignBU = data.unAssignBU;
        /*Validate Personal Step in Workflow*/
        this.workflowService.validateStep('step3', 'rule', valid);
    }

    getFormData(): FormData {
        /*Return the entire Form Data*/
        return this.formData;
    }

    resetForm() {
        this.formData.clear();
        this.workflowService.resetSteps('rule');
    }

    isUserLoggedIn() {
        if (sessionStorage.length > 0 && sessionStorage.hasOwnProperty('ngx_login_session')) {
            return true;
        } else {
            this.router.navigate(['/']);
            return false;
        }
    }

    getLoginInfo() {
        return JSON.parse(sessionStorage.getItem('ngx_login_session'));
    }

    setFormData(formData) {
        this.formData.setData(formData);
    }

    setMode(mode) {
        this.formData.mode = mode;
    }

    validateAllSteps() {
        this.workflowService.validateAllSteps('rule');
    }

    editSetStep1(data: any, valid: boolean) {
        /*Update the the Rule Step 1 data only when the the Rule Step 1 Form had been validated successfully*/
        this.formData.name = data.ruleName;
        this.formData.rulesCategory = data.rulesCategory;
        this.formData.override = data.override;
        this.formData.justification = data.justification;
        this.formData.otherInput = data.otherInput;
        this.formData.globalRule = data.globalRule;
        /*Validate Personal Step in Workflow*/
        this.workflowService.validateStep('step1', 'editRule', valid);
    }

    setStep4(data: any, valid: boolean) {
       /*Validate Personal Step in Workflow*/
        this.workflowService.validateStep('step4', 'rule', valid);
    }
}
