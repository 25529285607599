<sdps-modal id='beta-client-central-modal' location="center" [sdpsTitle]="addLinkConstant.clientCentralNotes.title" [size]="'flex--medium'">

  <div slot="modal-body">
    <p class="adl-modal__heading">
      <span><strong>{{addLinkConstant.clientCentralNotes.requestID}} {{ requestId }}</strong></span>
      <span class="sdps-pull-right"><strong>{{addLinkConstant.clientCentralNotes.accountNo}} {{ accountNumber }}</strong></span>
   </p>

    <sdps-notification type="information" *ngIf="displayMessage" class="sdps-m-top_none">
      <p class="sdps-notification__text">{{displayMessage}}</p>
    </sdps-notification>

    <div class="adl-textarea cc-textarea sdps-m-bottom_large sdps-p-around_small notes" *ngIf="notesArr && notesArr.length > 0">
      <div *ngFor="let notes of notesArr; let i = index;">
        <strong>{{ notes.createdDateAsString }}</strong>
        <strong *ngIf="notes.createdBy && notes.createdBy !== ' '">, {{notes.createdBy}}</strong>
        <span>: {{ notes.note }}</span>
      </div>
    </div>
  </div>

  <div slot="modal-footer">
    <button class="buttons" type="reset" (click)="sharedService.closeModal('beta-client-central-modal')">Close</button>
  </div>

</sdps-modal>
