import {Directive, ElementRef} from '@angular/core';
import {NG_VALIDATORS} from '@angular/forms';

@Directive({
  selector: '[emsNumberPatterncheck]',
  providers: [
    {provide: NG_VALIDATORS, useExisting: NumberPatterncheckDirective, multi: true}
  ]
})
export class NumberPatterncheckDirective {

  newExp: any;

  constructor(public el: ElementRef) {
  }

  validate() {
    this.newExp = new RegExp('^[0-9]*$');
    const elementVal = this.el.nativeElement.value;
    if (this.newExp.test(elementVal)) {
      return null;
    } else {
      return {
        emsNumberOnlyPattern: false
      };
    }
  }
}
