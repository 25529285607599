import {EventEmitter, Inject, Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import { ConfigUrlService } from '@ems/shared';
import {Validators} from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class AddlinkService {

  public currentOrg: any = new BehaviorSubject(null);
  public userPolicy = new BehaviorSubject(null);
  public clientInformation: any = new BehaviorSubject(null);
  public userType = new BehaviorSubject(null);
  public clientOrgId: any;
  env: any;
  configURL = this.config.setApplicationUrl();
  showSchError = new EventEmitter<boolean>();
  schErrorMsg = new EventEmitter<string[]>();

  constructor(@Inject('env') public e, private httpService: HttpClient, public config: ConfigUrlService) {
    this.env = e;
  }

  setCurrentOrg(org) {
    this.clientOrgId = org;
  }

  retrieveOrgId() {
    return this.clientOrgId;
  }

  getEnvPath() {
    return this.env;
  }

  public setEnvironment(environment: any): void {
    this.e = environment;
  }

}
