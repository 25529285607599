export interface SecurityRestrict {
    code?: string;
    desc?: string;
}
export const DefaultSecurityRecords: SecurityRestrict[] = [{
    code: 'STOCK',
    desc: 'Stock'
},
{
    code: 'ETF',
    desc: 'ETF'
},
{
    code: 'MF',
    desc: 'Mutual Funds'
}];
export const AllSecurityRecords: SecurityRestrict = {
    code: 'ALL',
    desc: 'All'
};

