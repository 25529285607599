import {Component, OnInit, Input, Output, EventEmitter, ViewChild} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import * as temp from 'moment';
const moment = temp['default'];
import { emtrConstant } from '../../constants/emtrConstants';
import {EmtrDatepickerComponent} from '../emtr-datepicker/emtr-datepicker.component';

@Component({
  selector: 'tr-from-to-datepicker',
  templateUrl: './emtr-from-to-datepicker.component.html',
  styleUrls: ['./emtr-from-to-datepicker.component.scss']
})
export class EmtrFromToDatepickerComponent implements OnInit {

  @ViewChild('emtrDatepickerComponent', { static: true }) toDate: EmtrDatepickerComponent;

  @Input() calendarForm: UntypedFormGroup = this.fb.group({
    dateTo: this.fb.group({
      date: ['', Validators.required],
      time: ['', Validators.required],
      timeMeridian: ['', Validators.required]
    }),
    dateFrom: this.fb.group({
      date: ['', Validators.required],
      time: ['', Validators.required],
      timeMeridian: ['', Validators.required]
    }),
    datepickerDisabled: ''
  });
  @Output() throwDateError: EventEmitter<string> = new EventEmitter<string>();
  @Output() calendarValidation: EventEmitter<any> = new EventEmitter<any>();
  @Input() endDateLessThan: boolean;
  @Input() timeError: boolean;

  getConstant = emtrConstant;
  public dateErrCss: boolean;
  public timeErrCss: boolean;
  datepickerDisabled: any = false;
  public minDate: Date;
  public startTimeErrCss: any = false;
  public errorMessage = '';

  constructor(private fb: UntypedFormBuilder) {
    this.minDate = new Date(moment().tz('America/New_York').format('ddd MMM DD YYYY HH:mm:ss'));
  }

  ngOnInit() {
    this.disableToForm();
    this.onChanges();
    if (!this.calendarForm.get('dateFrom').get('time').value) {
      this.getCurrentTime(this.calendarForm.get('dateFrom'));
    }
  }

  calendarValidationEvent(value) {
    this.calendarValidation.emit(value);
  }

  /* To Disable and Enable the To Date*/
  disableToForm() {
    if (!this.calendarForm.get('datepickerDisabled').value) {
      this.calendarForm.get('dateTo').disable();
      this.calendarForm.get('dateTo').reset();
      this.toDate.clearErrorFlag();
    } else {
      this.calendarForm.get('dateTo').enable();
      if (!this.calendarForm.get('dateTo').get('time').value) {
        this.getCurrentTime(this.calendarForm.get('dateTo'));
      }
    }
  }

  /* To set the timepicker with the current time */
  getCurrentTime(form) {
    const time = moment().tz('America/New_York').format('hh:mm'); /* Convertion to Eastern Standard Time */
    const timeMeridian = moment().tz('America/New_York').format('a').toUpperCase();
    form.get('time').setValue(time);
    form.get('timeMeridian').setValue(timeMeridian);
  }

  onChanges(): void {
    this.calendarForm.valueChanges.subscribe(() => {
      this.endDateLessThan = (this.calendarForm.get('dateFrom').valid &&
        this.calendarForm.get('dateTo').valid) ? this.validateTime() : false;
      /* To display error message for invalid time entered */
      if (this.calendarForm.get('dateFrom').get('time').invalid || this.calendarForm.get('dateTo').get('time').invalid) {
        this.timeError = true;
        this.errorMessage = this.getConstant.rulesConstant.dateTimePicker.validTimeError;
      } else {
        this.timeError = false;
      }

      /* To display error message for End date/time and Start date/time entered */
      if (this.endDateLessThan) {
        if (this.calendarForm.get('dateFrom').get('date').value > this.calendarForm.get('dateTo').get('date').value) {
          this.errorMessage = this.getConstant.rulesConstant.dateTimePicker.endDateError;
          this.dateErrCss = true;
        } else {
          this.errorMessage = this.getConstant.rulesConstant.dateTimePicker.endtimeError;
          this.dateErrCss = false;
          this.timeErrCss = true;
        }
      } else {
        this.dateErrCss = false;
        this.timeErrCss = false;
        this.errorMessage = this.timeError ? this.errorMessage : '';
      }

      /*const currentDate = new Date(moment().tz('America/New_York').format('YYYY-MM-DDTHH:mm:ss'));
      if (this.calendarForm.get('dateFrom').get('date').value &&
          (currentDate.getTime() > this.setTime(this.calendarForm.get('dateFrom')).getTime())) {
        this.errorMessage = 'Start Time should be greater than current Time';
        this.startTimeErrCss = true;
      } else {
        this.startTimeErrCss = false;
      }*/
      this.throwDateError.emit(this.errorMessage);
    });
  }

  /* To validate the entered From and To Date */
  validateTime() {
    const dateFrom = moment(this.setTime(this.calendarForm.get('dateFrom')));
    const dateTo = moment(this.setTime(this.calendarForm.get('dateTo')));
    /* StartTime & EndTime checking whether lessthan 30 minutes */
    return (dateTo.diff(dateFrom, 'minutes') < 30);
  }

  setTime(form) {
    const date = form.get('date').value;
    const time = form.get('time').value;
    const timeMeridian = form.get('timeMeridian').value;
    let hours = time.split(':')[0];
    const mins = time.split(':')[1];
    if (timeMeridian === 'PM') {
      hours = hours < 12 ? parseInt(hours, 10) + 12 : hours;
    } else {
      hours = (hours === '12') ? '00:00:00' : hours;
    }
    const newDate = new Date(date);
    newDate.setHours(parseInt(hours, 10));
    newDate.setMinutes(parseInt(mins, 10));
    return newDate;
  }
}
