import { Directive, ElementRef, Input } from '@angular/core';
import { Validator, NG_VALIDATORS } from '@angular/forms';

@Directive({
  selector: '[emsTimePatternValidation]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: TimePatterncheckDirective, multi: true }
  ]
})
export class TimePatterncheckDirective implements Validator {
  newExp: any;
  constructor(public el: ElementRef) {
    this.el = el;
  }
  validate() {
    this.newExp = /^(([1][012]|0?[1-9]):[0-5][0-9])$/;
    if (this.el.nativeElement.querySelector('.p-inputtext')) {
      /* To read the input value from the timepicker component */
      const elementVal = this.el.nativeElement.querySelector('.p-inputtext').value;
      if (this.newExp.test(elementVal)) {
        return null;
      } else {
        return {
          appTimePatternValidation: false
        };
      }
    }
  }
}

