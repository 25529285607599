import { Component, OnInit } from '@angular/core';
import { ShellConstant } from '@shell_components/constants/shellConstants';

@Component({
  selector: 'app-custom-reports',
  templateUrl: './custom-reports.component.html',
  styleUrls: ['./custom-reports.component.scss']
})
export class CustomReportsComponent implements OnInit {

  shellConstant = ShellConstant;
  reportConst = this.shellConstant.workBenchChart;


  constructor() { }

  ngOnInit() {
  }

}
