<div [formGroup]="calendarForm" class="sdps-row" novalidate>
  <div class="col-m-6 sdps-p-around_none" formGroupName="dateFrom">
    <label class="sdps-p-left_small sdps-p-right_small sdps-pull-left">{{getConstant.requestListFilterFields.fromDateLabel}}
      <span
        *ngIf="errorFlag.isFromDateEmpty"
        class="sdps-text-market-red" [innerHTML]="commonConstants.asterix"></span></label>
    <addlink-addlink-datepicker #addlinkFromDatepicker class="sdps-p-around_none calender-width"
                                [maxDateValue]="maxDateValue" [readonlyInput]="readonlyInput" [minDate]="minDate"
                                [dateLabel]="getConstant.requestListFilterFields.fromDateLabel"
                                [disabled]="disabled" [emptyDateField]="errorFlag.isFromDateEmpty"
                                (calendarValidationEvent)="calendarValidationEvent($event)"
                                [dateForm]="calendarForm.get('dateFrom')"></addlink-addlink-datepicker>
  </div>
  <div class="col-m-6 sdps-p-around_none" formGroupName="dateTo">
    <label class="sdps-p-left_small sdps-p-right_small sdps-pull-left">{{getConstant.requestListFilterFields.toDateLabel}}
      <span
        *ngIf="errorFlag.isToDateEmpty"
        class="sdps-text-market-red" [innerHTML]="commonConstants.asterix"></span></label>
    <addlink-addlink-datepicker #addlinkToDatepicker class="sdps-p-around_none calender-width"
                                [maxDateValue]="maxDateValue" [minDate]="minDate" [readonlyInput]="readonlyInput"
                                [dateLabel]="getConstant.requestListFilterFields.toDateLabel"
                                [disabled]="disabled" [emptyDateField]="errorFlag.isToDateEmpty"
                                (calendarValidationEvent)="calendarValidationEvent($event)"
                                [dateForm]="calendarForm.get('dateTo')"
                                [dateRangeError]="errorFlag.isDateRangeError"
                                [endDateLessThanFromDate]="errorFlag.isEndDateLessThanFromDate"></addlink-addlink-datepicker>
  </div>
</div>
