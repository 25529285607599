import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import { CommonService } from '../../../services/common.service';
import { AddlinkConstant } from '../../../../constants/addlinkConstants';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {SharedService} from '@ems/shared';

@Component({
  selector: 'addlink-add-authorized-agent-modal',
  templateUrl: './add-authorized-agent-modal.component.html',
  styleUrls: ['./add-authorized-agent-modal.component.scss']
})
export class AddAuthorizedAgentModalComponent implements OnInit {

  public title: string;
  public isModalOpen: boolean;
  public orgId: string;
  public authAgentForm: UntypedFormGroup;
  getConstant = AddlinkConstant;
  showCharLimit: any;

  constructor(private commonService: CommonService, private formBuilder: UntypedFormBuilder, public sharedService: SharedService, @Inject('commonConstants') public commonConstants) {
  }

  @Output() getAuthagents = new EventEmitter<any>();

  authAgentCreate() {
    this.authAgentForm = this.formBuilder.group({
      authAgentFirstName: ['', Validators.required],
      authAgentLastName: ['', Validators.required],
    });
  }

  ngOnInit() {
    this.authAgentCreate();
    this.title = 'Add Authorized Agent';
  }

  /* Opens The Modal */
  public openModal(e: Event, orgId: string): void {
    e.stopPropagation();
    this.authAgentCreate();
    this.orgId = orgId;
    this.sharedService.openModal('auth-agent-modal-id');
  }

  /* Closes The Modal */
  public closeModal(): void {
    this.authAgentForm.reset();
    this.sharedService.closeModal('auth-agent-modal-id');
  }

  public addAuthAgent(): void {

    const formInfo = this.authAgentForm.value;

    this.commonService.saveAuthorizedAgent(this.orgId, formInfo.authAgentFirstName, formInfo.authAgentLastName).subscribe(
      response => {
        if (response.message === 'AuthAgent is saved successfully') {
          this.closeModal();
          this.getAuthagents.emit();
          this.authAgentCreate();
        }
      }, error => {
        const errorMsg: string = error.error.errorResponse.errorMessage;

        if (errorMsg.startsWith('AuthAgent already exists')) {
          this.sharedService.sendErrorMessage('');
          this.authAgentForm.setErrors({ errorMessage: errorMsg });
        }
      });
  }
}
