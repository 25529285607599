import {Component, OnInit} from '@angular/core';
import {SharedService} from '@ems/shared';
import {entitlementConst} from '@shell_components/constants/entitlementConstants';
import {ShellConstant} from '@shell_components/constants/shellConstants';
import {Router} from '@angular/router';

@ Component({
  selector: 'app-admin-menu',
  templateUrl: './admin-menu.component.html',
  styleUrls: ['./admin-menu.component.scss']
})
export class AdminMenuComponent implements OnInit {
  adminMenuSubMenu: any;
  clientInfo: any;
  entitlementConst = entitlementConst;
  shellConstant = ShellConstant;

  constructor(public sharedService: SharedService, public router: Router) {
    /* Navigate to respective page based on the selected client access */
    this.sharedService.selectedClientInfo.subscribe(clientInfo => {
      this.setNavigation();
    });
  }

  ngOnInit() {
    this.setNavigation();
  }

  setNavigation() {
    this.adminMenuSubMenu = [
      {
        id: 1,
        name: 'Manage Clients',
        route: 'manage-clients',
        accessName: 'manageClients',
        showLink: this.sharedService.checkEntitlements(entitlementConst.navUUID.manageClient.uuid, '', '')
      },
      {
        id: 2,
        name: 'Manage Users',
        route: 'manage-users',
        accessName: 'manageUsers',
        showLink: this.sharedService.checkEntitlements(entitlementConst.navUUID.manageUser.uuid, '', '')
      }/*,
      {
        id: 3,
        name: 'Manage Roles',
        route: 'manage-roles',
        showLink: this.sharedService.checkEntitlements(entitlementConst.navUUID.adminMenu.subMenu.manageRolesAccess)
      }*/
    ];
  }
}
