export interface UploadsListTable {
  field?: string;
  header?: string;
  enum?: string;
}
export const UploadsListTableHeader: UploadsListTable[] = [
  { field: 'uploadId', header: 'Upload ID', enum: 'textbox' },
  { field: 'clientName', header: 'Client Name', enum: ''},
  { field: 'fileName', header: 'File Name', enum: 'textbox' },
  { field: 'uploadType', header: 'Upload Type', enum: 'dropdown' },
  { field: 'status', header: 'Status', enum: 'multiselect' },
  { field: 'submittedDate', header: 'Submitted Date', enum: 'dateRange' },
  { field: 'submittedBy', header: 'Submitted By', enum: 'textbox' },
  { field: 'action', header: 'Actions', enum: '' }
];
