import {Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {emtrConstant} from '../../constants/emtrConstants';
import {FormDataService, WorkflowService, SharedService, SharedConstant} from '@ems/shared';
import {RuleService} from '../../services/rule-service/rule.service';

@Component({
    selector: 'tr-create-rule',
    templateUrl: './create-rule.component.html'
})
export class CreateRuleComponent implements OnInit, OnDestroy {

    items: any = [];
    editRuleData: any;
    getConstant = emtrConstant;
    schErrorMsg: string[];
    showSchError = false;
    showRequestError = false;
    toastMsg: string;
    toastType: string;
    sharedConstant = SharedConstant;
    @ViewChild('toastMessage') msgToast: any;

    constructor(public route: ActivatedRoute, public formDataService: FormDataService, public workflowService: WorkflowService, public sharedService: SharedService, public ruleService: RuleService,
                @Inject('entitlementConst') public entitlementConst) {
        this.sharedService.clearErrorMessage();
        this.editRuleData = this.formDataService.getFormData();
        this.items = this.workflowService.getWorkflowSteps('rule');
        this.route.data.subscribe(data => {
            this.sharedService.clearErrorMessage();
        });

      /*this.route.data.subscribe(data => {
          const changeData = this.workflow[data.workflow];
          if (this.editRuleData.mode === this.getConstant.rulesLanding.restriction) {
              changeData[1].name = this.getConstant.editRuleStepIndicator.step2Restriction;
          } else if (this.editRuleData.mode === this.getConstant.rulesLanding.override) {
              changeData[1].name = this.getConstant.editRuleStepIndicator.step2Override;
          } else {
              changeData[1].name = this.getConstant.editRuleStepIndicator.step2Recurrence;
          }
          this.items = changeData;
      });*/
    }

  ngOnInit() {
    this.sharedService.disableClientSelector.next(true);
    this.ruleService.schErrorMsg.subscribe(value => {
      this.schErrorMsg = value;
    });

    this.ruleService.showSchError.subscribe(value => {
      this.showSchError = value;
    });

    this.ruleService.showRequestIdError.subscribe(value => {
      this.showRequestError = value;
    });

    this.ruleService.toastType.subscribe(value => {
      this.toastType = value ? value : '';
    });

    this.ruleService.toastMsg.subscribe(value => {
      this.toastMsg = value ? value : '';
      if (this.toastMsg) {
        setTimeout(() => {
          if (this.toastMsg !== '' && this.toastMsg !== undefined && this.msgToast) {
            this.msgToast.pushMessage(this.toastType, this.toastMsg);
          }
        }, 1);
      }
    });
  }

  ngOnDestroy() {
      this.sharedService.disableClientSelector.next(this.sharedService.ClientCount === this.sharedConstant.clientCount);
  }
}
