<ems-confirm-message #confirmationMessage></ems-confirm-message>

<div class="heading-font sdps-p-bottom_small sdps-display-inline"
     [innerHTML]="getSharedConstant[businessUnit].step2IndicatorName"></div>

<ul class="sdps-pull-right sdps-list--inline rule-info">
  <li>
    <span [innerHTML]="getSharedConstant[businessUnit].name+': '"></span>
    <strong [innerHTML]="addEmployeeFormData.name"></strong>
  </li>
</ul>

<p class="sdps-clearfix no-margin"></p>
<button type="button"#uploadList class="buttons sdps-pull-left sdps-m-top_small sdps-m-left_small"
        *ngIf="this.sharedService.groupType !== getSharedConstant.manageMasterGroups.groupTypeMGP"
        (click)="uploadEmployeeList()">{{getSharedConstant[businessUnit].uploadEmployeeOrParticipantList}}
</button>

<ems-shared-manage-employee-accounts [assignFormData]='addEmployeeFormData.employeeGroupAssignInfo' [getOrgId]='orgId'
                                     *ngIf="this.sharedService.groupType !== getSharedConstant.manageMasterGroups.groupTypeMGP"
                                     [empAccformData]="addEmployeeFormData.employeeGroupAssignInfo.employeeAccounts"
                                     [editGroupData]='addEmployeeFormData' [moduleType]="loadedModuleType"
                                     (selectedAccounts)="updateSelectedAccountsArray($event)"
                                     (selectedEmployeeAccounts)="updateSelectedArray($event)"
                                     (newlySelectedEmployeeAccounts)="updateNewlySelectedArray($event)"
                                     [showColumns]=false></ems-shared-manage-employee-accounts>

<tr-client-selection-table *ngIf="this.sharedService.groupType === getSharedConstant.manageMasterGroups.groupTypeMGP"
                           [assignMasterAcctFormData]='addEmployeeFormData' [moduleType]="loadedModuleType"
                           (newlySelectedMasterAccounts)="updateNewlySelectedArray($event)"
                           (selectedMasterAccounts)="updateSelectedArray($event)"></tr-client-selection-table>

<ems-step-wizard-buttons [showCancelBtn]=true (closeBtn)="cancelCreateEmpGroup()" [showContinueBtn]=true
                         [disableBtn]="sharedService.groupType === getSharedConstant.manageMasterGroups.groupTypeMGP ? this.selectedArr.length === 0 : false"
                         (goToNext)="confirmationPopup()" [showPreviousBtn]=true (goToPrevious)="goToPreviousStep()">
</ems-step-wizard-buttons>
