import {Inject, Injectable} from '@angular/core';
import {Observable, ReplaySubject} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {SharedConstant} from '../../constants/sharedConstants';
import {SharedService} from '../../shared.service';
import { ConfigUrlService } from '../config-url/config-url.service';


@Injectable({
  providedIn: 'root'
})

export class ManageEmpGrpService {

  sharedConstant = SharedConstant;
  orgId: string;
  empCount: any;
  businessUnit: any;
  empAccCount: any;
  private subject = new ReplaySubject<any>();
  configURL = this.config.setApplicationUrl();
  clientId: string;

  constructor(private httpService: HttpClient, @Inject('env') public env, public sharedService: SharedService, public config: ConfigUrlService) {
    this.sharedService.selectedClientInfo.subscribe(clientInfo => {
      this.orgId = clientInfo && clientInfo.orgId ? clientInfo.orgId : 0;
      this.businessUnit = clientInfo && clientInfo.businessUnit;
    });
  }

  checkEmployeeGroupNameExists(ruleName, orgId, planId, type): Observable<any> {
    this.sharedService.decodeUrl = false;
    let schwabClientHeader = [];
    const ruleNameNew = encodeURIComponent(`${ruleName}`);
    if (this.sharedService.groupType === this.sharedConstant.manageMasterGroups.groupTypeMGP) {
      return this.httpService.get(this.configURL.bffGroupEndpointV1 + '&name=' + ruleNameNew + '&type=' + type + '&Schwab-Client-Ids= ' + schwabClientHeader);
    } else {
      schwabClientHeader = this.sharedService.setSchwabClientHeaders(this.sharedService.applicationInfo.data.queryParamMap.orgId, orgId, schwabClientHeader);
      return this.httpService.get(this.configURL.bffGroupEndpointV1 + '&name=' + ruleNameNew + '&planId=' + planId + '&Schwab-Client-Ids= ' + schwabClientHeader);
    }
  }

  saveEmpGroup(empGrpObj): Observable<any> {
    let schwabClientHeader = [];
    schwabClientHeader = this.sharedService.setSchwabClientHeaders(this.sharedService.applicationInfo.data.queryParamMap.orgId, this.orgId, schwabClientHeader);
    if (empGrpObj.groupData && empGrpObj.groupData.id && empGrpObj.active) {
      const url = this.configURL.bffGroupEndpointV1 + '&empGroupId=' + empGrpObj.groupData.id + '&active=' + true;
      if (this.sharedService.groupType === this.sharedConstant.manageMasterGroups.groupTypeMGP) {
        return this.httpService.put(url, '', {observe: 'response'});
      } else {
        return this.httpService.put(url + '&Schwab-Client-Ids= ' + schwabClientHeader, '', {observe: 'response'});
      }
    } else if (empGrpObj.id) {
      if (this.sharedService.groupType === this.sharedConstant.manageMasterGroups.groupTypeMGP) {
        const url = this.configURL.bffGroupEndpointV1;
        return this.httpService.put(url, empGrpObj, { observe: 'response' });
      } else {
        const url = this.configURL.bffGroupEndpointV1 + '&Schwab-Client-Ids= ' + schwabClientHeader;
        return this.httpService.put(url, empGrpObj, { observe: 'response' });
      }
    } else {
      if (this.sharedService.groupType === this.sharedConstant.manageMasterGroups.groupTypeMGP) {
        const url = this.configURL.bffGroupEndpointV1;
        return this.httpService.post(url, empGrpObj);
      } else {
        const url = this.configURL.bffGroupEndpointV1 + '&Schwab-Client-Ids= ' + schwabClientHeader;
        return this.httpService.post(url, empGrpObj);
      }
    }
  }

  getEmployeeGrpList(pageObj): Observable<any> {
    let schwabClientHeader = [];
    schwabClientHeader = this.sharedService.setSchwabClientHeaders(this.sharedService.applicationInfo.data.queryParamMap.clientId, this.clientId, schwabClientHeader);
    const sortField = (pageObj.sortField) ? pageObj.sortField.map(data => '&sortField=' + data).join('') : '';
    if (this.sharedService.groupType === this.sharedConstant.manageMasterGroups.groupTypeMGP) {
      return this.httpService.get(this.configURL.empGroupEndPoint + '/orgId/' + this.orgId + '?pageNumber=' + pageObj.pageNo +
      '&pageSize=' + pageObj.pageSize + sortField + '&Schwab-Client-Ids= ' + schwabClientHeader);
     } else {
      schwabClientHeader = this.sharedService.setSchwabClientHeaders(this.sharedService.applicationInfo.data.queryParamMap.orgId, this.orgId, schwabClientHeader);
      return this.httpService.get(this.configURL.empGroupEndPoint + '/orgId/' + this.orgId + '?pageNumber=' + pageObj.pageNo +
      '&pageSize=' + pageObj.pageSize + sortField + '&Schwab-Client-Ids= ' + schwabClientHeader);
     }
  }

  sendMessage(message: any, type: any) {
    if (type === 'save') {
      const bu = this.sharedService.groupType === this.sharedConstant.manageMasterGroups.groupTypeMGP ? this.sharedConstant.manageMasterGroups.groupTypeMGP : this.businessUnit;
      this.subject.next({text: message + this.sharedConstant[bu].empGrpOrPlanSavedMsg});
    } else {
      this.subject.next({text: message});
    }
  }

  getMessage(): Observable<any> {
    return this.subject.asObservable();
  }

  clearMessage() {
    this.subject.next({text: ''});
  }

  validatePlanId(orgId, ruleName, planId): Observable<any> {
    this.sharedService.decodeUrl = false;
    let schwabClientHeader = [];
    const ruleNew = encodeURIComponent(`${ruleName}`);
    schwabClientHeader = this.sharedService.setSchwabClientHeaders(this.sharedService.applicationInfo.data.queryParamMap.orgId, this.orgId, schwabClientHeader);
    if (planId) {
      if (this.sharedService.groupType === this.sharedConstant.manageMasterGroups.groupTypeMGP) {
        return this.httpService.get(this.configURL.bffGroupEndpointV1 + '&name=' + ruleNew + '&planId=' + planId);
      } else {
        return this.httpService.get(this.configURL.bffGroupEndpointV1 + '&name=' + ruleNew + '&planId=' + planId + '&Schwab-Client-Ids= ' + schwabClientHeader);
      }

    } else {
      if (this.sharedService.groupType === this.sharedConstant.manageMasterGroups.groupTypeMGP) {
        return this.httpService.get(this.configURL.bffGroupEndpointV1 + '&name=' + ruleNew);

      } else {
        return this.httpService.get(this.configURL.bffGroupEndpointV1 + '&name=' + ruleNew + '&Schwab-Client-Ids= ' + schwabClientHeader);
      }
    }
  }

  getEmployeeGrpOrPlanList(pageObj, filterObj): Observable<any> {
    const empGrpPlanFilterInfo = filterObj;
    let schwabClientHeader = [];
    const sortField = (pageObj.sortField) ? pageObj.sortField.map(data => '&sortField=' + data).join('') : '';
    if (this.sharedService.groupType === this.sharedConstant.manageMasterGroups.groupTypeMGP) {
      const url = this.configURL.bffGroupSummaryEndpointV1 + '&pageNumber=' + pageObj.pageNo + '&pageSize=' + pageObj.pageSize + sortField;
      return this.httpService.post(url, empGrpPlanFilterInfo);
    } else {
      empGrpPlanFilterInfo.orgId = this.orgId;
      schwabClientHeader = this.sharedService.setSchwabClientHeaders(this.sharedService.applicationInfo.data.queryParamMap.orgId, this.orgId, schwabClientHeader);
      const url = this.configURL.bffGroupSummaryEndpointV1 + '&pageNumber=' + pageObj.pageNo + '&pageSize=' + pageObj.pageSize + sortField + '&Schwab-Client-Ids= ' + schwabClientHeader;
      return this.httpService.post(url, empGrpPlanFilterInfo);
    }
  }
}
