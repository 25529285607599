<div>
  <sdps-modal id='view-symbol-modal' [sdpsTitle]="modalTitle" [size]="'flex--large'"
             (close)="closeViewSymbolModal()" [backDropCloseDisabled]="true">
    <div slot="modal-body">
      <div class="view-history-container modal-container">
        <ems-table-record-count [count]="totalRecords" (emitter)="tt.reset();receiveDataFromChild($event)"
                                [showHeaderIcons]="false"></ems-table-record-count>
        <div class="sdps-m-around_none">
          <div>
            <p-table #tt [columns]="colHeader" [value]="tickerSymbolData" [sortOrder]=1 sortMode="multiple"
                     [rows]="noOfRecords" [paginator]="true" [totalRecords]="totalRecords"
                     class="common-data-table">
              <ng-template pTemplate="header" let-columns>
                <tr>
                  <th *ngFor="let col of columns" [pSortableColumn]="col.field"
                      [ngClass]="col.field === 'tickerSymbol' ? 'search-text-highlight' : null">
                    {{col.header}}
                    <p-sortIcon [field]="col.field" [attr.arialabel]="commonConstants.sorting.sortText"
                                [attr.ariaLabelDesc]="commonConstants.sorting.descText"
                                [attr.ariaLabelAsc]="commonConstants.sorting.ascText"></p-sortIcon>
                  </th>
                </tr>
              </ng-template>

              <ng-template pTemplate="emptymessage" let-columns>
                <tr>
                  <td [attr.colspan]="columns.length + 1" class="sdps-text-center sdps-font-weight-bold"
                      [innerHTML]="getEmtrConstants.rulesConstant.autoComplete.noRecords"></td>
                </tr>
              </ng-template>

              <ng-template pTemplate="body" let-rowData let-columns="columns">
                <tr class="table-checkbox-selection" [pSelectableRow]="rowData">
                  <td *ngFor="let col of columns">
                    <span *ngIf="col.field === 'name'">{{rowData[col.field] | titlecase}}</span>
                    <span *ngIf="col.field !== 'name'">{{rowData[col.field]}}</span>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>
      </div>
    </div>

    <!-- Footer Buttons -->
    <div slot="modal-footer">
      <div class="step-action-buttons sdps-pull-left">
        <button class="buttons" type="button" (click)="closeViewSymbolModal(); sharedService.closeModal('view-symbol-modal')" [innerHTML]="getEmtrConstants.rulesConstant.ruleGlobalConst.cancel"></button>
      </div>
    </div>
  </sdps-modal>
</div>
